import React, { Component } from 'react';
import Layout from '../Layout';
import { connect } from "react-redux";
import { startCase } from 'lodash';

import withAuth from "../../lib/auth";
import axios from "../../axios";
import moment from "moment";

import StepsBar from './StepsBar';
import Upload from './Upload';
import Match from './Match';
import Review from './Review';
import Complete from './Complete';
import { toast } from 'react-toastify';
import CSVUploadModal from '../modal/BulkCSVUploadModal';
import { checkRequired } from '../../utils';
import { Beforeunload } from 'react-beforeunload';
import { clearCsvData } from '../../actions';

class UploadCSV extends Component {
	constructor(props) {
		super(props);
		let steps = [
			{ count: 1, name: 'Upload' },
			{ count: 2, name: 'Match' },
			{ count: 3, name: 'Review' },
			{ count: 4, name: 'Complete' }
		]
		this.state = {
			uploadErrorObject:[],
			loading: false,
			showUploadModal: false,
			currentStep: steps[0],
			steps: steps,
			fieldMappings: [],
			reservationData: [],
			responseMappings: []
		}
		this.requiredFields = ["external_booking_id", "first_name", "last_name", "phone_number", "email_address", "no_of_adult", "arrival_date", "departure_date", "nationality"];
		this.fields = ["external_booking_id", "title", "first_name", "last_name", "phone_number", "email_address", "room_type", "room_rate", "rate_code_description", "room_number", "no_of_adult", "no_of_child", "arrival_date", "departure_date", "currency_code", "deposit_amount", "nationality"];
		this.fieldOptions = this.fields.map(col => ({ value: col, label: startCase(col) }));
		this.customStyles = {
			header: { style: { minHeight: '40px' } },
			headRow: { style: { borderTopStyle: 'solid', borderTopWidth: '1px', borderTopColor: 'rgba(0, 0, 0, 0.1)', minHeight: '40px' } },
			headCells: { style: { '&:not(:last-of-type)': { borderRightStyle: 'solid', borderRightWidth: '1px', borderRightColor: 'rgba(0, 0, 0, 0.1)', minHeight: '40px' } } },
			cells: { style: { '&:not(:last-of-type)': { borderRightStyle: 'solid', borderRightWidth: '1px', borderRightColor: 'rgba(0, 0, 0, 0.1)', minHeight: '40px' } } }
		};
	}

	componentDidMount() {
		this.fetchCSVMappings();
	}

	setStep = (step_count) => this.setState({ ...this.state, currentStep: this.state.steps[step_count - 1] })

	// -------------------------------------------------- Upload Modal --------------------------------------------------
	toggleUploadModal = () => this.setState({ ...this.state, showUploadModal: !this.state.showUploadModal })

	onDropComplete = () => {
		this.setState({
			...this.state,
			showUploadModal: false,
			currentStep: this.state.steps[0]
		}, this.mapCSVDataToState);
	}

	// -------------------------------------------------- Mappings --------------------------------------------------
	fetchCSVMappings = () => {
		let { csvReservationData } = this.props;
		let responseMappings = [];
		if (csvReservationData) {
			this.setState({ ...this.state, loading: true });
			axios('/dashboard/hotel/csv/mapping')
				.then(res => {
					if (res.status == 200) {
						let { status, data } = res.data;
						console.log('mapping response', data);
						if (status) { responseMappings = data }
					} else { console.log(res) }
				})
				.catch(console.error)
				.finally(() => {
					this.setState({ responseMappings, loading: false }, this.mapCSVDataToState);
				});
		} else {
			this.toggleUploadModal();
		}
	}

	mapCSVDataToState = () => {
		let { csvReservationData } = this.props;
		let { responseMappings } = this.state;
		if (csvReservationData) {
			let { headers, data } = csvReservationData;
			let fieldMappings = headers.map(h => {
				let check_header_key = h.toString().toLowerCase().replace(/\s/g, '_');
				let confirmed = false, old_key = h, new_key = "", ignored = false, matched = (this.fields.includes(check_header_key));
				if (matched) { new_key = check_header_key; confirmed = true; }

				let returnObj = { confirmed, new_key, old_key, matched, ignored };
				// check if mapping is already saved
				let savedMap = responseMappings.find(map => map.old_key === old_key);
				if (savedMap) {
					returnObj = { ...returnObj, ...savedMap };
				} else {
					console.log(`No mappings found for ${old_key}`);
				}
				return returnObj;
			});
			this.setState({ ...this.state, reservationData: data, fieldMappings });
		}
	}

	setField = (field, index) => {
		let { fieldMappings } = this.state;
		fieldMappings[index] = field;
		this.setState({ ...this.state, fieldMappings });
	}

	// -------------------------------------------------- Reservations --------------------------------------------------
	saveReservation = (reservationData) => {
		this.setState({ ...this.state, loading: true });
		let { fieldMappings } = this.state;
		let ignored_fields = fieldMappings.filter(f => f.ignored);
		console.log('ignored_fields', ignored_fields);
		let newReservationData = [...reservationData.map(r => ({ ...r }))];

		newReservationData = newReservationData.map(row => {
			if ('arrival_date' in row)
				row.arrival_date = moment(row.arrival_date).format('YYYY-MM-DD');
			if ("departure_date" in row)
				row.departure_date = moment(row.departure_date).format('YYYY-MM-DD');
			if("id" in row)
				delete row.id;
			if("show" in row)
				delete row.show;

			// delete ignored keys from row
			for (let i = 0; i < ignored_fields.length; i++) {
				const field = ignored_fields[i];
				delete row[field.old_key];
			}

			return ({ ...row, invalid: checkRequired(row) });
		})

		let validated = newReservationData.every(r => (r.invalid && Array.isArray(r.invalid) && r.invalid.length === 0));
		if (!validated) {
			toast.error('Some rows contain invalid data');
			console.log(newReservationData.filter(r => (r.invalid && Array.isArray(r.invalid) && r.invalid.length)));
			this.setState({ ...this.state, loading: false });
			return;
		}

		let postData = {
			due_in: newReservationData.map(row => {
				delete row.invalid;
				return ({ reservation_detail: row, guest_profiles: [] })
			})
		}

		if (process.env.NODE_ENV === "development") {
			console.log('postData', postData);
			// this.setState({ ...this.state, loading: false });
			// return;
		}

		axios.post('/dashboard/reservations', postData)
			.then((response) => {
				if (response.status == 200) {
					let api_response = response.data
					let { status, message,data } = api_response;
					if (status) {
						this.setState({uploadErrorObject:data})
						toast.success(message);
						this.props.dispatch(clearCsvData());
						this.setStep(4);
					} else {
						toast.error(message);
					}
				} else {
					toast.error(response.data.message);
				}
			})
			.catch(err => {
				console.error(err);
				toast.error(err.message);
			})
			.finally(() => this.setState({ ...this.state, loading: false }));
	}

	mapReservationFields = () => {
		this.postFieldMappings();
		let { fieldMappings, reservationData, currentStep } = this.state;

		fieldMappings = fieldMappings.filter(field => (field.confirmed && (field.old_key !== field.new_key)));
		// console.log('fields to be mapped', fieldMappings);
		// console.log('original reservation data', reservationData);

		reservationData = reservationData.map(d => {
			fieldMappings.forEach(field => {
				let { old_key, new_key } = field;
				d[new_key] = d[old_key];
				delete d[old_key];
			})
			d.invalid = checkRequired(d);
			d.phone_number = (d.phone_number.indexOf('E+') !== -1 || d.phone_number.indexOf('e+') !== -1) ? Number(d.phone_number) : d.phone_number;
			return d;
		})

		// Sort reservation to show invalid fields first
		reservationData.sort((a, b) => (b.invalid.length - a.invalid.length));

		this.setState({ ...this.state, reservationData, currentStep: this.state.steps[currentStep.count] });
	}

	postFieldMappings = () => {
		let { fieldMappings } = this.state;
		let postData = { field_mappings: fieldMappings }
		axios.post('/dashboard/hotel/csv/mapping', postData)
			.then(res => {
				if (res.status == 200) {
					console.log(res.data);
				} else { console.log(res) }
			})
			.catch(console.error);
	}

	render() {
		let { currentStep } = this.state;
		return (
			<Layout isSignUP dashboardBtn>
				{process.env.NODE_ENV !== "development" ? <Beforeunload onBeforeunload={event => event.preventDefault()} /> : null}
				<div className="customContainer content">
					<StepsBar steps={this.state.steps} currentStep={this.state.currentStep} />
					{currentStep.count === 1
						? <Upload
							customStyles={this.customStyles}
							setStep={this.setStep}
							toggleUploadModal={this.toggleUploadModal}
						/>
						: null
					}
					{currentStep.count === 2
						? <Match
							loading={this.state.loading}
							setField={this.setField}
							mapReservationFields={this.mapReservationFields}
							fieldOptions={this.fieldOptions}
							requiredFields={this.requiredFields}
							reservationData={this.state.reservationData}
							fieldMappings={this.state.fieldMappings}
						/>
						: null
					}
					{currentStep.count === 3
						? <Review
							reservationData={this.state.reservationData}
							customStyles={this.customStyles}
							requiredFields={this.requiredFields}
							loading={this.state.loading}
							saveReservation={this.saveReservation}
						/>
						: null
					}
					{currentStep.count === 4
						? <Complete
							reservationCount={this.state.reservationData.length}
							toggleUploadModal={this.toggleUploadModal}
							uploadErrorObject={this.state.uploadErrorObject}
						/>
						: null
					}
					<CSVUploadModal
						showModal={this.state.showUploadModal}
						onDropComplete={this.onDropComplete}
						onHide={this.toggleUploadModal}
					/>
				</div>
			</Layout>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		csvReservationData: state.csvReservationData,
		hotel: state.hotel
	};
};

export default connect(mapStateToProps)(withAuth(UploadCSV));