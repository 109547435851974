import React from "react";
import FormElements from "../components/formElements/FormElements";
import { connect } from "react-redux";
import { loginUser } from "../actions";
import withAuth from "../lib/auth";
import { withRouter, Link } from 'react-router-dom';
import { checkValidation } from "../lib/formValidation";


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationData: {
        email: "",
        login_password: "",
        formErrors: {
          email: "",
          login_password: "",
        },
      },
      loginBtnText: "Login"
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading) {
      if (nextProps.loading) {
        this.setState({ loginBtnText: "Loading.." });
      } else {
        this.setState({ loginBtnText: "Login" });
      }
    }
  }


  formValid = ({ formErrors, ...rest }) => {
    let valid = true;
    Object.entries(formErrors).forEach(([key, value]) => {
      if (value.length > 0) {
        return (valid = false);
      }
    });

    // if (valid) {
      Object.entries(rest).forEach(([key, value], index) => {
        if (value.length <= 0) {
          this.updateValidFormState(key, value);
          return (valid = false);
        }
      });
    // }
    return valid;
  };

  updateValidFormState = (name, value) => {
    let formErrors = this.state.validationData.formErrors;
    let formValidationData = checkValidation(name, value, formErrors);
    this.setState({
      ...this.state,
      validationData: {
        ...this.state.validationData,
        formErrors: formValidationData,
        [name]: value,
      },
    });
  };

  handleBlur =(e)=>{
    let { name, value } = e.target;
    this.updateValidFormState(name, value);
  }


  onChangeHandle = (e) => {
    let { name, value } = e.target;
    // this.updateValidFormState(name, value);
    this.setState({
      ...this.state,
      validationData: {
        ...this.state.validationData,
        [name]: value,
      },
    });
  };

  userLoingHandle = (e) => {
    e.preventDefault();
    if (this.formValid(this.state.validationData)) {
      let { email, login_password } = this.state.validationData;
      this.props.dispatch(loginUser(email, login_password, this.props.history));
    }
  }

  render() {
    let _state = this.state;
    let formErrors = this.state.validationData.formErrors;
    return (
      <div className="login_container height_100">
        <div className="clearfix d-flex align-items-center height_100">
          <div className="height_100 loginForm">
            <div className="clearfix width_100 fomrWrapMax">
              <div className="thru-logo">
                <img src="/images/logo.png" alt="thru logo" />
              </div>
              <div className="clearfix">
                <div className="login_heading">
                  <h1>Login to your Thru Account</h1>
                </div>
                <form
                  autoComplete="off"
                  method="post"
                  onSubmit={this.userLoingHandle}
                >
                  <div className="floating-form">
                    <FormElements
                      type="email"
                      defaultValue={this.state.email}
                      label="Email"
                      name="email"
                      value={_state.email}
                      errorMessage={formErrors.email}
                      onChange={this.onChangeHandle}
                      onBlur ={this.handleBlur}
                    />

                    <FormElements
                      type="password"
                      defaultValue={this.state.login_password}
                      name="login_password"
                      label="Password"
                      errorMessage={formErrors.login_password}
                      value={_state.password}
                      onChange={this.onChangeHandle}
                      onBlur ={this.handleBlur}
                    />
                  </div>
                  <div>
                    {/* <div className="rememberButton clearfix">
                      <label className="wrapperRadio">
                        Remember me
                          <input type="checkbox" name="remember_me" />
                        <span className="checkmark"></span>
                      </label>
                    </div> */}
                    <div className="clearfix forgotPassword" style={{ cursor: "pointer" }}>
                      <Link to={"/forgotpassword"}>
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="formSubmitButton">
                    <button
                      type="submit"
                      disabled={
                        this.state.loginBtnText == "Login" ? false : true
                      }
                      className="themeButton"
                    >
                      {this.state.loginBtnText}
                    </button>
                    <button
                      type="button"
                      className="signUpAction">
                      <Link to={"/signup"}>
                        Sign Up
                        </Link>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className=""></div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return state;
}

export default connect(mapState)(withAuth(withRouter(Login)));
