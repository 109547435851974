import { constants } from '../constants';
import { getStorage } from '../utils';

const getInitialState = () => {
  let state = getStorage('state', 'object', false);
  return {
    loading: state && state.loading ? state.loading : false,
    user: state && state.user ? state.user : {},
    is_admin: state && state.is_admin ? state.is_admin : false,
    isLoggedIn: state && state.isLoggedIn ? state.isLoggedIn : false,
    reservationData: state && state.reservationData ? state.reservationData : [],
    hotel: state && state.hotel ? state.hotel : false, 
    signUpData: state && state.signUpData ? state.signUpData : {},
    errorObj: state && state.errorObj ? state.errorObj : {},
    loadingVerify: state && state.loadingVerify ? state.loadingVerify : false,
    checkinUserStatus: state && state.checkinUserStatus ? state.checkinUserStatus : false,
    dashboardFilter: state && state.dashboardFilter ? state.dashboardFilter : false,
    currentUserAttributes: state && state.currentUserAttributes ? state.currentUserAttributes : false,
    csvReservationData: process.env.NODE_ENV === "development" ? (state && state.csvReservationData ? state.csvReservationData : false) : false,
    balance: false,
    guestDetails: state && state.guestDetails ? state.guestDetails : false,
    hotelIntegration:state && state.hotelIntegration ? state.hotelIntegration : false
  }
}

export const initialState = getInitialState();

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SET_KEY:
      return {
        ...state,
        [action.key]: action.value,
      };

    case constants.CLEAR_STATE:
      let newState = getInitialState();
      return newState;

    case constants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };

    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.data,
        loading: false,
        isLoggedIn: true
      };

    case constants.LOGIN_FAILED:
      return {
        ...state,
        user: {},
        loading: false,
        isLoggedIn: false
      };

    case constants.LOGOUT_REQUEST:
      return {
        ...state,
      };

    case constants.LOGOUT_SUCCESS:
      return {
        ...state,
        user: action.data,
        validity_alert: false
      };

    case constants.LOGOUT_FAILED:
      return {
        ...state,
      };

    case constants.GET_CURRENT_USER_DATA_REQUEST:
      return {
        ...state,
        isLoggedIn: true,
        loading: false
      };

    case constants.GET_CURRENT_USER_DATA_SUCCESS:
      return {
        ...state,
        user: action.data,
        loading: false,
        isLoggedIn: true
      };

    case constants.GET_CURRENT_USER_DATA_FAILED:
      return {
        ...state,
        user: action.data,
        loading: false,
        isLoggedIn: false
      };

    case constants.GET_RESERVATION_REQUEST:
      return {
        ...state,
        loading: true
      };

    case constants.GET_RESERVATION_SUCCESS:
      return {
        ...state,
        reservationData: action.data,
        loading: false
      };

    case constants.GET_RESERVATION_FAILED:
      return {
        ...state,
        reservationData: action.data,
        loading: false
      };

    case constants.USER_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true
      };

    case constants.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        signUpData: action.data
      };

    case constants.USER_SIGNUP_FAILED:
      return {
        ...state,
        loading: false
      };

    case constants.VARIFY_EMAIL_AND_PHONE_REQUEST:
      return {
        ...state,
        loadingVerify: true
      }

    case constants.VARIFY_EMAIL_AND_PHONE_SUCCESS:
      return {
        ...state,
        loadingVerify: false
      };

    case constants.VARIFY_EMAIL_AND_PHONE_FAILED:
      return {
        ...state,
        loadingVerify: false
      };

    case constants.ARRIVED_SUCCESS_FAILED:
      return {
        ...state,
        checkinUserStatus: false
      };


    case constants.ARRIVED_REQUEST:
      return {
        ...state,
        checkinUserStatus: true
      };

    case constants.FAILED_RESPONSE:
      return {
        ...state,
        loading: false,
        errorObj: action.data
      }

    case constants.CSV_DATA_ADD:
      return {
        ...state,
        loading: true,
        csvReservationData: action.data
      };

    case constants.CSV_DATA_REMOVE:
      return {
        ...state,
        csvReservationData: false
      };

    case constants.GET_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.data
      }

    default:
      return state
  }
}
