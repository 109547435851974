import { Auth } from "aws-amplify";
import { constants } from "../constants/index";
import { loginUser } from "./authAction";
import { getStorage } from "../utils";

export const userSignUp = (signUpDetails, history) => {
  return (dispatch) => {
    dispatch({ type: constants.USER_SIGNUP_REQUEST, data: {} });
    Auth.signUp(signUpDetails)
      .then((singupUser) => {
        localStorage.setItem("signUpUser", JSON.stringify(signUpDetails));
        history.push("/verifyConfirmUser");
      })
      .catch((err) => {
        console.log(err);
        // dispatch({ type: constants.USER_SIGNUP_FAILED, data: err });
        dispatch({ type: constants.FAILED_RESPONSE, data: err });
      });
  };
};

export const confirmSignUp = (confirmationDetails, history) => {
  return (dispatch) => {
    dispatch({ type: constants.VARIFY_EMAIL_AND_PHONE_REQUEST });
    Auth.confirmSignUp(confirmationDetails.username, confirmationDetails.code)
      .then((confirmuUser) => {
        let signUpUserDetails = getStorage("signUpUser", "object", false);
        if (signUpUserDetails) {
          let { username, password } = signUpUserDetails;
          dispatch(loginUser(username, password, history))
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: constants.VARIFY_EMAIL_AND_PHONE_FAILED });
        dispatch({ type: constants.FAILED_RESPONSE, data: err });
      });
  };
};

export const resendConfirmationCode = (username) => {
  return (dispatch) => {
    Auth.resendSignUp(username)
      .then((resendCode) => {
        console.log("resendCode", resendCode);
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: constants.FAILED_RESPONSE, data: err });
      });
  };
};
