import React from "react";
import { Modal } from 'react-bootstrap';

export default function AddNoteModal(props) {
    return (
        <Modal
            show={props.showNoteModal}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Add Note</Modal.Title>
                <div className="modalPopupClose clearfix" onClick={props.onHide}>
                    <img src="/images/popup_close.png" alt="close" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modalForm clearfix">
                    <div className="floating-form">
                    <div className="floating-label mb-0">
                        <textarea placeholder="Type something.."></textarea>
                    </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="themeButton">Save</button>
            </Modal.Footer>
        </Modal>
    );
}