import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class Complete extends PureComponent {

    render() {
        let { reservationCount, toggleUploadModal,uploadErrorObject } = this.props;
        return (<fieldset className="stepFour clearfix">
            <div className="fieldsetMain">
                <div className="row">
                    <div className="col-md-12">
                        <div className="fieldsetHeader">
                            <h1 className="bigFont">{reservationCount} Reservations were <br />successfully imported</h1>
                            {/* <p>Fabio vel iudice vincam, sunt in culpa qui officia. Nihilne <br /> te nocturnum praesidium Palati, nihil urbis vigiliae. <br /> Excepteur sint obcaecat cupiditat non proident culpa. <br />Quis aute iure reprehenderit in voluptate velit esse.</p> */}
                           {uploadErrorObject && uploadErrorObject.length>0?<p>There {uploadErrorObject.length==1?"was":"were"} {uploadErrorObject.length} {uploadErrorObject.length==1?"error":"errors"}, and the details have been mailed to you.</p>:""} 
                        </div>
                    </div>
                </div>
                <div className="row fieldsetFooter noFixed">
                    <div className="col-md-12">
                        <button className="inactive_themeButton" onClick={toggleUploadModal}>Import Another</button>
                        <Link to='/dashboard'>
                            <button className="themeButton mr-16">Go to Dashboard</button>
                        </Link>
                    </div>
                </div>
            </div>
        </fieldset>)
    }
}

Complete.propTypes = {
    reservationCount: PropTypes.number.isRequired,
    toggleUploadModal: PropTypes.func.isRequired
}

export default Complete
