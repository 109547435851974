import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

class SignupModal extends Component {
    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="signup_modal"
                centered
            >
                <div className="signup_modal_wrapper">
                    <div className="signup_header_modal">
                        <div className="signup_header_text">
                            <h1>One-Time Sign Up</h1>
                            <h3>Takes less than 10 minutes</h3>
                        </div>
                        <div className="signup_modal_close clearfix" onClick={this.props.onHide}>
                            <img src='/images/popup_close.png' alt="close" />
                        </div>
                    </div>
                    <div className="signup_modal_content">
                        <div className="signup_modal_steps">
                            <div className="step_no">1</div>
                            <div className="step_content">
                                <p>
                                    Information to configure the app with your hotel’s identity.
                                </p>
                                <ul>
                                    <li>Your contact details like phone number, email address, street address. </li>
                                    <li>Your hotel logo in SVG format</li>
                                    <li>A picture of your hotel</li>
                                </ul>
                            </div>
                        </div>
                        <div className="signup_modal_steps">
                            <div className="step_no">2</div>
                            <div className="step_content">
                                <p>
                                    Information to setup the billing details.
                                </p>
                                <ul>
                                    <li>Your GST/VAT/TRN number as is applicable in your region </li>
                                    <li> Billing address</li>

                                </ul>
                            </div>
                        </div>
                        <div className="signup_modal_steps">
                            <div className="step_no">3</div>
                            <div className="step_content">
                                <p>
                                    Customize the fields that you need in your Registration Card.
                                </p>
                                <ul>
                                    <li> You know this by heart.  </li>
                                    <li> Here you can select the fields that are mandatory, the ones that are optional and the ones that are not required.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="signup_modal_steps">
                            <div className="step_no">4</div>
                            <div className="step_content">
                                <p>
                                    Terms on your Registration Card and COVID-19 Declaration
                                </p>
                                <ul>
                                    <li> Simply copy & paste the terms that you have currently on your Registration Card.</li>
                                    <li> If you need any declaration from the guest about his health condition here is where you can list the questions. </li>

                                </ul>
                            </div>
                        </div>
                        <div className="signup_modal_steps">
                            <div className="step_no">5</div>
                            <div className="step_content">
                                <p>
                                    Payment Gateway
                                </p>
                                <ul>
                                    <li> If you have an account with a payment gateway let us know your account details. </li>
                                    <li> If you don't have one - we can help you setup an account.</li>

                                </ul>
                            </div>
                        </div>
                        <div className="signup_modal_steps">
                            <div className="step_no">6</div>
                            <div className="step_content">
                                <p>
                                    Invite your team members
                                </p>
                                <ul>
                                    <li>Setup accounts for your associates who would be using this system in addition to yourself. </li>
                                    <li> Simply add the email address and define their role.  </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        )
    }
}

export default SignupModal
