import axios from "../axios";

// import responseJson from './reservations_new.json'
export const getReservationService = (filters) => {
  return axios.get('/dashboard/reservations', { params: filters })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error
    })
};

export const getBalanceService = () => {
  return axios.get('/dashboard/hotel/getBalanceReservation')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error
    })
};

export const getHotelData = () => {
  return axios.get('/dashboard/hotel')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("gethotelData Error", error);
      return error
    })
};

export const checkinUserService = (reservation_code) => {
  return axios.put('/dashboard/reservation/checkIn', { reservation_code: reservation_code })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error
    })
};


export const getGuestDetail = () => {
  return axios.get('dashboard/hotel/guestdetails')
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("guestdetails Error", error);
      return error
    })
};

export const hotelIntegrationFun = () => {
  return axios.get("/dashboard/hotel/integrations",{
        params:{
            type:"hotel"
        }
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("guestdetails Error", error);
      return error
    })
};