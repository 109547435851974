import React from "react";
import { connect } from 'react-redux';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { logoutUser } from '../../actions';
import { withRouter, Link } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel_logo: "",
            enabledSearch: false
        }
    }
    componentDidMount() {
        let { hotel } = this.props;
        if (hotel && Object.keys(hotel) !== 0 && hotel.constructor === Object) {
            let { hotel_assets } = hotel;
            if (hotel_assets && Array.isArray(hotel_assets) && hotel_assets.length > 0) {
                let darkLogo = hotel_assets.find(({ asset_type }) => asset_type === "hotel_logo_dark");
                this.setState({
                    ...this.state,
                    hotel_logo: darkLogo.file_path
                })
            }
        }
    }

    logoutUser = () => {
        this.props.dispatch(logoutUser(this.props.history))
    }

    SearchToggle = () => {
        this.setState({ enabledSearch: !this.state.enabledSearch }, () => {
            if (this.state.enabledSearch == false) {
                this.props.searchHandle("");
            }
        });
    }

    render() {
        let { hotel_logo } = this.state;



        return (
            <header>
                <div className="customContainer">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="headerLogo">
                                <img className="img-responsive" src='/images/logo.png' alt="thru logo" />
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                            <div className="headerPropertyLogo">
                                <img src={(hotel_logo ? hotel_logo : "")} alt="thru logo" />
                            </div>
                        </div>
                        {!this.props.dashboardBtn ?
                            <div className="col-lg-4 pl-0">
                                <div className="userInfoIcon pull-right">
                                    {/* <div className="userInfo" onClick = {this.logoutUser}>
                                    <div className="clearfix">
                                        <img src='/images/user.png' alt="user" />
                                    </div>
                                </div> */}
                                    <DropdownButton id="dropdown-basic-button"
                                        title=""
                                        key="right"
                                        drop="down">
                                        {/* <Dropdown.Item href="#">Profile</Dropdown.Item> */}
                                        {/* <Link className="support_portal" to='/billing/edit'>
                                            <i className="fa fa-credit-card" aria-hidden="true"></i> Billing
                                    </Link> */}
                                        <a className="support_portal" target="_blank" href="https://support.thru.app/"><i className="fa fa-question-circle" aria-hidden="true"></i> Support</a>
                                        {this.props.is_admin ?
                                            <Link className="support_portal" to='/account/edit'>
                                                <i className="fa fa-user" aria-hidden="true"></i> Account
                                 </Link>
                                            : null}
                                        <Dropdown.Item className="logout_link" href="#" onClick={this.logoutUser}><i className="fa fa-power-off" aria-hidden="true"></i> Logout</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                                {this.props.searchValue != undefined && this.props.searchValue != "undefined" ?
                                    <div className={"clearfix searchIcon " + (this.state.enabledSearch || this.props.searchValue != "" ? 'searchshowparent' : 'searchhiddenParent')}  >
                                        <div className={"form-group has-feedback " + (this.state.enabledSearch || this.props.searchValue != "" ? 'searchshow' : 'searchhidden')}>
                                            <label htmlFor="search" className="sr-only">Search</label>
                                            <DebounceInput
                                                className="form-control"
                                                id="search"
                                                name="search"
                                                autoComplete="off"
                                                placeholder="Search Name or Booking Id..."
                                                minLength={3}
                                                value={this.props.searchValue}
                                                debounceTimeout={300}
                                                onChange={(e) => this.props.searchHandle(e.target.value)} />
                                            {/* <input type="text" className="form-control" value ={this.props.searchValue} name="search" id="search" onChange={(e)=>this.props.searchHandle(e.target.value)} placeholder="Search Name or Booking Id..." /> */}
                                            <span onClick={this.SearchToggle} className="fa fa-search form-control-feedback"></span>
                                        </div>
                                    </div>
                                    : null
                                }

                            </div>
                            :
                            <div className="col-lg-4 text-right">
                                <div className="actiondashboardbutton d-inline">
                                    <Link to="/dashboard">
                                        <button className="dashboardButton"> <img src="/images/back_arrow.png" /> Back to Dashboard</button>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </header>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        hotel: state.hotel,
        is_admin: state.is_admin
    };
};

export default connect(mapStateToProps)(withRouter(Header));