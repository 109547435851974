import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { TextField } from '@material-ui/core';
import Switch from "react-switch";
import { getSelectTheme } from '../../utils/ui';

const options = [
  { value: "text", label: "Text" },
  { value: "date", label: "Date" }
]
class CustomField extends Component {
  constructor(props) {
    super(props);

    let { index, custom_fields } = this.props;
    let field = custom_fields[index];
    this.field_name = `guest_custom_fields[fields][udf${index + 1}][name]`;
    this.type_name = `guest_custom_fields[fields][udf${index + 1}][type]`;
    this.required_name = `guest_custom_fields[fields][udf${index + 1}][required]`;

    this.state = {
      [this.field_name]: field.name,
      [this.type_name]: options.find(o => o.value === field.type),
      [this.required_name]: field.required
    }
  }

  handleInputChange = (e) => {
    let { value, name } = e.target;
    this.setState({ ...this.state, [name]: value })
  }

  handleSelect = (e, target) => {
    let { name } = target;
    this.setState({ ...this.state, [name]: e })
  }

  handleSwitch = (checked) => {
    this.setState({ ...this.state, [this.required_name]: checked })
  }

  render() {
    let { index } = this.props;
    this.field_name = `guest_custom_fields[fields][udf${index + 1}][name]`;
    this.type_name = `guest_custom_fields[fields][udf${index + 1}][type]`;
    this.required_name = `guest_custom_fields[fields][udf${index + 1}][required]`;
    return (
      <div className="custom_field_wrapper">
        <div className="header_detail_wrapper content">
          <div className="header_detail_content">
            <h4 className="steps_header">
              <TextField
                name={this.field_name}
                value={this.state[this.field_name]}
                onChange={this.handleInputChange}
              />
            </h4>
          </div>
          <div className="header_detail_content header_span">
            <Select
              className="type_select"
              name={this.type_name}
              classNamePrefix="react_select"
              value={this.state[this.type_name]}
              options={options}
              onChange={this.handleSelect}
              theme={getSelectTheme}
            />
          </div>
          <div className="header_detail_content header_span mandatory">
            <span>
              <Switch
                name={this.required_name}
                checked={this.state[this.required_name]}
                value={true}
                onChange={this.handleSwitch}
                onHandleColor="#fff"
                onColor="#1772ad"
                handleDiameter={15}
                width={40}
                height={24}
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </span>
          </div>
        </div>
        <div className="custom_field_error_wrapper">
          <div className="error-text">
            {this.props.validator.message(this.field_name, this.state[this.field_name], 'required', { messages: { required: "Please enter name" } })}
          </div>
          <div className="error-text">
            {this.props.validator.message(this.type_name, (this.state[this.type_name] ? this.state[this.type_name]['value'] : ''), 'required', { messages: { required: "Please select type" } })}
          </div>
        </div>
        <span className="close_btn" onClick={() => this.props.onRemove(this.props.index)}>
          <img src="/images/close.svg" alt="close" />
        </span>
      </div>
    );
  }
}

CustomField.propTypes = {
  custom_fields: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  setCustomField: PropTypes.func.isRequired,
};

export default CustomField;