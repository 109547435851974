import React, { Component, Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import Flag from 'react-world-flags'
import { getStatusDetails } from "../../utils";
import StatusChips from "../common/StatusChips";
import MoveToPMSModal from "./MoveToPMSModal";

class CheckinDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showView: "details",
            pmsModal: false // details, accompanying, regcard, idcard
        };
    }

    checkinUser = (reservation_code,enable_push_to_pms) => {
        console.log(reservation_code)
        if (reservation_code) {
            // this.props.toggleConfirmModal(reservation_code)
        this.props.showConfirmModal({
          title: "Confirm",
          confirmBtnText: "Yes",
          hideOnConfirm:false,
          onConfirm: () => this.props.confirmation(enable_push_to_pms),
          children:  <p>Would you like to change status?</p>
        })
        }
    };

    pmsModalToggle = () => {
        console.log('PMS MODAl TOGGLED');
        this.setState(prevState => ({ ...prevState, pmsModal: !prevState.pmsModal }));
    }

    pushToPMS = (event, reservation_code) => {
        event.preventDefault();
        this.props.showConfirmModal({
          title: "Confirm",
          confirmBtnText: "Yes",
          hideOnConfirm:false,
          onConfirm: () => this.props.pushToPMS(),
          children: <p>Would you like to push this reservation to the PMS?</p>
        })
    }

    mapOrder = (a, order, key) => {
        const map = order.reduce((r, v, i) => ((r[v] = i), r), {})
        return a.sort((a, b) => map[a[key].toLowerCase()] - map[b[key].toLowerCase()])
    }

    handleView = (view) => this.setState({ showView: view });

    render() {
        let { hotel, data, checkinUserStatus, dashboardFilter, guestDetails,hotelIntegration } = this.props;
        let hotel_config = guestDetails.hasOwnProperty("hotel_config") ? guestDetails.hotel_config : {};
        let guest_detail_config = hotel_config.hasOwnProperty("guest_detail_config") ? hotel_config.guest_detail_config : {};
        let guest_profile = guest_detail_config.hasOwnProperty("guest_profile") ? guest_detail_config.guest_profile : {};
        let primary = guest_profile.hasOwnProperty("primary") ? guest_profile.primary : {};
        let accompany = guest_profile.hasOwnProperty("primary") ? guest_profile.accompany : {};
        let id_card = guest_detail_config.hasOwnProperty("id_card") ? guest_detail_config.id_card : {};
        let travel = guest_detail_config.hasOwnProperty("travel") ? guest_detail_config.travel : {};
        let visa = guest_detail_config.hasOwnProperty("visa") ? guest_detail_config.visa : {};
        let guest_custom_fields = hotel_config.hasOwnProperty("guest_custom_fields") ? hotel_config.guest_custom_fields : {};
        let custom_fields = guest_custom_fields.hasOwnProperty("fields") ? guest_custom_fields.fields : {};
        let { selectGuestType } = dashboardFilter ? dashboardFilter : {};
        let propsData = this.props.data;
        let hotel_country = "",
            hotel_country_code = "",
            gurst_country_code = data.country_code,
            guestType = "local";

            if(!gurst_country_code){
                let primaryGuest = getGuestDetails(propsData);
                let { guest } = primaryGuest;
                if (guest.documents && guest.documents.length > 0) {
                    gurst_country_code = guest.documents[0].country_code
                }
            }

        if (hotel && Object.keys(hotel) !== 0 && hotel.constructor === Object) {
            hotel_country = hotel.country_name
            hotel_country_code = hotel.country_code;
        }
        if (hotel_country_code != gurst_country_code) guestType = "intl";

        let arrivalBtn = false;
        if (data.arrival_date) {
            let date1 = moment().format('YYYY-MM-DD').valueOf()
            let date2 = moment.parseZone(data.arrival_date).format("YYYY-MM-DD").valueOf();
             arrivalBtn = (date2 ==date1)?true:false;
        }
        let { showView } = this.state;
        switch (showView) {
            case "details":
                let statusDetails = getStatusDetails(
                    propsData.reservation_status
                );
                let { reservation_code, is_sms_resend, reservation_status } = propsData;
                let statusCount = Array.isArray(statusDetails)
                    ? statusDetails.filter(d => d.chipclassName == "alert").length
                    : 0;
                    let data = getGuestDetails(propsData);
                let { guest } = data;
                let name = [guest.last_name, guest.first_name]
                    .filter((g) => g)
                    .join(", ");
                let { country_code} = guest;
                let documents = [];
                let phone_number =
                propsData && propsData.phone_number?
                propsData.phone_number:
                ((Object.keys(guest).length !== 0 && guest.constructor === Object)?
               ( guest &&(guest?.phone_number) && guest.phone_number?guest.phone_number :""):"");

               
                if (guest.documents && guest.documents.length > 0) {
                    const order = ["passport", "visa"]
                    documents = this.mapOrder(guest.documents, order, "docid_type")
                }
                let enable_push_to_pms =false;
                if (hotelIntegration && Object.keys(hotelIntegration).length !== 0 && hotelIntegration.constructor === Object) {   
                    if(hotelIntegration?.pms_gateway){
                        let pms_gateway = hotelIntegration.pms_gateway;
                            if (Array.isArray(pms_gateway) && pms_gateway.length > 0) {
                                let payment_confival = pms_gateway[0];
                                if(payment_confival?.vendor_config){
                                    let {vendor_config} =payment_confival;
                                    let {config} = vendor_config;
                                        let {push_to_pms} = config; 
                                        enable_push_to_pms =push_to_pms?push_to_pms:false; 
                                }
                            }
                    }
                    
                }
                console.log("enable_push_to_pms====",enable_push_to_pms)
                return (
                    <div>
                        <div className="clearfix detailPanel">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="backButtonDiv">
                                        <Link to="/dashboard">
                                            <button className="themeBackButton">
                                                <img src="/images/back_arrow.png" alt="Back" />
                                                Back
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="text-center">
                                        <h1 className="memberName">{name}</h1>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="memberProfile pull-right">
                                        <div className="d-inline clearfix mr-25">
                                            <div className="form-group mb-0">
                                                {/* <select className="form-control" defaultValue="silver">
                                                    <option defaultValue="gold">Gold Member</option>
                                                    <option defaultValue="silver">Silver Member</option>
                                                </select> */}
                                            </div>
                                        </div>
                                        <div className="d-inline clearfix">
                                            <div className="countryFlag">
                                                {country_code ?
                                                    <Flag code={country_code} className="flagLib" />
                                                    : ""}
                                            </div>
                                            <div className="countryFlag">
                                                {/* <img src="/images/flag/india.png" alt="Flag" /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row memeberDetail">
                                <div className="col-md-4">
                                    <div className="reservationDetail">
                                        <ReservationDetails
                                            handleView={this.handleView}
                                            pmsModalToggle={this.pmsModalToggle}
                                            data={this.props.data}
                                            primary={primary}
                                            guestType={guestType}
                                        />
                                        {Object.keys(primary).length !== 0 &&
                                            primary.constructor === Object &&
                                            filteredObj(primary, guestType).length>0 ?
                                            <ContactInformation
                                                data={this.props.data}
                                                primary={primary}
                                                guestType={guestType} />
                                            : ""}
                                            <CustomFields
                                            data={this.props.data}
                                            custom_fields_config={custom_fields} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="reservationDetail">
                                        {Object.keys(id_card).length !== 0 &&
                                            id_card.constructor === Object &&
                                            filteredObj(id_card, guestType).length>0 ?
                                            <IDDetails
                                                data={this.props.data}
                                                id_card={id_card}
                                                guestType={guestType} />
                                            : ""}
                                        {Object.keys(travel).length !== 0 &&
                                            travel.constructor === Object &&
                                            filteredObj(travel, guestType).length>0 ?
                                            <TravelDetails
                                                data={this.props.data}
                                                hotel_country={hotel_country}
                                                travel={travel}
                                                guestType={guestType} />
                                            : ""}
                                        {/*  */}
                                        {Object.keys(visa).length !== 0 &&
                                            visa.constructor === Object &&
                                            filteredObj(visa, guestType).length>0 ?
                                            <VisaDetails
                                                data={this.props.data}
                                                hotel_country={hotel_country}
                                                visa_config={visa}
                                                guestType={guestType} />
                                            : ""}
                                       
                                        


                                        {/* <ArrivalDetails data={this.props.data} /> */}
                                        {/* <PaymentDetails data={this.props.data} /> */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="reservationDetailActions">
                                        <div className="DetailActionsButtons">
                                            {(this.props.data.reservation_status != "staying" &&
                                                this.props.data.reservation_status != "cancelled" &&
                                                this.props.data.reservation_status != "checkout" &&
                                                 selectGuestType != "departing") ? (
                                                    <React.Fragment>
                                                            <React.Fragment>
                                                        {arrivalBtn?  <button className="activebutton"
                                                            onClick={(e) => this.checkinUser(propsData.reservation_code,enable_push_to_pms)}>{checkinUserStatus ? "Loading..." : "Arrived"}</button>:""}
                                                      </React.Fragment>
                                                        {this.props.data.reservation_status != "checkin_completed_with_payment" ? (
                                                            <button
                                                                onClick={(e) =>
                                                                    this.props.inviteModalToggle(
                                                                        reservation_code,
                                                                        phone_number,
                                                                        country_code,
                                                                        is_sms_resend,
                                                                        reservation_status)
                                                                }
                                                            >Invite</button>
                                                        ) : null}
                                                    </React.Fragment>
                                                ) : null}
                                            {/* <button>Invite to Thru</button> */}
                                            <button onClick={(e) =>
                                                this.props.regCardModalModalToggle(this.props.data.id)} >Download Registration Card</button>

                                            {/* MKAMKA */}
                                            <button
                                                onClick={(e) =>
                                                    this.props.showIdModalToggle(documents, name)}>Download ID Card</button>

                                                {(this.props.data.reservation_status != "checkin_pending" &&
                                                this.props.data.reservation_status != "cancelled" &&
                                                this.props.data.reservation_status != "checkout" &&
                                                selectGuestType != "departing" && 
                                                this.props.data.is_pushed_to_pms==0 && enable_push_to_pms) ?
                                                <button onClick={this.pushToPMS}>Push to PMS</button>
                                                :""}

                                           
                                            {/* <button>Generate Payment Link</button> */}
                                        </div>
                                        <div className="DetailActionsButtonsBorder clearfix"></div>
                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className="documentStatus clearfix">
                                                    <div className="guestStatus">
                                                        <StatusChips
                                                            statusKey={this.props.data.reservation_status}
                                                            uploadAlert={
                                                                this.props.data.alert &&
                                                                    this.props.data.reservation_status != this.props.data.alert &&
                                                                    this.props.data.reservation_status != "checkin_completed_with_alert"
                                                                    ?
                                                                    "upload_alert"
                                                                    :
                                                                    null} />

                                                        {/* <StatusChips
                                                            statusKey={this.props.data.reservation_status}
                                                        /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {statusCount != 0 ?
                                                <div className="col-md-2">
                                                    <div className="badgeCount">
                                                        <p>{statusCount}</p>
                                                    </div>
                                                </div>

                                                : ""}

                                            {(
                                                propsData.reservation_status == "profile_uploaded_with_alert" ||
                                                propsData.reservation_status == "checkin_completed_with_alert") ?
                                                <React.Fragment>
                                                    <div className="DetailActionsButtonsBorder clearfix"></div>
                                                    <div className="col-md-12">
                                                        <div className="documentStatusText clearfix">
                                                            <p>Document uploaded could not be verified.</p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                : (propsData.reservation_status == "checkin_completed_without_payment" ||
                                                    propsData.reservation_status == "payment_failure") ?
                                                    <React.Fragment>
                                                        <div className="DetailActionsButtonsBorder clearfix"></div>
                                                        <div className="col-md-12">
                                                            <div className="documentStatusText clearfix">
                                                                <p>Payment pending.</p>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    : ""}
                                        </div>
                                        <div className="DetailActionsButtonsBorder clearfix"></div>
                                        {
                                            documents && documents.length > 0 ?
                                                documents.map((data12,index) => {
                                                    return <DocumentPhoto
                                                        data={data12} key={index} />
                                                })
                                                : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        <MoveToPMSModal
                            showPMSModal={this.state.pmsModal}
                            onHide={this.pmsModalToggle}
                        />
                    </div>
                );
            case "accompanying":
                return (
                    <Fragment>
                         <AdultDetails
                        data={this.props.data}
                        handleView={this.handleView}
                        showIdModalToggle={this.props.showIdModalToggle}
                        pmsModalToggle={this.pmsModalToggle}
                        accompany={accompany}
                        visa_config={visa}
                        travel={travel}
                        id_card={id_card}
                        hotel_country_code={hotel_country_code} />

                        <MoveToPMSModal
                            showPMSModal={this.state.pmsModal}
                            onHide={this.pmsModalToggle}
                        />
                    </Fragment>
                );
            default:
                return null;
        }
    }
}

const filteredObj = (obj, guestType) => {
    return Object.keys(obj).filter((ele)=>obj[ele][guestType]==true, []);
}


const ReservationDetails = (props) => {
    let data = getGuestDetails(props.data);
    let { guest } = data;
    let { primary, guestType } = props;
    let { first_name, last_name } = guest;
    let { arrival_date, departure_date, rate_code_description } = props.data;
    return (
        <div className="reservationDetailWrapper">
            {primary && ((primary.first_name && primary.first_name[guestType]) ||
                primary.last_name && primary.last_name[guestType]) ?
                <React.Fragment>
                    <h2 className="memeberDetailLabel">Reservation Details</h2>
                    <div className="row">
                        {(primary.first_name && primary.first_name[guestType]) ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>First Name</label>
                                    <p>{first_name}</p>
                                </div>
                            </div>
                            : ""}
                        {(primary.first_name && primary.last_name[guestType]) ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>Last Name</label>
                                    <p>{last_name}</p>
                                </div>
                            </div>
                            : ""}

                    </div>
                </React.Fragment>
                :
                ""
            }

            <div className="memberDetail_fields_border clearfix"></div>
            <div className="row">
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Arrival</label>
                        <p>{arrival_date ? moment.parseZone(arrival_date).format("MMM D, YYYY") : "-"}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Departure</label>
                        <p>{departure_date ? moment.parseZone(departure_date).format("MMM D, YYYY") : "-"}</p>
                    </div>
                </div>
            </div>
            <div className="memberDetail_fields_border clearfix"></div>
            <div className="row">
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Room Type</label>
                        <p>{props.data.room_type}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Adults / Kids</label>
                        <p>
                            {props.data.no_of_adult} / {props.data.no_of_child}
                        </p>
                        {props.data.no_of_adult > 1 ?
                            <React.Fragment>
                                <span
                                    className="eyeImage"
                                    onClick={() => props.handleView("accompanying")}>
                                    <img src="/images/eye.svg" alt="eye" />
                                </span>
                            </React.Fragment> : ""}

                    </div>
                </div>
            </div>
            <div className="memberDetail_fields_border clearfix"></div>
            <div className="row">
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Room Tariff</label>
                        <p>{props.data.currency_symbol + props.data.room_rate}</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Rate Description</label>
                        <p>{rate_code_description ? rate_code_description : "-"}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const getColumnVal = (array, length) => {
    return array.reduce(
        (result, item, index) => {
            if (index % length === 0) result.push([])
            result[Math.floor(index / length)].push(item)
            return result
        },
        {}
    );
}

const CustomFields = (props) => {
    let data = getGuestDetails(props.data);
    let { custom_fields_config} = props;
    let { guest } = data;
    let { guest_custom_details } = guest;
    console.log("===guest_custom_fields=====",guest_custom_details)
        let CustomFields = guest_custom_details?JSON.parse(guest_custom_details):{};
    return (
        <React.Fragment>
                {(Object.keys(CustomFields).length !== 0 && 
                CustomFields.constructor === Object)?
                 <div className="reservationDetailWrapper">
                 <h2 className="memeberDetailLabel">Others Details</h2>
                 { Object.keys(CustomFields).map((field,index)=>{
                     let filedName= custom_fields_config && 
                     custom_fields_config[field]? custom_fields_config[field].name:field
                     return(
                        <React.Fragment key={filedName+index}   >
                        <div className="row" key={filedName+index}>
                            <div className="col-md-12">
                                <div className="memberfieldsData">
                                    <label>{filedName}</label>
                                    <p>{CustomFields && CustomFields[field] ?CustomFields[field]:""}</p>
                                </div>
                            </div>
                        </div>
                        <div className="memberDetail_fields_border clearfix"></div>
                    </React.Fragment>
                     )
                 })
                 }
             </div>
         :""}
        </React.Fragment>
       );
}

const VisaDetails = (props) => {
    let data = getGuestDetails(props.data);
    let { visa_config, guestType } = props;
    let { guest } = data;
    let { documents } = guest;
    let visa = false;
    if (Array.isArray(documents) && documents.length) {
        visa = documents.find((d) => {
            d.docid_type = d.docid_type ? d.docid_type.toString().toLowerCase() : d.docid_type;
            return d.docid_type === "visa"
        });
    }

    return (
        <div className="reservationDetailWrapper">
            <h2 className="memeberDetailLabel">Visa Details</h2>
            {visa_config &&
                (visa_config.docid_number &&
                    visa_config.docid_number[guestType] ||
                    visa_config.place_of_issue &&
                    visa_config.place_of_issue[guestType]) ?
                <React.Fragment>
                    <div className="row">
                        {visa_config &&
                            visa_config.docid_number &&
                            visa_config.docid_number[guestType] ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>Visa Number</label>
                                    <p>
                                        {visa && visa.docid_number
                                            ? visa.docid_number
                                            : "-"}
                                    </p>
                                </div>
                            </div>
                            : ""}
                        {visa_config &&
                            visa_config.place_of_issue &&
                            visa_config.place_of_issue[guestType] ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>Place of Issue</label>
                                    <p>
                                        {visa && visa.place_of_issue
                                            ? visa.place_of_issue
                                            : "-"}
                                    </p>
                                </div>
                            </div>
                            : ""}
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment>
                : ""
            }

            {visa_config && (visa_config.issue_date &&
                visa_config.issue_date[guestType] ||
                visa_config.expiry_date &&
                visa_config.expiry_date[guestType]) ?
                <React.Fragment>
                    <div className="row">
                        {visa_config &&
                            visa_config.issue_date &&
                            visa_config.issue_date[guestType] ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>Date of Issue</label>
                                    <p>
                                        {visa && visa.issue_date
                                            ? moment.parseZone(visa.issue_date).format("MMM D, YYYY")
                                            : "-"}
                                    </p>
                                </div>
                            </div>
                            : ""}
                        {visa_config &&
                            visa_config.expiry_date &&
                            visa_config.expiry_date[guestType] ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>Date of Expiry</label>
                                    <p>
                                        {visa && visa.expiry_date
                                            ? moment.parseZone(visa.expiry_date).format("MMM D, YYYY")
                                            : "-"}
                                    </p>
                                </div>
                            </div>
                            : ""}
                    </div>
                    {/* <div className="memberDetail_fields_border clearfix"></div> */}
                </React.Fragment>
                : ""

            }
            {/* <div className="memberDetail_fields_border clearfix"></div> */}
        </div>
    );
}


const AdultDetails = (props) => {
    let { data, accompany, visa_config, hotel_country_code, id_card } = props;
    let { guests_profile } = data;
    
    if (Array.isArray(guests_profile) && guests_profile.length) {
        return guests_profile.map((guest, index) => {
            console.log("guest------------", guest);
            let guestType = "local";
            if(guest.country_code =="" ||guest.country_code ==null){
                let c_code =guest?.documents?(Array.isArray(guest.documents) && guest.documents.length > 0?guest.documents[0].country_code:""):"";
                if (hotel_country_code != c_code) guestType = "intl";
                  
            }else{
                if (hotel_country_code != guest.country_code) guestType = "intl";
            }

            let {
                first_name,
                last_name,
                documents,
            } = guest;
            let name = [last_name, first_name]
                .filter((g) => g)
                .join(", ");
            let other_doc = false,
                visa = false;
            if (Array.isArray(documents) && documents.length) {
                other_doc = documents.find((d) => {
                    d.docid_type = d.docid_type ? d.docid_type.toString().toLowerCase() : d.docid_type;
                    return d.docid_type !== "visa"
                });
                visa = documents.find((d) => {
                    d.docid_type = d.docid_type ? d.docid_type.toString().toLowerCase() : d.docid_type;
                    return d.docid_type === "visa"
                });
            }
            return (
                <div key={index} className="clearfix detailPanel detailPanelAccomp">
                    {index === 0 ? (
                        <div className="row">
                            <div className="col-md-4">
                                <div className="backButtonDiv">
                                    <button
                                        className="themeBackButton"
                                        onClick={() => props.handleView("details")}
                                    >
                                        <img src="/images/back_arrow.png" alt="Back" />
                                        {"Back"}
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-center">
                                    <h1 className="memberName">Accompanying Guests</h1>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="row memeberDetail">
                        <div className="col-md-12">
                            <div className="reservationDetail">
                                <div className="reservationDetailWrapper">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h2 className="memeberDetailLabel">Adult {index + 1}</h2>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <div className="buttons_wrapper">
                                             
                                                <div className="accompIDButton">
                                                    <button className="showIdButton"
                                                        onClick={(e) => props.showIdModalToggle(documents, name)}>Show ID</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <h2 className="guestDetailLabel">ID Details</h2>

                                            {Object.keys(accompany).length !== 0 &&
                                                accompany.constructor === Object &&
                                                filteredObj(accompany, guestType).length>0 ?
                                                <div className="row">
                                                    {accompany &&
                                                        accompany.first_name &&
                                                        accompany.first_name[guestType] ?
                                                        <div className="col-md-6">
                                                            <div className="memberfieldsData">
                                                                <label>First Name</label>
                                                                <p>{first_name}</p>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                    {accompany &&
                                                        accompany.last_name &&
                                                        accompany.last_name[guestType] ?
                                                        <div className="col-md-6">
                                                            <div className="memberfieldsData">
                                                                <label>Last Name</label>
                                                                <p>{last_name}</p>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                                : ""}

                                            {Object.keys(id_card).length !== 0 &&
                                                id_card.constructor === Object &&
                                                filteredObj(id_card, guestType).length>0 ?
                                                <React.Fragment>

                                                    <div className="memberDetail_fields_border clearfix"></div>
                                                    {id_card && id_card.docid_number && id_card.docid_number[guestType] ?
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="memberfieldsData">
                                                                    <label>ID Number</label>
                                                                    <p>
                                                                        {other_doc && other_doc.docid_number
                                                                            ? other_doc.docid_number
                                                                            : "-"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""}

                                                    {id_card && id_card.place_of_issue && id_card.place_of_issue[guestType] ?
                                                        <React.Fragment>
                                                            <div className="memberDetail_fields_border clearfix"></div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="memberfieldsData">
                                                                        <label>Place Of Issue</label>
                                                                        <p>
                                                                            {other_doc && other_doc.place_of_issue
                                                                                ? other_doc.place_of_issue
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : ""}


                                                    {id_card && id_card.issue_date && id_card.issue_date[guestType] ?
                                                        <React.Fragment>
                                                            <div className="memberDetail_fields_border clearfix"></div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="memberfieldsData">
                                                                        <label>Place Of Issue</label>
                                                                        <p>
                                                                            {other_doc && other_doc.issue_date
                                                                                ? moment.parseZone(other_doc.issue_date).format(
                                                                                    "MMM D, YYYY"
                                                                                )
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : ""}

                                                    {id_card && id_card.expiry_date && id_card.expiry_date[guestType] ?
                                                        <React.Fragment>
                                                            <div className="memberDetail_fields_border clearfix"></div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="memberfieldsData">
                                                                        <label>Place Of Expiry</label>
                                                                        <p>
                                                                            {other_doc && other_doc.expiry_date
                                                                                ? moment.parseZone(other_doc.expiry_date).format(
                                                                                    "MMM D, YYYY"
                                                                                )
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : ""}
                                                    <div className="memberDetail_fields_border clearfix"></div>

                                                </React.Fragment> : ""}

                                        </div>
                                        <div className="col-md-4">
                                            {Object.keys(visa_config).length !== 0 &&
                                                visa_config.constructor === Object &&
                                                filteredObj(visa_config, guestType).length>0 ?
                                                <React.Fragment>
                                                    <h2 className="guestDetailLabel">Visa Details</h2>
                                                    {visa_config &&
                                                        visa_config.docid_number &&
                                                        visa_config.docid_number[guestType] ?
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="memberfieldsData">
                                                                        <label>Visa Number</label>
                                                                        <p>
                                                                            {visa && visa.docid_number
                                                                                ? visa.docid_number
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="memberDetail_fields_border clearfix"></div>
                                                        </React.Fragment>
                                                        : ""}

                                                    {visa_config &&
                                                        visa_config.place_of_issue &&
                                                        visa_config.place_of_issue[guestType] ?
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="memberfieldsData">
                                                                        <label>Place of Issue</label>
                                                                        <p>
                                                                            {visa && visa.place_of_issue
                                                                                ? visa.place_of_issue
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="memberDetail_fields_border clearfix"></div>
                                                        </React.Fragment>
                                                        : ""}

                                                    {visa_config &&
                                                        visa_config.issue_date &&
                                                        visa_config.issue_date[guestType] ?
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="memberfieldsData">
                                                                        <label>Date of Issue</label>
                                                                        <p>
                                                                            {visa && visa.issue_date
                                                                                ? moment.parseZone(visa.issue_date).format("MMM D, YYYY")
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="memberDetail_fields_border clearfix"></div>
                                                        </React.Fragment>
                                                        : ""}

                                                    {visa_config &&
                                                        visa_config.expiry_date &&
                                                        visa_config.expiry_date[guestType] ?
                                                        <React.Fragment>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="memberfieldsData">
                                                                        <label>Date of Expiry</label>
                                                                        <p>
                                                                            {visa && visa.expiry_date
                                                                                ? moment.parseZone(visa.expiry_date).format("MMM D, YYYY")
                                                                                : "-"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="memberDetail_fields_border clearfix"></div>
                                                        </React.Fragment>
                                                        : ""}
                                                </React.Fragment>
                                                : ""}

                                        </div>
                                        {/* <div className="col-md-4">
                                            <h2 className="guestDetailLabel">Arrival Details</h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="memberfieldsData">
                                                        <label>Arriving From</label>
                                                        <p>-</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="memberfieldsData">
                                                        <label>Departing To</label>
                                                        <p>-</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="memberDetail_fields_border clearfix"></div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="memberfieldsData">
                                                        <label>Purpose of Visit</label>
                                                        <p>{purpose_of_visit}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="memberDetail_fields_border clearfix"></div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="memberfieldsData">
                                                        <label>Date of Arrival</label>
                                                        <p>{arrival_date ? moment(arrival_date).format("MMM D, YYYY") : "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="memberDetail_fields_border clearfix"></div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="memberfieldsData">
                                                        <label>Birth Date</label>
                                                        <p>{date_of_birth ? moment(date_of_birth).format("MMM D, YYYY") : "-"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="memberDetail_fields_border clearfix"></div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    } else {
        return (
            <div className="clearfix detailPanel detailPanelAccomp">
                <div className="row">
                    <div className="col-md-4">
                        <div className="backButtonDiv">
                            <button className="themeBackButton" onClick={() => props.handleView("details")}>
                                <img src="/images/back_arrow.png" alt="Back" />
                                Back
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="text-center">
                            <h1 className="memberName">No details found or Guest may opted to Stay alone.</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const ContactInformation = (props) => {
    let data = getGuestDetails(props.data);
    let { guest, isGuestProfile } = data;
    let { primary, guestType } = props;
    let {
        email_address,
        phone_number,
        home_address,
        country_name,
        nationality,
    } = guest;
    let country = isGuestProfile ? country_name : nationality;
    return (
        <div className="reservationDetailWrapper">
            <h2 className="memeberDetailLabel">Contact Information</h2>
            {primary &&
                primary.email_address &&
                primary.email_address[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Email Address</label>
                                <p>{email_address}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment> : ""}

            {primary &&
                primary.phone_number &&
                primary.phone_number[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Phone Number</label>
                                <p>{phone_number}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment> : ""}

            {primary &&
                primary.home_address &&
                primary.home_address[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Address</label>
                                <p>{home_address ? home_address : "NA"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment> : ""}

            {primary &&
                primary.country &&
                primary.country[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Country</label>
                                <p>{country ? country : "NA"}</p>
                            </div>
                        </div>
                    </div>
                </React.Fragment> : ""}
        </div>
    );
};



const IDDetails = (props) => {
    let { id_card, guestType } = props;
    let data = getGuestDetails(props.data),
        { guest } = data,
        { documents } = guest;
    var docid_number = "-",
        place_of_issue = "-",
        issue_date = "-",
        expiry_date = "-";
    if (Array.isArray(documents) && documents.length) {
        documents = documents[0];
        docid_number = (documents.docid_number ? documents.docid_number : "-");
        place_of_issue = (documents.place_of_issue ? documents.place_of_issue : "-");
        issue_date = (documents.issue_date ? moment(documents.issue_date).format("MMM D, YYYY") : "-");
        expiry_date = (documents.expiry_date ? moment(documents.expiry_date).format("MMM D, YYYY") : "-");
    }
    return (
        <div className="reservationDetailWrapper">
            <h2 className="memeberDetailLabel">ID Details</h2>
            {id_card && id_card.docid_number && id_card.docid_number[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>ID Number</label>
                                <p>{docid_number}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment> : ""}

            {id_card && id_card.place_of_issue && id_card.place_of_issue[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Place of Issue</label>
                                <p>{place_of_issue}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment> : ""}
            <div className="row">
                {id_card && id_card.issue_date && id_card.issue_date[guestType] ?
                    <div className="col-md-6">
                        <div className="memberfieldsData">
                            <label>Date of Issue</label>
                            <p>{issue_date}</p>
                        </div>
                    </div>
                    : ""}

                {id_card && id_card.expiry_date && id_card.expiry_date[guestType] ?
                    <div className="col-md-6">
                        <div className="memberfieldsData">
                            <label>Date of Expiry</label>
                            <p>{expiry_date}</p>
                        </div>
                    </div>
                    : ""}
            </div>
        </div>
    );
};


const TravelDetails = (props) => {
    let { hotel_country, travel, guestType } = props;
    let data = getGuestDetails(props.data);
    let { guest } = data;
    let { purpose_of_visit, arrival_date, departure_date, country_of_origin, city_of_origin } = guest;
    return (
        <div className="reservationDetailWrapper">
            <h2 className="memeberDetailLabel">Travel Details</h2>
            {travel && travel.arrival_date && travel.arrival_date[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Arrival Date in {hotel_country}</label>
                                <p>{arrival_date ? moment(arrival_date).format("MMM D, YYYY") : "-"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment>
                : ""}

            {travel && travel.departure_date && travel.departure_date[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Departure Date from {hotel_country}</label>
                                <p>{departure_date ? moment(departure_date).format("MMM D, YYYY") : "-"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment>
                : ""}

            {travel && travel.purpose_of_visit && travel.purpose_of_visit[guestType] ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="memberfieldsData">
                                <label>Purpose of visit</label>
                                <p> {purpose_of_visit ? purpose_of_visit : "-"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="memberDetail_fields_border clearfix"></div>
                </React.Fragment>
                : ""}


            {travel &&
                ((travel.country_of_origin && travel.country_of_origin[guestType]) ||
                    (travel.city_of_origin && travel.city_of_origin[guestType])) ?
                <React.Fragment>
                    <div className="row">
                        {travel &&
                            travel.country_of_origin &&
                            travel.country_of_origin[guestType] ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>Country of origin</label>
                                    <p> {country_of_origin ? country_of_origin : "-"}</p>
                                </div>
                            </div>
                            : ""}
                        {travel &&
                            travel.city_of_origin &&
                            travel.city_of_origin[guestType] ?
                            <div className="col-md-6">
                                <div className="memberfieldsData">
                                    <label>City of origin</label>
                                    <p> {city_of_origin ? city_of_origin : "-"}</p>
                                </div>
                            </div>
                            : ""}
                    </div>
                </React.Fragment>
                : ""}
        </div>
    );
};

// const ArrivalDetails = (props) => {
//     let data = getGuestDetails(props.data);
//     let { guest, isGuestProfile } = data;
//     let { arrival_date, departure_date } = data;
//     let { country_name, nationality, date_of_birth } = guest;
//     let country = isGuestProfile ? country_name : nationality;
//     return (
//         <div className="reservationDetailWrapper">
//             <h2 className="memeberDetailLabel">Arrival Details</h2>
//             <div className="row">
//                 <div className="col-md-12">
//                     <div className="memberfieldsData">
//                         <label>Arriving From</label>
//                         <p>{arrival_date?moment(arrival_date).format("MMM D, YYYY"):"-"}</p>
//                     </div>
//                 </div>
//             </div>
//             <div className="memberDetail_fields_border clearfix"></div>
//             <div className="row">
//                 <div className="col-md-12">
//                     <div className="memberfieldsData">
//                         <label>Departing to</label>
//                         <p>{arrival_date?moment(departure_date).format("MMM D, YYYY"):"-"}</p>
//                     </div>
//                 </div>
//             </div>
//             <div className="memberDetail_fields_border clearfix"></div>
//             <div className="row">
//                 <div className="col-md-12">
//                     <div className="memberfieldsData">
//                         <label>Birth Date</label>
//                         <p> {arrival_date?moment(date_of_birth).format("MMM D, YYYY"):"-"}</p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

const PaymentDetails = (props) => {
    return (
        <div className="reservationDetailWrapper">
            <h2 className="memeberDetailLabel">Payment Details</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Authorized Amount</label>
                        <p>-</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Partial Refund</label>
                        <p>-</p>
                    </div>
                </div>
            </div>
            <div className="memberDetail_fields_border clearfix"></div>
            <div className="row">
                <div className="col-md-6">
                    <div className="memberfieldsData">
                        <label>Captured Amount</label>
                        <p>-</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const downloadImg = (url) => {
    return new Promise((resolve, reject) => {
        fetch(url, {
            mode: 'cors'
        })
            .then(res => res.blob())
            .then(res => {
                console.log("res 2", res);
                const reader = new FileReader();
                reader.readAsDataURL(res);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    resolve(base64data);
                }
            })
            .catch(err => {
                console.log("Error in fetch document front image", err);
            });
    })
}


const DocumentPhoto = (props) => {
    const [front, setFront] = useState("")
    const [back, setBack] = useState("")
    const fetchIDs = async () => {
        let { docid_front_url, docid_back_url } = props.data;
        if (docid_front_url != null) {
            let frontImg = await downloadImg(docid_front_url);
            setFront(frontImg);
        }

        if (docid_back_url != null) {
            let backImg = await downloadImg(docid_back_url);
            setBack(backImg);
        }


    }

    useEffect(() => {
        fetchIDs();
    }, [props.data])
    return (<Fragment>
        {back || front ?
            <div>
                <div className="col-md-12">
                    <div className="documentStatusText text-center clearfix">
                        <h4 className="memeberDetailLabel">
                            {props.data.docid_type ?
                                props.data.docid_type.charAt(0).toUpperCase() + props.data.docid_type.slice(1)
                                :
                                ""}</h4>
                    </div>
                </div>

            </div>

            : ""}
        {front ? (
            <div className="row">
                <div className="col-md-12">
                    <div className="documentPhoto">
                        <img src={front} alt="not found" />
                    </div>
                </div>
            </div>
        ) : null}
        {back ? (
            <div className="row">
                <div className="col-md-12">
                    <div className="documentPhoto">
                        <img src={back} alt="not found" />
                    </div>
                </div>
            </div>
        ) : null}

    </Fragment>);
};

const getGuestDetails = (data) => {
    let guest = data;
    let { guests_profile } = guest;
    let isGuestProfile = Boolean(
        Array.isArray(guests_profile) && guests_profile.length
    );
    if (isGuestProfile) guest = guests_profile[0];
    return { guest, isGuestProfile };
};

CheckinDetail.propTypes = {
    data: PropTypes.object.isRequired,
};

export default CheckinDetail;