import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, DropdownButton, OverlayTrigger, Tooltip } from 'react-bootstrap';

import StatusChips from "../components/common/StatusChips";

import Flag from 'react-world-flags'
import { downloadImage } from '../utils/index'
import msgSentImg from '../assets/images/sms_sent.svg';

class RervervationGrid extends React.Component {

    downloadIDCardImg = (front, back) => {
        let imageArray = [];
        if (front) {
            imageArray.push(front)
        }
        if (back) {
            imageArray.push(back)
        }
        downloadImage(imageArray);
    }

    render() {
        let { tailsDetails } = this.props;
        let filters = this.props.filters;
        let guestName = [tailsDetails.last_name, tailsDetails.first_name];
        let guestFullName = guestName.join(", ");
        let primaryGuestDocument = (tailsDetails.documents) ? tailsDetails.documents.find(doc => (doc.guest_type).toLowerCase() === "primary") : null;
        
        let nationality = tailsDetails.nationality;
        let countryCode = tailsDetails.country_code;
        if(!countryCode && primaryGuestDocument ) {
            nationality = primaryGuestDocument.nationality;
            countryCode = primaryGuestDocument.country_code;
        }
        let {guest_detail} = tailsDetails; 
        let phone_number =
                    tailsDetails && tailsDetails.phone_number?
                    tailsDetails.phone_number:
                    ((Object.keys(guest_detail).length !== 0 && guest_detail.constructor === Object)?
                   ( guest_detail &&(guest_detail?.phone_number) && guest_detail.phone_number?guest_detail.phone_number :""):"");
         
        let {documents} = tailsDetails;
        return (
            <React.Fragment>
                <div className="col-lg-4">
                    <div className="guestDetailBox" >
                        <NavLink to={`/reservation/${tailsDetails.id}`}>
                            <div className="clearfix wrapperDetailShow">
                                <div className="clearfix row">
                                    <div className="guestInfo col-lg-10 pr-0">
                                        <h1 className="guestName">{guestFullName}</h1>
                                        <div className="guestStatus">{}
                                            <StatusChips 
                                            statusKey={tailsDetails.reservation_status} 
                                            uploadAlert={ 
                                                tailsDetails.alert && 
                                                tailsDetails.reservation_status!=tailsDetails.alert && 
                                                tailsDetails.reservation_status!= "checkin_completed_with_alert"
                                                ?
                                                "upload_alert"
                                                :
                                                null} />
                                            {tailsDetails.is_credited ?
                                                <div className="message_status">
                                                    <OverlayTrigger overlay={<Tooltip>Message Sent.</Tooltip>}>
                                                        <img width="24" src={msgSentImg} alt="message sent" />
                                                    </OverlayTrigger>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                    <div className="guestcountry col-lg-2">
                                        <div className="countryFlag">
                                            {countryCode ? <Flag code={countryCode} className="flagLib" /> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 guestReservationDetail">
                                        <h3 className="reservationLabel">Booking Id #</h3>
                                        <p className="reservationNumber">{tailsDetails.external_booking_id}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="guestNationality">
                                            <h1 className="guestNationalityLabel">Nationality</h1>
                                            <p>{nationality}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="guestRoomNumber">
                                            <h1 className="guestRoomNumberLabel">Room #</h1>
                                            <p>{tailsDetails.room_number}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <div className="row guestDetailAction">
                            {filters.selectGuestType != "departing" ?
                                <React.Fragment>
                                    {(filters.selectGuestType == "staying" || tailsDetails.reservation_status == "staying") ?
                                        <React.Fragment>
                                            <div className="col-lg-11 pr-0">
                                                <div className="actionbutton d-inline">
                                                    <button className="showIdButton" onClick={(e) => this.props.showIdModalToggle(documents,guestFullName)}>Show ID</button>
                                                </div>
                                                <div className="actionbutton d-inline">
                                                    <button className="showIdButton" onClick={(e) => this.props.regCardModalModalToggle(tailsDetails.id)} >Download Reg Card</button>
                                                </div>
                                            </div>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <div className="col-lg-10 pr-0">
                                                {tailsDetails.reservation_status != "staying" &&
                                                    tailsDetails.reservation_status != "cancelled" &&
                                                    tailsDetails.reservation_status != "checkout" ?
                                                    <React.Fragment>
                                                        {filters.withDays == 0 ?
                                                            <div className="actionbutton d-inline">
                                                                <button className="checkinButton" onClick={(e) => this.props.checkinUser(tailsDetails.reservation_code)}>{"Arrived"}</button>
                                                            </div>
                                                            : null
                                                        }
                                                        {tailsDetails.reservation_status != "checkin_completed_with_payment" ? (
                                                        <div className="actionbutton d-inline">
                                                            <button className="inviteButton"
                                                                onClick={(e) =>
                                                                    this.props.inviteModalToggle(
                                                                        tailsDetails.reservation_code,
                                                                        phone_number,
                                                                        tailsDetails.country_code,
                                                                        tailsDetails.is_sms_resend,
                                                                        tailsDetails.reservation_status

                                                                    )
                                                                  }>Invite</button>
                                                        </div>
                                                        ) : null}
                                                    </React.Fragment>
                                                    :
                                                    null}
                                                <div className="actionbutton d-inline">
                                                    <button className="showIdButton"
                                                        onClick={(e) => this.props.showIdModalToggle(documents,guestFullName)}>Show ID</button>
                                                </div>
                                            </div>

                                            <div className="col-lg-2">
                                                <div className="moreActionButton">
                                                    <DropdownButton id="dropdown-basic-button" title=""
                                                        key="right"
                                                        drop="down">
                                                        <Dropdown.Item onClick={(e) => this.props.regCardModalModalToggle(tailsDetails.id)}>Download Registration Card</Dropdown.Item>
                                                        <Dropdown.Item onClick={(e) => this.props.showIdModalToggle(documents,guestFullName)}  >Download ID Card</Dropdown.Item>
                                                         <Dropdown.Item onClick={(e) => this.props.addAlertToggle(tailsDetails.external_booking_id,tailsDetails.reservation_code)}  >Add Alert</Dropdown.Item>
                                                        {/* <Dropdown.Item href="#/action-3">Push to PMS</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">Generate Payment Link</Dropdown.Item> */}
                                                    </DropdownButton>
                                                </div>
                                            </div>

                                        </React.Fragment>}

                                </React.Fragment> :
                                <React.Fragment>
                                    {/* <div className="col-lg-10 pr-0">
                                        <div className="actionbutton d-inline">
                                            <button className="checkinButton">Send Checkout Invite </button>
                                        </div>

                                    </div> */}
                                </React.Fragment>
                            }


                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default RervervationGrid;
