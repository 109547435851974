module.exports = { 
    "environment": {
      api_baseurl: "https://api.thru.app/dashboard",
      payment_api_baseurl: "ttps://api.thru.app/payment",
      api_key: "tiOJa0y9Ng9XSYs7bnGN95BWbpmkFBMUjgSkKiWc",
      headers: { 
        'Content-Type': 'application/json',
        'Accept':'application/json',
        'Accept-Language':'en',
        'X-API-Key':'tiOJa0y9Ng9XSYs7bnGN95BWbpmkFBMUjgSkKiWc'
      }
    },
    "auto_recharge": {
      threshold_limit: 25,
      auto_recharge_quantity: 50,
      max_auto_recharge_quantity: 10000
    },
    "balance_poll_time": 15
  }
