import React, { Component, Fragment } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import axios from '../../axios';

import AddCardModal from "../modal/AddCardModal";
import TopupRecharge from '../billing/TopUpRecharge';
import PaymentDetails from "./PaymentDetails";
import TopupModal from '../modal/TopupModal';
import ConfirmModal from '../modal/ConfirmModal';
import { toast } from 'react-toastify';
import moment from 'moment';
import { connect } from 'react-redux';
import NeedHelp from '../common/NeedHelp';

class Credits extends Component {
    toggleConfirmModal = () => this.setState({ confirmModal: !this.state.confirmModal });
    addCardModalToggle = () => this.setState({ AddCardModal: !this.state.AddCardModal });
    toggleTopupModal = () => this.setState({ TopupModal: !this.state.TopupModal });
    // getUserAdminStatus = () => {
    //     let is_admin = false, { user } = this.props;
    //     if (user) {
    //         let { attributes } = user;
    //         if(attributes)
    //             is_admin = attributes['custom:is_admin'] === "1" ? true : false;
    //     }
    //     return is_admin;
    // }

    state = {
        loading: true,
        configs: false,

        AddCardModal: false,
        TopupModal: false,
        confirmModal: false,
        confirmModalProps: {
            title: "",
            showModal: false,
            children: "",
            onConfirm: () => { },
            onHide: this.toggleConfirmModal
        },

        is_admin: this.props.is_admin,
        stripePromise: false,
        payment_methods: false,
        autoRechargeDetails: false,
        pending_invoices: false,
        topupdata: false,
        balance: false
    }

    componentDidMount() {
        let { is_admin } = this.state;
        this.fetchBalance();
        if (is_admin) {
            this.fetchPaymentMethods();
            this.fetchAutoRechargeDetails();
        }
    }

    fetchConfigs = () => {
        this.setState({ configs: false });
        axios.get('/dashboard/hotel/getPaymentConfig')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, data } = api_response;
                    if (status) {
                        let { publishable_key } = data;
                        this.setState({ ...this.state, configs: data, stripePromise: loadStripe(publishable_key) });
                    } else {
                        this.setState({ ...this.state, loading: false })
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(e => {
                console.error(e);
            });
    }

    fetchBalance = () => {
        axios.get('/dashboard/hotel/getBalanceReservation')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, data } = api_response;
                    if (status) {
                        this.setState({ ...this.state, balance: data });
                    } else {
                        console.log(res);
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(e => {
                console.error(e);
            })
    }

    fetchPaymentMethods = () => {
        axios.get('/dashboard/hotel/getPaymentMethod')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, data } = api_response;
                    if (status) {
                        this.setState({ ...this.state, payment_methods: data });
                    } else {
                        console.log(res);
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                this.setState({ ...this.state, loading: false });
            });
    }

    fetchAutoRechargeDetails = () => {
        axios.get('/dashboard/hotel/autoRechargeDetail')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, data } = api_response;
                    if (status) {
                        this.setState({ ...this.state, autoRechargeDetails: data });
                    } else {
                        console.log(res);
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                this.setState({ ...this.state, loading: false });
            });
    }

    fetchPendingInvoice = () => {
        this.setState({ ...this.state, loading: true });
        let setLoading = true;
        axios.get('/dashboard/hotel/getPendingRechargeInvoice')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, data, message } = api_response;
                    if (status) {
                        let { invoice_details } = data;
                        if (Array.isArray(invoice_details) && invoice_details.length) {
                            this.showConfirmModal({
                                title: "Confirmation",
                                size: "lg",
                                footer: true,
                                cancelBtn: true,
                                cancelBtnText: "No",
                                confirmBtn: true,
                                confirmBtnText: "Yes",
                                children: (<div>
                                    <p>You have pending invoices. Do you wish to continue to payment?</p>
                                    <div className="modal_body_confirm">
                                        {invoice_details.map(d =>
                                            <div className="date_time_wrapper">
                                                <div>
                                                    <div className="data_created">
                                                        {d.created_by}
                                                    </div>
                                                    <div className="date_time">
                                                        {moment(d.payment_time).format("MMM D, YYYY")}
                                                    </div>
                                                </div>
                                                <div className="date_quantity">
                                                    {d.quantity + " credits"}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>),
                                onConfirm: this.createInvoice,
                                onHide: this.toggleConfirmModal
                            })
                        } else {
                            setLoading = false;
                            this.createInvoice();
                        }
                    } else {
                        console.log(res);
                        toast.error(message);
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(e => {
                console.error(e);
                toast.error('Unable to create Invoice. Please try again.');
            })
            .finally(() => {
                if (setLoading) this.setState({ ...this.state, loading: false });
            });
    }

    createInvoice = () => {
        let postData = {
            payment_method: "topup",
            quantity: this.state.topupdata.quantity
        }
        this.setState({ ...this.state, loading: true });
        axios.post('/dashboard/hotel/createInvoice', postData)
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, message } = api_response;
                    if (status) {
                        this.toggleTopupModal();
                        toast.success(message);
                    } else {
                        toast.error(message);
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(e => {
                console.error(e);
                toast.error('Unable to create Invoice. Please try again.');
            })
            .finally(() => this.setState({ ...this.state, loading: false }));
    }

    saveTopUpData = (data) => {
        this.setState({
            ...this.state,
            loading: true,
            topupdata: data
        }, this.fetchPendingInvoice);
    }

    showConfirmModal = (props) => {
        this.setState({
            ...this.state,
            confirmModalProps: {
                ...this.state.confirmModalProps,
                ...props
            },
            confirmModal: true
        })
    }

    render() {
        let { is_admin, balance } = this.state;
        return (
            <Fragment>
                <div className="signup_steps_content_wrapper billing_credits">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <TopupRecharge
                                is_admin={this.state.is_admin}
                                autoRechargeDetails={this.state.autoRechargeDetails}
                                balance={this.state.balance}
                                payment_methods={this.state.payment_methods}
                                toggleTopupModal={this.toggleTopupModal}
                            />
                            {is_admin && balance && !balance.is_unlimited
                                ? <Fragment>
                                    <h3 className="steps_header">Payment Details</h3>
                                    <PaymentDetails
                                        autoRechargeDetails={this.state.autoRechargeDetails}
                                        payment_methods={this.state.payment_methods}
                                        fetchAutoRechargeDetails={this.fetchAutoRechargeDetails}
                                        balance={this.state.balance}
                                        submitAutoRechargeForm={this.submitAutoRechargeForm}
                                        fetchConfigs={this.fetchConfigs}
                                        loading={this.state.loading}
                                        toggleCardModal={this.addCardModalToggle}
                                        showConfirmModal={this.showConfirmModal}
                                        toggleConfirmModal={this.toggleConfirmModal}
                                    />
                                </Fragment>
                                : null
                            }
                        </div>
                        {/* <div className="col-lg-4 col-md-12">
                            <div className="tip_container">
                                <div className="tip_image"><img src='/images/status.svg' /></div>
                                <div className="tip_text">
                                    <p>Add the billing address to receive the invoice. If billing address is same as the hotel address then click on the button.</p>
                                </div>
                            </div>
                        </div>
                     */}
                    </div>
                </div>
               <NeedHelp/>
                <AddCardModal
                    configs={this.state.configs}
                    stripePromise={this.state.stripePromise}
                    showConfirmModal={this.showConfirmModal}
                    fetchPaymentMethods={this.fetchPaymentMethods}
                    showInviteModal={this.state.AddCardModal}
                    onHide={this.addCardModalToggle}
                />
                <TopupModal
                    payment_methods={this.state.payment_methods}
                    showModal={this.state.TopupModal}
                    saveTopUpData={this.saveTopUpData}
                    loading={this.state.loading}
                    onHide={this.toggleTopupModal}
                    fetchPaymentMethods={this.fetchPaymentMethods}
                    fetchPendingInvoice={this.fetchPendingInvoice}
                    toggleConfirmModal={this.toggleConfirmModal}
                />
                <ConfirmModal {...this.state.confirmModalProps} showModal={this.state.confirmModal} />
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    is_admin: state.is_admin
})

export default connect(mapStateToProps)(Credits);