import React from "react";
import Layout from "../components/Layout";
import OtpInput from "react-otp-input";
import Loader from "../components/common/Loader";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import {
  resendConfirmationCode,
  loginUser
} from "../actions";
import { getStorage } from "../utils";
import VerifyContact from "../components/common/VerifyContact";

class VerifyConfirmUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resendBtn: false,
      count: "00:00",
      loading:false,
      mobileVarify:false,
      signUpUserDetails: {},
      otp: " ",
      varifyEmail: {
        username: "",
        code: "",
      },
    };
  }

  componentDidMount() {
    let signUpUserDetails = getStorage("signUpUser", "object", false);
    let userType = getStorage("userType", "string", false);
    if (signUpUserDetails) {
      this.setState({
        signUpUserDetails: signUpUserDetails,
      }, () => {
        if (userType) {
          console.log("==signUpUserDetails=====",signUpUserDetails)
          localStorage.removeItem("userType");
          this.resendCode();
        }
      });
      this.countdown(60);
    }
    // start the countdown
  }

  handleOTPChange = (otp) => {
    this.setState({ otp: otp }, () => {
      let SignUpUser = this.state.signUpUserDetails;
      if (SignUpUser && SignUpUser.hasOwnProperty("username")) {
        let username = SignUpUser.hasOwnProperty("username")
          ? SignUpUser.username
          : "";
        let otpVal = this.state.otp;
        if (otpVal.length == 6) {
          this.setState(
            {
              ...this.state,
              varifyEmail: {
                ...this.state.varifyEmail,
                username: username,
                code: otpVal,
              },
            },
            () => {
              let { varifyEmail } = this.state;
              this.setState({loading:true})
              Auth.confirmSignUp(varifyEmail.username, varifyEmail.code)
              .then((confirmuUser) => {
                let signUpUserDetails = getStorage("signUpUser", "object", false);
                if (signUpUserDetails) {
                  let { username, password } = signUpUserDetails;
                  this.setState({loading:false,mobileVarify:true})
                  this.props.dispatch(loginUser(username, password, this.props.history))
                }
        
              })
              .catch((err) => {
                this.setState({loading:false,otp:""})
                console.log(err);
               toast.error(err.message);
              });
            }
          );
        }
      }
    });
  };

  countdown = (seconds) => {
    let _this = this;
    this.sec = seconds;
    this.mins = seconds > 60 ? seconds / 60 : 0;

    const tick = () => {
      if (this.sec > 60) {
        this.current_minutes = this.mins - 1;
      }
      this.sec--;
      this.setState({
        count:
          (this.sec < 10 ? "0" : "") +
          String(this.sec),
      });
      if (this.sec > 0) {
        setTimeout(tick, 1000);
      } else {
        _this.setState({ resendBtn: true });
        if (this.mins > 1) {
          this.countdown(this.mins - 1);
        }
      }
    };
    tick();
  };

  resendCode = () => {
    let SignUpUser = this.state.signUpUserDetails;
    if (SignUpUser && SignUpUser.hasOwnProperty("username")) {
      let username = SignUpUser.hasOwnProperty("username")
        ? SignUpUser.username
        : "";
      this.setState({ otp: "", resendBtn: false });
      window.clearTimeout()
      this.countdown(60);
      this.props.dispatch(resendConfirmationCode(username));
    }
  };

  render() {
    let SignUpUser = (this.state.signUpUserDetails.hasOwnProperty("username") ? this.state.signUpUserDetails : {});

    let { loading,mobileVarify } = this.state;
    return (
      <Layout isSignUP>
        <div className="signup_container">
          <div className="mobile_validate_form">
            <div className="signup_form_content">
              <h1>Validate your phone number</h1>
              <h3>
                We’ve sent a code to {" "}

                {SignUpUser.hasOwnProperty("attributes")
                  ? <b>{SignUpUser.attributes.phone_number}</b>
                  : " your registered phone number"}

                {/* <a className="change_request">Change</a> */}
              </h3>
              <div className="otp_input_signup">
                <OtpInput
                  value={this.state.otp}
                  onChange={this.handleOTPChange}
                  numInputs={6}
                  shouldAutoFocus={true}
                  // isInputNum
                  separator={<span className="otp_seperator"></span>}
                />
              </div>
              <div className="loader_signup">
                {loading ? (
                  <React.Fragment>
                    <Loader /> <span>Please Wait..</span>
                  </React.Fragment>
                ) : (
                    ""
                  )}
              </div>
              <div className="privacy_policy">
                {this.state.resendBtn ?
                  <React.Fragment>
                    Did not receive an confirmation code?{" "} <a onClick={this.resendCode}> Resend </a>
                  </React.Fragment>
                  :
                  <React.Fragment>
                   You can request to resend the confirmation code in {this.state.count} seconds..
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
        {mobileVarify ?<VerifyContact verifyText={"Phone Number Verified!"} />:null}
      </Layout>
    );
  }
}

function mapState(state) {
  return {
    // confirmSignUp:state.confirmSignUp,
    resendConfirmationCode:state.resendConfirmationCode,
    hotel:state.hotel
  };
}

export default connect(mapState)(withRouter(VerifyConfirmUser));