import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from "react-toastify";

import Login from './pages/login';
import ForgotPassword from './pages/forgotpassword';

import Dashboard from './pages/dashboard';
import ReservationDetail from './pages/reservation/reservation';

import SignUp from './pages/signup';
import MobileVerify from './pages/mobileverify';
import EmailVerify from './pages/emailverify';
import VerifyConfirmUser from './pages/verifyConfirmUser';
import CompleteNewPassword from './pages/complete-new-password';

import HotelInfo from './pages/hotelinfo';
import Billing from './pages/billing';
import GuestDetail from './pages/guestdetail';
import Terms from './pages/terms';
import Payment from './pages/payment';
import Team from './pages/team';
import LanguageSupport from './pages/language';

import Account from './pages/account';

import UploadCSV from './pages/uploadCSV';
import topup from './pages/topup';
import NotificationToast from './components/common/NotificationToast';

import './App.css';


class App extends Component {

  render() {
    let path = this.props.location.pathname;
    let showNotification = ['/dashboard', '/reservation', '/edit'].some(p => path.indexOf(p) !== -1)
    return (
      <Fragment>
        <ToastContainer />
        {showNotification ? <NotificationToast /> : null}
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/mobileverify" component={MobileVerify} />
          <Route exact path="/emailverify" component={EmailVerify} />
          <Route exact path="/VerifyConfirmUser" component={VerifyConfirmUser} />
          <Route exact path="/complete-new-password" component={CompleteNewPassword} />

          <Route exact path="/forgotpassword" component={ForgotPassword} />

          <Route exact path="/hotelinfo" component={HotelInfo} />
          <Route exact path="/billing" component={Billing} />
          <Route exact path="/guestdetail" component={GuestDetail} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/payment" component={Payment} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/topup" component={topup} />

          <Route path="/account/edit" component={Account} />
          <Route path="/billing/edit" component={Billing} />
          <Route path="/hotelinfo/edit" component={HotelInfo} />
          <Route path="/guestdetail/edit" component={GuestDetail} />
          <Route path="/terms/edit" component={Terms} />
          <Route path="/payment/edit" component={Payment} />
          <Route path="/team/edit" component={Team} />
          <Route path="/language/edit" component={LanguageSupport} />

          <Route exact path="/uploadcsv" component={UploadCSV} />
          <Route exact path="/reservation/:id" component={ReservationDetail} />

          <Route path="/*" component={() => <h1>404! Page Not Found</h1>} />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(App);
