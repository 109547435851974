import { parsePhoneNumberFromString } from 'libphonenumber-js';
const validEmailRegex = RegExp("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")
let  upperCaseLetters = RegExp('(?=.*?[A-Z])');
let  lowerCaseLetters = RegExp('(?=.*?[a-z])');
let  digitVal = RegExp('(?=.*?[0-9])');
let  specialChar = RegExp('(?=.*?[#?!@$%^&*-])');
let  passwordLength = RegExp('.{8,}');
let space =RegExp('^\\S*$');
export const checkValidation = (name, value, formErrors) => {
  switch (name) {

    case "username":
      formErrors[name] =
        value.length < 3 ? "Please enter valid username." : "";
      return formErrors;

      case "login_password":
        formErrors[name] =
        value.length < 8 ? "Please enter valid password." : "";
      return formErrors;

    case "password":
      if(!value.match(upperCaseLetters)){
        formErrors[name] = "At least one uppercase letter";
      }else if(!value.match(lowerCaseLetters)){
        formErrors[name] = "At least one lowercase letter";
      }else if(!value.match(digitVal)){
        formErrors[name] = "At least one numeric value";
      }else if(!value.match(specialChar)){
        formErrors[name] = "At least one special character";
      }else if(!value.match(passwordLength)){
        formErrors[name] = "Minimum  8 characters required. ";
      }else if(!value.match(space)){
        formErrors[name] = "Space not allowed.";
      }else{
        formErrors[name] ="";
      }
      return formErrors;

    case "email":
      formErrors[name] = validEmailRegex.test(value)
        ? ""
        : "Please enter valid email address.";
      return formErrors;

    case "phone_number":
     if (value.length > 0) {
        let phoneNumber = parsePhoneNumberFromString(value);
        if (phoneNumber != undefined) {
          if (!phoneNumber.isValid()) {
            formErrors[name] = "Enter valid phone number.";
          } else {
            formErrors[name] = "";
          }
        }else{
          formErrors[name] = "Enter valid phone number.";
        }
      } else {
        formErrors[name] = "Enter valid phone number.";
      }
      return formErrors;
      
    case "otp":
      formErrors[name] =
        value.length == 6
          ? ""
          : "Enter valid confirmation code";
      return formErrors;

      case "description":
        formErrors[name] =
        value.length !=""
          ? ""
          : "Please enter description.";
      return formErrors;
     
      default:
        return false;

  }
};
