import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { startCase } from "lodash";
// import SimpleReactValidator from 'simple-react-validator';
import Switch from 'react-switch';
import FormElements from '../formElements/FormElements';
import Loader from '../common/Loader';
import { toast } from 'react-toastify';
import { formToJSON, formatNumber } from '../../utils';
import { formatCurrency } from 'country-currency-map-3';
import axios from '../../axios';
import appConfig from '../../app-config';

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      auto_recharge: false,
      threshold_limit: appConfig.auto_recharge.threshold_limit,
      auto_recharge_quantity: appConfig.auto_recharge.auto_recharge_quantity
    };
    this.setChecked = false;
    this.form = new createRef();
    // this.validator = new SimpleReactValidator({
    //   messages: {
    //     required: "Please enter :attribute"
    //   }
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.autoRechargeDetails !== false && !this.setChecked) {
      this.setChecked = true;
      let { autoRechargeDetails } = nextProps;
      if (autoRechargeDetails) {
        let { auto_recharge, auto_recharge_quantity, threshold_limit } = autoRechargeDetails;
        if (threshold_limit < appConfig.auto_recharge.threshold_limit)
          threshold_limit = appConfig.auto_recharge.threshold_limit;
        if (auto_recharge_quantity < appConfig.auto_recharge.auto_recharge_quantity)
          auto_recharge_quantity = appConfig.auto_recharge.auto_recharge_quantity;
        this.setState({ auto_recharge, auto_recharge_quantity, threshold_limit });
      }
    }
  }

  checkStateChange = () => {
    let isSame = true;
    if (this.props.autoRechargeDetails !== false) {
      isSame = (this.state.auto_recharge == this.props.autoRechargeDetails.auto_recharge
        && this.state.threshold_limit == this.props.autoRechargeDetails.threshold_limit
        && this.state.auto_recharge_quantity == this.props.autoRechargeDetails.auto_recharge_quantity
      )
    }
    return isSame;
  }

  checkLess = () => {
    let { auto_recharge, auto_recharge_quantity, threshold_limit } = this.state;
    if (auto_recharge) {
      if (auto_recharge_quantity === "" || threshold_limit === "") return true;
      try {
        auto_recharge_quantity = parseInt(auto_recharge_quantity);
        threshold_limit = parseInt(threshold_limit);
      } catch (e) {
        console.log(e);
        auto_recharge_quantity = 0;
        threshold_limit = 0
      }
      return auto_recharge_quantity < appConfig.auto_recharge.auto_recharge_quantity || threshold_limit < appConfig.auto_recharge.threshold_limit;
    } else {
      return false;
    }
  }

  handleSwitch = (auto_recharge) => {
    this.setState({ auto_recharge });
  }

  getCardDetails = () => {
    if (this.props.payment_methods === false) {
      return (<div><Loader /></div>);
    } else {
      if (Array.isArray(this.props.payment_methods.cardDetails)
        && this.props.payment_methods.cardDetails.length) {
        let { cardDetails } = this.props.payment_methods;
        return cardDetails.map((c, i) => {
          return (<div key={i} className="form_element_wrapper">
            <div className="form_element">
              <FormElements
                type="text"
                onChange={() => { }}
                value={`${startCase(c.brand)} ****${c["last4"]}`}
                readOnly
                label="Card"
                name="card"
              />
            </div>
            {/* <button className="editbtn" onClick={()=>this.props.showConfirmModal({
              title: "Confirmation",
              onConfirm: ()=>console.log('Confirmed delete'),
              children: <p>Are you sure you want to delete this card?</p>
            })}>Delete</button> */}
          </div>);
        })
      } else {
        return (<div className="form_element_wrapper">
          <div className="form_element">
            <div className="addmore_btn">
              <button type="button" onClick={() => {
                this.props.fetchConfigs();
                this.props.toggleCardModal();
              }}>
                {"Add Credit Card"}
                <img src='/images/iconbtn-plus.svg' alt="add icon" />
              </button>
            </div>
          </div>
        </div>);
      }
    }
  }

  onKeyPress = (e) => {
    let keys = Array.from(Array(10).keys()).map(i => i.toString());
    if (!keys.includes(e.key)) e.preventDefault();
  }

  handleInputChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, loading: true });
    // if (this.validator.allValid()) {
    let postData = formToJSON(this.form.current);
    postData.auto_recharge = this.state.auto_recharge;
    console.log(postData);
    axios.post('/dashboard/hotel/autoRechargeDetail', postData)
      .then(res => {
        console.log(res);
        if (res.status === 200) {
          let api_response = res.data;
          let { status } = api_response;
          if (status) {
            this.setState({ ...this.state, loading: false }, this.props.fetchAutoRechargeDetails);
            toast.success('Auto recharge details updated successfully.');
          } else {
            this.setState({ ...this.state, loading: false });
          }
        } else {
          console.log(res);
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({ ...this.state, loading: false });
      });
    // } else {
    //   this.validator.showMessages();
    //   this.setState({ ...this.state, loading: false }, this.handleScroll);
    // }
  }

  getPrice = () => {
    let returnHTML = 0;
    let { auto_recharge_quantity } = this.state;
    let { balance } = this.props;
    if (balance) {
      let { unit_price, currency_code } = balance;
      if (auto_recharge_quantity !== "") {
        let amount = parseInt(auto_recharge_quantity) * unit_price;
        returnHTML = formatCurrency(formatNumber(amount), currency_code);
      }
    }
    return returnHTML;
  }

  render() {
    let showAutoRecharge = (this.props.autoRechargeDetails !== false && this.props.payment_methods
      && Array.isArray(this.props.payment_methods.cardDetails) && this.props.payment_methods.cardDetails.length);
    let disabled = (this.state.loading || this.checkStateChange() || this.checkLess());
    return (
      <div className="floating-form">
        {this.getCardDetails()}
        {showAutoRecharge
          ? <form ref={this.form} onSubmit={this.handleSubmit}>
            <div className="setup-recharge-block">
              <h4 className="setup_header">
                <span>Setup Auto-Recharge</span>
                <span className="switch_declaration">
                  <Switch
                    onChange={this.handleSwitch}
                    checked={this.state.auto_recharge}
                    onHandleColor="#fff"
                    onColor="#1772ad"
                    handleDiameter={10}
                    //offColor="rgba(0,0,0,0.3)"
                    width={32}
                    height={18}
                    checkedIcon={false}
                    uncheckedIcon={false}
                  />
                </span>
              </h4>
              <div className="switch_text">
                {"Specify a threshold for your Thru credits. Once the threshold is reached, your specified number of top-up reservations will automatically be added to your account, ensuring that your check-ins continue uninterrupted."}
              </div>
            </div>
            {this.state.auto_recharge
              ? (<Fragment>
                <div className="form_element_wrapper">
                  <div className="form_element">
                    <FormElements
                      type="number"
                      onChange={this.handleInputChange}
                      min={appConfig.auto_recharge.threshold_limit}
                      // validator={this.validator}
                      // validations="required|min:25"
                      // validatorOptions={{ messages: { min: ":attribute must be greater or equal to 25." } }}
                      onKeyPress={this.onKeyPress}
                      value={this.state.threshold_limit}
                      label="Minimum Number of Reservations"
                      name="threshold_limit"
                    />
                  </div>
                  <span className="help_info">
                    {"When this minimum is reached, we will top-up your account automatically."}
                  </span>
                </div>
                <div className="form_element_wrapper top_up_wrapper">
                  <div className="form_element">
                    <FormElements
                      type="number"
                      onChange={this.handleInputChange}
                      min={appConfig.auto_recharge.auto_recharge_quantity}
                      max={appConfig.auto_recharge.max_auto_recharge_quantity}
                      // validator={this.validator}
                      // validations="required|min:100"
                      // validatorOptions={{ messages: { min: ":attribute must be greater or equal to 100." } }}
                      onKeyPress={this.onKeyPress}
                      value={this.state.auto_recharge_quantity}
                      label="Top-Up Quantity"
                      name="auto_recharge_quantity"
                    />
                  </div>
                  <span className="help_info">
                    {"The amount of "}<span className="primary">{this.getPrice()}</span> {`(${this.state.auto_recharge_quantity !== "" ? formatNumber(parseInt(this.state.auto_recharge_quantity)): 0} reservations) will be charged on the next cycle.`}
                  </span>
                </div>
              </Fragment>)
              : null
            }
            <div className="continue-btn">
              <div className="formSubmitButton">
                <button disabled={disabled} className="themeButton">
                  {this.state.loading ? "Please wait.." : "Save"}
                </button>
              </div>
            </div>
          </form>
          : null
        }
      </div>
    );
  }
}

PaymentDetails.propTypes = {
  payment_methods: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  toggleCardModal: PropTypes.func.isRequired
};

export default PaymentDetails;