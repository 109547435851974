import { constants } from "../constants";

// export const setKey = (key, value) => ({
//   type: constants.SET_KEY,
//   key: key,
//   value: value
// });

export const setKey = (key, value) => dispatch => {
  dispatch({
    type: constants.SET_KEY,
    key: key,
    value: value
  })
  return Promise.resolve();
  
};

export const clearState = () => ({
  type: constants.CLEAR_STATE
})