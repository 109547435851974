import React, { Component } from 'react';
import Layout from './Layout';
import StepsHeader from './StepsHeader';
import StepsEditHeader from './StepsEditHeader';
// import { StickyContainer, Sticky } from 'react-sticky';
import { withRouter } from 'react-router-dom';

class SignupStepLayout extends Component {

    headerStyle = (style) => {
        return {
            ...style,
            top: '0',
            left: '0',
            width: '100%'
        }
    }

    render() {
        let { pathname } = this.props.location;
        let isEdit = pathname.includes('/edit');
        if(isEdit || this.props.isEdit){
            return (
                <Layout isSignUP dashboardBtn>
                    {/* <StickyContainer className="signup_main_container">
                        <div className="signup_steps_container_wrapper">
                            <Sticky topOffset={32}>
                                {({ style, isSticky },index) => (
                                    <div><StepsEditHeader  sticky={isSticky} style={this.headerStyle(style)} /></div>
                                )}
                            </Sticky>
                            <div className="signup_steps_container edit">
                                {this.props.children}
                            </div>
                        </div>
                    </StickyContainer> */}
                     <div className="signup_main_container">
                        <div className="signup_steps_container_wrapper">
                        <div><StepsEditHeader  /></div>
                            <div className="signup_steps_container edit">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </Layout>
            );
        } else {
            return (
                <Layout isSignUP steps>
                    {/* <StickyContainer className="signup_main_container">
                        <div className="signup_steps_container_wrapper">
                            <Sticky topOffset={120} disableCompensation>
                                {({ style, isSticky }) => (
                                    <div><StepsHeader sticky={isSticky} style={this.headerStyle(style)} /></div>
                                )}
                            </Sticky>
                            <div className="signup_steps_container">
                                {this.props.children}
                            </div>
                        </div>
                    </StickyContainer> */}
                     <div className="signup_main_container">
                        <div className="signup_steps_container_wrapper">
                        <div><StepsHeader /></div>
                            <div className="signup_steps_container">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </Layout>
            );
        }
    }
}

export default withRouter(SignupStepLayout);