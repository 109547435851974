
import React from "react";
import FormElements from "../components/formElements/FormElements";
import { Auth } from "aws-amplify";
import { checkValidation } from "../lib/formValidation";
import { toast } from 'react-toastify';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameVal: "",
            loading: false,
            resendBtn: false,
            count: 60,
            userValidation: {
                email: "",
                formErrors: {
                    email: ""
                },
            },
            passwordVarification: {
                otp: "",
                password: "",
                formErrors: {
                    otp: "",
                    password: "",
                }
            },
            usernameVarify: false,
        };
    }

    formValid = ({ formErrors, ...rest }) => {
        let valid = true;
        Object.entries(formErrors).forEach(([key, value]) => {
            if (value.length > 0) {
                return (valid = false);
            }
        });

        // if (valid) {
            Object.entries(rest).forEach(([key, value], index) => {
                if (value.length <= 0) {
                    this.updateValidFormState(key, value);
                    return (valid = false);
                }
            });
        // }
        return valid;
    };

    updateValidFormState = async (name, value) => {
        let { usernameVarify } = this.state;
        let formErrors = {};
        let stateVal = ""
        if (!usernameVarify) {
            formErrors = this.state.userValidation.formErrors;
            stateVal = "userValidation";
        } else {
            formErrors = this.state.passwordVarification.formErrors;
            stateVal = "passwordVarification";
        }
        if (Object.keys(formErrors) !== 0 && formErrors.constructor === Object) {
            let formValidationData = await checkValidation(name, value, formErrors);
            this.setState({
                ...this.state,
                [stateVal]: {
                    ...this.state[stateVal],
                    formErrors: formValidationData,
                    [name]: value,
                },
            });
        }
    };

    onChangeHandle = (e) => {
        let { name, value } = e.target;
        if (name == "email") {
            this.setState({
                ...this.state,
                userValidation: {
                    ...this.state.userValidation,
                    [name]: value
                }
            });
        } else {
            this.setState({
                ...this.state,
                passwordVarification: {
                    ...this.state.passwordVarification,
                    [name]: value
                }
            });
        }
       
    };

    handleOTPChange = (otp) => {
        this.setState({
            ...this.state,
            passwordVarification: {
                ...this.state.passwordVarification,
                otp: otp
            }
        })
        this.updateValidFormState('otp', otp);
    };

    handleBlur =(e)=>{
        let { name, value } = e.target;
        console.log(name,"====name=value====",value)
        this.updateValidFormState(name, value);
      }

    confirmUserName = (e) => {
        e.preventDefault();
        if (this.formValid(this.state.userValidation)) {
            let { loading, usernameVal } = this.state;
            if (!loading) {
                this.setState({ loading: true })
                let { email } = this.state.userValidation;
                if (usernameVal != email) {
                   this.setState({ usernameVal: email })
                    Auth.forgotPassword(email)
                        .then(data => {
                            console.log("==data=====",data)
                            this.countdown(60);
                            this.setState({ usernameVarify: true, loading: false })
                            toast.success("Code send to your registered email.")
                        })
                        .catch(err => {
                            console.log("error=====", err)
                            this.setState({ loading: false })
                        });
                } else {
                    this.setState({ usernameVarify: true, loading: false })
                }
            }
        }
    }


    ResetPassword = (e) => {
        e.preventDefault();
        let resetPasswordData = this.state.passwordVarification
        if (this.formValid(resetPasswordData)) {
            let { loading } = this.state;
            if (!loading) {
                this.setState({ loading: true })
                let { email } = this.state.userValidation;
                let { otp, password } = resetPasswordData;
                Auth.forgotPasswordSubmit(email, otp, password)
                    .then(data => {
                        toast.success("Password reset successfully.")
                        console.log("forgotPasswordSubmit====", data)
                        this.props.history.push('/login');
                    })
                    .catch(err => {
                        console.log(err)
                        this.setState({ loading: false })
                        if (err.code == "CodeMismatchException") {
                            toast.error(err.message)
                            this.handleOTPChange("")
                        } else {
                            toast.error(err.message)
                        }
                    });
            }

        }
    }

    backBtn = () => {
        this.setState({ usernameVarify: false })
    }

    countdown = (seconds) => {
        let _this = this;
        this.sec = seconds;
        this.mins = seconds > 60 ? seconds / 60 : 0;

        const tick = () => {
            if (this.sec > 60) {
                this.current_minutes = this.mins - 1;
            }
            this.sec--;
            this.setState({
                count:
                    (!this.mins ? "00" : this.current_minutes.toString()) +
                    ":" +
                    (this.sec < 10 ? "0" : "") +
                    String(this.sec),
            });
            if (this.sec > 0) {
                setTimeout(tick, 1000);
            } else {
                _this.setState({ resendBtn: true });
                if (this.mins > 1) {
                    this.countdown(this.mins - 1);
                }
            }
        };
        tick();
    };

    resendCode = () => {
        let { email } = this.state.userValidation;
        if (email) {
            this.setState({ otp: "", resendBtn: false });
            window.clearInterval();
            console.log("email======", email)
            Auth.forgotPassword(email)
                .then(data => {
                    this.countdown(60);
                    toast.success("Code send to your registered email.")
                })
                .catch(err => {
                    console.log("error=====", err)
                });
        }
    };

    render() {
        let { otp, password } = this.state.passwordVarification;
        let { email } = this.state.userValidation;
        let userValidation = this.state.userValidation.formErrors;
        let passwordVarification = this.state.passwordVarification.formErrors;
        return (
            <div className="login_container height_100">
                <div className="clearfix d-flex align-items-center height_100">
                    <div className="height_100 loginForm">
                        <div className="clearfix width_100 fomrWrapMax">
                            <div className="thru-logo">
                                <img src="/images/logo.png" alt="thru logo" />
                            </div>

                            <div className="clearfix">
                                <div className="login_heading">
                                    <h1>Reset your thru account password</h1>
                                </div>
                                {!this.state.usernameVarify ?
                                    <React.Fragment>
                                        <form autoComplete="off" method="post" onSubmit={this.confirmUserName} >
                                            <div className="floating-form">
                                                <FormElements
                                                    type="text"
                                                    defaultValue={email}
                                                    label="Email"
                                                    name="email"
                                                    value={email}
                                                    errorMessage={userValidation.email}
                                                    onChange={this.onChangeHandle}
                                                    onBlur ={this.handleBlur}
                                                />
                                            </div>
                                            <div className="formSubmitButton row">
                                                <div className="col-lg-5 pr-0">
                                                    <button
                                                        type="button"
                                                        className="signUpAction">
                                                        <Link to={"/login"}>
                                                            Back
                                                        </Link>
                                                    </button>

                                                </div>
                                                <div className="col-lg-5 pr-0">
                                                    <button
                                                        type="submit"
                                                        className="themeButton"
                                                    >{this.state.loading ? 'Loading..' : 'Submit'}</button>
                                                </div>

                                            </div>
                                        </form>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <form autoComplete="off" method="post" onSubmit={this.ResetPassword} >
                                            <div className="floating-form">
                                                <div className="floating-label">
                                                    <FormElements
                                                        type="text"
                                                        defaultValue={password}
                                                        label="Confirmation code"
                                                        name="otp"
                                                        value={otp}
                                                        errorMessage={passwordVarification.otp}
                                                        onChange={this.onChangeHandle}
                                                        onBlur ={this.handleBlur}
                                                    />
                                                    <div className="privacy_policy_forgot">
                                                        {this.state.resendBtn ?
                                                            <React.Fragment>
                                                                Did not receive an confirmation code?{" "} <a onClick={this.resendCode}> Resend </a>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                You can resend confirmation code in {this.state.count} seconds..
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>

                                                <FormElements
                                                    type="password"
                                                    defaultValue={password}
                                                    label="New Password"
                                                    name="password"
                                                    value={password}
                                                    errorMessage={passwordVarification.password}
                                                    onChange={this.onChangeHandle}
                                                    onBlur ={this.handleBlur}
                                                />
                                            </div>

                                            <div className="formSubmitButton row ">
                                                <div className="col-lg-5 pr-0">
                                                    <button
                                                        onClick={this.backBtn}
                                                        type="button"
                                                        className="themeButton">
                                                        Back
                                                </button>
                                                </div>
                                                <div className="col-lg-5 pr-0">
                                                    <button
                                                        type="submit"
                                                        className="themeButton">
                                                        {this.state.loading ? "Loading.." : "Reset Password"}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <div className=""></div>
                </div>
            </div >
        );
    }
}

function mapState(state) {
    return state;
}

export default connect(mapState)((withRouter(ForgotPassword)));
