import React from "react";
import { Modal } from 'react-bootstrap';

export default function DataPMSModal(props) {
    return (
        <Modal
            show={props.showNoteModal}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Verify Team Members</Modal.Title>
                <div className="modalPopupClose clearfix" onClick={props.onHide}>
                    <img src="/images/popup_close.png" alt="close" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modalForm clearfix">
                    <div className="verified_team">
                        <div>
                            <ul>
                                <li>
                                    <div className="verify_wrapper">
                                        <label className="container_check">
                                            avi.jain@yopmail.com
                                            <input type="checkbox" checked disabled />
                                            <span className="checkmark_check" />
                                        </label>
                                        <span className="status verified">Verified</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="verify_wrapper">
                                        <label className="container_check">
                                            avi.jain@yopmail.com
                                            <input type="checkbox" />
                                            <span className="checkmark_check" />
                                        </label>
                                        <span className="status not_verified">Not-Verified</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="verify_wrapper">
                                        <label className="container_check">
                                            avi.jain@yopmail.com
                                            <input type="checkbox" />
                                            <span className="checkmark_check" />
                                        </label>
                                        <span className="status not_verified">dsaufgaikbk duysag iuybdsuayg io fhujdgsv iufyadskfu ouyabdsfjkh</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="check_member_buttons">
                <button className="secondaryBtn">Delete</button>
                <button className="themeButton">Re-verify</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}