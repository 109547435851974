import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from "react-switch";

class WrapperRow extends Component {
  constructor(props) {
    super(props);
    this.required_name = `${this.props.inputName}[required]`;
    this.local_name = `${this.props.inputName}[local]`;
    this.intl_name = `${this.props.inputName}[intl]`;

    this.state = {
      [this.required_name]: this.getValue(this.required_name),
      [this.local_name]: this.getValue(this.local_name),
      [this.intl_name]: this.getValue(this.intl_name)
    }
  }

  getValue = (name) => {
    let { isEdit, flattened_default_config, flattened_hotel_config } = this.props;
    let value = false;

    if (isEdit) {
      if (name in flattened_hotel_config) value = flattened_hotel_config[name];
      else if (name in flattened_default_config) value = flattened_default_config[name];
    } else {
      if (name in flattened_default_config) value = flattened_default_config[name];
    }
    return value;
  }

  handleCheckbox = e => {
    let { name, checked } = e.target;
    this.setState({ ...this.state, [name]: checked }, () => {
      if (!this.state[this.local_name] && !this.state[this.intl_name]) {
        this.setState({ ...this.state, [this.required_name]: false })
      }
    });
  }

  handleSwitch = (checked, e) => {
    this.setState({ ...this.state, [this.required_name]: checked });
  }

  render() {
    let { title } = this.props;
    return (
      <div className="header_detail_wrapper content">
        <div className="header_detail_content"><h4 className="steps_header">{title}</h4></div>
        <div className="header_detail_content header_span">
          <span>
            <label className="container_check">
              <input type="hidden" name={this.intl_name} value={false} />
              <input
                type="checkbox"
                name={this.intl_name}
                value={true}
                onChange={this.handleCheckbox}
                checked={this.state[this.intl_name]}
              />
              <span className="checkmark_check" />
            </label>
          </span>
          <span>
            <label className="container_check">
              <input type="hidden" name={this.local_name} value={false} />
              <input
                type="checkbox"
                name={this.local_name}
                value={true}
                onChange={this.handleCheckbox}
                checked={this.state[this.local_name]}
              />
              <span className="checkmark_check" />
            </label>
          </span>
        </div>
        <div className="header_detail_content header_span mandatory">
          <span>
          <input type="hidden" name={this.required_name} value={false} />
            <Switch
              name={this.required_name}
              checked={this.state[this.required_name]}
              value={true}
              onChange={this.handleSwitch}
              disabled={!this.state[this.intl_name] && !this.state[this.local_name]}
              onHandleColor="#fff"
              onColor="#1772ad"
              handleDiameter={15}
              width={40}
              height={24}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </span>
        </div>
      </div>
    );
  }
}

WrapperRow.propTypes = {
  title: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  flattened_hotel_config: PropTypes.object.isRequired,
  flattened_default_config: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default WrapperRow;