export const getBase64Image = (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    })
}

export const getImage = (file) => {
    return new Promise((resolve, reject) => {
        var image = new Image();
        image.src = file;
        image.onload = () => {
            resolve(image);
        };
        image.onerror = (error) => {
            reject(error);
        };
    })
}

function gcd(a, b) {
    return (b == 0) ? a : gcd(b, a % b);
}

export const getAspectRatio = (image) => {
    let { width, height } = image;
    let d = gcd(width, height);
    return [width / d, height / d].join(":");
}




const isDataURLReg = /^\s*data:([a-z]+\/[a-z0-9\-\+]+(;[a-z\-]+\=[a-z0-9\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
export const isDataURL=(s)=>{
    return !!s.match(isDataURLReg);
  }
  