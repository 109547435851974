import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions";

// const options = [
//     { value: 'U.K.', label: <div><img width="16" src='/images/flag/india.png' /> {'Spanish'} </div> },
//     { value: 'U.S.', label: <div><img width="16" src='/images/flag/india.png' /> {'Arabic'} </div> },
//     { value: 'English', label: <div><img width="16" src='/images/flag/india.png' /> {'English'} </div> },
// ];


class SignupHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: {
                value: 'English', label: <div><img width="16" src='/images/flag/india.png' alt="en"/> {'English'} </div>
            },
        };
    }


    logoutUser = () => {
        this.props.dispatch(logoutUser(this.props.history))
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    render() {
        // const { selectedOption } = this.state;
        let { isLogoutBtn } = this.props;
        return (
            <header>
                <div className="signup_main_container">
                    <div className="row">
                        <div className="col-sm-4 header_logo_container">
                            <div className="headerLogo signup_header_logo">
                                <img className="img-responsive" src='/images/logo.png' alt="thru logo" />
                            </div>
                        </div>
                        {!this.props.steps ?
                            <div className="col-sm-8">
                                <div className="signup_header">
                                    {/* <div className="language_select_signup">
                                    <Select
                                        value={selectedOption}
                                        onChange={this.handleChange}
                                        options={options}
                                        className="language_select"
                                        classNamePrefix="react_select"
                                        theme={theme => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                            ...theme.colors,
                                            primary25: '#ecf1f5',
                                            primary: '#ecf1f5',
                                            },
                                        })}
                                    />   
                                </div> */}
                                    <div>
                                        {isLogoutBtn ?
                                            <button className="sign-primary login-button-header" onClick={this.logoutUser}>
                                                <Link>
                                                    {"Logout"}
                                                </Link>
                                            </button>
                                            :
                                            <button className="sign-primary login-button-header">
                                                <Link to="/login">
                                                    {"Login"}
                                                </Link>
                                            </button>}
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </header>
        );
    }
}


export default connect()(withRouter(SignupHeader));
