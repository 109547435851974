import React from "react";
import FormElements from "../components/formElements/FormElements";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import Select from "react-select";
import SimpleReactValidator from 'simple-react-validator';
import countryPhoneCode from "../utils/countryPhoneCode.json";
import Flag from "react-world-flags";
import { getStorage } from "../utils";
import {currentAuthenticatedUser,setKey} from "../actions";

const options_phone = countryPhoneCode.map((d) => ({
    value: d.dial_code,
    label: (
        <div>
            <Flag code={d.code} width="30" /> {d.dial_code}
        </div>
    ),
}));

class CompleteNewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            phone_number: "",
            selectedOption: {
                value: "+91",
                label: (
                    <div>
                        <Flag code="IND" width="30" /> {"+91"}
                    </div>
                ),
            },
            loading: false
        };
        this.form = new React.createRef();

        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute",
                email: "Please enter a valid email address",
                numeric: "Please enter a valid :attribute",
                phone_number: "Please enter a valid phone number",
            }
        });
    }

    onChangeHandle = (e) => {
        let { name, value } = e.target;
        this.setState({
            [name]: value
        });
        this.validator.showMessageFor(name);
        this.forceUpdate();
    }

    ChangePassword = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            let { loading } = this.state;
            if (!loading) {
                this.setState({ loading: true })
                let { value } = this.state.selectedOption;
                let { password, phone_number } = this.state;
                let signUpUser = getStorage("signUpUser", "object", false);
                signUpUser.phone_number = value + phone_number;
                let { username } = signUpUser;
                let passwordold = signUpUser.password;
                if (signUpUser) {
                    Auth.signIn(username, passwordold)
                        .then((user) => {
                            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                                Auth.completeNewPassword(
                                    user,               // the Cognito User Object
                                    password,       // the new password
                                    // OPTIONAL, the required attributes
                                    {
                                        email: username,
                                        phone_number: value + phone_number
                                    }
                                ).then(user => {
                                    this.setState({ loading: false })
                                    console.log("===user===complete Password==",user)
                                    toast.success("Password changed successfully")
                                    this.props.dispatch(setKey('user', user));
                                    localStorage.setItem("signUpUser", JSON.stringify(signUpUser));
                                    // this.props.history.push("/login");
                                    this.props.dispatch(currentAuthenticatedUser(this.props.history))

                                }).catch(e => {
                                    this.setState({ loading: false })
                                    console.log("====completeNewPassword= e=");
                                    console.log(e);
                                });
                            }
                        })
                        .catch((err) => {
                            this.setState({ loading: false })
                            console.log(err);
                        });
                }
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };

    render() {
        const { selectedOption, password, phone_number } = this.state;
        return (
            <div className="login_container height_100">
                <div className="clearfix d-flex align-items-center height_100">
                    <div className="height_100 loginForm">
                        <div className="clearfix width_100 fomrWrapMax">
                            <div className="thru-logo">
                                <img src="/images/logo.png" alt="thru logo" />
                            </div>

                            <div className="clearfix">
                                <div className="login_heading">
                                    <h1>Change your thru account password</h1>
                                </div>
                                <React.Fragment>
                                    <form autoComplete="off" method="post" ref={this.form} onSubmit={this.ChangePassword} >
                                        <div className="floating-form">
                                            <div className="password_signup">
                                                <FormElements
                                                    value={password}
                                                    validator={this.validator}
                                                    validations="required|min:8"
                                                    validatorOptions={{ messages: { required: "Please enter password", min: "Minimum  8 characters required." } }}
                                                    type="password"
                                                    name="password"
                                                    label="Password"
                                                    onChange={this.onChangeHandle}
                                                />
                                            </div>
                                            {/* <FormElements
                                                type="text"
                                                defaultValue={username}
                                                label="New password"
                                                name="password"
                                                value={username}
                                                errorMessage={userValidation.username}
                                                onChange={this.onChangeHandle}
                                            /> */}
                                            <div className="country_phone_wrapper">
                                                <FormElements
                                                    value={phone_number}
                                                    type="text"
                                                    name="phone_number"
                                                    label=""
                                                    placeholder="Phone Number"
                                                    validator={this.validator}
                                                    validations='required|numeric|between:3,12'
                                                    validatorOptions={{
                                                        messages:
                                                        {
                                                            required: "Please enter phone number",
                                                            numeric: "Please enter valid phone number",
                                                            between: "Please enter valid phone number",
                                                        }
                                                    }}
                                                    onChange={this.onChangeHandle}
                                                />
                                                <div className="country_phone">
                                                    <Select
                                                        value={selectedOption}
                                                        onChange={this.handleChange}
                                                        options={options_phone}
                                                        className="language_select"
                                                        classNamePrefix="react_select"
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: "#ecf1f5",
                                                                primary: "#ecf1f5",
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formSubmitButton row">
                                            {/* <div className="col-lg-5 pr-0">
                                                <button
                                                    onClick={() => Router.push("/login")}
                                                    type="button"
                                                    className="themeButton">
                                                    Back
                                                </button>
                                            </div> */}
                                            <div className="col-lg-12 pr-0">
                                                <button
                                                    type="submit"
                                                    className="themeButton"
                                                >{this.state.loading ? 'Loading..' : 'Submit'}</button>
                                            </div>

                                        </div>
                                    </form>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                    <div className=""></div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {state}
};

export default connect(mapStateToProps)((CompleteNewPassword));

