import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./store";

import Amplify from "aws-amplify";
import ScrollMemory from 'react-router-scroll-memory';
import awsConfigs from "./lib/aws-exports";

import "bootstrap/dist/css/bootstrap.min.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "./assets/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";

import "./assets/css/header.scss";
import "./assets/css/library.scss";
import "./assets/css/styles.scss";
import "./assets/css/signup.scss";

Amplify.configure(awsConfigs);
    
ReactDOM.render(
    <Provider store={store}>
        {/* <BrowserRouter basename="/thrudashboard"> */}
        <BrowserRouter>
            <ScrollMemory />
            <App />
        </BrowserRouter>
    </Provider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === 'production') {
//     serviceWorker.register();
// } else {
    serviceWorker.unregister();
// }
