import React, { useState } from "react";

const Statistics = (props) => {
    const [isShown, setIsShown] = useState("");
    return <div className="col" onMouseEnter={() => (props.selectFilterStics == "" ? setIsShown(props.value) : "")}
        onMouseLeave={() => setIsShown("")}
    onClick={() =>
    (props.selectFilterStics === "")
        ? props.withStatisticsFilter(props.value)
        : (props.selectFilterStics !== "" && props.selectFilterStics == props.value ? props.removeStatisticsFilter("") : "")

    }
    >
        <div className={props.value == isShown || props.selectFilterStics == props.value ? "statisticsCol  active " + props.type : "statisticsCol " + (props.selectFilterStics != "" ? "inactive" : "")}>
            <p className="count">{props.count}</p>
            <p className={"label " + props.type}>{props.display_name}</p>
            <span className="close-btn-filter" onClick={(e) => { setIsShown(""); }}>
                <img src={"/images/close-white.svg"} alt="close"/>
            </span>
        </div>
    </div>
};

export default Statistics;

//             [{
//                 display_name: "Arrival Today",
//                 count: 0,
//                 type: "arriving_today",
//                 prant_id: 1
//             },
//             {
//                 display_name: "Completed",
//                 count: 0,
//                 type: "completed",
//                 prant_id: 1
//             },
//             {
//                 display_name: "In Progress",
//                 count: 0,
//                 type: "in_progress",
//                 prant_id: 1
//             },
//             {
//                 display_name: "With Alert",
//                 count: 0,
//                 type: "with_alert",
//                 prant_id: 1
//             },
//             {
//                 display_name: "Pending",
//                 count: 0,
//                 type: "pending",
//                 prant_id: 1
//             }]
