import React from "react";
import { Modal } from 'react-bootstrap';
import FormElements from "../formElements/FormElements";

export default function MoveToPMSModal(props) {
    return (
        <Modal
            show={props.showPMSModal}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Push To PMS</Modal.Title>
                <div className="modalPopupClose clearfix" onClick={props.onHide}>
                    <img src="/images/popup_close.png" alt="close" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modalForm clearfix">
                    <div className="pms_modal_fields">
                        <div className="floating-form">
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        disabled
                                        type="text"
                                        label="First Name"
                                        name="first_name"
                                        value="Avi"
                                        onChange={() => { }}
                                    />
                                </div>
                                <div className="form_element">
                                    <FormElements
                                        disabled
                                        type="text"
                                        label="Last Name"
                                        name="last_name"
                                        value="Jain"
                                        onChange={() => { }}
                                    />
                                </div>
                            </div>
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="ID Number"
                                        name="id_number"
                                        onChange={() => { }}
                                    />
                                </div>
                                
                            </div>
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="Place Of Issue"
                                        name="id_number"
                                        onChange={() => { }}
                                    />
                                </div>
                                
                            </div>
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="date Of Issue"
                                        name="id_number"
                                        onChange={() => { }}
                                    />
                                </div>
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="date Of Expiry"
                                        name="id_number"
                                        onChange={() => { }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="check_member_buttons">
                    <button className="themeButton">Merge</button>
                    <button className="themeButton">Upload</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}