import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

class CustomDatePicker extends PureComponent {

  handleDateChange = (date) => {
    let e = { target: { name: this.props.name, value: date.format('YYYY/MM/DD') } };
    this.props.onChange(e)
  }

  render() {
    return (
      <div className="datepicker-wrapper">
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <DatePicker
            {...this.props}
            onChange={this.handleDateChange}
            animateYearScrolling
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
};

CustomDatePicker.defaultProps = {
  format: "YYYY-MM-DD"
}

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default CustomDatePicker