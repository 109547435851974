import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import alert from '../../assets/images/alert.png';
import checked from '../../assets/images/checked.png';
import { startCase } from 'lodash';
import { toast } from 'react-toastify';
import { checkField } from '../../utils';

class FieldDataWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      matchedRows: [],
    }
  }

  componentDidMount() {
    let state = Object.assign({}, this.state);

    // set default option for dropdown
    state.selectedOption = this.getDefaultOption();

    // set the rows for the existing key
    if (state.selectedOption) {
      state.matchedRows = this.matchRows(state.selectedOption.value);
    }

    this.setState(state);
  }

  getDefaultOption = () => {
    let selectedOption = null;
    let { field, fieldOptions } = this.props;
    let { matched, new_key } = field;
    // if matched select the default option
    if (matched) {
      selectedOption = fieldOptions.find(o => o.value === new_key);
    }
    return selectedOption;
  }

  matchRows = (key) => {
    let rows = [];
    let { reservationData, field } = this.props;
    let { old_key } = field;

    if (Array.isArray(reservationData)) {
      let data = Array.from(reservationData);
      for (let i = 0; i < data.length; i++) {
        let row = { ...data[i] };
        row[key] = row[old_key];
        let valid = checkField(key, row);
        if (valid) {
          rows.push(row);
        }
      }
      rows = rows.map(row => row[old_key]);
    }
    return rows;
  }

  handleFieldChange = selectedOption => {
    let { value } = selectedOption;
    let { matchedRows } = this.state;
    let { field, index } = this.props;
    field = { ...field, matched: true, ignored: false, confirmed: true, new_key: value };
    matchedRows = this.matchRows(value);
    this.setState({ selectedOption, matchedRows }, () => {
      this.props.setField(field, index);
    });
  }

  confirmMapping = () => {
    let { index, field } = this.props, { selectedOption } = this.state;
    if (!selectedOption) {
      toast.warning('Please select field to confirm');
      return;
    } else {
      field = { ...field, confirmed: true, ignored: false }
      this.props.setField(field, index);
    }
  }

  ignoreMapping = () => {
    let { index, field } = this.props;
    field = { ...field, confirmed: false, ignored: true }
    this.props.setField(field, index);
  }

  editMapping = () => {
    let { index, field } = this.props;
    field = { ...field, confirmed: false, ignored: false }
    this.props.setField(field, index);
  }

  getFieldDescription = () => {
    let { field } = this.props;
    let { matched, new_key, confirmed, ignored } = field;
    if (ignored) {
      return (<div className="row">
        <div className="col-md-8">
          <div className="d-inline">
            <img className="img-responsive" src={alert} alt="not found" />
          </div>
          <div className="d-inline">
            <p className="matchFieldsDescMesg">Ignored</p>
          </div>
          <div className="d-inline">
          </div>
        </div>
        <div className="col-md-4 matchFieldsDescBtns">
          <button className="ConfirmedBtn" onClick={this.editMapping}>Edit</button>
        </div>
      </div>)
    } else if (confirmed || matched) {
      return (<div className="row">
        <div className="col-md-8">
          <div className="d-inline">
            <img className="img-responsive" src={checked} alt="not found" />
          </div>
          <div className="d-inline">
            <p className="matchFieldsDescMesg">{confirmed ? 'Mapped to' : 'Matched to'}</p>
          </div>
          <div className="d-inline">
            <p className="matchFieldsDescFieldName">{startCase(new_key)}</p>
          </div>
        </div>
        {confirmed
          ? <div className="col-md-4 matchFieldsDescBtns">
            <button className="ConfirmedBtn" onClick={this.editMapping}>Edit</button>
          </div>
          : null
      }
      </div>);
    } else {
      return (<div className="row">
        <div className="col-md-12">
          <div className="d-inline">
            <img className="img-responsive" src={alert} alt="not found" />
          </div>
          <div className="d-inline">
            <p className="matchFieldsDescMesg">Unable to match automatically</p>
          </div>
          <div className="d-inline">
          </div>
        </div>
      </div>)
    }
  }

  getFieldRows = () => {
    let { reservationData, field } = this.props;
    let { old_key, ignored } = field;
    if (ignored) {
      return null;
    }
    let rows = reservationData.filter(f => f[old_key] && f[old_key] !== '').slice(0, 2)
      .map((r, i) => (<div key={i + r[old_key] + old_key} className="clearfix"><label>{r[old_key]}</label></div>));

    return rows;
  }

  getFieldOptions = () => {
    let { fieldOptions, fieldMappings, index } = this.props;
    let options = [];
    // get all the fields mapped except the one from props
    let selectedFields = fieldMappings.filter((m, i) => (i !== index && m.confirmed)).map(f => f.new_key);
    options = fieldOptions.filter(o => (selectedFields.indexOf(o.value) === -1));
    return options;
  }

  render() {
    let { field, reservationData } = this.props, { matchedRows, selectedOption } = this.state;

    return (
      <div className="matchFieldsDataWrapper">
        <div className="row">
          <div className="col-md-8">
            <div className="matchFieldsData">
              <div className="matchFieldsDataHeader">
                <div className="row">
                  <div className="col-md-7">
                    <div className="systemLabel">
                      <label>{startCase(field.old_key)}</label>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="selectMatchDropdown">
                      <Select
                        name="selectselectMatchDropdown"
                        value={this.state.selectedOption}
                        placeholder="Select Field"
                        isDisabled={field.confirmed}
                        options={this.getFieldOptions()}
                        onChange={this.handleFieldChange}
                        className="matchFieldsDataSelect"
                        classNamePrefix="mfds"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="matchFieldsDataContent">
                {this.getFieldRows()}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="matchFieldsDescription">
              {this.getFieldDescription()}
              {selectedOption && !field.ignored
                ? matchedRows.length == 0
                  ? (<div className="row">
                    <div className="col-md-12">
                      <ul className="matchFieldsDescListMesg">
                        <li>Validations failed.</li>
                        <li>{Math.round((matchedRows.length / reservationData.length) * 100)}% of rows contain a value for this column.</li>
                      </ul>
                    </div>
                  </div>)
                  : (<div className="row">
                    <div className="col-md-12">
                      <ul className="matchFieldsDescListMesg">
                        <li>Validations passed.</li>
                        <li>{Math.round((matchedRows.length / reservationData.length) * 100)}% of rows contain a value for this column.</li>
                      </ul>
                    </div>
                  </div>)
                : null
              }
              {!(field.confirmed || field.ignored)
                ? <div className="row">
                  <div className="col-md-12 matchFieldsDescBtns">
                    <button className="confirmMappingBtn" onClick={this.confirmMapping}>Confirm Mapping</button>
                    <button className="ignoreColumnBtn" onClick={this.ignoreMapping}>Ignore Column</button>
                  </div>
                </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FieldDataWrapper.propTypes = {
  fieldOptions: PropTypes.array.isRequired,
  reservationData: PropTypes.array.isRequired,
  requiredFields: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setField: PropTypes.func.isRequired
};

export default FieldDataWrapper;