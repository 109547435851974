import React, { Component, createRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Flag from 'react-world-flags';

import withAuth from "../../lib/auth";
import { formToJSON, uuid } from "../../utils";
import { getCookie } from "../../lib/session";
import axios from '../../axios';

import TeamMember from "./TeamMember";
import SignupStepLayout from '../SignupStepLayout';
import { connect } from "react-redux";
import { setKey } from "../../actions";
import CheckMemberModal from "../modal/CheckMemberModal";
import LoaderSpinner from "../common/LoaderSpinner";
import countryPhoneCode from '../../utils/countryPhoneCode.json';
import NeedHelp from "../common/NeedHelp";
import { withRouter } from "react-router-dom";

class Team extends Component {
    constructor(props) {
        super(props);
        let country_code_options = countryPhoneCode.map(d => ({ value: d.dial_code, label: (<div><Flag code={d.code} width="30" /> {d.dial_code}</div>) }));
        this.state = {
            loading: false,
            verifyModal: false,
            country_code_options: country_code_options,
            formData: {
                user_email: ""
            },
            members: [uuid()],
            email_response: []
        }
        this.form = new createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute",
                email: "Please enter a valid email address"
            },
            validators: {
                phone_number: {
                    message: 'Please enter a valid phone number',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0-9]{4,15}$/i);
                    }
                }
            }
        });
    }

    verifyModalToggle = () => this.setState({ verifyModal: !this.state.verifyModal })

    componentDidMount() {
        document.scrollingElement.scrollTop = 0;
        let email = getCookie('userEmail');
        console.log("===email====",email)
        if (email) {
            this.handleInputChange({ target: { name: "user_email", value: email } });
        }
        this.setCountryCode(0);
    }

    setCountryCode = (index) => {
        let { hotel } = this.props;
        let country_code_name = `team[${index}][country_code]`;
        let state = this.state, countryCodeOption;
        if (hotel) {
            let { country_code } = hotel;
            countryCodeOption = this.getCountryCodeOption(country_code);
        }
        state.formData[country_code_name] = countryCodeOption;
        this.setState(state);
    }

    getCountryCodeOption = (country_code) => {
        let country = countryPhoneCode.find(c => c.code === country_code), option;
        if (country) {
            let { dial_code, code } = country;
            option = { value: dial_code, label: (<div><Flag code={code} width="30" /> {dial_code}</div>) };
        }
        return option;
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.validator.showMessageFor(name);
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    addEmail = () => {
        let state = this.state;
        let { members } = state;
        this.setCountryCode(members.length);
        members.push(uuid());
        this.setState({
            ...state,
            members
        })
    }

    removeEmail = (index) => {
        let { members, formData } = this.state;
        let lastIndex = members.length - 1;
        members = members.filter((t, i) => i !== index);
        for (let i = index; i < lastIndex; i++) {
            if (`team[${index}][email]` in formData) {
                formData[`team[${index}][email]`] = formData[`team[${index + 1}][email]`];
                formData[`team[${index}][phone_number]`] = formData[`team[${index + 1}][phone_number]`];
                formData[`team[${index}][country_code]`] = formData[`team[${index + 1}][country_code]`];
                formData[`team[${index}][is_admin]`] = formData[`team[${index + 1}][is_admin]`];
            }
        }
        delete formData[`team[${lastIndex}][email]`];
        delete formData[`team[${lastIndex}][phone_number]`];
        delete formData[`team[${lastIndex}][country_code]`];
        delete formData[`team[${lastIndex}][is_admin]`];
        this.setState({ members, formData });
    }



    setResponseData = (data) => {
        let state = this.state;
        let { email_response } = state;
        if (Array.isArray(data) && data.length) {
            data.forEach(e => {
                let { email } = e;
                // check if email exist in state
                let index = email_response.findIndex(e => e.email === email);
                // console.log(email, index);
                if (index === -1) {
                    email_response.push(e);
                } else {
                    email_response[index] = e;
                }
            })
        }
        state.email_response = email_response;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, loading: true });
        if (this.validator.allValid()) {
            let postData = formToJSON(this.form.current);
            if (Array.isArray(postData.team)) {
                postData.team = postData.team.map(d => {
                    d.phone_number = d.country_code + d.phone_number;
                    delete d.country_code;
                    return d;
                }).filter(t => t);
            }
            console.log('postData', postData);
            axios.post('/dashboard/hotel/team', postData)
                .then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        let api_response = res.data;
                        let { status, data } = api_response;
                        if (status) {
                            let { hotel } = this.props;
                            if (hotel) {
                                hotel.step_completed = 6;
                                this.props.dispatch(setKey('hotel', hotel));
                            }
                            this.props.history.replace("/topup");
                        } else {
                            console.log('api_response', data);
                            this.setResponseData(data);
                        }
                    } else {
                        console.log(res);
                    }
                })
                .catch(console.error)
                .finally(() => this.setState({ ...this.state, loading: false }));
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false });
        }
    }

    render() {
        this.validator.purgeFields();
        let { loading } = this.state;

        return (
            <SignupStepLayout>
                {loading ? <LoaderSpinner /> : null}
                <form autoComplete="off" onSubmit={this.onSubmit} ref={this.form}>
                    {/* <div className="signup_steps_content_wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">Account</h3>
                                <div className="floating-form">
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <FormElements
                                                disabled
                                                type="text"
                                                label="Your Email Address"
                                                name="user_email"
                                                value={this.state.formData.user_email}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' /></div>
                                    <div className="tip_text">
                                        <p>Add the existing terms and condition of the hotel for guest checkin. Special section is created for you to set up question for COVID declaration.The guest can answer it through the SMS link only.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="signup_steps_content_wrapper invite_teams">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">Add Thru Users</h3>
                                <div className="floating-form">
                                    {this.state.members.map((e, i) => {
                                        return (<TeamMember
                                            key={e}
                                            formData={this.state.formData}
                                            email_response={this.state.email_response}
                                            country_code_options={this.state.country_code_options}
                                            hotel={this.props.hotel}
                                            validator={this.validator}
                                            onRemove={this.removeEmail}
                                            onChange={this.handleInputChange}
                                            index={i}
                                        />)
                                    })}
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <div className="addmore_btn">
                                                <button type="button" onClick={this.addEmail}>Add More <img src='/images/iconbtn-plus.svg' alt="add icon" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                                    <div className="tip_text">
                                        <p>Add your team members to give them access to your hotel’s Thru dashboard.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <NeedHelp />
                    <div className="continue-btn">
                        <div className="formSubmitButton">
                            <button disabled={this.state.loading} className="themeButton">
                                {this.state.loading ? "Please wait.." : "Continue"}
                            </button>
                        </div>
                    </div>
                </form>
                <CheckMemberModal
                    showNoteModal={this.state.verifyModal}
                    onHide={this.verifyModalToggle}
                />
            </SignupStepLayout>
        );
    }
}

const mapStateToProps = state => ({
    hotel: state.hotel
})

export default connect(mapStateToProps)(withAuth(withRouter(Team)));