import React from "react";
import { Modal } from 'react-bootstrap';
import FormElements from "../../formElements/FormElements";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import Loader from "../../common/Loader";
import appConfig from "../../../app-config";

class TopupModal extends React.Component {
    state = {
        quantity: appConfig.auto_recharge.auto_recharge_quantity
    }

    getCardDetails = () => {
        if (this.props.payment_methods === false) {
            return <Loader />
        } else {
            if (Array.isArray(this.props.payment_methods.cardDetails)
                && this.props.payment_methods.cardDetails.length) {
                let { cardDetails } = this.props.payment_methods;
                return cardDetails.map((c, i) => (<div key={i} className="form_element_wrapper">
                    <div className="form_element">
                        <FormElements
                            type="text"
                            onChange={() => { }}
                            value={`${startCase(c.brand)} ****${c["last4"]}`}
                            readOnly
                            label="Card"
                            name="card"
                        />
                    </div>
                </div>))
            }
        }
        return null;
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value
        })
    }


    onKeyPress = (e) => {
        let keys = Array.from(Array(10).keys()).map(i => i.toString());
        if (!keys.includes(e.key)) e.preventDefault();
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.saveTopUpData(this.state);
    }

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Top-Up</Modal.Title>
                    <div className="modalPopupClose clearfix" onClick={this.props.onHide}>
                        <img src='/images/popup_close.png' alt="close" />
                    </div>
                </Modal.Header>
                <form onSubmit={this.onSubmit}>
                    <Modal.Body>
                        <div className="add_card_modal clearfix">
                            <div className="clearfix floating-form">
                                {this.getCardDetails()}
                                <div className="form_element_wrapper">
                                    <div className="form_element">
                                        <FormElements
                                            name="quantity"
                                            type="number"
                                            min={appConfig.auto_recharge.auto_recharge_quantity}
                                            max={appConfig.auto_recharge.max_auto_recharge_quantity}
                                            required
                                            onKeyPress={this.onKeyPress}
                                            onChange={this.handleInputChange}
                                            value={this.state.quantity}
                                            label="Top-up Quantity"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p>Note: It may take a few hours to process the payment.</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="themeButton" disabled={this.props.loading}>{this.props.loading ? 'Please wait..' : 'Save'}</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

TopupModal.propTypes = {
    payment_methods: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    onHide: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired
};

export default TopupModal;