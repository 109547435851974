import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

function RowDetails({ data }) {
  if (typeof data === "object") {
    return (<div className="data_table_container">
      {Object.entries(data).map(([key, value]) => {
        if (!['id', 'index', 'invalid'].includes(key)) {
          return (<div key={key} className="table_data_wrapper">
            <div className="data_label">{startCase(key)}: </div>
            <div className="data_value"> {(value == "") ? "--" : value}</div>
          </div>);
        } else {
          return null;
        }
      })}
    </div>)
  }
  return null;
}

RowDetails.propTypes = {
  data: PropTypes.object
};

export default RowDetails;