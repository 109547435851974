import React from 'react';
import renderHTML from 'react-render-html';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getStatusDetails } from "../../utils";

function StatusChips(props) {
  let { statusKey,uploadAlert } = props;

  let status = getStatusDetails(statusKey,uploadAlert);
  if (status) {
    if (Array.isArray(status) && status.length) {
      return status.map((statusObj, index) => {
        if (statusObj.tooltip) {
          return (<OverlayTrigger overlay={getTooltip(statusObj)} key={index}>
            <p className={`d-inline ${statusObj.chipClass}`}>{statusObj.chipTxt}
              {/* <span><img src={Danger} alt="not found" /></span> */}
            </p>
          </OverlayTrigger>)
        } else {
          return (<p className={`d-inline ${statusObj.chipClass}`} key={index}>{statusObj.chipTxt}</p>);
        }
      })
    }
  } else {
    return null;
  }
}

function getTooltip(status) {
  let { tooltip } = status;

  return (<Tooltip>
    <ul className="tooltipPointsListing">
      {renderHTML(tooltip)}
    </ul>
  </Tooltip>);
}

export default StatusChips;