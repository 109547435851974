import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducer, { initialState } from '../reducers';
import Tostify from '../utils/Tostify';
import { saveState } from './storage';
import { throttle } from "lodash";

let middlewares = [thunk, Tostify];

if (process.env.NODE_ENV === `development`) {
    const logger = createLogger({
        duration: true,
        diff: false,
        collapsed: true
    });
    middlewares.push(logger);
}

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(...middlewares)
    )
)

store.subscribe(throttle(() => {
    let state = store.getState();
    saveState({ ...state });
}, 1000));

export default store;