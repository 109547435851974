import csvtojson from 'csvtojson'

/**
 * Converts CSV file to a json
 * @param file The csv file.
 * @returns A Promise containing an object with headers and data.
 */
export const csvFileToJSON = (file) => {
    return new Promise(function (resolve, reject) {
        if (!window.FileReader || !window.File) {
            reject(new Error('Does not support File API'));
            return;
        }
        if (!(file instanceof File)) {
            reject(new Error('Not a file'));
            return;
        }

        var reader = new FileReader();

        reader.onerror = function (err) {
            reject(new Error(err.message));
        };

        // Closure to capture the file information.
        reader.onload = function () {
            let headers = [];
            csvtojson()
                .fromString(reader.result)
                .on('header', (data) => {
                    headers = data;
                })
                .on('error', (err) => {
                    reject(new Error(err.message));
                })
                .then(rows => {
                    resolve({ headers: headers, data: rows })
                })
        };

        // Read in the image file as a data URL.
        reader.readAsText(file);
    });
}
