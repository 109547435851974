import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import WrapperRow from './WrapperRow';
import { startCase } from 'lodash';
import { flatten } from '../../utils';

class Wrapper extends PureComponent {
  getTitle = (parent, key) => {
    let mappings = {
      "id_card": {
        "docid_type": "Document Type",
        "docid_number": "Document Number"
      },
      "visa": {
        "docid_type": "Visa Type",
        "docid_number": "Visa Number"
      },
      "travel": {
        "arrival_date": `Date of Arrival in ${this.props.hotel.country_name}`,
        "departure_date": `Departure Date from ${this.props.hotel.country_name}`
      }
    }

    if (mappings[parent] !== undefined && mappings[parent][key] !== undefined) {
      return mappings[parent][key];
    } else {
      return startCase(key);
    }
  }

  getRows = () => {
    let returnHTML = [];
    let { data, isEdit, default_config, hotel_config, inputName } = this.props;
    if (data) {
      let flattened_hotel_config = flatten(hotel_config);
      let flattened_default_config = flatten(default_config);
      for (const key in data) {
        returnHTML.push(<WrapperRow key={key}
          inputName={`${inputName}[${key}]`}
          title={this.getTitle(this.props.parentKey, key)}
          isEdit={isEdit}
          default_config={default_config}
          hotel_config={hotel_config}
          flattened_hotel_config={flattened_hotel_config}
          flattened_default_config={flattened_default_config}
        />);
      }
    }
    return returnHTML;
  }

  render() {
    return (
      <div className="signup_steps_content_wrapper">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="header_detail_wrapper header">
              <div className="header_detail_content"><h3 className="steps_header">{this.props.title}</h3></div>
              <div className="header_detail_content header_span">
                <span>International Guest</span>
                <span>Domestic Guest</span>
              </div>
              <div className="header_detail_content header_span mandatory">
                <span>Mandatory</span>
                {/* <span>For Domestic <br/>Guest</span> */}
              </div>
            </div>
            {this.getRows()}
          </div>
        </div>
      </div>
    );
  }
}

Wrapper.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  hotel: PropTypes.object.isRequired,
  parentKey: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired
};

export default Wrapper;