import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axios from '../../axios';
import withAuth from "../../lib/auth";
import CheckinDetails from '../../components/modal/CheckinDetail';
import ShowIdModal from "../../components/modal/ShowIdModal";
import InviteModal from "../../components/modal/InviteModal";
import PrintRegCardModal from "../../components/modal/PrintRegCardModal";
import Layout from '../../components/Layout';
import { connect } from "react-redux";
import LoaderSpinner from '../../components/common/LoaderSpinner';
import { toast } from 'react-toastify';
import ConfirmModal from '../../components/modal/ConfirmModal';
import AddAlertModal from "../../components/modal/AddAlertModal";

function Reservation(props) {
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState(false);
  let [error, setError] = useState(false);
  let [requested, setRequested] = useState(false);
  let [regCardModal, setRegCardModal] = useState(false);
  let [regCardId, setRegCardId] = useState(false);
  let [showIDModal, setShowIDModal] = useState(false);
  let [idShowPopupName, setIdShowPopupName] = useState("");
  let [userDocument, setUserDocument] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  let [confirmModal, setConfirmModal] = useState(false);
  let [confirmModalProps, setconfirmModalProps] = useState({
    title: "",
    showModal: false,
    children: "",
    onConfirm: () => { },
    onHide: () => setConfirmModal(false)
  })

  const showConfirmModal = props => {
    setconfirmModalProps({
      ...confirmModalProps,
      ...props
    })
    setConfirmModal(true);
  }

  let [inviteModal, setInviteModal] = useState(false);
  let [resendInvitationData, setResendInvitationData] = useState({
    reservation_code: "",
    phone_number: "",
    country_code: "",
    is_sms_resend: "",
    reservation_status: ""
  });

  const [addAlert, setAddAlert] = useState({
    reservation_code: "",
    external_booking_id: "",
    alert_type: "custom"
  })

  const params = useParams();
  const { id } = params;
  console.log('id', id);



  const fetchData = () => {
    axios(`/dashboard/reservations/${id}`)
      .then(res => {
        console.log(res);
        let api_response = res.data;
        let { status, data } = api_response;
        console.log("===data====", data)
        if (status) {
          setData(data);
        } else {
          let error = new Error(api_response.message);
          setError(error);
        }
      }).catch(err => {
        console.log(err);
        setError(err);
      }).finally(() => {
        setLoading(false);
      });
  }

  if (requested === false) {
    fetchData();
    setRequested(true);
  }

  const pushToPMS = () => {
    let { reservation_code } = data;
    if (reservation_code) {
      showConfirmModal({
        confirmBtnText: "Loading...",
        children: <p>Would you like to push this reservation to the PMS?</p>
      })
      axios.post('/updatePmsReservation',
        {
          reservation_code: reservation_code,
          operation: "update"
        })
        .then((response) => {
          if (response.status == 200) {
            setConfirmModal(false);
            fetchData();
            toast.success('Reservation data pushed successfully.');
          } else {
            toast.error('Error in push status.')
          }

        })
        .catch((error) => {
          toast.error('Error in push status.')
        })
    }
  }

  const confirmation = (enable_push_to_pms) => {
    let { reservation_code } = data;
    if (reservation_code) {
      axios.put('/dashboard/reservation/checkIn', { reservation_code: reservation_code })
        .then((response) => {
          if (response.status == 200) {
            setConfirmModal(!confirmModal);
            fetchData();
            toast.success('Change status successfully.')
          } else {
            toast.error('Error in change status.')
          }
        })
        .catch((error) => {
          toast.error('Error in change status.')
        }).finally(() => {
          let { is_pushed_to_pms } = data;
          if (is_pushed_to_pms == 0  && enable_push_to_pms) {
            setTimeout(() => showConfirmModal({
              title: "Confirm",
              confirmBtnText: "Yes",
              hideOnConfirm: false,
              onConfirm: pushToPMS,
              children: <p>Would you like to push this reservation to the PMS?</p>
            }), 300)
          }
        })
    }
  }


  const alertModalToggle = (reservation_code, external_booking_id) => {
    setAlertModal(!alertModal);
    setAddAlert({
      reservation_code: reservation_code,
      external_booking_id: external_booking_id,
      alert_type: "custom"
    })
  }

  const regCardModalModalToggle = (id) => {
    setRegCardModal(!regCardModal);
    setRegCardId(id ? id : false);
  };

  const inviteModalToggle = (reservation_code, phone_number, country_code, is_sms_resend, reservation_status) => {
    setResendInvitationData({
      reservation_code: reservation_code,
      phone_number: phone_number,
      country_code: country_code,
      is_sms_resend: is_sms_resend,
      reservation_status: reservation_status
    })
    if (reservation_code == "") {
      fetchData();
    }

    setInviteModal(!inviteModal)
  }


  const showIdModalToggle = (document, name) => {
    setUserDocument(document)
    setShowIDModal(!showIDModal);
    setIdShowPopupName(name);
  };

  if (loading) {
    return (<LoaderSpinner />);
  } else if (error) {
    return <h1>Error</h1>
  } else if (data) {
    return (
      <Layout>
        <div className="customContainer content">
          <CheckinDetails
            dashboardFilter={props.dashboardFilter}
            fetchData={fetchData}
            dispatch={props.dispatch}
            hotel={props.hotel}
            checkinUserStatus={props.checkinUserStatus}
            data={data}
            inviteModalToggle={inviteModalToggle}
            regCardModalModalToggle={regCardModalModalToggle}
            showIdModalToggle={showIdModalToggle}
            showConfirmModal={showConfirmModal}
            alertModalToggle={alertModalToggle}
            guestDetails={props.guestDetails}
            confirmation={confirmation}
            pushToPMS={pushToPMS}
            hotelIntegration ={props.hotelIntegration}
          />

          <ShowIdModal
            documents={userDocument}
            showIDModal={showIDModal}
            idShowPopupName={idShowPopupName}
            showIdModalToggle={showIdModalToggle}
          />
          <ConfirmModal {...confirmModalProps} showModal={confirmModal} />
          {/* <ConfirmModal
            title={"Confirmation"}
            showModal={confirmModal}
            onConfirm={confirmation}
            onHide={toggleConfirmModal}
            confirmBtnText="Yes"
          >
            <p>Would you like to change status?</p>
          </ConfirmModal> */}
          {/* <ConfirmModal
            title={"Confirmation"}
            showModal={pmsConfirmModal}
            onConfirm={()=>{}}
            onHide={togglePMSConfirmModal}
            confirmBtnText="Yes"
          >
            <p>Would you like to push this reservation?</p>
          </ConfirmModal> */}
          <InviteModal
            showInviteModal={inviteModal}
            hotel={props.hotel}
            inviteModalToggle={inviteModalToggle}
            resendInvitationData={resendInvitationData}
          />
          <AddAlertModal
            showAlertModal={alertModal}
            onHide={alertModalToggle}
            addAlert={addAlert}
          />
          {regCardId ?
            <PrintRegCardModal
              showRegCardModal={regCardModal}
              regCardId={regCardId}
              regCardModalModalToggle={regCardModalModalToggle}
            /> :
            ""}
          {/* 
          <InviteModal
            showInviteModal={this.state.InviteModal}
            onHide={this.inviteModalHide}
          /> */}
        </div>
      </Layout>
    );
  } else {
    return null;
  }
}


const mapStateToProps = (state) => {
  return {
    checkinUser: state.checkinUser,
    checkinUserStatus: state.checkinUserStatus,
    hotel: state.hotel,
    dashboardFilter: state.dashboardFilter,
    guestDetails: state.guestDetails,
    hotelIntegration:state.hotelIntegration
  };
};

export default connect(mapStateToProps)(withAuth(Reservation));
