import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getSelectTheme } from '../../utils/ui';

class TaxInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tax_type: false,
      selected: null,
      format: "",
      options: this.getOptions()
    }
  }

  getOptions = () => {
    let options = [], { tax_types } = this.props;
    if (Array.isArray(tax_types) && tax_types.length) {
      options = tax_types.map(t => ({ value: t.type, label: t.country + ' - ' + t.description }));
    }
    return options;
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.tax_types.length < this.props.tax_types.length) return true;
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      let { tax_types, hotel, index } = this.props, state = this.state;
      state.options = tax_types.map(t => ({ value: t.type, label: t.country + ' - ' + t.description }));
      state.selected = null;
      if (hotel) {
        let { country_id } = hotel;
        let tax_type = tax_types.find(t => t.country_id === country_id);
        if (tax_type) {
          let { type, country, description } = tax_type
          state.selected = { value: type, label: country + ' - ' + description };
        }
      }
      this.setState(state, () => {
        console.log('this.state', this.state);
        if (this.state.selected) this.handleSelectChange(this.state.selected, { name: `tax_detail[${index}][type]` })
      });
    }
  }

  handleSelectChange = (e, target) => {
    let { value } = e;
    let { name } = target;
    let tax_type = this.props.tax_types.find(t => t.type === value);
    console.log(tax_type);
    if (tax_type) {
      let { format } = tax_type;
      this.setState({
        ...this.state,
        tax_type: tax_type,
        selected: e,
        format: format
      }, () => {
        let e = { target: { name: name, value: value } }
        this.props.onChange(e)
      });
    }
  }

  render() {
    let { tax_type, format } = this.state;
    let { index, removeTaxInvoice } = this.props;
    let name = `tax_detail[${index}][type]`;
    let value_name = `tax_detail[${index}][value]`;
    let value_rules = ['required'];
    if (format) {
      value_rules.push({ regex: format });
    }
    return (
      <div className="form_element_wrapper">
        <div className="form_element">
          <div className="thru_react_select">
            <label>Tax Type</label>
            <Select
              options={this.state.options}
              value={this.state.selected}
              placeholder=""
              onChange={this.handleSelectChange}
              className="tax_select"
              name={name}
              classNamePrefix="react_select"
              theme={getSelectTheme}
            />
            <div className="error-text">
              {this.props.validator.message(name, this.props.formData[name], 'required', { messages: { required: "Please select tax type" } })}
            </div>
          </div>
        </div>
        <div className="form_element">
          <div className="floating-label tax_invoice_label">
            <label>Tax Value/Tax Registration Number </label>
            <input className="floating-input"
              type="text"
              name={value_name}
              autoComplete="off"
              onChange={this.props.onChange}
              placeholder={tax_type ? tax_type.placeholder : ""}
            />
            <div className="error-text">
              {this.props.validator.message(value_name, this.props.formData[value_name], value_rules, { messages: { required: "Please enter tax value", regex: "Please enter valid tax value" } })}
            </div>
          </div>
        </div>
        {this.props.showRemove
          ? <span className="close_btn" onClick={() => removeTaxInvoice(index)}><img src="/images/close.svg" alt="close" /></span>
          : null
        }
      </div>
    );
  }
}

TaxInvoice.propTypes = {
  tax_types: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  showRemove: PropTypes.bool.isRequired
};

export default TaxInvoice;