import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";

class StepsEditHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: this.getSteps()
        }
    }

    getSteps = () => {
        let steps = [
            { name: "Account", path: "/account/edit" },
            { name: "Hotel Information", path: "/hotelinfo/edit" },
            { name: "Billing Details", path: "/billing/edit" },
        ];
        if (this.props.is_admin) {
            steps.push(
                { name: "Guest Details", path: "/guestdetail/edit" },
                // { name: "Terms & Conditions", path: "/terms/edit" },
                // { name: "Payment Gateway", path: "/payment/edit" },
                { name: "Language Support", path: "/language/edit" }
            )
        }

        return steps;
    }

    render() {
        let { steps } = this.state;
        return (
        //<div style={this.props.style} className={`steps_header ${this.props.sticky ? 'sticky' : ''}`}>
        <div  className="steps_header">
          <div className="steps_header_wrapper edit">
                {steps.map((step) => {
                    return (<NavLink to={step.path} key={step.path} className={"steps"}>
                        <span className="steps_info">{step.name}</span>
                    </NavLink>)
                })}
            </div>
        </div>);
    }
}

const mapStateToProps  = state =>({
    is_admin: state.is_admin
})


export default connect(mapStateToProps)(withRouter(StepsEditHeader));