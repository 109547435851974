import React, { createRef } from 'react';
import FormElements from '../formElements/FormElements';
import { formToJSON, scrollToError } from '../../utils';
import axios from '../../axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import LoaderSpinner from '../common/LoaderSpinner';

class TabComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formData: this.props.data,
            hotel_detail:this.props.hotel_detail?this.props.hotel_detail:[]
        };
        this.form = new createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute",
                email: "Please enter a valid email address",
                numeric: "Please enter a valid :attribute",
                phone_number: "Please enter a valid phone number",
            }
        });
    }

    handleInputChange = (e) => {
        let { formData } = this.state;
        let { name, value } = e.target;
        this.validator.showMessageFor(name);
        formData.forEach((data, index) => {
            if (data.tag == name) {
                data.text = value;
                this.setState({ formData: formData })
            }
        })
    }

    handleInputHotelDetails = (e) => {
        let { hotel_detail } = this.state;
        let { name, value } = e.target;
        this.validator.showMessageFor(name);
        hotel_detail.forEach((data, index) => {
            if (data.tag == name) {
                data.text = value;
                this.setState({ hotel_detail: hotel_detail })
            }
        })
    }

    

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, loading: true });
        if (this.validator.allValid()) {
            let { selectedOption,heading} = this.props;
           
            let hotel_detail =this.state.hotel_detail;
            let updateObject = {
                lang_code: selectedOption.value,
                translations: this.props.data,
                hotel_detail:hotel_detail
            }
            console.log("====updateObject=====",updateObject)
            axios.put(`/dashboard/hotel/languageSupport/`, updateObject)
                .then((data) => {
                    if (data.status == 200) {
                        toast.success(heading+" updated successfully.")
                    } else {
                        toast.error("Error in add hotel language support data.")
                    }
                }).catch((error) => {
                    toast.error("Error in add hotel language support data.")
                }).finally(() => {
                    this.setState({ ...this.state, loading: false });
                })
        } else {
            this.setState({ ...this.state, loading: false }, scrollToError);
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }

    }

    splitEvery = (array, length) =>
    array.reduce(
        (result, item, index) => {
            if (index % length === 0) result.push([])
            result[Math.floor(index / length)].push(item)
            return result
        },
        []
    );

    render() {
        let { formData, loading,hotel_detail} = this.state;
        return (
            <React.Fragment>
                {loading ? <LoaderSpinner /> : 
                <div className="signup_steps_content_wrapper">
                    <form ref={this.form} autoComplete="off" onSubmit={this.onSubmit} encType="multipart/form-data">
                        <div className="language_support_wrapper">
                            <h3 className="steps_header">{this.props.heading}</h3>
                            <button type="submit" className="grey_button saveButton">Save Changes</button>
                        </div>
                        <div className="floating-form">
                        {(Array.isArray(hotel_detail) && hotel_detail.length > 0) ?
                                <React.Fragment>
                                    {this.splitEvery(hotel_detail, 2).map((dataChunk, index) => (
                                            <div className="form_element_wrapper" key={1000+index}>
                                                { dataChunk.map((data, indx) => {
                                                    return (
                                                        <div className="form_element" key={indx}>
                                                            <FormElements
                                                                validator={this.validator}
                                                                validations="required"
                                                                validatorOptions={{ messages: { required: "Please enter valid input." } }}
                                                                type="text"
                                                                maxLength ={null}
                                                                label={data.label && data.label !="NA" ?data.label:data.tag}
                                                                value={data.text}
                                                                name={data.tag}
                                                                onChange={this.handleInputHotelDetails}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        ))
                                    }
                                </React.Fragment>
                                : ""}
                            {(Array.isArray(formData) && formData.length > 0) ?
                                <React.Fragment>
                                    {this.splitEvery(formData, 3).map((dataChunk, index) => (
                                            <div className="form_element_wrapper" key={index}>
                                                { dataChunk.map((data, indx) => {
                                                    return (
                                                        <div className="form_element" key={indx}>
                                                            <FormElements
                                                                validator={this.validator}
                                                                validations="required"
                                                                validatorOptions={{ messages: { required: "Please enter valid input." } }}
                                                                type="text"
                                                                maxLength ={null}
                                                                label={data.label && data.label !="NA" ?data.label:data.tag}
                                                                value={data.text}
                                                                name={data.tag}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        ))
                                    }
                                </React.Fragment>
                                : ""}
                        </div>
                    </form>
                </div>}
            </React.Fragment>
        )
    }
}
export default TabComponent;