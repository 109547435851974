import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import axios from '../../axios';
import { toast } from 'react-toastify';


export default function AddLanguageModal(props) {

  let [selectLang, setLang] = useState([]);
  let [loading, setLoading] = useState(false);

  const updateStateList = (e, value) => {
     if (e.target.checked) {
      //append to array
      selectLang.push(value);
      setLang(selectLang)
    } else {
      //remove from array
      let updateArray = selectLang.filter(function (val) { return val !== value })
      setLang(updateArray)
    }
  }

  const addLanguage = _ => {
    if (selectLang.length > 0) {
      setLoading(true);
      axios.post('/dashboard/hotel/languages', {
        lang_code:selectLang
      }).then((response) => {
        if (response.status == 200) {
          let { data } = response;
          if (data.status) {
            setTimeout(() => {
              toast.success("Language added successfully.");
              props.onHide("added"); 
            }, 1000);
          } else {
            toast.warning(data.message)
          }
        } else {
          toast.error("Error in add language.")
        }
      })
        .catch((error) => {
          toast.error("Error in add language.")

        }).finally(() => setLoading(false))
    } else {
      toast.warning("Please add at least one language.")
    }

  }
  return (
    <Modal
      show={props.showNoteModal}
      onHide={()=>props.onHide("")}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add Languages</Modal.Title>
        <div className="modalPopupClose clearfix" onClick={()=>props.onHide("")}>
          <img src='/images/popup_close.png' alt="close" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="language_select_wrapper">
          {Array.isArray(props.languageData) && props.languageData.length > 0 ?
            <React.Fragment>
              {props.languageData.map((lang, index) => {
                let addAlready = (props.addedOption.length > 0 ? props.addedOption.filter(({ value }) => value == lang.code) : [])
                return (<div className="languageIDwrapper" key={index}>
                  <div className="form_element" >
                    <label className="container_check">
                      {lang.name}
                      <input
                        type="checkbox"
                        disabled={addAlready.length > 0 ? "disabled" : ""}
                        defaultChecked={addAlready.length > 0 ? true : false}
                        onClick={(e) => updateStateList(e, lang.code)} />
                      <span className="checkmark_check" />
                    </label>
                  </div>
                </div>)
              })}
            </React.Fragment>
            : ""}

        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={(e) => !loading ? addLanguage() : ""} className="themeButton">{loading ? "Loading..." : "Confirm"}</button>
      </Modal.Footer>
    </Modal>
  );
}