import React from "react";
import { Modal } from 'react-bootstrap';
import FormElements from '../formElements/FormElements';
import Select from "react-select";
import Flag from "react-world-flags";
import countryPhoneCode from "../../utils/countryPhoneCode.json";
import { checkValidation } from "../../lib/formValidation";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import axios from '../../axios';
import { toast } from 'react-toastify';

class InviteModal extends React.Component {
    constructor(props) {
        super(props);
        let country_code_options = countryPhoneCode.map(d => ({ value: d.dial_code, label: (<div><Flag code={d.code} width="30" /> {d.dial_code}</div>) }));
     
        this.state = {
            loading: false,
            phone_number: "",
            reservation_code: "",
            country_code_options: country_code_options,
            selectedOption: {},
            is_sms_resend:false,
            validationData: {
                phone_number: "",
                formErrors: {
                    phone_number: ""
                },
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props != nextProps) {
            let { phone_number, reservation_code,is_sms_resend,reservation_status} = nextProps.resendInvitationData;
            let country_code = "";
            let selectedOption={}
             let phoneNumber  ="";
             let defultCountryCode ="";
            if (phone_number) {
                // phone_number = phone_number.indexOf("+")!=-1?phone_number:"+"+phone_number;
                 phoneNumber  = parsePhoneNumberFromString(phone_number);
                if (phoneNumber != undefined) {
                    country_code = phoneNumber.country;
                    if (country_code) {
                        let options_phone = countryPhoneCode.find(({ code }) => code == country_code.toUpperCase());
                        if (options_phone) {
                             selectedOption = {
                                value: options_phone.dial_code,
                                label: (
                                    <div>
                                        <Flag code={options_phone.code} width="30" /> {options_phone.dial_code}
                                    </div >
                                ),
                            }
                            // this.setState({
                            //     ...this.state,
                            //     phone_number: phoneNumber.nationalNumber,
                            //     selectedOption: selectedOption,
                            //     reservation_code: reservation_code,
                            //     is_sms_resend:is_sms_resend,
                            //     reservation_status:reservation_status,
                            //     validationData: {
                            //         ...this.state.validationData,
                            //         phone_number:  phone_number
                            //     }
                            // })
                        }
                    }
                }
            }else{
                let  {hotel} = this.props;

                if(hotel){
                    let  {country_code} = hotel;
                    let options_phone = countryPhoneCode.find(({ code }) => code == country_code.toUpperCase());
                    if (options_phone) {
                        defultCountryCode = {
                            value: options_phone.dial_code,
                            label: (
                                <div>
                                    <Flag code={options_phone.code} width="30" /> {options_phone.dial_code}
                                </div >
                            ),
                        }
                    }
                }
            }
               
            selectedOption = Object.keys(selectedOption).length !== 0 && selectedOption.constructor === Object?selectedOption:defultCountryCode
             this.setState({
                ...this.state,
                phone_number:phoneNumber && phoneNumber.nationalNumber?phoneNumber.nationalNumber:(phone_number?phone_number.replace("+",""):""),
                selectedOption: selectedOption,
                reservation_code: reservation_code,
                is_sms_resend:is_sms_resend,
                reservation_status:reservation_status,
                validationData: {
                    ...this.state.validationData,
                    phone_number:  phone_number
                }
            })
        }
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        let { phone_number } = this.state.validationData;
        let phoneNumber = parsePhoneNumberFromString(phone_number);
        if (phoneNumber != undefined) {
          let value = selectedOption.value + phoneNumber.nationalNumber;
          this.updateValidFormState("phone_number", value);
        }
      };
      
    formValid = ({ formErrors, ...rest }) => {
        let valid = true;
        Object.entries(formErrors).forEach(([key, value]) => {
            if (value.length > 0) {
                return (valid = false);
            }
        }, Object.create(null));
    
        if (valid) {
            Object.entries(rest).forEach(([key, value], index) => {   
                if(key=="phone_number"){
                    if (value.length <= 0) {
                        this.updateValidFormState(key, value);
                        return (valid = false);
                    }else{
                    let  phoneNumber  = parsePhoneNumberFromString(value);
                        if (phoneNumber == undefined || !phoneNumber.isValid()) {
                            this.updateValidFormState(key, value);
                            return (valid = false);
                        }
                    }
                }else {
                    if (value.length <= 0) {
                        this.updateValidFormState(key, value);
                        return (valid = false);
                    }
                }
                
            }, Object.create(null));
        }
            return valid; 
    };

    updateValidFormState = async (name, value) => {
       
        let formErrors = this.state.validationData.formErrors;
        let formValidationData = await checkValidation(name, value, formErrors);
        console.log("==formValidationData====",formValidationData)
        this.setState({
            ...this.state,
            validationData: {
                ...this.state.validationData,
                formErrors: formValidationData,
                [name]: value,
            },
        });
    };

    handleChangeInput = (e) => {
        let { name, value } = e.target;
        let { selectedOption } = this.state;
        this.setState({ phone_number: value })
        this.updateValidFormState(name, selectedOption.value + value);
    };

    resendInvitation = (e) => {
        e.preventDefault();
        if (this.formValid(this.state.validationData)) {
            let { reservation_code, validationData } = this.state;
            let { phone_number } = validationData;
           let { loading } = this.props;
            if (!loading) {
                this.setState({ loading: true });
                axios.post('/resendSMS', {
                    reservation_code: reservation_code,
                    phone_number: phone_number
                })
                    .then((response) => {
                        this.setState({ loading: false })
                        if (response.status == 200) {
                            let { data } = response;
                            this.props.inviteModalToggle("", "", "","","")
                            if (data.status) {
                                toast.success("Resend invitation successfully.")
                            } else {
                                toast.warning(data.message)
                            }
                        } else {
                            toast.error("Error in send invitation .")
                        }
                        console.log("====respomse====", response)

                    })
                    .catch((error) => {
                        console.log("====error====", error)
                        this.setState({ loading: false })
                        toast.error("Error in send invitation .")
                        console.log("Resend invite Error", error);

                    })
            }
        }
    }


    render() {
        let { loading, validationData, selectedOption,is_sms_resend,reservation_status,country_code_options} = this.state;
        let { formErrors } = validationData;
        
        return (
            <Modal
                show={this.props.showInviteModal}
                onHide={(e) => this.props.inviteModalToggle("", "", "","","")}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Invite for Thru Checkin</Modal.Title>
                    <div className="modalPopupClose clearfix" onClick={(e) => this.props.inviteModalToggle("0", "", "","","")}>
                        <img src='/images/popup_close.png' alt="close" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                {is_sms_resend==null && reservation_status!="checkin_completed_with_payment"?
                 <div className="inviteModalData clearfix">
                 <div className="formfieldNumber clearfix floating-form">
                     <div className="country_phone_wrapper">
                         <FormElements
                             type="text"
                             value={this.state.phone_number}
                             name="phone_number"
                             label=""
                             placeholder="Phone Number"
                             errorMessage={formErrors.phone_number}
                             onChange={this.handleChangeInput}
                         />
                         <div className="country_phone">
                             <Select
                                 value={selectedOption}
                                 onChange={this.handleChange}
                                 options={country_code_options}
                                 className="language_select"
                                 classNamePrefix="react_select"
                                 theme={(theme) => ({
                                     ...theme,
                                     borderRadius: 0,
                                     colors: {
                                         ...theme.colors,
                                         primary25: "#ecf1f5",
                                         primary: "#ecf1f5",
                                     },
                                 })}
                             />
                         </div>
                     </div>
                 </div>
                 </div>
                 :
                 <div className="modalimageid clearfix">
                    Invite request already sent.
                </div>}
                   
                        {/* <div className="ortext clearfix">
                            <p className="text-uppercase text-center">OR</p>
                        </div>
                        <div className="formfieldemail clearfix floating-form">
                            <FormElements
                                type="text"
                                label="Email Address" />
                        </div> */}
                   
                </Modal.Body>
                {is_sms_resend==null && reservation_status!="checkin_completed_with_payment"?
                  <Modal.Footer>
                  <button className="themeButton" onClick={(e) => loading ? "" : this.resendInvitation(e)}>{loading ? "Loading.." : "Send Invite"}</button>
              </Modal.Footer>:null}
              
            </Modal>
        );
    }
}

export default InviteModal;