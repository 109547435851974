import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { connect } from 'react-redux';
import { startCase, isEqual } from 'lodash';

class Upload extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps.csvReservationData, this.props.csvReservationData);;
    }

    getColumns = () => {
        let { csvReservationData } = this.props;
        let { headers } = csvReservationData;
        let tableHeaders = [];
        if (Array.isArray(headers)) {
            tableHeaders = headers.map(h => ({
                name: startCase(h),
                selector: h,
                sortable: true
            }))
        }
        return tableHeaders;
    }

    getData = () => {
        let tableData = [];
        let { csvReservationData } = this.props;
        let { data } = csvReservationData;
        if (Array.isArray(data)) {
            tableData = data.slice(0, 2);
        }
        return tableData;
    }

    render() {
        let columns = this.getColumns(), data = this.getData()
        return (<fieldset className="stepOne clearfix">
            <div className="fieldsetMain">
                <div className="row">
                    <div className="col-md-12">
                        <div className="fieldsetHeader">
                            <h1>Confirm Column Headers</h1>
                            <h3>Does the highlighted row below contain column headers?</h3>
                        </div>
                        <div className="fieldsetData clearfix simpleTable">
                            <DataTable
                                columns={columns}
                                data={data}
                                customStyles={this.props.customStyles}
                            />
                        </div>
                    </div>
                </div>
                <div className="row fieldsetFooter">
                    <div className="col-md-12">
                        <button className="themeButton mr-16" onClick={() => this.props.setStep(2)}>Yes</button>
                        <button className="inactive_themeButton" onClick={this.props.toggleUploadModal}>Change File</button>
                    </div>
                </div>
            </div>
        </fieldset>)
    }
}

Upload.propTypes = {
    csvReservationData: PropTypes.shape({
        headers: PropTypes.array,
        data: PropTypes.array,
    })
}

const mapStateToProps = state => ({
    csvReservationData: state.csvReservationData
})

export default connect(mapStateToProps)(Upload)