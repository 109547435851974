import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import { getBase64Image, getImage, getAspectRatio } from '../../utils/image';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 0
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  marginBottom: 8,
  marginRight: 'auto',
  marginLeft: 'auto',
  width: 'auto',
  maxWidth: '100%',
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const DropZone = (props) => {
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState('');


  const validateRatio = (image) => {
    let ratio = getAspectRatio(image);
    return ratio === props.aspectRatio;
  }

  const validateImage = (image) => {
    let { width, height } = image;
    let { minWidth, minHeight, aspectRatio, maxHeight, maxWidth } = props;

    let checkMinHeight = minHeight !== 0, validMinHeight = true;
    let checkMinWidth = minWidth !== 0, validMinWidth = true;
    let checkMaxHeight = maxHeight !== 0, validMaxHeight = true;
    let checkMaxWidth = maxWidth !== 0, validMaxWidth = true;
    let checkRatio = aspectRatio !== "", validRatio = true;
    let validLogo = true;

    if (checkMinHeight) validMinHeight = (minHeight <= height);
    if (checkMinWidth) validMinWidth = (minWidth <= width);
    if (checkMaxHeight) validMaxHeight = (maxHeight >= height);
    if (checkMaxWidth) validMaxWidth = (maxWidth >= width);
    if (checkRatio) validRatio = validateRatio(image);

    if (props.isLogo || props.hotelBackgroundImage) {
      validLogo = width >= height;
    }

    console.log('width:', width, ', height:', height);
    console.log('validMinHeight', validMinHeight);
    console.log('validMinWidth', validMinWidth);
    console.log('validMaxHeight', validMaxHeight);
    console.log('validMaxWidth', validMaxWidth);
    console.log('validRatio', validRatio);
    console.log('validLogo', validLogo);

    return validMinHeight && validMinWidth && validMaxHeight && validMaxWidth && validLogo;
  }

  const checkImage = async file => {
    return new Promise((resolve, reject) => {
      if (props.maxFileSize === 0 || file.size < props.maxFileSize) {
        getBase64Image(file)
          .then(base64Image => {
            getImage(base64Image)
              .then(image => {
                if (validateImage(image)) {
                  let url = Object.assign(file, {
                    preview: URL.createObjectURL(file)
                  })
                  setImage(base64Image);
                  resolve(url);
                } else {
                  reject(new Error('Please upload an image of required size'));
                }
              })
              .catch(console.error);
          })
          .catch(console.error);
      } else {
        reject(new Error('File size must be less than 5MB.'));
      }
    })
  }

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length <= props.maxFiles) {
      Promise.all(acceptedFiles.map(file => checkImage(file)))
        .then(files => {
          files = files.filter(f => f);
          setFiles(files);
        })
        .catch(err => {
          console.log(err)
          toast.warning(err.message);
        });
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.jpeg, .jpg, .png, .svg',
    onDrop: onDrop
  });

  const thumbs = files.map(file => {
    return (<div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt={file.name} />
      </div>
    </div>)
  });

  useEffect(() => {
    let isEdit = props.isEdit;
    let imageVal = props.imageVal;

    if (isEdit) {
      if (Object.keys(imageVal) !== 0 && imageVal.constructor === Object) {
        let { file_path } = imageVal
        let filename = file_path.substring(file_path.lastIndexOf('/') + 1);
        let files = [];
        files.push({ name: filename, preview: file_path });
        setImage(file_path)
        setFiles(files)
      }
    }
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [props.imageVal, props.isEdit]);

  return (
    <section className="">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <input type="hidden" name={props.inputName} value={image} />
        <div className="brand_container_wrapper">
          {thumbs.length < 1
            ? <div className="brand_details">
              <div className="dragdrop">Drop <span>{props.title}</span> file here {thumbs}</div>
              <div className="brand_or">or</div>
              <button className="themeButton" type="button">{props.btnText}</button>
              {props.hotelBackgroundImage
                ? <div className="formats">
                  {"Suggested formats: PNG and JPEG"}
                  <div>
                    {"Will be used as landing page background."}
                  </div>
                </div>
                : <div className="formats">
                  {"Suggested formats: SVG and PNG"}
                  {/* <div>
                    {props.minWidth !== 0 ? "Min width: " + props.minWidth : ""}
                  </div>
                  <div>
                    {props.minHeight !== 0 ? "Min height: " + props.minHeight : ""}
                  </div> */}
                </div>
              }
            </div>
            : null
          }
          <div className="brand_images images" style={thumbsContainer}>
            {thumbs}
          </div>
        </div>
      </div>
      <div className="error-text">
        {props.validator
          ? props.validator.message(props.inputName, image, props.validations, props.validatorOptions)
          : null
        }
      </div>
    </section>
  );
}

DropZone.defaultProps = {
  maxFiles: 1,
  validator: false,
  maxFileSize: 0,
  minWidth: 0,
  minHeight: 0,
  maxWidth: 0,
  maxHeight: 0,
  aspectRatio: "",
  isEdit: false,
  imageVal: {}
}

DropZone.propTypes = {
  title: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  maxFiles: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  aspectRatio: PropTypes.string.isRequired,
  imageVal: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
}

export default DropZone;