import { constants } from '../constants';
import { getReservationService } from '../services';
import { getCurrentSession } from "../utils"
import { logoutUser } from "./authAction";

export const getReservationList = (filters) => {
    return dispatch => {
        getCurrentSession().then((data) => {
            if (data) {
                dispatch({ type: constants.GET_RESERVATION_REQUEST, data: {} });
                getReservationService(filters)
                    .then(function (response) {
                        if (response.status == 200) {
                            if (response && response.data.hasOwnProperty('data') && response.data.data.length > 0) {
                                dispatch({ type: constants.GET_RESERVATION_SUCCESS, data: response.data.data });
                            } else {
                                dispatch({ type: constants.GET_RESERVATION_FAILED, data: {}});
                            }
                        } else {
                            dispatch({ type: constants.GET_RESERVATION_FAILED, data: {} });
                        }
                    })
                    .catch(function (error) {
                        dispatch({ type: constants.GET_RESERVATION_FAILED, data: error });
                        dispatch({ type: constants.FAILED_RESPONSE, data: error });
                        console.log(error);
                    });
            }else{
                dispatch(logoutUser());
            }
        })
      
    };
};


// export const checkinUser = (reservation_code) => {
//     return dispatch => {
     
//         dispatch({ type: constants.ARRIVED_REQUEST });
//         let JwtToken = data.idToken.jwtToken;
//         checkinUserService(reservation_code, JwtToken)
//             .then(function (response) {
//                 if (response.status == 200) {
//                     if (response && response.hasOwnProperty('data')) {
//                         let { status } = response.data;
//                         if (status)
//                             dispatch({ type: constants.ARRIVED_SUCCESS });
//                     } else {
//                         dispatch({ type: constants.ARRIVED_SUCCESS_FAILED });
//                     }
//                 } else {
//                     dispatch({ type: constants.ARRIVED_SUCCESS_FAILED });
//                 }
//             })
//             .catch(function (error) {
//                 dispatch({ type: constants.ARRIVED_SUCCESS_FAILED });
//                 dispatch({ type: constants.FAILED_RESPONSE, data: error });
//                 console.log(error);
//             });
//     };
// };

export const setPropData = (type,data) => {
    const action = {
		type: type,
		data: data
	}
	return dispatch => {
		dispatch(action)
	}
}

export const setCsvData = (data) => ({
    type: constants.CSV_DATA_ADD,
    data: data
})

export const clearCsvData = () => ({
    type: constants.CSV_DATA_REMOVE
})