import React, { useState } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import axios from '../../../axios';

import CardSection from './CardSection';
import { toast } from 'react-toastify';

function CardSetupForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  
  
  const submitPaymentMethod = (payment_method) => {
    axios.put('/dashboard/hotel/billing', { default_payment_method: payment_method })
      .then(res => {
        if (res.status === 200) {
          let api_response = res.data
          console.log(api_response);
        } else {
          console.log(res);
        }
      })
      .catch(console.error);
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let { client_secret } = props.configs;
    setLoading(true);
    const result = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
        },
      }
    });

    if (result.error) {
      // Display result.error.message in your UI.
      console.log(result.error);
      toast.error(result.error.message);
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      console.log(result);
      submitPaymentMethod(result.setupIntent.payment_method);
      let { payment_method } = result.setupIntent;
      if (payment_method) {
        props.fetchPaymentMethods();
        props.onHide();
        props.showConfirmModal({
          showModal: true,
          title: "Success",
          footer: false,
          confirmBtn: false,
          cancelBtn: false,
          children: <p>Your card has been added successfully.</p>
        })
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardSection />
      <button className="themeButton" disabled={!stripe || loading}>{loading ? "Saving.." : "Save Card"}</button>
    </form>
  );
}

CardSetupForm.propTypes = {
  configs: PropTypes.object.isRequired
}

export default CardSetupForm;