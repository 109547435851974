import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import SimpleReactValidator from 'simple-react-validator';
import { startCase } from 'lodash';

import axios from '../../axios';
import withAuth from "../../lib/auth";
import { setKey, getGuestDetails } from "../../actions";
import { formToJSON, uuid, getCountryStateCity, unflatten } from "../../utils";

import SignupStepLayout from '../SignupStepLayout';
import Wrapper from "./Wrapper";
import LoaderSpinner from "../common/LoaderSpinner";
import NeedHelp from "../common/NeedHelp";
import ExceptionContent from "./ExceptionContent";
import AcceptedIDWrapper from "./AcceptedIDWrapper";
import CustomField from "./CustomField";

class GuestDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: false,
      default_config: false,
      hotel_config: false,
      formData: {},
      country_options: [],
      identity_docs: [],
      exceptions: [],
      custom_fields: []
    };
    this.form = new createRef();
    this.validator = new SimpleReactValidator({
      messages: {
        required: "Please enter :attribute",
        email: "Please enter a valid email address"
      },
      validators: {
        phone_number: {
          message: 'Please enter a valid phone number',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[0-9]{4,15}$/i);
          }
        }
      }
    });
    this.isEdit = this.props.location.pathname.includes('/edit');
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    this.fetchConfigData();
    this.fetchCountries();
    // this.fetchIdentityDocs();
  }

  getTitle = (key) => {
    let mappings = {
      "guest_profile": "Guest Profile",
      "id_card": "Documents"
    }

    if (mappings[key] !== undefined) {
      return mappings[key];
    } else {
      return startCase(key);
    }
  }

  getWrappers = () => {
    let returnHTML = [];
    let { hotel_config, default_config } = this.state;
    let { hotel } = this.props;
    if (default_config?.guest_detail_config) {
      for (const key in default_config.guest_detail_config) {
        let keyData = default_config.guest_detail_config[key];
        let inputName = `guest_detail_config[${key}]`;

        switch (key) {
          case "guest_profile":
            let data = keyData['primary'];
            returnHTML.push(<Wrapper key={key}
              inputName={inputName + '[primary]'}
              title={this.getTitle(key)}
              parentKey={key}
              data={data}
              hotel={hotel}
              isEdit={this.isEdit}
              hotel_config={hotel_config}
              default_config={default_config}
            />)
            break;
          default:
            returnHTML.push(<Wrapper key={key}
              inputName={inputName}
              title={this.getTitle(key)}
              parentKey={key}
              data={keyData}
              hotel={hotel}
              isEdit={this.isEdit}
              hotel_config={hotel_config}
              default_config={default_config}
            />)
            break;
        }
      }
    }
    return returnHTML;
  }

  getIdentityWrappers = () => {
    let returnHTML = [];
    let { identity_docs } = this.state;
    let { hotel } = this.props;
    if (Array.isArray(identity_docs) && identity_docs.length) {
      returnHTML = (<div className="signup_steps_content_wrapper">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="header_detail_wrapper header">
              <div className="header_detail_content">
                <h3 className="steps_header">Accepted Documents (Domestic Guest)</h3>
              </div>
            </div>
            {identity_docs.map((identity, index) => {
              if (identity.country_id === hotel.country_id) {
                return (<AcceptedIDWrapper
                  key={identity.document_id}
                  readOnly={identity.document_type.toLowerCase() === "passport"}
                  checked={identity.checked}
                  onChange={(e) => this.handleIDCheckbox(e, index)}
                  identity={identity}
                />)
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </div>)
    }
    return returnHTML;
  }

  fetchCountries = () => {
    getCountryStateCity()
      .then(res => {
        if (res.status === 200) {
          let api_response = res.data
          let { status, data } = api_response;
          if (status) {
            let country_options = data.map(d => ({ value: d.id, label: d.name }));
            this.setState({ ...this.state, country_options })
          }
        } else {
          console.log(res);
        }
      })
      .catch(console.error);
  }

  fetchConfigData = () => {
    axios('/dashboard/hotel/guestdetails').then(res => {
      if (res.status === 200) {
        let api_response = res.data;
        let { status, data } = api_response;
        console.log('configs', data);
        if (status) {
          // set custom fields for guest details
          let custom_fields = [];
          if (this.isEdit) {
            custom_fields = this.getCustomFields(data.hotel_config);
          } else {
            custom_fields = this.getCustomFields(data.default_config);
          }
          this.setState({ ...this.state, ...data, custom_fields }, this.fetchIdentityDocs);
        } else {
          console.log(api_response);
        }
      } else {
        console.log(res);
      }
    }).catch(console.error)
  }
  
  getCustomFields = (configs) => {
    let custom_fields = [];
    let { guest_custom_fields: { fields } } = configs.guest_custom_fields?.fields ? configs : { guest_custom_fields: { fields: [] } };
    if (typeof fields === "object") {
      Object.entries(fields).forEach(([key, value]) => {
        custom_fields.push({ id: uuid(), ...value });
      });
    }
    return custom_fields;
  }

  fetchIdentityDocs = () => {
    axios('/dashboard/hotel/identitydoc')
      .then(res => {
        if (res.status === 200) {
          let api_response = res.data;
          let { status, data } = api_response;
          if (status) {
            let { hotel } = this.props, { hotel_config, exceptions } = this.state;
            let { ids } = hotel_config;

            // set exceptions for hotel identity docs only for edit
            if (this.isEdit) {
              let countries = [...new Set(ids.map(id => id.country_id).filter(f => f && f !== hotel.country_id))];
              exceptions = countries.map(c => ({ id: uuid(), country_id: c }));
            }

            // map identity docs to checked status
            let identity_docs = data.map(d => {
              let checked = d.document_type.toLowerCase() === "passport";
              if (this.isEdit) {
                if (!checked) {
                  checked = ids.some(id => (id.country_id === d.country_id && id.document_id === d.document_id));
                }
              }
              return ({ ...d, checked })
            });
            if (exceptions.length === 0) exceptions.push({ id: uuid(), country_id: null });
            this.setState({ ...this.state, identity_docs, exceptions });
          }
        }
      }).catch(console.error);
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({ ...this.state, loading: true });
    if (this.validator.allValid()) {
      let { hotel } = this.props;
      if (hotel) {
        let postData = formToJSON(this.form.current, false);

        // convert true/false from string to boolean type
        for (let key in postData) {
          if (postData[key] === 'true') { postData[key] = true } else if (postData[key] === 'false') { postData[key] = false }
        }
        postData = unflatten(postData);

        // set ids key in postData
        let countries = this.state.exceptions.map(e => e.country_id).filter(d => d);
        countries.push(this.props.hotel.country_id);
        let ids = this.state.identity_docs.filter(doc => doc.checked && countries.includes(doc.country_id))
        ids = ids.map(doc => { let { document_id, country_id } = doc; return { document_id, country_id } });
        postData.ids = ids;

        // spread postdata with default_config to fill up missing keys from default_config
        postData = { ...this.state.default_config, ...postData };
        // spread guest_detail_config from default_config to hotel_config
        postData.guest_detail_config.guest_profile = {
          ...this.state.default_config.guest_detail_config.guest_profile,
          ...postData.guest_detail_config.guest_profile
        };
        
        if(this.state.custom_fields.length === 0){
          postData.guest_custom_fields = {
            fields: {}
          }
        }

        console.log(postData);
        // if (process.env.NODE_ENV === "development") {
        //   this.setState({ ...this.state, loading: false });
        //   return;
        // }
        axios({
          method: this.isEdit ? 'put' : 'post',
          url: '/dashboard/hotel/guestdetails',
          data: postData
        }, postData)
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              let api_response = res.data;
              let { status, message } = api_response;
              if (status) {
                this.props.dispatch(getGuestDetails(this.props.history)) 
                if (this.isEdit) {
                  toast.success(message);
                } else {
                  let { hotel } = this.props;
                  if (hotel) {
                    hotel.step_completed = 3;
                    this.props.dispatch(setKey('hotel', hotel));
                  }
                  this.props.history.replace('/terms');
                }
              } else {
                toast.error(message);
              }
            } else {
              console.log(res);
            }
          })
          .catch(e => {
            console.log(e);
            toast.error(e.message);
          })
          .finally(() => this.setState({ ...this.state, loading: false }));
      } else {
        console.log("No hotel details found");
        this.setState({ ...this.state, loading: false });
      }
    } else {
      this.validator.showMessages();
      this.setState({ ...this.state, loading: false });
    }
  }

  // ------------------------------------------------ Exceptions ------------------------------------------------
  addException = () => {
    let { exceptions } = this.state;
    let add = exceptions.every(e => e.country_id);
    if (add) {
      exceptions.push({ id: uuid(), country_id: null });
      this.setState({ ...this.state, exceptions });
    }
  }

  removeException = (index) => {
    let { exceptions } = this.state;
    exceptions = exceptions.filter((e, i) => i !== index);
    this.setState({ exceptions });
  }

  setException = (e, index) => {
    let { exceptions } = this.state;
    exceptions[index] = e;
    this.setState({ ...this.state, exceptions });
  }

  // ------------------------------------------------ Custom Fields ------------------------------------------------
  addCustomField = () => {
    let { custom_fields } = this.state;
    let add = custom_fields.length < 5;
    // &&custom_fields.every(e => e.name && e.name !== "");
    if (add) {
      custom_fields.push({ id: uuid(), name: "", required: false, type: null });
      this.setState({ ...this.state, custom_fields });
    }
  }

  removeCustomField = (index) => {
    let { custom_fields } = this.state;
    custom_fields = custom_fields.filter((e, i) => i !== index);
    this.setState({ custom_fields });
  }

  setCustomField = (e, index) => {
    let { custom_fields } = this.state;
    custom_fields[index] = e;
    this.setState({ ...this.state, custom_fields });
  }

  handleIDCheckbox = (e, index) => {
    let { checked } = e.target;
    let { identity_docs } = this.state;
    identity_docs[index] = { ...identity_docs[index], checked };
    this.setState({ ...this.state, identity_docs })
  }

  render() {
    this.validator.purgeFields();
    return (
      <SignupStepLayout>
        {this.state.loading ? <LoaderSpinner /> : null}
        <form autoComplete="off" ref={this.form} onSubmit={this.onSubmit}>
          <div className="signup_steps_content_wrapper">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="extra_para_guest_detail">
                  {"The following fields which are required in every registration card are updated based on the information received in the data export-"}
                  <div className="extra_para_div">
                    <div className="extra_para_ul_wrapper">
                      <ul>
                        <li>Arrival Date</li>
                        <li>Departure Date</li>
                        <li>Room Type</li>
                        <li>Room Number</li>
                      </ul>
                    </div>
                    <div className="extra_para_ul_wrapper">
                      <ul>
                        <li>Room Rate</li>
                        <li>Rate Code and Description</li>
                        <li>Number of Adults</li>
                        <li>Number of Children</li>
                      </ul>
                    </div>
                  </div>
                  {"In addition to the above, Thru currently allows the guests to fill following pre-defined fields as part of your registration card. Going forward, you will be able to customize your registration card and choose which fields you would like the guest to fill."}
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-12">
                <div className="tip_container">
                  <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                  <div className="tip_text">
                    <p>Mark fields that you would like the guest to fill in the first column. All fields are mandatory by default. You can make them optional by selecting the second column. </p>
                    <p>Further, you can choose whether you’d like only international or domestic guests or both to fill these fields.</p>
                    {/* <p>Note: Choose the ID’s carefully as per the hotel’s policy and guidelines for domestic and international guest</p> 
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {this.getWrappers()}
          <div className="signup_steps_content_wrapper">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="header_detail_wrapper header">
                  <div className="header_detail_content">
                    <h3 className="steps_header">Custom Fields</h3>
                    {this.state.custom_fields.length < 5
                      ? <span className="add_exception">
                        <img src='/images/iconbtn-plus.svg' alt="add icon" onClick={this.addCustomField} />
                      </span>
                      : null
                    }
                  </div>
                </div>
                {Array.isArray(this.state.custom_fields) && this.state.custom_fields.length
                  ? <div className="header_detail_wrapper header">
                    <div className="header_detail_content"><h4 className="steps_header">{"Field Name"}</h4></div>
                    <div className="header_detail_content header_span">
                      <span className="header_detail_type">Type</span>
                    </div>
                    <div className="header_detail_content header_span mandatory">
                      <span>Mandatory</span>
                    </div>
                  </div>
                  : null
                }
                {this.state.custom_fields.map((e, index) => (<CustomField key={e.id}
                  index={index}
                  setCustomField={this.setCustomField}
                  custom_fields={this.state.custom_fields}
                  isEdit={this.isEdit}
                  onRemove={this.removeCustomField}
                  validator={this.validator}
                />))}
              </div>
            </div>
          </div>
          {this.getIdentityWrappers()}
          <div className="signup_steps_content_wrapper">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="header_detail_wrapper header">
                  <div className="header_detail_content">
                    <h3 className="steps_header">Accepted Documents (Foreign Guest)</h3>
                    <span className="add_exception">
                      <img src='/images/iconbtn-plus.svg' alt="add icon" onClick={this.addException} />
                    </span>
                  </div>
                </div>
                {this.state.exceptions.length
                  ? <p className="headerDetailWrapperDescription">Passports are selected for all nationalities. Please select any additional documents per nationality</p>
                  : null
                }
                {this.state.exceptions.map((e, index) => (<ExceptionContent key={e.id}
                  index={index}
                  setException={this.setException}
                  exceptions={this.state.exceptions}
                  hotel={this.props.hotel}
                  isEdit={this.isEdit}
                  handleIDCheckbox={this.handleIDCheckbox}
                  identity_docs={this.state.identity_docs}
                  country_options={this.state.country_options}
                  onRemove={this.removeException}
                  validator={this.validator}
                />))}
              </div>
            </div>
          </div>
          <NeedHelp />
          <div className="continue-btn">
            <div className="formSubmitButton">
              <button type="submit" disabled={this.state.loading} className="themeButton">
                {this.state.loading ? "Please wait.." : "Continue"}
              </button>
            </div>
          </div>
        </form>
      </SignupStepLayout>
    );
  }
}

const mapStateToProps = state => ({
  hotel: state.hotel
})

export default connect(mapStateToProps)(withAuth(withRouter(GuestDetail)));