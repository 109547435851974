import React from "react";
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DropzoneCSV from '../common/DropZoneCSV';

function CSVUploadModal(props) {
    return (
        <Modal
            show={props.showModal}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Add Reservations</Modal.Title>
                <div className="modalPopupClose clearfix" onClick={props.onHide}>
                    <img src="/images/popup_close.png" alt="close" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modalForm clearfix">
                    <div className="floating-form">
                        <div className="floating-label mb-0">
                            <DropzoneCSV onDropComplete={props.onDropComplete} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

CSVUploadModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onDropComplete: PropTypes.func
};

export default CSVUploadModal