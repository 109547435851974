import React,{useState} from "react";
import { Modal } from 'react-bootstrap';
import { checkValidation } from "../../lib/formValidation";
import axios from '../../axios';
import { toast } from 'react-toastify';


export default function AddAlertModal (props) {
    const [validationData, setValidationData] = useState({
        description: "",
        formErrors: {
            description: ""
        },
    })
const [loading, setLoading] = useState(false)

   const  formValid = ({ formErrors, ...rest }) => {
        let valid = true;
        Object.entries(formErrors).forEach(([key, value]) => {
            if (value.length > 0) {
                return (valid = false);
            }
        });

        if (valid) {
            Object.entries(rest).forEach(([key, value], index) => {
                if (value.length <= 0) {
                    updateValidFormState(key, value);
                    return (valid = false);
                }
            });
        }
        return valid;
    };

    const updateValidFormState = async (name, value) => {
        let formErrors = validationData.formErrors;
        let formValidationData = await checkValidation(name, value, formErrors);
        setValidationData({
            formErrors: formValidationData,
            [name]: value
        });
    };


    const onChnagehandle = (e) =>{
        let {name,value} =e.target;
        updateValidFormState(name, value); 
    }

    const submitAlert = (e) => {
        e.preventDefault();
        if (formValid(validationData)) {
            let addAlert = props.addAlert;
            addAlert.description =validationData.description.trim();
            console.log("addAlert=====",addAlert);
            setLoading(true);
            axios.post('dashboard/reservations/alert',addAlert) 
            .then((response) => {
                setLoading(false);
                if (response.status == 200) {
                    let { data } = response;
                    props.onHide("", "", "","","")
                    if (data.status) {
                        toast.success("Alert added successfully.")
                    } else {
                        toast.warning(data.message)
                    }
                } else {
                    toast.error("Error in add alert.")
                }

            })
            .catch((error) => {
                setLoading(false);
                toast.error("Error in add alert.")
                console.log("Resend invite Error", error);

            })
        }
    }


    return (
        <Modal
            show={props.showAlertModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter"><img src="/images/alert.png" alt="Not found" className="modalalertImg"/>Add Alert</Modal.Title>
                <div className="modalPopupClose clearfix" onClick={(e)=>props.onHide("","")}>
                    <img  src='/images/popup_close.png'  alt="close" />
                </div>
            </Modal.Header>
            
            <Modal.Body>
                <div className="modalForm clearfix">
                    <div className="floating-form">
                    <div className="floating-label mb-0">
                        <textarea name="description" placeholder="Type something.." onChange={onChnagehandle}></textarea>
                    </div>
                    {validationData && validationData.formErrors.description.length > 0 ? (<span className="errorMessage">{validationData.formErrors.description   }</span>) : null}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="themeButton withalert" onClick={(e)=>loading?"":submitAlert(e)}>{loading?"Loading...":"Save"}</button>
            </Modal.Footer>
        </Modal>
    );
}