import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

class ConfirmModal extends Component {
  componentDidMount() {
    if (this.props.autoClose) {
      setTimeout(() => {
        this.props.onHide();
      }, 2000);
    }
  }

  onConfirm = () => {
    this.props.onConfirm();
    if(this.props.hideOnConfirm){
      this.props.onHide();
    }
  }

  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.onHide}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{this.props.title}</Modal.Title>
          <div className="modalPopupClose clearfix" onClick={this.props.onHide}>
            <img src='/images/popup_close.png' alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body>

          {this.props.children}

        </Modal.Body>
        {this.props.footer || this.props.confirmBtn || this.props.cancelBtn
          ? <Modal.Footer>
            {this.props.cancelBtn
              ? (<button className="secondaryBtn small" onClick={this.props.onHide}>
                {(this.props.cancelBtnText) ? this.props.cancelBtnText : "No"}
              </button>)
              : null
            }

            {this.props.confirmBtn
              ? (<button className="themeButton small" onClick={this.onConfirm}>
                {(this.props.confirmBtnText) ? this.props.confirmBtnText : "Yes"}
              </button>)
              : null
            }
          </Modal.Footer>
          : null}
      </Modal>
    );
  }
}

ConfirmModal.defaultProps = {
  autoClose: false,
  hideOnConfirm: true,
  confirmBtn: true,
  cancelBtn: true,
  confirmBtnText: "Yes",
  cancelBtnText: "No",
  size: "md",
  footer: true
}

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  hideOnConfirm: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  footer: PropTypes.bool.isRequired,
  confirmBtn: PropTypes.bool.isRequired,
  cancelBtn: PropTypes.bool.isRequired,
  autoClose: PropTypes.bool.isRequired,
  confirmBtnText: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string.isRequired
};

export default ConfirmModal;