import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormElements from '../formElements/FormElements';
import Select from 'react-select';
// import Flag from "react-world-flags";
// import PhoneNumber from '../formElements/PhoneNumber';
// import countryPhoneCode from '../../utils/countryPhoneCode.json';
import { getSelectTheme } from '../../utils/ui';

class TeamMember extends Component {

    handleChange = (e) => {
        this.props.onChange(e);
    }

    handleSelect = (e, target) => {
        let { name } = target;
        this.props.onChange({ target: { name: name, value: e } });
    }

    getDisabledState = () => {
        let disabled = false;
        let { email_response, formData } = this.props;
        let name = `team[${this.props.index}][email]`;
        if (Array.isArray(email_response) && email_response.length && formData[name]) {
            let index = email_response.findIndex(e => e.email === this.props.formData[name]);
            if (index !== -1) {
                // console.log('email_response index', email_response[index]);
                disabled = email_response[index]['status'];
            }
        }
        return disabled;
    }

    getMessage = () => {
        let { email_response, formData, index } = this.props;
        let returnHTML = [];
        let name = `team[${this.props.index}][email]`;
        if (Array.isArray(email_response) && email_response.length && formData[name]) {
            let index = email_response.findIndex(e => e.email === this.props.formData[name]);
            if (index !== -1) {
                let { message, status } = email_response[index];
                returnHTML.push(<div key="status" className="verify_wrapper">
                    <span className={`status ${status ? 'verified' : 'not_verified'}`}>{message}</span>
                </div>)
                if (status === false) {
                    returnHTML.push(<span key="close" className="close_btn" onClick={() => this.props.onRemove(index)}>
                        <img src="/images/close.svg" alt="close" />
                    </span>)
                }
            } else {
                returnHTML.push(<span key="close" className="close_btn" onClick={() => this.props.onRemove(index)}>
                    <img src="/images/close.svg" alt="close" />
                </span>)
            }
        } else {
            returnHTML.push(<span key="close" className="close_btn" onClick={() => this.props.onRemove(index)}>
                <img src="/images/close.svg" alt="close" />
            </span>)
        }
        return returnHTML;
    }

    render() {
        let options = [{ value: '1', label: 'Admin' }, { value: '0', label: 'Front Office' }];
        let disabled = this.getDisabledState();
        this.name = `team[${this.props.index}][email]`;
        this.phone_name = `team[${this.props.index}][phone_number]`;
        this.country_code_name = `team[${this.props.index}][country_code]`;
        this.role_name = `team[${this.props.index}][is_admin]`;
        return (
            <div className={'team_member_wrapper' + (disabled ? ' disabled' : '')}>
                <div className="form_element_wrapper">
                    <div className="form_element">
                        <FormElements
                            type="text"
                            value={this.props.formData[this.name]}
                            disableEnter
                            label="Email Address"
                            disabled={disabled}
                            validator={this.props.validator}
                            validations='required|email'
                            validatorOptions={{ messages: { required: "Please enter email address" } }}
                            onChange={this.handleChange}
                            name={this.name}
                        />
                    </div>
                    {this.getMessage()}
                </div>
                <div className="form_element_wrapper">
                    <div className="form_element">
                        <div className="country_phone_wrapper">
                            <div className="floating-label">
                                <input className="floating-input"
                                    type='text'
                                    value={this.props.formData[this.phone_name]}
                                    disabled={disabled}
                                    name={this.phone_name}
                                    autoComplete="off"
                                    placeholder='Phone Number'
                                    onChange={this.handleChange}
                                />
                                {!disabled
                                    ? (<div className="error-text">
                                        {this.props.validator.message(this.phone_name, this.props.formData[this.phone_name], 'required|phone_number', { messages: { required: "Please enter phone number" } })}
                                    </div>)
                                    : null
                                }
                            </div>
                            <div className="country_phone">
                                <Select
                                    value={this.props.formData[this.country_code_name]}
                                    onChange={this.handleSelect}
                                    options={this.props.country_code_options}
                                    name={this.country_code_name}
                                    isDisabled={disabled}
                                    className="language_select"
                                    classNamePrefix="react_select"
                                    theme={getSelectTheme}
                                />
                            </div>
                        </div>
                        {/* <PhoneNumber
                        type="text"
                        value={this.state[phone_name]}
                        disableEnter
                        validator={this.props.validator}
                        validations='required|numeric'
                        validatorOptions={{ messages: { required: "Please enter phone number", numeric: "Please enter a valid phone number" } }}
                        onChange={this.handleChange}
                        name={phone_name}
                        country_code_name={country_code_name}
                    /> */}
                    </div>
                    <div className="form_element">
                        <div className="thru_react_select">
                            <label>Role</label>
                            <Select
                                options={options}
                                name={this.role_name}
                                value={this.props.formData[this.role_name]}
                                className="tax_select"
                                isDisabled={disabled}
                                classNamePrefix="react_select"
                                placeholder=""
                                onChange={this.handleSelect}
                                theme={getSelectTheme}
                            />
                            {!disabled
                                ? (<div className="error-text">
                                    {this.props.validator.message(this.role_name, (this.props.formData[this.role_name] ? this.props.formData[this.role_name].value : ""), 'required', { messages: { required: "Please select role" } })}
                                </div>)
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

TeamMember.propTypes = {
    index: PropTypes.number.isRequired,
    hotel: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired
}

export default TeamMember
