import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import axios from '../../axios';
import { formToJSON, scrollToError, uuid } from '../../utils';
import withAuth from "../../lib/auth";

import FormElements from "../formElements/FormElements";
import SignupStepLayout from '../SignupStepLayout';
import PolicyAgreement from "../modal/PolicyAgreement";
import { connect } from "react-redux";
import { setKey } from "../../actions";
import { toast } from "react-toastify";
import Credits from "../credits";
import TaxInvoice from "./TaxInvoice";
import LoaderSpinner from "../common/LoaderSpinner";
import NeedHelp from "../common/NeedHelp";
import { getSelectTheme } from "../../utils/ui";

class Billing extends Component {
    constructor(props) {
        super(props);
        let { user } = this.props, email;
        if (user) {
            let { attributes } = user;
            if (attributes) email = attributes.email;
        }
        this.state = {
            loading: false,
            isSameAddress: false,
            countries: [],
            formData: {
                country_id: null,
                state_id: null,
                city_id: null,
                legal_entity_name: "",
                email_address: email ? email : '',
            },
            showAgreement: false,
            agreement: false,
            tax_invoices: [uuid()],
            tax_types: []
        };
        this.form = new React.createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute",
                email: "Please enter a valid email address"
            }
        });
    }

    showAgreementToggle = () => this.setState({ showAgreement: !this.state.showAgreement });

    componentDidMount() {
        document.scrollingElement.scrollTop = 0;
        this.fetchCountries();
        this.fetchTaxDetails();
    }

    fetchAgreement = (country_id) => {
        axios('/dashboard/hotel/legaldocuments?type=dashboard&country_id=' + country_id)
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, data } = api_response;
                    if (status && Array.isArray(data)) {
                        let html = data.find(d => d.type && d.type.toString().toLowerCase() === "saas_agreement");
                        if (html) {
                            this.setState({ agreement: html, showAgreement: true });
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err.message);
                toast.error("Unable to process. Please try again");
            })
            .finally(() => this.setState({ ...this.state, loading: false }));
    }

    fetchCountries = () => {
        axios.get('/getCountryStateCity')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data
                    console.log('Countries response', api_response);
                    let { status, data } = api_response;
                    if (status) {
                        this.setState({
                            ...this.state,
                            countries: data.map(d => ({ value: d.id, label: d.name })),
                            states: [],
                            cities: [],
                            formData: {
                                ...this.state.formData,
                                country_id: null,
                                state_id: null,
                                city_id: null
                            }
                        })
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(console.error);
    }

    fetchStates = (country_id) => {
        axios.get('/getCountryStateCity?country_id=' + country_id)
            .then(res => {
                let api_response = res.data
                console.log('States response', api_response);
                let { status, data } = api_response;
                if (status) {
                    this.setState({
                        ...this.state,
                        states: data.map(d => ({ value: d.id, label: d.name })),
                        cities: [],
                        formData: {
                            ...this.state.formData,
                            state_id: null,
                            city_id: null
                        }
                    })
                }
            })
            .catch(console.error);
    }

    fetchCities = (state_id) => {
        axios.get(`/getCountryStateCity?state_id=${state_id}`)
            .then(res => {
                let api_response = res.data
                console.log('Cities response', api_response);
                let { status, data } = api_response;
                if (status) {
                    this.setState({
                        ...this.state,
                        cities: data.map(d => ({ value: d.id, label: d.name })),
                        formData: {
                            ...this.state.formData,
                            city_id: null
                        }
                    })
                }
            })
            .catch(console.error);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, loading: true });
        if (this.validator.allValid()) {
            let country_id = false;
            if (this.state.isSameAddress) {
                let { hotel } = this.props;
                if (hotel) {
                    country_id = hotel.country_id;
                } else {
                    console.log('No hotel details found.');
                    return;
                }
            } else {
                country_id = this.state.formData.country_id.value;
            }
            console.log('country_id', country_id);
            if (country_id) this.fetchAgreement(country_id);
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false }, scrollToError);
        }
    }

    saveBillingDetails = () => {
        this.setState({ ...this.state, loading: true, showAgreement: false });
        if (this.validator.allValid()) {
            console.log('You submitted the form and stuff!');
            let postData = formToJSON(this.form.current);
            postData.user_agent = navigator.userAgent;
            // update address as required by api
            if (this.state.isSameAddress) {
                let { hotel } = this.props;
                if (hotel) {
                    postData.hotel_id = hotel.id;
                    postData.address = hotel.hotel_address;
                    postData.postal_code = hotel.postal_code;
                    postData.country_id = hotel.country_id;
                    postData.city_id = hotel.city_id;
                } else {
                    console.log('No hotel details found.');
                    return;
                }
            }
            console.log('postData', postData);

            axios.post('/dashboard/hotel/billing', postData)
                .then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        let api_response = res.data;
                        let { status } = api_response;
                        if (status) {
                            let { hotel } = this.props;
                            if (hotel) {
                                hotel.step_completed = 2;
                                this.props.dispatch(setKey('hotel', hotel));
                            }
                            this.props.history.replace("/guestdetail");
                        } else {
                            console.log(res);
                            toast.error("Unable to process. Please try again");
                        }
                    } else {
                        console.log(res);
                        toast.error("Unable to process. Please try again");
                    }
                })
                .catch(err => {
                    console.log(err.message);
                    toast.error("Unable to process. Please try again");
                })
                .finally(() => this.setState({ ...this.state, loading: false }));
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false }, scrollToError);
        }
    }

    handleSelect = (e, name) => {
        let { value } = e;
        let state = {
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: e
            }
        };
        switch (name) {
            case "country_id":
                this.setState(state, () => this.fetchStates(value));
                break;
            case "state_id":
                this.setState(state, () => this.fetchCities(value));
                break;
            case "city_id":
                this.setState(state);
                break;
            default:
                break;
        }
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.validator.showMessageFor(name);
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleAddressCheckbox = (e) => {
        let { checked } = e.target;
        let state = this.state;
        if (checked) {
            state.isSameAddress = true;
            this.validator.purgeFields();
        } else {
            state.isSameAddress = false
        }
        this.setState(state);
    }

    fetchTaxDetails = () => {
        axios('/dashboard/hotel/taxdetails')
            .then(res => {
                console.log(res);
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status, data } = api_response;
                    if (status) {
                        this.setState({
                            ...this.state,
                            tax_types: data
                        })
                    }
                } else {
                    console.log(res);
                }
            })
            .catch(console.error);
    }

    addTaxInvoice = () => {
        let state = this.state;
        let { tax_invoices } = state;
        tax_invoices.push(uuid());
        this.setState({ ...state, tax_invoices })
    }

    removeTaxInvoice = (index) => {
        let { tax_invoices } = this.state;
        if (tax_invoices.length > 1) tax_invoices = tax_invoices.filter((t, i) => i !== index);
        this.validator.purgeFields();
        this.setState({ tax_invoices });
    }

    render() {
        let { loading } = this.state,
        isEdit = this.props.location.pathname.includes('/edit');

        return (
            <SignupStepLayout>
                {loading ? <LoaderSpinner /> : null}
                {isEdit
                    ? (<div className="signup_main_container">
                        <div className="signup_steps_container_wrapper">
                            <div className="signup_steps_container edit">
                                <Credits />
                            </div>
                        </div>
                    </div>)
                    : <form ref={this.form} autoComplete="off" onSubmit={this.onSubmit}>
                        {/* <GovernmentRegistrations
                            formData={this.state.formData}
                            onChange={this.handleInputChange}
                            validator={this.validator}
                            countries={this.state.countries}
                        /> */}
                        <div className="signup_steps_content_wrapper">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    {/* <div className="extra_para">
                                {"We need your billing details sociis natoque penatibus et magnis dis parturient. Contra legem facit qui id facit quod lex prohibet."}
                                </div> */}
                                    <h3 className="steps_header">Billing Information</h3>
                                    <div className="floating-form">
                                        <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <FormElements
                                                    type="text"
                                                    value={this.state.formData.legal_entity_name}
                                                    validator={this.validator}
                                                    validations='required'
                                                    onChange={this.handleInputChange}
                                                    label="Legal Entity Name"
                                                    name="legal_entity_name"
                                                />
                                            </div>
                                        </div>
                                        {this.state.tax_invoices.map((invoice, index) => {
                                            return <TaxInvoice key={invoice}
                                                validator={this.validator}
                                                formData={this.state.formData}
                                                onChange={this.handleInputChange}
                                                showRemove={this.state.tax_invoices.length > 1}
                                                hotel={this.props.hotel}
                                                tax_types={this.state.tax_types}
                                                removeTaxInvoice={this.removeTaxInvoice}
                                                index={index}
                                            />
                                        })}
                                        <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <div className="addmore_btn">
                                                    <button type="button" onClick={this.addTaxInvoice}>
                                                        {"Add More "}
                                                        <img src='/images/iconbtn-plus.svg' alt="add icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="tip_container">
                                        <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                                        <div className="tip_text">
                                            <p>The legal entity name and corresponding tax registration number/tax value will be used to raise invoices for use of Thru.</p>
                                            {/* <ul>
                                                <li>Name of Legal Entity in which you need the invoice from Thru.</li>
                                                <li>Your Tax Registration Number like GST or VAT as applicable in your country is necessary.</li>
                                                <li>Add the billing address to receive the invoice. If billing address is same as the hotel address then click on the button.</li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="signup_steps_content_wrapper">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <h3 className="steps_header">Send Thru Receipts To</h3>
                                    <div className="floating-form">
                                        <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <FormElements
                                                    type="text"
                                                    value={this.state.formData.email_address}
                                                    validator={this.validator}
                                                    validations='required|email'
                                                    onChange={this.handleInputChange}
                                                    label="Email Address"
                                                    name="email_address"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="signup_steps_content_wrapper">
                            <div className="row">
                                <div className="col-lg-8 col-md-12">
                                    <h3 className="steps_header">Billing Address</h3>
                                    <div className="form_element_wrapper billing_address_confirm">
                                        <label className="container_check">
                                            {"Same as hotel address"}
                                            <input type="checkbox" onChange={this.handleAddressCheckbox} />
                                            <span className="checkmark_check"></span>
                                        </label>
                                    </div>
                                    {this.state.isSameAddress === false
                                        ? (<div className="floating-form">
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <FormElements
                                                        type="text"
                                                        value={this.state.formData.address}
                                                        validator={this.validator}
                                                        validations='required'
                                                        onChange={this.handleInputChange}
                                                        label="Address"
                                                        name="address"
                                                    />
                                                </div>
                                                <div className="form_element width-150">
                                                    <div className="thru_react_select">
                                                        <label>Country</label>
                                                        <Select
                                                            options={this.state.countries}
                                                            name='country_id'
                                                            value={this.state.formData.country_id}
                                                            id='country_id'
                                                            className="tax_select"
                                                            onChange={(e) => this.handleSelect(e, "country_id")}
                                                            classNamePrefix="react_select"
                                                            placeholder=""
                                                            theme={getSelectTheme}
                                                        />
                                                    </div>
                                                    <div className="error-text">
                                                        {this.validator.message('country_id', this.state.formData.country_id, 'required', { messages: { required: "Please select country" } })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_element_wrapper">
                                                <div className="form_element width-150">
                                                    <div className="thru_react_select">
                                                        <label>State</label>
                                                        <Select
                                                            options={this.state.states}
                                                            name='state_id'
                                                            id='state_id'
                                                            value={this.state.formData.state_id}
                                                            className="tax_select"
                                                            classNamePrefix="react_select"
                                                            onChange={(e) => this.handleSelect(e, "state_id")}
                                                            placeholder=""
                                                            theme={getSelectTheme}
                                                        />
                                                        <div className="error-text">
                                                            {this.validator.message('state_id', this.state.formData.state_id, 'required', { messages: { required: "Please select state" } })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_element">
                                                    <div className="thru_react_select">
                                                        <label>City</label>
                                                        <Select
                                                            options={this.state.cities}
                                                            name='city_id'
                                                            value={this.state.formData.city_id}
                                                            id='city_id'
                                                            onChange={(e) => this.handleSelect(e, "city_id")}
                                                            className="tax_select"
                                                            classNamePrefix="react_select"
                                                            placeholder=""
                                                            theme={getSelectTheme}
                                                        />
                                                        <div className="error-text">
                                                            {this.validator.message('city_id', this.state.formData.city_id, 'required', { messages: { required: "Please select city" } })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form_element width-150">
                                                    <FormElements
                                                        type="text"
                                                        validator={this.validator}
                                                        value={this.state.formData.postal_code}
                                                        maxLength={25}
                                                        onChange={this.handleInputChange}
                                                        validations='required'
                                                        label="Zip Code"
                                                        name="postal_code"
                                                    />
                                                </div>
                                            </div>
                                        </div>)
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        <NeedHelp />
                        <div className="continue-btn">
                            <div className="formSubmitButton">
                                <button type="submit" disabled={this.state.loading} className="themeButton">
                                    {this.state.loading ? "Please wait.." : "Continue"}
                                </button>
                            </div>
                        </div>
                    </form>
                }
                <PolicyAgreement
                    showAgreementModal={this.state.showAgreement}
                    data={this.state.agreement}
                    onSubmit={this.saveBillingDetails}
                    onHide={this.showAgreementToggle}
                />
            </SignupStepLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotel: state.hotel,
        user: state.user
    }
}

export default connect(mapStateToProps)(withAuth(withRouter(Billing)));
