import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';

class EditorHtml extends Component {
  constructor(props) {
    super(props);
   let content = (props.term_condition ? stateFromHTML(props.term_condition):"")
    this.state = {
      editorState:this.props.term_condition? EditorState.createWithContent(content):EditorState.createEmpty(),
      html: (props.term_condition?props.term_condition:""),
      text: (props.term_condition?props.term_condition:"")
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if(nextProps.terms_and_conditions !== this.props.terms_and_conditions){
      const html = nextProps.terms_and_conditions;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          editorState,
          html,
          text: editorState.getCurrentContent().getPlainText().replace(/(\r\n|\r|\n)/g, '')
        });
      }
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      text: editorState.getCurrentContent().getPlainText().replace(/(\r\n|\r|\n)/g, '')
    });
  };

  render() {
    const { editorState, html, text } = this.state;
    return (
      <Fragment>
        <div className="terms_wrapper_editor">
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={this.onEditorStateChange}
            toolbar={ 
              {
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                fontSize: {
                  options: [12, 14, 16, 18],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                }
              }
            }
          />
          <textarea name="terms_and_conditions" readOnly
            style={{ display: "none" }}
            value={html}
          />
        </div>
        <div className="error-text">
          {this.props.validator !== false
            ? this.props.validator.message('terms_and_conditions', text, 'required', { messages: { min: "Please enter :attribute" } })
            : null
          }
        </div>
      </Fragment>
    )
  }
}

EditorHtml.propTypes = {
  validator: PropTypes.isRequired,
  terms_and_conditions: PropTypes.string.isRequired
}

export default EditorHtml;