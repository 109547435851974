import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { matchPath } from 'react-router';
import { Auth } from 'aws-amplify';
import { removeCookie, setCookie } from "./session";
import {  getRedirectPage } from "../utils";
import { clearState } from '../actions';
import {setKey} from "../actions";

const withAuth = (ComponentToProtect) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        validUser: 0,
        redirect: false
      };
    }

    componentDidMount() {
      // let state = getStorage("state", "object", false);
      let {hotel} =this.props;
      Auth.currentAuthenticatedUser().then(user => {
        let { signInUserSession, attributes } = user;
        let { email } = attributes;
        let { idToken } = signInUserSession;
        let { jwtToken } = idToken;
        setCookie("jwtToken", jwtToken);
        setCookie("userEmail", email);
        attributes.username = user.username;
        localStorage.setItem('userAttributes', JSON.stringify(attributes))
        Auth.userAttributes(user).then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            this.props.dispatch(setKey('currentUserAttributes', data));
            let is_admin = data.find(({ Name }) => Name === "custom:is_admin");
            if (is_admin) {
              let { Value } = is_admin;
              is_admin = Value === "1" ? true : false;
              this.props.dispatch(setKey('is_admin', is_admin));
            }
            let email_verified = data.find(({ Name }) => Name === "email_verified");
            let phone_number_varified = data.find(({ Name }) => Name === "phone_number_verified");
            if (phone_number_varified == undefined ||
              phone_number_varified == "undefined" ||
              phone_number_varified.Value === "false") {
              localStorage.setItem('authType', 'phone_number')
              this.checkRedirection(2, hotel);
            } else
              if (email_verified == undefined ||
                email_verified == "undefined" ||
                email_verified.Value === "false") {
                localStorage.setItem('authType', 'email')
                this.checkRedirection(3, hotel);
              } else {
                this.checkRedirection(1, hotel);
              }
          }
        }).catch((e) => {
          console.log("userAttributes error====", e)
          if (e.code == 'UserNotFoundException' || e.code == "NotAuthorizedException") {
            this.checkRedirection(0, hotel)
          }
        })
      }).catch(e => {
        console.log("===current User Data error =====", e)
        this.checkRedirection(0, hotel)
      })

    }

    checkRedirection = async (isValidUser, hotelData) => {
      console.log("isValidUser", isValidUser);
      const pathname = this.props.history.location.pathname;
      let redirect;
      if (isValidUser == 1) {
        // if (hotelData) {
        //   if(hotelData.step_completed<6){
        //     let redirectPath = await getRedirectPage(hotelData.step_completed,this.props.history);
        //     if (redirectPath) {
        //       this.props.history.push(redirectPath)
        //     }
        //   }
        // } else {
        //   this.props.history.push("/hotelinfo")
        // }
        if (matchPath(pathname, { path: "/login", strict: true })) {
          redirect = '/dashboard'
        }
      }
      else if (isValidUser == 2) {
        if (!matchPath(pathname, { path: "/mobileverify", strict: true })) {
          redirect = '/mobileverify'
        }
      }
      else if (isValidUser == 3) {
        if (!matchPath(pathname, { path: "/emailverify", strict: true })) {
          redirect = '/emailverify'
        }
      }
      else if (isValidUser == 0) {
        removeCookie("jwtToken");
        localStorage.clear();
        this.props.dispatch(clearState());
        if (!matchPath(pathname, { path: "/login", strict: true })) {
          redirect = '/login';
        }
      }

      this.setState({
        redirect: redirect,
        loading: false
      });
    }

    render() {
      let { loading, redirect } = this.state;
      if (redirect) {
        return (<Redirect to={redirect} /> );
      }
      return (
        <React.Fragment>
          {!loading
            ? <ComponentToProtect {...this.props} />
            : null}
        </React.Fragment>
      );
    }
  }
}

export default withAuth;