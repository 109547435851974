import { getBalanceService } from "../services/index";
import { constants } from "../constants";

export const getBalance = () => {
  return (dispatch, getState) => {
    let { balance } = getState();
    if (balance === false) {
      getBalanceService()
        .then((response) => {
          if (response.status == 200) {
            let api_response = response.data,
              { status, data } = api_response;
            if (status) {
              dispatch({ type: constants.GET_BALANCE_SUCCESS, data: data });
            } else {
              dispatch({ type: constants.FAILED_RESPONSE, data: api_response });
            }
          }
        }).catch((error) => {
          console.error(error);
          dispatch({ type: constants.FAILED_RESPONSE, data: error });
        })
    }
  }
}