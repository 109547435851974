import React from "react";
import { connect } from "react-redux";
import Layout from "../components/Layout";
import Filters from "../components/filters/Filters";
import ShowIdModal from "../components/modal/ShowIdModal";
import InviteModal from "../components/modal/InviteModal";
import AddNoteModal from "../components/modal/AddNoteModal";
import AddAlertModal from "../components/modal/AddAlertModal";
import CheckoutInviteModal from "../components/modal/CheckoutInviteModal";
import PrintRegCardModal from "../components/modal/PrintRegCardModal";
import { getReservationList, getHotelDetails, setKey, getGuestDetails,hotelIntegrations } from "../actions";
import RervervationGrid from "../components/ReservationGrid";
import Statistics from "../components/Statistics";
import moment from "moment";
import withAuth from "../lib/auth";
import LoaderSpinner from "../components/common/LoaderSpinner";
import ConfirmModal from '../components/modal/ConfirmModal';
import axios from "../axios";
import { toast } from 'react-toastify';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState = () => {
        let { hotel } = this.props;
        return {
            arrival_reservation_code: "",
            confirmModal: false,
            showIDModal: false,
            InviteModal: false,
            CheckoutInviteModal: false,
            NoteModal: false,
            AlertModal: false,
            regCardModal: false,
            regCardId: "",
            selectFilterStics: "",
            resendInvitationData: {
                reservation_code: "",
                phone_number: "",
                country_code: "",
                is_sms_resend: "",
                reservation_status: ""
            },
            reservationData: [],
            statisticsData: {
                arrival_today: 0,
                completed: 0,
                in_progress: 0,
                with_alert: 0,
                pending: 0,
                departing: 0,
                checkedout: 0,
            },
            documents: [],
            idShowPopupName: "",
            filters: {
                selectGuestType: "arrival",
                withDays: 0,
                searchVal: "",
                orderBy: "last_name",
                orderByObj: {
                    display_name: "Last Name",
                    value: "last_name",
                    color: "",
                    color_d_name: "",
                    type: "",
                },
            },
            requestParams: {
                hotel_id: hotel ? hotel.id : 0,
                arrival_date: moment(new Date()).format("YYYY-MM-DD"),
                departure_date: "",
                reservation_status: "",
                order_by: "last_name",
                filter: ""
            },
            addAlert: {
                alert_type: "custom",
                description: "",
                external_booking_id: "",
                reservation_code: ""
            }
        };
    };

    componentWillReceiveProps(nextProps) {

        if (this.props.reservationData !== nextProps.reservationData) {
            let _state = this.state;
            let statistics = _state.statisticsData;
            statistics.arrival_today =
                nextProps.reservationData && nextProps.reservationData.length > 0
                    ? nextProps.reservationData.length
                    : 0;
            statistics.departing =
                nextProps.reservationData && nextProps.reservationData.length > 0
                    ? nextProps.reservationData.length
                    : 0;
            this.setState({ reservationData: nextProps.reservationData });
            let reservationData = nextProps.reservationData;

            let result = [];
            if (Array.isArray(reservationData) && reservationData.length > 0) {
                reservationData.forEach(function (a) {
                    if( (a.reservation_status=="checkin_pending" ||
                        a.reservation_status== "profile_uploaded" || 
                        a.reservation_status =="checkin_completed_with_payment" )&& a.alert){
                            statistics.with_alert += 1;
                        } 
                    if (!this[a.reservation_status]) {
                        a.count = 0;
                        this[a.reservation_status] = a;
                        result.push(this[a.reservation_status]);
                    }
                    this[a.reservation_status].count += 1;
                }, Object.create(null));
                result.forEach(function (a) {
                    switch (a.reservation_status) {
                     
                        case "checkin_pending":
                            statistics.pending += a.count;
                            break;
                        case "profile_uploaded":
                            statistics.in_progress += a.count;
                            break;
                        case "payment_failure":
                            statistics.with_alert += a.count;
                            statistics.in_progress += a.count;
                            break;
                        case "profile_uploaded_with_alert":
                            statistics.with_alert += a.count;
                            statistics.in_progress += a.count;
                            break;
                        case "checkout":
                            statistics.checkedout += a.count;
                            break;
                        case "checkin_completed_with_alert":
                            statistics.completed += a.count;
                            statistics.with_alert += a.count;
                            break;
                        case "checkin_completed_without_payment":
                            statistics.with_alert += a.count;
                            statistics.completed += a.count;
                            break;
                        case "checkin_completed_with_payment":
                            statistics.completed += a.count;
                            break;
                        default:
                            console.log('Invalid status', a.reservation_status);
                    }
                }, Object.create(null));

            }
        }
    }


    componentDidMount() {
        let { hotel, history, dashboardFilter,guestDetails ,hotelIntegration} = this.props;
      
        if (!hotel) {
            this.props.dispatch(getHotelDetails(history))
        }
        console.log("===guestDetails=====",guestDetails)
        if(!guestDetails || 
            (guestDetails && Object.keys(guestDetails.hotel_config).length === 0 && 
            guestDetails.hotel_config.constructor === Object)) {
                console.log("===guestDetails====In=")
                this.props.dispatch(getGuestDetails(history))
        }

        if(!hotelIntegration){
            this.props.dispatch(hotelIntegrations(history));
        }

        if (dashboardFilter) {
            this.setState({
                ...this.state,
                filters: dashboardFilter
            }, () => this.getReservationListData());
        } else {
            this.setState(
                {
                    ...this.state,
                    requestParams: {
                        ...this.state.requestParams
                    },
                },
                () => {
                    let filters = this.state.requestParams;
                    this.props.dispatch(setKey('dashboardFilter', this.state.filters));
                    this.props.dispatch(getReservationList(filters));
                }
            );
        };
    }

    confirmation = (data) => {
        let { arrival_reservation_code } = this.state;
        this.setState({
            confirmModal: true
        }, () => {
            if (arrival_reservation_code) {
                axios.put('/dashboard/reservation/checkIn', { reservation_code: arrival_reservation_code })
                    .then((response) => {
                        if (response.status == 200) {
                            toast.success('Reservation status updated successfully.')
                            this.setState({
                                statisticsData: {
                                    arrival_today: 0,
                                    completed: 0,
                                    in_progress: 0,
                                    with_alert: 0,
                                    pending: 0,
                                    departing: 0,
                                    checkedout: 0,
                                },
                                selectFilterStics: "",
                            }, () => this.getReservationListData());

                        } else {
                            toast.error('Error in change status.')
                        }

                    })
                    .catch((error) => {
                        toast.error('Error in change status.')
                    })
            }
        });
    }


    toggleConfirmModal = (reservation_code) =>
        this.setState({
            confirmModal: !this.state.confirmModal,
            arrival_reservation_code: reservation_code
        });

    showIdModalToggle = (documents, name) => {
        if (Array.isArray(documents) && documents.length > 0) {
            documents = documents.filter(({ guest_type }) => guest_type.toString().toLowerCase() === "primary");
        }
        this.setState({
            showIDModal: !this.state.showIDModal,
            documents: documents,
            idShowPopupName: name,
        })
    };

    inviteModalToggle = (reservation_code, phone_number, country_code, is_sms_resend, reservation_status) => {
        this.setState({
            ...this.state,
            resendInvitationData: {
                ...this.state.resendInvitationData,
                phone_number: phone_number,
                country_code: country_code,
                reservation_code: reservation_code,
                is_sms_resend: is_sms_resend,
                reservation_status: reservation_status
            },
            InviteModal: !this.state.InviteModal
        })
        if (reservation_code == "") {
            this.setState({
                statisticsData: {
                    arrival_today: 0,
                    completed: 0,
                    in_progress: 0,
                    with_alert: 0,
                    pending: 0,
                    departing: 0,
                    checkedout: 0,
                },
                selectFilterStics: "",
            }, () => this.getReservationListData());
        }
    }
    checkoutInviteModalToggle = () => {
        this.setState({ CheckoutInviteModal: !this.state.CheckoutInviteModal });
    }
    NoteModalToggle = () => {
        this.setState({ NoteModal: !this.state.NoteModal });
    }
    alertModalToggle = (external_booking_id, reservation_code) => {
        let { addAlert } = this.state;
        addAlert.reservation_code = reservation_code;
        addAlert.external_booking_id = external_booking_id;
        this.setState({ AlertModal: !this.state.AlertModal, addAlert: addAlert });
    }
    regCardModalModalToggle = (id) => {
        this.setState({ regCardModal: !this.state.regCardModal, regCardId: id });
    }

    getReservationListData = () => {
        let requestParams = this.state.requestParams;
        let filterData = this.state.filters;

        if (filterData.selectGuestType == "arrival") {
            (requestParams.arrival_date = moment(new Date())
                .add("days", filterData.withDays)
                .format("YYYY-MM-DD"));
            (requestParams.departure_date = "");
            (requestParams.reservation_status = "");
            requestParams.order_by = filterData.orderBy;
        }
        if (filterData.selectGuestType == "staying") {
            (requestParams.arrival_date = "");
            (requestParams.departure_date = "");
            (requestParams.reservation_status = "staying");
            requestParams.order_by = filterData.orderBy;
        }
        if (filterData.selectGuestType == "departing") {
            (requestParams.arrival_date = "");
            (requestParams.departure_date = moment(new Date())
                .add("days", filterData.withDays)
                .format("YYYY-MM-DD"));
            (requestParams.reservation_status = "");
            requestParams.order_by = filterData.orderBy;
        }
        requestParams.filter = filterData.searchVal;
        this.props.dispatch(setKey('dashboardFilter', filterData));
        this.props.dispatch(getReservationList(requestParams));

    };

    selectFiltes = (data, type) => {
        switch (type) {
            case "guestType":
                if (Object.keys(data).length !== 0 && data.constructor === Object) {
                    let filterData = this.state.filters;
                    filterData.selectGuestType = data.type;
                    filterData.orderByObj = {
                        display_name: "Last Name",
                        value: "last_name",
                        color: "",
                        color_d_name: "",
                        type: "",
                    };
                    filterData.searchVal = "";
                    filterData.orderBy = "last_name";
                    this.setState({ filters: filterData });
                }
                break;
            case "withDays":
                let filterData = this.state.filters;
                filterData.withDays = data;
                this.setState({ filters: filterData });
                break;

            case "orderBy":
                let filterObj = this.state.filters;
                filterObj.orderByObj = data;
                filterObj.orderBy = data.value;
                this.setState({ filters: filterObj });
                break;
            default:
                return false;

        }

        this.setState({
            statisticsData: {
                arrival_today: 0,
                completed: 0,
                in_progress: 0,
                with_alert: 0,
                pending: 0,
                departing: 0,
                checkedout: 0,
            },
            selectFilterStics: "",
        });
        this.getReservationListData();
    };

    removeStatisticsFilter = (type) => {
        this.setState({
            selectFilterStics: type,
            reservationData: this.props.reservationData,
        });
    };

    withStatisticsFilter = (type) => {
        let reservationDataVal = this.props.reservationData;
        let fitlerReservation = [];

        if (Array.isArray(reservationDataVal) && reservationDataVal.length > 0) {
            if (type == "arriving_today" || type == "departing") {
                fitlerReservation = reservationDataVal;
            } else if (type == "checkout") {
                fitlerReservation = reservationDataVal.filter(
                    (obj) =>
                        obj.reservation_status == "checkout"
                );
            }
            else
                if (type == "completed") {
                    fitlerReservation = reservationDataVal.filter(
                        (obj) =>
                            obj.reservation_status == "checkin_completed_without_payment" ||
                            obj.reservation_status == "checkin_completed_with_payment" ||
                            obj.reservation_status == "checkin_completed_with_alert"
                    );
                } else if (type == "profile_uploaded") {
                    fitlerReservation = reservationDataVal.filter(
                        (obj) =>
                            obj.reservation_status == "profile_uploaded" ||
                            obj.reservation_status == "payment_failure" ||
                            obj.reservation_status == "profile_uploaded_with_alert" 
                    );
                }
                else if (type == "checkin_completed_with_alert") {
                    fitlerReservation = reservationDataVal.filter(
                        (obj) =>
                            obj.reservation_status == "checkin_completed_with_alert" ||
                            obj.reservation_status == "checkin_completed_without_payment" ||
                            obj.reservation_status == "payment_failure" ||
                            obj.reservation_status == "profile_uploaded_with_alert" ||
                            obj.alert == "profile_uploaded_with_alert"
                            
                    );
                }
                else {
                    fitlerReservation = reservationDataVal.filter(
                        (obj) => obj.reservation_status == type
                    );
                }

        }
        this.setState({
            selectFilterStics: type,
            reservationData: fitlerReservation,
        });
    };

    searchHandle = (val) => {
        let searchValue = this.state.filters.searchVal;
        if (searchValue != val) {
            this.setState({
                ...this.state,
                filters: {
                    ...this.state.filters,
                    searchVal: val
                },
                statisticsData: {
                    ...this.state.statisticsData,
                    arrival_today: 0,
                    completed: 0,
                    in_progress: 0,
                    with_alert: 0,
                    pending: 0,
                    departing: 0,
                    checkedout: 0,
                },
                selectFilterStics: "",
            }, () => this.getReservationListData());
        }
    }

    render() {
        let { selectFilterStics, statisticsData, filters } = this.state;
        let { loading, hotel,hotelIntegration} = this.props;
        return (
            <React.Fragment>
                <Layout searchHandle={this.searchHandle} searchValue={filters.searchVal}>
                    <div className="customContainer content">
                        <div className="clearfix">
                            <Filters
                                filters={this.state.filters}
                                selectFiltes={this.selectFiltes}
                                hotelIntegration={hotelIntegration}
                            />
                            <div className="row justify-content-between statistics">
                                {this.state.filters.selectGuestType == "arrival" ? (
                                    <React.Fragment>
                                        <Statistics
                                            selectFilterStics={selectFilterStics}
                                            display_name={"Arriving"}
                                            count={statisticsData.arrival_today}
                                            value={"arriving_today"}
                                            type={"arriving_today"}
                                            withStatisticsFilter={this.withStatisticsFilter}
                                            removeStatisticsFilter={this.removeStatisticsFilter}
                                        />
                                        <Statistics
                                            selectFilterStics={selectFilterStics}
                                            display_name={"Completed"}
                                            count={statisticsData.completed}
                                            value={"completed"}
                                            type={"completed"}
                                            withStatisticsFilter={this.withStatisticsFilter}
                                            removeStatisticsFilter={this.removeStatisticsFilter}
                                        />
                                        <Statistics
                                            selectFilterStics={selectFilterStics}
                                            display_name={"In Progress"}
                                            count={statisticsData.in_progress}
                                            type={"in_progress"}
                                            value={"profile_uploaded"}
                                            withStatisticsFilter={this.withStatisticsFilter}
                                            removeStatisticsFilter={this.removeStatisticsFilter}
                                        />
                                        <Statistics
                                            selectFilterStics={selectFilterStics}
                                            display_name={"With Alert"}
                                            count={statisticsData.with_alert}
                                            type={"with_alert"}
                                            value={"checkin_completed_with_alert"}
                                            withStatisticsFilter={this.withStatisticsFilter}
                                            removeStatisticsFilter={this.removeStatisticsFilter}
                                        />
                                        <Statistics
                                            selectFilterStics={selectFilterStics}
                                            display_name={"Pending"}
                                            count={statisticsData.pending}
                                            type={"pending"}
                                            value={"checkin_pending"}
                                            withStatisticsFilter={this.withStatisticsFilter}
                                            removeStatisticsFilter={this.removeStatisticsFilter}
                                        />
                                    </React.Fragment>
                                ) : this.state.filters.selectGuestType == "departing" ? (
                                    <React.Fragment>
                                        <Statistics
                                            selectFilterStics={selectFilterStics}
                                            display_name={"Departing"}
                                            count={statisticsData.departing}
                                            type={"in_progress"}
                                            value={"departing"}
                                            withStatisticsFilter={this.withStatisticsFilter}
                                            removeStatisticsFilter={this.removeStatisticsFilter}
                                        />
                                        <Statistics
                                            selectFilterStics={selectFilterStics}
                                            display_name={"Checked out"}
                                            count={statisticsData.checkedout}
                                            type={"pending"}
                                            value={"checkout"}
                                            withStatisticsFilter={this.withStatisticsFilter}
                                            removeStatisticsFilter={this.removeStatisticsFilter}
                                        />
                                    </React.Fragment>
                                ) : null}
                            </div>
                            {loading ? <LoaderSpinner />
                                :
                                <div className="row guestDetailBoxRow">
                                    {
                                        Array.isArray(this.state.reservationData) &&
                                            this.state.reservationData.length > 0 ? (
                                                <React.Fragment>
                                                    {this.state.reservationData.map((detail) => {
                                                        return (
                                                            <RervervationGrid
                                                                regCardModalModalToggle={this.regCardModalModalToggle}
                                                                filters={this.state.filters}
                                                                key={detail.id}
                                                                checkinUser={this.toggleConfirmModal}
                                                                displayProfileDetails={this.detailShow}
                                                                showIdModalToggle={this.showIdModalToggle}
                                                                inviteModalToggle={this.inviteModalToggle}
                                                                tailsDetails={detail}
                                                                addAlertToggle={this.alertModalToggle}
                                                            />
                                                        );
                                                    })}
                                                </React.Fragment>
                                            ) : (
                                                <div className="col">
                                                    <div className="no_data_found">
                                                        <img src="/images/icon.svg" width="52" alt="no reservation available" />
                                                        <h2>No Data Available</h2>
                                                        <h4>There are no Thru {filters.selectGuestType} for selected time period.</h4>
                                                    </div>
                                                </div>
                                            )
                                    }
                                </div>}

                        </div>
                        <ShowIdModal
                            documents={this.state.documents}
                            idShowPopupName={this.state.idShowPopupName}
                            showIDModal={this.state.showIDModal}
                            showIdModalToggle={this.showIdModalToggle}
                        />
                        <InviteModal
                            showInviteModal={this.state.InviteModal}
                            inviteModalToggle={this.inviteModalToggle}
                            hotel ={hotel}
                            resendInvitationData={this.state.resendInvitationData}
                        />
                        <CheckoutInviteModal
                            showCheckoutInviteModal={this.state.CheckoutInviteModal}
                            onHide={this.checkoutInviteModalToggle}
                        />
                        <AddNoteModal
                            showNoteModal={this.state.NoteModal}
                            onHide={this.NoteModalToggle}
                        />
                        <AddAlertModal
                            showAlertModal={this.state.AlertModal}
                            onHide={this.alertModalToggle}
                            addAlert={this.state.addAlert}
                        />
                        <ConfirmModal
                            title={"Confirmation"}
                            showModal={this.state.confirmModal}
                            onConfirm={this.confirmation}
                            onHide={this.toggleConfirmModal}
                            confirmBtnText="Yes"
                        >
                            <p>Would you like to change status?</p>
                        </ConfirmModal>

                        {this.state.regCardId ?
                            <PrintRegCardModal
                                hotel={hotel}
                                showRegCardModal={this.state.regCardModal}
                                regCardId={this.state.regCardId}
                                regCardModalModalToggle={this.regCardModalModalToggle}
                            /> :
                            ""}

                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reservationData: state.reservationData,
        hotel: state.hotel,
        dashboardFilter: state.dashboardFilter,
        loading: state.loading,
        guestDetails:state.guestDetails,
        hotelIntegration:state.hotelIntegration
    };
};

export default connect(mapStateToProps)(withAuth(Dashboard));
