import React from "react";
import { Modal } from 'react-bootstrap';
import FormElements from "../formElements/FormElements";

export default function DataPMSModal(props) {
    return (
        <Modal
            show={props.showNoteModal}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">PMS Data Exchange - Opera</Modal.Title>
                <div className="modalPopupClose clearfix" onClick={props.onHide}>
                    <img src="/images/popup_close.png" alt="close" />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modalForm clearfix">
                    <div className="pms_modal_fields">
                        <div className="floating-form">
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        disabled
                                        type="text"
                                        label="Hotel Name"
                                        name="hotel_name"
                                        value="Hotel ABCD"
                                        onChange={() => { }}
                                    />
                                </div>
                            </div>
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="Address"
                                        name="address"
                                        onChange={() => { }}
                                    />
                                </div>
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="Address"
                                        name="address"
                                        onChange={() => { }}
                                    />
                                </div>
                            </div>
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="Zip Code"
                                        name="postal_code"
                                        onChange={() => { }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <button className="themeButton">Save</button>
            </Modal.Footer> */}
        </Modal>
    );
}