
import React from "react";
import Layout from "../components/Layout";
import OtpInput from 'react-otp-input';
import Loader from "../components/common/Loader";
import { connect } from 'react-redux';
import { getStorage } from "../utils";
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import {currentAuthenticatedUser} from "../actions";
import VerifyContact from "../components/common/VerifyContact";
class MobileVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resendBtn: false,
      mobileVarify:false,
      count: "00:00",
      otp: '',
    };
  }

  componentDidMount() {

    let userAttributes = this.props.currentUserAttributes;
    let authType = getStorage("authType", "string", false);

    if (userAttributes) {
      let phone_number = userAttributes.find(({ Name }) => Name === "phone_number");
      if(phone_number){
        this.setState({
          userAttributes: {phone_number:phone_number.Value}
        }, () => {
          if (authType && authType == "phone_number") {
            localStorage.removeItem("authType");
            this.sendVerifyCode();
          } else {
            this.setState({ resendBtn: true });
          }
        })
      }
      
    }
  }

  sendVerifyCode = () => {
    Auth.verifyCurrentUserAttribute('phone_number')
      .then((response) => {
        this.setState({ resendBtn: false })
        window.clearTimeout()
        this.countdown(60);
      }).catch((e) => {
        console.log("====verifyAttribute===", e)
        toast.error(e.message)
      }
      )
  }

  submitCode = (code) => {
    this.setState({ loading: true })
    Auth.verifyCurrentUserAttributeSubmit('phone_number', code)
      .then((data) => {
        // this.props.history.push("/")
        this.setState({ loading: false,mobileVarify:true})
        this.props.dispatch(currentAuthenticatedUser(this.props.history))
      }).catch((e) => {
        toast.error(e.message)
        if (e.code == "CodeMismatchException") {
          this.setState({ loading: false, otp: "" })
        } else {
          this.setState({ loading: false })
        }
        console.log("====verifyAttribute===", e)

      })
  }

  resend = () => {
    this.setState({ otp: "" });
    this.sendVerifyCode();
  }

  handleOTPChange = (otp) => {
    this.setState({ otp: otp }, () => {
      let otpVal = this.state.otp.toString();
      if (otpVal.length == 6) {
        this.submitCode(otpVal)
      }
    });
  };

  countdown = (seconds) => {
    let _this = this;
    this.sec = seconds;
    this.mins = seconds > 60 ? seconds / 60 : 0;

    const tick = () => {
      if (this.sec > 60) {
        this.current_minutes = this.mins - 1;
      }
      this.sec--;
      this.setState({
        count:
          (this.sec < 10 ? "0" : "") +
          String(this.sec),
      });
      if (this.sec > 0) {
        setTimeout(tick, 1000);
      } else {
        _this.setState({ resendBtn: true });
        if (this.mins > 1) {
          this.countdown(this.mins - 1);
        }
      }
    };
    tick();
  };

  render() {
    let {mobileVarify} = this.state;
    let SignUpUser = (this.state.userAttributes && this.state.userAttributes.hasOwnProperty("phone_number") ? this.state.userAttributes : {});
    return (
      <Layout isSignUP isLogoutBtn={true}>
        <div className="signup_container">
          <div className="mobile_validate_form">
            <div className="signup_form_content">
              <h1>Validate your phone number</h1>
              <h3>
                We’ve sent a code to{" "}
                {SignUpUser.hasOwnProperty("phone_number")
                  ? <b>{SignUpUser.phone_number}</b>
                  : " your registered phone number "}

                {/* <a className="change_request">Change</a> */}
              </h3>
              <div className="otp_input_signup">
                <OtpInput
                  value={this.state.otp}
                  onChange={this.handleOTPChange}
                  numInputs={6}
                  shouldAutoFocus={true}
                  // isInputNum
                  separator={<span className="otp_seperator"></span>}
                />
              </div>
              <div className="loader_signup">
                {this.state.loading ? (
                  <React.Fragment>
                    <Loader /> <span>Please Wait..</span>
                  </React.Fragment>
                ) : (
                    ""
                  )}
              </div>
              <div className="privacy_policy">
                {this.state.resendBtn ?
                  <React.Fragment>
                    Did not receive an confirmation code?{" "} <a onClick={this.resend}> Resend </a>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    You can request to resend the confirmation code in {this.state.count} seconds..
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
        {mobileVarify ?<VerifyContact verifyText={"Phone Number Verified!"} />:null}
      </Layout>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    currentUserAttributes:state.currentUserAttributes
  };
};

export default connect(mapStateToProps)(withRouter((MobileVerify)));
