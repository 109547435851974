import React, { Component } from 'react';
import UIfx from 'uifx';
import beepMp3 from '../../assets/sound/notification.mp3';

const beep = new UIfx(beepMp3)

class VerifyContact extends Component {

  componentDidMount() {
    beep.play();
  }
    render() {
        return (
            <div className="verifyContact">
            <div>
              <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
              <h1>{this.props.verifyText}</h1>
            </div>
          </div>
        )
    }
}
export default VerifyContact;
