import React, { Component, Fragment, createRef } from "react";

import FormElements from "../components/formElements/FormElements";
import SignupStepLayout from './../components/SignupStepLayout';
import SimpleReactValidator from 'simple-react-validator';
import { formToJSON, scrollToError } from "../utils";
import countryPhoneCode from '../utils/countryPhoneCode.json';
import Flag from "react-world-flags";
import withAuth from "../lib/auth";
import axios from "../axios";
import { setKey } from "../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import LoaderSpinner from '../components/common/LoaderSpinner';
import NeedHelp from '../components/common/NeedHelp';
import { getSelectTheme } from "../utils/ui";

class Payment extends Component {
    constructor(props) {
        super(props);
        let country_code_options = countryPhoneCode.map(d => ({ value: d.dial_code, label: (<div><Flag code={d.code} width="30" /> {d.dial_code}</div>) }));
        this.state = {
            loading: false,
            country_code_options: country_code_options,
            formData: {
                selection: "need_gateway"
            },
        };
        this.form = new createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute"
            },
            validators: {
                phone_number: {
                    message: 'Please enter a valid phone number',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0-9]{4,15}$/i);
                    }
                }
            }
        });
    }

    componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    let { hotel } = this.props;
        let state = this.state, countryCodeOption;
        if (hotel) {
            let { country_code } = hotel;
            countryCodeOption = this.getCountryCodeOption(country_code);
        }
        state.formData['contact[country_code]'] = countryCodeOption;
        this.setState(state);
    }
    
    getCountryCodeOption = (country_code) => {
        let country = countryPhoneCode.find(c => c.code === country_code), option;
        if (country) {
            let { dial_code, code } = country;
            option = { value: dial_code, label: (<div><Flag code={code} width="30" /> {dial_code}</div>) };
        }
        return option;
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === "selection") this.validator.purgeFields();
        this.validator.showMessageFor(name);
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    handleSelect = (e, target) => {
        let { name } = target;
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: e
            }
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, loading: true });
        if (this.validator.allValid()) {
            let postData = formToJSON(this.form.current);
            if (postData.selection === "need_gateway") {
                let { payment_gateway } = this.state.formData;
                let { contact } = postData;
                if (payment_gateway) postData.payment_gateway = payment_gateway;
                if (contact) {
                    let { phone_number, country_code } = contact;
                    postData.contact.phone_number = country_code + phone_number;
                    delete postData.contact.country_code;
                }
            }
            delete postData.selection;
            console.log(postData);
            axios.post('/dashboard/hotel/paymentintegration', postData)
                .then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        let api_response = res.data;
                        let { status } = api_response;
                        if (status) {
                            let { hotel } = this.props;
                            if (hotel) {
                                hotel.step_completed = 5;
                                this.props.dispatch(setKey('hotel', hotel));
                            }
                            this.props.history.replace('/team');
                        } else {
                            toast.error('Unable to save payment details. Please try again.');
                        }
                    } else {
                        console.log(res);
                        toast.error('Unable to save payment details. Please try again.');
                    }
                })
                .catch(e => {
                    console.error(e);
                    toast.error('Unable to save payment details. Please try again.');
                })
                .finally(() => this.setState({ ...this.state, loading: false }));
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false }, this.handleScroll);
        }
    }

    handleScroll = () => {
        let { formData } = this.state;
        if (formData.payment_gateway) {
            scrollToError();
        } else {
            scrollToError("srv-validation-message", "payment_gateway_options");
        }
    }

    render() {
        let { loading } = this.state;

        return (
            <SignupStepLayout>
                {loading ? <LoaderSpinner /> : null}
                <form autoComplete="off" onSubmit={this.onSubmit} ref={this.form}>
                    <div className="signup_steps_content_wrapper payment_page">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">Payment Gateway Integration</h3>
                                <div className="payment-wrapper">
                                    {/* <div className="payment-wrapper-option">
                                        <label className="container_radio">I have an electronic payment gateway with:
                                        <input type="radio" name="one" onChange={this.handleInputChange} />
                                            <span className="checkmark_check"></span>
                                        </label>
                                    </div> */}
                                    <div className="payment-wrapper-option">
                                        <label className="container_radio">
                                            {"I want an electronic payment gateway"}
                                            <input type="radio"
                                                name="selection"
                                                value="need_gateway"
                                                onChange={this.handleInputChange}
                                                defaultChecked={this.state.formData.selection === "need_gateway"}
                                            />
                                            <span className="checkmark_check"></span>
                                        </label>
                                    </div>
                                    {this.state.formData.selection === "need_gateway"
                                        ? <Fragment>
                                            <div className="payment_gateway_options">
                                                <div className="payment-btns">
                                                    <div className="payment_btn_wrapper">
                                                        <button type="button"
                                                            onClick={() => this.handleInputChange({ target: { name: "payment_gateway", value: "razorpay" } })}
                                                            className={this.state.formData.payment_gateway === "razorpay" ? "active" : ""}>
                                                            <img src="/images/razorpay.png" alt="razorpay" />
                                                        </button>
                                                    </div>
                                                    <div className="payment_btn_wrapper">
                                                        <button type="button"
                                                            onClick={() => this.handleInputChange({ target: { name: "payment_gateway", value: "ingenico" } })}
                                                            className={this.state.formData.payment_gateway === "ingenico" ? "active" : ""}>
                                                            <img src="/images/Ingenico.png" alt="ingenico" />
                                                        </button>
                                                    </div>
                                                    <div className="payment_btn_wrapper">
                                                        <button type="button" onClick={() => this.handleInputChange({ target: { name: "payment_gateway", value: "2c2p" } })}
                                                            className={this.state.formData.payment_gateway === "2c2p" ? "active" : ""}>
                                                            <img src="/images/2c2p.png" alt="2c2p" />
                                                        </button>
                                                    </div>
                                                    <div className="payment_btn_wrapper">
                                                        <button type="button" onClick={() => this.handleInputChange({ target: { name: "payment_gateway", value: "stripe" } })}
                                                            className={this.state.formData.payment_gateway === "stripe" ? "active" : ""}>
                                                            <img src="/images/stripe.png" alt="stripe" />
                                                        </button>
                                                    </div>
                                                    <div className="payment_btn_wrapper">
                                                        <button type="button" onClick={() => this.handleInputChange({ target: { name: "payment_gateway", value: "adyen" } })}
                                                            className={this.state.formData.payment_gateway === "adyen" ? "active" : ""}>
                                                            <img src="/images/adyen.png" alt="adyen" />
                                                        </button>
                                                    </div>
                                                    <div className="payment_btn_wrapper">
                                                        <button type="button" onClick={() => this.handleInputChange({ target: { name: "payment_gateway", value: "other" } })}
                                                            className={this.state.formData.payment_gateway === "other" ? "active" : ""}>
                                                            {"Other"}
                                                        </button>
                                                    </div>
                                                    <div className="error-text">
                                                        {this.validator.message('payment_gateway', this.state.formData.payment_gateway, 'required', { messages: { required: "Please select payment gateway" } })}
                                                    </div>
                                                </div>
                                                {/* <div className="floating-form">
                                                    <div className="form_element_wrapper">
                                                        <div className="form_element">
                                                            <FormElements
                                                                type="text"
                                                                label="Request for a Payment Gateway Integration..."
                                                                name="integration"
                                                            />
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <div className="payment_gateway_options electronic_way">
                                                <div className="floating-form">
                                                    <div className="yes_questions">
                                                        {"Contact Person"}
                                                    </div>
                                                    <div className="form_element_wrapper">
                                                        <div className="form_element">
                                                            <FormElements
                                                                type="text"
                                                                label="Name"
                                                                value={this.state.formData['contact[name]']}
                                                                validator={this.validator}
                                                                validations="required"
                                                                onChange={this.handleInputChange}
                                                                validatorOptions={{ messages: { required: "Please enter name" } }}
                                                                name="contact[name]"
                                                            />
                                                        </div>
                                                        <div className="form_element">
                                                            <div className="country_phone_wrapper">
                                                                <div className="floating-label">
                                                                    <input className="floating-input"
                                                                        type='text'
                                                                        value={this.state.formData['contact[phone_number]']}
                                                                        name='contact[phone_number]'
                                                                        autoComplete="off"
                                                                        placeholder='Phone Number'
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                    <div className="error-text">
                                                                        {this.validator.message('contact[phone_number]', this.state.formData['contact[phone_number]'], 'required|phone_number', { messages: { required: "Please enter phone number" } })}
                                                                    </div>
                                                                </div>
                                                                <div className="country_phone">
                                                                    <Select
                                                                        value={this.state.formData['contact[country_code]']}
                                                                        onChange={this.handleSelect}
                                                                        options={this.state.country_code_options}
                                                                        name='contact[country_code]'
                                                                        className="language_select"
                                                                        classNamePrefix="react_select"
                                                                        theme={getSelectTheme}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <PhoneNumber
                                                                type="text"
                                                                validator={this.validator}
                                                                validations="required|numeric"
                                                                onChange={this.handleInputChange}
                                                                validatorOptions={{ messages: { required: "Please enter phone number", numeric: "Please enter a valid phone number" } }}
                                                                name="contact[phone_number]"
                                                                country_code_name="contact[country_code]"
                                                            /> */}
                                                        </div>
                                                    </div>
                                                    <div className="form_element_wrapper">
                                                        <div className="form_element">
                                                            <FormElements
                                                                type="text"
                                                                value={this.state.formData['contact[email]']}
                                                                validator={this.validator}
                                                                validations="required|email"
                                                                onChange={this.handleInputChange}
                                                                validatorOptions={{ messages: { required: "Please enter email address", email: "Please enter a valid email address" } }}
                                                                label="Email Address"
                                                                name="contact[email]"
                                                            />
                                                        </div>
                                                        <div className="form_element">
                                                            <FormElements
                                                                type="text"
                                                                value={this.state.formData['contact[designation]']}
                                                                validator={this.validator}
                                                                validations="required"
                                                                onChange={this.handleInputChange}
                                                                validatorOptions={{ messages: { required: "Please enter designation" } }}
                                                                label="Designation"
                                                                name="contact[designation]"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                        : null
                                    }
                                    <div className="payment-wrapper-option">
                                        <label className="container_radio">
                                            {"I dont want an electronic payment gateway. I’d like to handle all transactions at the front desk"}
                                            <input type="radio" name="selection" value="dont_need_gateway" onChange={this.handleInputChange} defaultChecked={this.state.formData.selection === "dont_need_gateway"} />
                                            <span className="checkmark_check"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' alt="status"/></div>
                                    <div className="tip_text">
                                        <p>If you would like to use an existing account with an electronic payment gateway or would like to set up a new one, please provide contact details of a member of your team and we will reach out to them shortly.</p>
                                        {/* <p>In case you don’t have and you wish to have one, fill up the contact details of the concerned person from your side and we’ll get in touch with you.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <NeedHelp/>
                    <div className="continue-btn">
                        <div className="formSubmitButton">
                            <button type="submit" disabled={this.state.loading} className="themeButton">
                                {this.state.loading ? "Please wait.." : "Continue"}
                            </button>
                        </div>
                    </div>
                </form>
            </SignupStepLayout>
        );
    }
}

const mapStateToProps = state => ({
    hotel: state.hotel
})

export default connect(mapStateToProps)(withAuth(Payment));