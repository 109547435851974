import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import axios from '../../axios';
import Loader from "../common/Loader";
import moment from "moment";
import { connect } from "react-redux";

function PrintRegCardModal(props) {
    let [data, setData] = useState(false);
    let [id, setId] = useState(props.regCardId);
    let [signImage, setSignImage] = useState('');
    let [hotelLogo, setHotelLogo] = useState(false);
    let [hotelCountry, setHotelCountry] = useState(false);
    let [buttonLoading, setButtonLoading] = useState(false);
    let [countryCode, setCountryCode] = useState(false);

    const fetchData = (id) => {
        if (id) {
            axios(`/dashboard/reservations/${id}`)
                .then(res => {
                    console.log(res);
                    let api_response = res.data;
                    let { status, data } = api_response;
                    if (status) {
                        setData(data);
                        setId(false);
                        fetchSignImage(data.e_signature);
                    } else {
                        setId(false);
                    }
                }).catch(err => {
                    console.log(err);
                    setId(false);
                }).finally(() => {
                    setId(false);
                });
        }
    }

    const fetchSignImage = (imgUrl) => {
        fetch(imgUrl, {
            mode: 'cors',
        })
            .then(res => res.blob())
            .then(res => {
                console.log("download image", res);
                let reader = new FileReader();
                reader.readAsDataURL(res);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    console.log(base64data);
                    setSignImage(base64data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }



    if (id) {
        fetchData(id);
        let {hotel} =props;
        if (hotel) {
            if (hotel && Object.keys(hotel) !== 0 && hotel.constructor === Object) {
                let { hotel_assets } = hotel;
                setHotelCountry(hotel.country_name)
                setCountryCode(hotel.country_code);

                if (hotel_assets && Array.isArray(hotel_assets) && hotel_assets.length > 0) {
                    let darkLogo = hotel_assets.find(({ asset_type }) => asset_type === "hotel_logo_dark");
                    setHotelLogo(darkLogo.file_path);

                }
            }
        }
        setId(false);
    }

    const generatePdf = (reservationCode, bookingId) => {
        const requestOptions = {
            method: 'GET'
        };
        setButtonLoading(true);
        axios(`/dashboard/generateRegCard?reservation_code=${reservationCode}`, requestOptions)
            .then(res => {
                console.log("download reg card pdf res:", res);
                let pdfbase64Data = res.data;
                let downloadLink = document.createElement('a');
                downloadLink.download = `Regcard_${bookingId}.pdf`;
                downloadLink.href = 'data:application/octet-stream;base64,' + pdfbase64Data;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            }).catch(err => {
                console.log(err);
            }).finally(_ => {
                setButtonLoading(false);
            })
    }

    const ref = React.createRef();

    return (
        <Modal
            show={props.showRegCardModal}
            size="lg"
            className="customModal printregCardModal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <div className="reg_card_modal_close">
                    <div className="modalPopupClose clearfix" onClick={(e) => props.regCardModalModalToggle("")} >
                        <img src="/images/popup_close.png" alt="close" />
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                {data ?
                    <React.Fragment>
                        {data.reservation_status != "cancelled" && data.reservation_status != "checkin_pending" ?
                            <div className="regCardModalBody" id="divToPrint" ref={ref}>
                                <div className="modalHotelLogo">{
                                    hotelLogo ?
                                        <img className="img-responsive" src={hotelLogo} alt="not found" /> : null
                                }
                                </div>
                                <PrintRegCardBody
                                    data={data}
                                    signImage={signImage}
                                    guestDetails={props.guestDetails}
                                    countryCode={countryCode}
                                    hotelCountry={hotelCountry}
                                />
                            </div>
                            :
                            <React.Fragment>
                                <div className="modalimageid clearfix">
                                    No Registration Card Available..
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Loader /> <span>Please Wait..</span>
                    </React.Fragment>
                }
            </Modal.Body>
            {data && data.reservation_status != "cancelled" && data.reservation_status != "checkin_pending" ?
                <Modal.Footer>
                    <div className="regCardDownloadButton">
                        <button
                            type="button"
                            onClick={(e) => generatePdf(data.reservation_code, data.external_booking_id)}
                            className="themeButton"
                            disabled={buttonLoading}>
                            {buttonLoading ? 'Downloading...' : 'Download'}
                        </button>
                    </div>
                </Modal.Footer>
                :
                ""
            }
        </Modal>
    );
}

const PrintRegCardBody = (props) => {

    let { arrival_date, departure_date, rate_code_description } = props.data
    let { countryCode, guestDetails, hotelCountry } = props;
    let data = getGuestDetails(props.data);
    let { guest, isGuestProfile } = data;
    let { first_name,
        last_name,
        email_address,
        phone_number,
        home_address,
        country_name,
        nationality,
        // date_of_birth,
        purpose_of_visit,
        country_of_origin,
        city_of_origin,
        documents
    } = guest;

    let hotel_config = guestDetails.hasOwnProperty("hotel_config") ? guestDetails.hotel_config : {};
    let guest_detail_config = hotel_config.hasOwnProperty("guest_detail_config") ? hotel_config.guest_detail_config : {};
    let guest_profile = guest_detail_config.hasOwnProperty("guest_profile") ? guest_detail_config.guest_profile : {};
    let primary = guest_profile.hasOwnProperty("primary") ? guest_profile.primary : {};
    let id_card = guest_detail_config.hasOwnProperty("id_card") ? guest_detail_config.id_card : {};
    let travel = guest_detail_config.hasOwnProperty("travel") ? guest_detail_config.travel : {};
    let visa = guest_detail_config.hasOwnProperty("visa") ? guest_detail_config.visa : {};
    let guest_custom_fields = hotel_config.hasOwnProperty("guest_custom_fields") ? hotel_config.guest_custom_fields : {};
    let custom_fields = guest_custom_fields.hasOwnProperty("fields") ? guest_custom_fields.fields : {};
    let guestType = "local";
    let gurst_country_code = props.data.country_code;
    console.log(gurst_country_code, "=====gurst_country_code====")
    if(gurst_country_code =="" ||gurst_country_code==null){
        let c_code =guest?.documents?(Array.isArray(guest.documents) && guest.documents.length > 0?guest.documents[0].country_code:""):"";
        if (countryCode != c_code) guestType = "intl";
          
    }else{
        if (countryCode != gurst_country_code) guestType = "intl";
    }
    
    let name = [guest.last_name, guest.first_name]
        .filter((g) => g)
        .join(", ");
    let country = isGuestProfile ? country_name : nationality;
    var docid_number = "-",
        place_of_issue = "-",
        issue_date = "-",
        expiry_date = "-";
    let visa_data = false;
    if (Array.isArray(documents) && documents.length) {
        let documentsVal = documents;
        // documents = documents[0];
      let   other_doc = documents.find((d) => {
            d.docid_type = d.docid_type ? d.docid_type.toString().toLowerCase() : d.docid_type;
            return d.docid_type !== "visa"
        });
        if(other_doc){
            docid_number = (other_doc.docid_number ? other_doc.docid_number : "_");
            place_of_issue = (other_doc.place_of_issue ? other_doc.place_of_issue : "_");
            issue_date = (other_doc.issue_date ? moment.parseZone(other_doc.issue_date).format("MMM D, YYYY") : '-');
            expiry_date = (other_doc.expiry_date ? moment.parseZone(other_doc.expiry_date).format("MMM D, YYYY") : '-');
    
        }
    
        visa_data = documentsVal.find((d) => {
            d.docid_type = d.docid_type ? d.docid_type.toString().toLowerCase() : d.docid_type;
            return d.docid_type === "visa"
        });
    }
    let covidDeclarations = [];
    try {
        covidDeclarations = (guest && guest.declarations) ? JSON.parse(guest.declarations) : [];
    } catch (err) {
        console.error(err);
    }

    let signImage = props.data.e_signature;
    if (props.signImage) {
        signImage = props.signImage;
    }




    return (
        <React.Fragment>
            <div className="clearfix">
                <div className="row">
                    <div className="col-md-6">
                        <div className="regCardModalUserInfo">
                            <h1>{name}</h1>
                            {/* <h3>GOLD MEMBER</h3> */}
                        </div>
                    </div>
                    <div className="col-md-6"></div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="reservationDetail">
                            <div className="reservationDetailWrapper">
                                <h2 className="memeberDetailLabel">Reservation Details</h2>
                                {primary && ((primary.first_name && primary.first_name[guestType]) ||
                                    (primary.last_name && primary.last_name[guestType])) ?
                                    <React.Fragment>
                                        <div className="row">
                                            {(primary.first_name && primary.first_name[guestType]) ?
                                                <div className="col-md-6">
                                                    <div className="memberfieldsData">
                                                        <label>First Name</label>
                                                        <p>{first_name}</p>
                                                    </div>
                                                </div>
                                                : ""}
                                            {(primary.first_name && primary.last_name[guestType]) ?
                                                <div className="col-md-6">
                                                    <div className="memberfieldsData">
                                                        <label>Last Name</label>
                                                        <p>{last_name}</p>
                                                    </div>
                                                </div>
                                                : ""}

                                        </div>
                                    </React.Fragment>
                                    :
                                    ""
                                }
                                <div className="memberDetail_fields_border clearfix"></div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Arrival</label>
                                            <p>{arrival_date ? moment.parseZone(arrival_date).format("MMM D, YYYY") : "-"}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Departure</label>
                                            <p>{departure_date ? moment.parseZone(departure_date).format("MMM D, YYYY") : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="memberDetail_fields_border clearfix"></div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Room Type</label>
                                            <p>{props.data.room_type}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Adults / Kids</label>
                                            <p> {props.data.no_of_adult} / {props.data.no_of_child}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="memberDetail_fields_border clearfix"></div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Room Tariff</label>
                                            <p>{props.data.currency_symbol + props.data.room_rate}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Rate Description</label>
                                            <p>{rate_code_description ? rate_code_description : "-"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {Object.keys(primary).length !== 0 &&
                                primary.constructor === Object &&
                                filteredObj(primary, guestType).length > 0 ?
                                <div className="reservationDetailWrapper">
                                    <h2 className="memeberDetailLabel">Contact Information</h2>
                                    {primary &&
                                        primary.email_address &&
                                        primary.email_address[guestType] ?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="memberfieldsData">
                                                        <label>Email Address</label>
                                                        <p>{email_address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="memberDetail_fields_border clearfix"></div>
                                        </React.Fragment> : ""}

                                    {primary &&
                                        primary.phone_number &&
                                        primary.phone_number[guestType] ?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="memberfieldsData">
                                                        <label>Phone Number</label>
                                                        <p>{phone_number}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="memberDetail_fields_border clearfix"></div>
                                        </React.Fragment> : ""}

                                    {primary &&
                                        primary.home_address &&
                                        primary.home_address[guestType] ?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="memberfieldsData">
                                                        <label>Address</label>
                                                        <p>{home_address ? home_address : "NA"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="memberDetail_fields_border clearfix"></div>
                                        </React.Fragment> : ""}

                                    {primary &&
                                        primary.country &&
                                        primary.country[guestType] ?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="memberfieldsData">
                                                        <label>Country</label>
                                                        <p>{country ? country : "NA"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment> : ""}
                                </div> : ""}

                            <CustomFields
                                data={props.data}
                                custom_fields_config={custom_fields} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="reservationDetail">
                            {/* <div className="reservationDetailWrapper">
                                <h2 className="memeberDetailLabel">Arrival Details</h2>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="memberfieldsData">
                                            <label>Arriving From</label>
                                            <p>-</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="memberDetail_fields_border clearfix"></div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="memberfieldsData">
                                            <label>Departing to</label>
                                            <p>-</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {Object.keys(id_card).length !== 0 &&
                                id_card.constructor === Object &&
                                filteredObj(id_card, guestType).length > 0 ?
                                <React.Fragment>
                                    <div className="reservationDetailWrapper">
                                        <h2 className="memeberDetailLabel">ID Details</h2>
                                        {id_card && id_card.docid_number && id_card.docid_number[guestType] ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="memberfieldsData">
                                                            <label>ID Number</label>
                                                            <p>{docid_number}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="memberDetail_fields_border clearfix"></div>
                                            </React.Fragment>
                                            : ""}

                                        {id_card && id_card.place_of_issue && id_card.place_of_issue[guestType] ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="memberfieldsData">
                                                            <label>Place of Issue</label>
                                                            <p>{place_of_issue}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="memberDetail_fields_border clearfix"></div>
                                            </React.Fragment>
                                            : ""}

                                        {id_card &&
                                            ((id_card.issue_date && id_card.issue_date[guestType]) ||
                                                (id_card.expiry_date && id_card.expiry_date[guestType])) ?
                                            <div className="row">
                                                {id_card && id_card.issue_date && id_card.issue_date[guestType] ?
                                                    <div className="col-md-6">
                                                        <div className="memberfieldsData">
                                                            <label>Date of Issue</label>
                                                            <p>{issue_date}</p>
                                                        </div>
                                                    </div>
                                                    : ""}

                                                {id_card && id_card.expiry_date && id_card.expiry_date[guestType] ?
                                                    <div className="col-md-6">
                                                        <div className="memberfieldsData">
                                                            <label>Date of Expiry</label>
                                                            <p>{expiry_date}</p>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>
                                            : ""}

                                    </div>
                                </React.Fragment>
                                : ""}

                            {Object.keys(visa).length !== 0 &&
                                visa.constructor === Object &&
                                filteredObj(visa, guestType).length > 0 ?
                                <React.Fragment>
                                    <div className="reservationDetailWrapper">
                                        <h2 className="memeberDetailLabel">Visa Details</h2>
                                        {visa && visa.docid_number && visa.docid_number[guestType] ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="memberfieldsData">
                                                            <label>Visa  Number</label>
                                                            <p>{visa_data && visa_data.docid_number ? visa_data.docid_number : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="memberDetail_fields_border clearfix"></div>
                                            </React.Fragment>
                                            : ""}

                                        {visa && visa.place_of_issue && visa.place_of_issue[guestType] ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="memberfieldsData">
                                                            <label>Place of Issue</label>
                                                            <p>{visa_data && visa_data.place_of_issue ? visa_data.place_of_issue : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="memberDetail_fields_border clearfix"></div>
                                            </React.Fragment>
                                            : ""}

                                        {visa &&
                                            ((visa.issue_date && visa.issue_date[guestType]) ||
                                                (visa.expiry_date && visa.expiry_date[guestType])) ?
                                            <div className="row">
                                                {visa && visa.issue_date && visa.issue_date[guestType] ?
                                                    <div className="col-md-6">
                                                        <div className="memberfieldsData">
                                                            <label>Date of Issue</label>
                                                            <p>{visa_data && visa_data.issue_date ? moment.parseZone(visa_data.issue_date).format("MMM D, YYYY") : "-"}</p>
                                                        </div>
                                                    </div>
                                                    : ""}

                                                {visa && visa.expiry_date && visa.expiry_date[guestType] ?
                                                    <div className="col-md-6">
                                                        <div className="memberfieldsData">
                                                            <label>Date of Expiry</label>
                                                            <p>{visa_data && visa_data.expiry_date ? moment.parseZone(visa_data.expiry_date).format("MMM D, YYYY") : "-"}</p>
                                                            <p>{}</p>
                                                        </div>
                                                    </div>
                                                    : ""}
                                            </div>
                                            : ""}

                                    </div>
                                </React.Fragment>
                                : ""}


                            {Object.keys(travel).length !== 0 &&
                                travel.constructor === Object &&
                                filteredObj(travel, guestType).length > 0 ?
                                <React.Fragment>
                                    <div className="reservationDetailWrapper">
                                        <h2 className="memeberDetailLabel">Travel Details</h2>
                                        {travel && travel.arrival_date && travel.arrival_date[guestType] ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="memberfieldsData">
                                                            <label>Arrival Date in {hotelCountry}</label>
                                                            <p>{arrival_date ? moment.parseZone(arrival_date).format("MMM D, YYYY") : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="memberDetail_fields_border clearfix"></div>
                                            </React.Fragment>
                                            : ""}

                                        {travel && travel.departure_date && travel.departure_date[guestType] ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="memberfieldsData">
                                                            <label>Departure Date from {hotelCountry}</label>
                                                            <p>{departure_date ? moment.parseZone(departure_date).format("MMM D, YYYY") : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="memberDetail_fields_border clearfix"></div>
                                            </React.Fragment>
                                            : ""}

                                        {travel && travel.purpose_of_visit && travel.purpose_of_visit[guestType] ?
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="memberfieldsData">
                                                            <label>Purpose of visit</label>
                                                            <p> {purpose_of_visit ? purpose_of_visit : "-"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="memberDetail_fields_border clearfix"></div>
                                            </React.Fragment>
                                            : ""}


                                        {travel &&
                                            ((travel.country_of_origin && travel.country_of_origin[guestType]) ||
                                                (travel.city_of_origin && travel.city_of_origin[guestType])) ?
                                            <React.Fragment>
                                                <div className="row">
                                                    {travel &&
                                                        travel.country_of_origin &&
                                                        travel.country_of_origin[guestType] ?
                                                        <div className="col-md-6">
                                                            <div className="memberfieldsData">
                                                                <label>Country of origin</label>
                                                                <p> {country_of_origin ? country_of_origin : "-"}</p>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                    {travel &&
                                                        travel.city_of_origin &&
                                                        travel.city_of_origin[guestType] ?
                                                        <div className="col-md-6">
                                                            <div className="memberfieldsData">
                                                                <label>City of origin</label>
                                                                <p> {city_of_origin ? city_of_origin : "-"}</p>
                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </React.Fragment>
                                            : ""}
                                    </div>

                                </React.Fragment>
                                : ""}
                            {/* <div className="reservationDetailWrapper">
                                <h2 className="memeberDetailLabel">Payment Details</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Authorized Amount</label>
                                            <p>-</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="memberfieldsData">
                                            <label>Partial Refund</label>
                                            <p>-</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="memberDetail_fields_border clearfix"></div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="memberfieldsData">
                                            <label>Captured Amount</label>
                                            <p>-</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="reservationDetail">
                            <div className="reservationDetailWrapper">
                                <h2 className="memeberDetailLabel">Terms and Conditions</h2>
                                <div className="health_declaration">
                                    <div dangerouslySetInnerHTML={{ __html: props.data.declarations }} />
                                </div>
                            </div>
                            <div className="reservationDetailWrapper">
                                <h2 className="memeberDetailLabel">Health Declaration</h2>
                                <div className="covid_declaration">
                                    <div>
                                        <ul>
                                            {covidDeclarations.map((record, index) => {
                                                return (<li key={index}>
                                                    {record.question}
                                                    <span className="question_ans">{record.answer ? "Yes" : "No"}</span>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="reservationDetailWrapper">
                                <h2 className="memeberDetailLabel">Signature</h2>
                                <div className="signature_image">
                                    <img width="200" src={signImage} alt="sign" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-right regFooterModal">
                    <div className="thruLogoFooter">
                        <img src="/images/logo.png" alt="logo" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const filteredObj = (obj, guestType) => {
    // return Object.keys(obj).reduce((p, c) => obj[c][guestType] == true, {});
    return Object.keys(obj).filter((ele) => obj[ele][guestType] == true, []);
}

const CustomFields = (props) => {
    let data = getGuestDetails(props.data);
    let { custom_fields_config } = props;
    let { guest } = data;
    let { guest_custom_details } = guest;
    console.log("===guest_custom_fields=====", guest_custom_details)
    let CustomFields = guest_custom_details ? JSON.parse(guest_custom_details) : {};



    return (
        <React.Fragment>
            {(Object.keys(CustomFields).length !== 0 &&
                CustomFields.constructor === Object) ?
                <div className="reservationDetailWrapper">
                    <h2 className="memeberDetailLabel">Others Details</h2>
                    {Object.keys(CustomFields).map((field) => {
                        let filedName = custom_fields_config &&
                            custom_fields_config[field] ? custom_fields_config[field].name : field
                        return (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="memberfieldsData">
                                            <label>{filedName}</label>
                                            <p>{CustomFields && CustomFields[field] ? CustomFields[field] : ""}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="memberDetail_fields_border clearfix"></div>
                            </React.Fragment>
                        )
                    })}
                </div>

                : ""}

        </React.Fragment>
    );
}

const getGuestDetails = (data) => {
    let guest = data;
    let { guests_profile } = guest;
    let isGuestProfile = Boolean(
        Array.isArray(guests_profile) && guests_profile.length
    );
    if (isGuestProfile) guest = guests_profile[0];
    return { guest, isGuestProfile };
};

const mapStateToProps = (state) => {
    return {
        hotel: state.hotel,
        guestDetails: state.guestDetails
    };
};

export default connect(mapStateToProps)(PrintRegCardModal);