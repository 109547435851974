import React, { Component, createRef, Fragment } from "react";
import FormElements from "../formElements/FormElements";
import SignupStepLayout from '../SignupStepLayout';
import DropZone from '../common/DropZone';
import Select from 'react-select';
import Flag from "react-world-flags";
import axios from '../../axios';
import SimpleReactValidator from 'simple-react-validator';
import { getCountry, getCurrencyList } from 'country-currency-map-3';
import withAuth from "../../lib/auth";
import { formToJSON, scrollToError } from "../../utils";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { setKey } from "../../actions";
import { connect } from "react-redux";
import countryPhoneCode from '../../utils/countryPhoneCode.json';
import LoaderSpinner from '../common/LoaderSpinner';
import NeedHelp from "../common/NeedHelp";
import { getSelectTheme } from "../../utils/ui";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { isDataURL } from "../../utils/image";

class HotelInfo extends Component {
    constructor(props) {
        super(props);
        let country_code_options = countryPhoneCode.map(d => ({ value: d.dial_code, label: (<div><Flag code={d.code} width="30" /> {d.dial_code}</div>) }));
        let currencies = getCurrencyList().map(c => ({ value: c.abbr, label: c.name }));
        this.state = {
            formData: {
                country_code: country_code_options[0],
                'contacts[0][country_code]': country_code_options[0],
                'contacts[1][country_code]': country_code_options[0],
                website: 'https://'
            },
            currencies: currencies,
            countries: [],
            country_options: [],
            country_code_options: country_code_options,
            states: [],
            state_options: [],
            cities: [],
            city_options: [],
            timezones: [],
            response_data: [],
            isEdit: false
        }

        this.form = new createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute",
                email: "Please enter a valid email address",
                numeric: "Please enter a valid phone number",
                url: "Please enter a valid url",
            },
            validators: {
                phone_number: {
                    message: 'Please enter a valid phone number',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0-9]{4,15}$/i);
                    }
                }
            }
        });
    }

    getphoneNumber = (phone_number) => {
        let phoneNumber = parsePhoneNumberFromString(phone_number);
        if (phoneNumber != undefined) {
            return phoneNumber.nationalNumber
        } else {
            return null;
        }
    }

    // componentWillMount(){
    //     let { pathname } = this.props.location;
    //     let isEdit = pathname.includes('/edit');
    //     if(isEdit || this.props.isEdit){
    //         this.setState({ isEdit:true})
    //     }
    // }

    componentDidMount() {
        document.scrollingElement.scrollTop = 0;
        this.setState({ loading: true });
        this.fetchCountries();
    }


    editHotelInfo = () => {

        let { pathname } = this.props.location;
        let { country_options } = this.state;
        let isEdit = pathname.includes('/edit');
        if (isEdit || this.props.isEdit) {

            let { hotel } = this.props;
            if (hotel) {
                let { hotel_name, hotel_address, country_code, postal_code, phone_number, website,
                    email_address, contacts, country_id, state_id, hotel_assets } = hotel;
                this.handleSelectChange({ value: country_id }, { name: "country_id" });


                let countryCodeOption = this.getCountryCodeOption(country_code);
                phone_number = this.getphoneNumber(phone_number)

                let general_manager = contacts ? contacts.find(({ contact_type }) => contact_type === "general_manager") : {};
                let general_full_name = general_manager ? general_manager.full_name : null;
                let general_email_address = general_manager ? general_manager.email_address : null;
                let general_phone_number = general_manager.phone_number;
                if (general_manager.phone_number.indexOf('+') != -1) {
                    general_phone_number = this.getphoneNumber(general_manager.phone_number)
                }

                let technical_contact = contacts ? contacts.find(({ contact_type }) => contact_type === "technical_contact") : {};
                let tech_full_name = technical_contact ? technical_contact.full_name : null;
                let tech_email_address = technical_contact ? technical_contact.email_address : null;
                let tech_phone_number = technical_contact.phone_number;
                if (technical_contact.phone_number.indexOf('+') != -1) {
                    tech_phone_number = this.getphoneNumber(technical_contact.phone_number)
                }

                let country_options_val = country_options.find(({ value }) => value === country_id);


                let hotel_logo_light = hotel_assets.find(({ asset_type }) => asset_type === "hotel_logo_light");
                let hotel_background_image = hotel_assets.find(({ asset_type }) => asset_type === "hotel_background_image");

                this.setState({
                    ...this.state,
                    isEdit: true,
                    formData: {
                        ...this.state.formData,
                        hotel_name: hotel_name,
                        address: hotel_address,
                        postal_code: postal_code,
                        country_code: countryCodeOption,
                        phone_number: phone_number,
                        website: website,
                        email_address: email_address,
                        country_id: country_options_val,

                        hotel_logo_light: hotel_logo_light,
                        hotel_background_image: hotel_background_image,
                        "contacts[0][country_code]": countryCodeOption,
                        "contacts[0][full_name]": tech_full_name,
                        "contacts[0][email_address]": tech_email_address,
                        "contacts[0][phone_number]": tech_phone_number,

                        "contacts[1][country_code]": countryCodeOption,
                        "contacts[1][full_name]": general_full_name,
                        "contacts[1][email_address]": general_email_address,
                        "contacts[1][phone_number]": general_phone_number,
                    }
                }, () => setTimeout(() => {
                    this.fetchStates(country_id, state_id);
                    this.setState({ loading: false })
                }, 200))
            }

        } else {
            this.setState({ loading: false })
        }
    }

    fetchCountries = (hotel) => {
        axios.get('/getCountryStateCity')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data
                    console.log('Countries response', api_response);
                    let { status, data } = api_response;
                    if (status) {
                        let country_options = data.map(d => ({ value: d.id, label: d.name }));
                        this.setState({
                            ...this.state,
                            countries: data,
                            country_options: country_options,
                            states: [],
                            cities: [],
                            formData: {
                                ...this.state.formData,
                                country_id: null,
                                state_id: null,
                                city_id: null
                            }
                        })
                        this.editHotelInfo()

                    }
                } else {
                    console.log(res);
                }
            })
            .catch(console.error);
    }

    fetchStates = (country_id, state_id) => {
        axios.get('/getCountryStateCity?country_id=' + country_id)
            .then(res => {
                let api_response = res.data
                console.log('States response', api_response);
                let { status, data } = api_response;
                if (status) {
                    let state_options = data.map(d => ({ value: d.id, label: d.name }));
                    let state_options_val = null;
                    if (state_id) {
                        state_options_val = state_options.find(({ value }) => value === state_id);
                    }

                    this.setState({
                        ...this.state,
                        states: data,
                        state_options: state_options,
                        cities: [],
                        formData: {
                            ...this.state.formData,
                            state_id: state_options_val,
                            city_id: null
                        }
                    });
                    if (this.state.isEdit) {
                        let { city_id } = this.props.hotel;
                        this.fetchCities(state_id, city_id);
                    }
                }
            })
            .catch(console.error);
    }

    fetchCities = (state_id, city_id) => {
        axios.get(`/getCountryStateCity?state_id=${state_id}`)
            .then(res => {
                let api_response = res.data
                console.log('Cities response', api_response);
                let { status, data } = api_response;
                if (status) {
                    let city_options = data.map(d => ({ value: d.id, label: d.name }));
                    let default_city_select = null;
                    if (city_id) {
                        default_city_select = city_options.find(({ value }) => value == city_id);
                    }
                    this.setState({
                        ...this.state,
                        cities: data,
                        city_options: city_options,
                        formData: {
                            ...this.state.formData,
                            city_id: default_city_select
                        }
                    })
                }
            })
            .catch(console.error);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, loading: true });
        if (this.validator.allValid()) {
            let postData = formToJSON(this.form.current);
            postData.phone_number = postData.country_code + postData.phone_number;
            delete postData.country_code;
            postData.contacts = postData.contacts.map(c => {
                c.phone_number = c.country_code + c.phone_number;
                delete c.country_code;
                return c;
            });
            let dark_logo = isDataURL(postData.assets.hotel_logo_light);
            let bg_img = isDataURL(postData.assets.hotel_background_image);
            if (!dark_logo) {
                delete postData.assets.hotel_logo_light;
            } else {
                postData.assets.hotel_logo_dark = postData.assets.hotel_logo_light;
            }
            if (!bg_img) {
                delete postData.assets.hotel_background_image;
            }

            console.log('postData', postData);
            let _axios = "";
            let { isEdit } = this.state;
            if (isEdit) {
                _axios = axios.put('/dashboard/hotel', postData)
            } else {
                _axios = axios.post('/dashboard/hotel', postData)
            }

            _axios.then(res => {
                console.log(res);
                if (res.status === 200) {
                    let api_response = res.data
                    let { status, data, message } = api_response;
                    if (status) {
                        localStorage.setItem("hotel", JSON.stringify(data));
                        this.props.dispatch(setKey('hotel', data));
                        if (!isEdit) {
                            this.props.history.replace('/billing');
                        } else {
                            toast.success("Hotel info updated successfully.")
                        }
                    } else {
                        console.log(api_response);
                        toast.error(message);
                    }
                } else {
                    console.log(res);
                    toast.error(res.message);
                }
            })
                .catch(console.error)
                .finally(() => this.setState({ ...this.state, loading: false }));
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false }, scrollToError);
        }
    }

    handleSelectChange = (e, target) => {
        let { value } = e;
        let { name } = target;

        let state = {
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: e
            }
        };
        switch (name) {
            case "country_id":
                let { countries, timezones, currencies } = state, countryCodeOption;
                let country = countries.find(c => c.id === value);
                console.log("===country===", country)
                if (country) {
                    let { country_code, name } = country;
                    let sc = getCountry(name);
                    if (sc) {
                        console.log('sc', sc);
                        let { currency } = sc;
                        let selectedCurrency = currencies.find(c => c.value === currency);
                        if (selectedCurrency) state.formData.currency_code = selectedCurrency;
                    } else {
                        state.formData.currency_code = null;
                    }
                    timezones = moment.tz.zonesForCountry(country_code);
                    if (Array.isArray(timezones) && timezones.length) { }
                    else { timezones = moment.tz.names(); }
                    countryCodeOption = this.getCountryCodeOption(country_code);
                    if (countryCodeOption) {
                        state.formData.country_code = countryCodeOption;
                        state.formData["contacts[0][country_code]"] = countryCodeOption;
                        state.formData["contacts[1][country_code]"] = countryCodeOption;
                    }
                } else { timezones = moment.tz.names(); }
                timezones = timezones.map(t => ({ value: t, label: t }));
                state.formData.hotel_time_zone = timezones[0];
                state.timezones = timezones;

                this.setState(state, () => this.fetchStates(value, ""));
                break;
            case "state_id":
                this.setState(state, () => this.fetchCities(value, ""));
                break;
            default:
                this.setState(state);
                break;
        }
    }

    getCountryCodeOption = (country_code) => {
        let country = countryPhoneCode.find(c => c.code === country_code), option;
        if (country) {
            let { dial_code, code } = country;
            option = { value: dial_code, label: (<div><Flag code={code} width="30" /> {dial_code}</div>) };
        }
        return option;
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.validator.showMessageFor(name);
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    render() {
        return (
            <SignupStepLayout>
                {this.state.loading ? <LoaderSpinner /> : null}
                <form ref={this.form} autoComplete="off" onSubmit={this.onSubmit} encType="multipart/form-data">
                    <div className="signup_steps_content_wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">General</h3>
                                <div className="floating-form">
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <FormElements
                                                type="text"
                                                maxLength={100}
                                                value={this.state.formData.hotel_name}
                                                validator={this.validator}
                                                validations='required'
                                                onChange={this.handleInputChange}
                                                label="Hotel Name"
                                                name="hotel_name"
                                            />
                                        </div>
                                    </div>
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <FormElements
                                                type="text"
                                                maxLength={100}
                                                value={this.state.formData.address}
                                                validator={this.validator}
                                                validations='required'
                                                onChange={this.handleInputChange}
                                                label="Address"
                                                name="address"
                                            />
                                        </div>
                                    </div>
                                    <div className="form_element_wrapper m-b-45">
                                        <div className="form_element">
                                            <div className="thru_react_select">
                                                <label>Country</label>
                                                <Select
                                                    options={this.state.country_options}
                                                    name='country_id'
                                                    value={this.state.formData.country_id}
                                                    id='country_id'
                                                    className="tax_select"
                                                    onChange={this.handleSelectChange}
                                                    classNamePrefix="react_select"
                                                    placeholder=""
                                                    theme={getSelectTheme}
                                                />
                                                <div className="error-text">
                                                    {this.validator.message('country_id', this.state.formData.country_id, 'required', { messages: { required: "Please select country" } })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_element">
                                            <div className="thru_react_select">
                                                <label>State</label>
                                                <Select
                                                    options={this.state.state_options}
                                                    name='state_id'
                                                    id='state_id'
                                                    value={this.state.formData.state_id}
                                                    className="tax_select"
                                                    classNamePrefix="react_select"
                                                    onChange={this.handleSelectChange}
                                                    placeholder=""
                                                    theme={getSelectTheme}
                                                />
                                                <div className="error-text">
                                                    {this.validator.message('state_id', this.state.formData.state_id, 'required', { messages: { required: "Please select state" } })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_element">
                                            <div className="thru_react_select">
                                                <label>City</label>
                                                <Select
                                                    options={this.state.city_options}
                                                    name='city_id'
                                                    value={this.state.formData.city_id}
                                                    id='city_id'
                                                    onChange={this.handleSelectChange}
                                                    className="tax_select"
                                                    classNamePrefix="react_select"
                                                    placeholder=""
                                                    theme={getSelectTheme}
                                                />
                                                <div className="error-text">
                                                    {this.validator.message('city_id', this.state.formData.city_id, 'required', { messages: { required: "Please select city" } })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <div className="thru_react_select">
                                                <label>Time Zone</label>
                                                <Select
                                                    options={this.state.timezones}
                                                    name='hotel_time_zone'
                                                    id='hotel_time_zone'
                                                    value={this.state.formData.hotel_time_zone}
                                                    className="tax_select"
                                                    classNamePrefix="react_select"
                                                    onChange={this.handleSelectChange}
                                                    placeholder=""
                                                    theme={getSelectTheme}
                                                />
                                                <div className="error-text">
                                                    {this.validator.message('hotel_time_zone', this.state.formData.hotel_time_zone, 'required', { messages: { required: "Please select time zone" } })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_element">
                                            <FormElements
                                                type="text"
                                                maxLength={100}
                                                validator={this.validator}
                                                value={this.state.formData.postal_code}
                                                onChange={this.handleInputChange}
                                                validations='required'
                                                label="Zip Code"
                                                name="postal_code"
                                            />
                                        </div>
                                        <div className="form_element">
                                            <div className="thru_react_select">
                                                <label>Currency</label>
                                                <Select
                                                    options={this.state.currencies}
                                                    name='currency_code'
                                                    id='currency_code'
                                                    value={this.state.formData.currency_code}
                                                    className="tax_select"
                                                    classNamePrefix="react_select"
                                                    onChange={this.handleSelectChange}
                                                    placeholder=""
                                                    theme={getSelectTheme}
                                                />
                                                <div className="error-text">
                                                    {this.validator.message('currency_code', this.state.formData.currency_code, 'required', { messages: { required: "Please select currency" } })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                                    <div className="tip_text">
                                        <p>These are the details of the hotel that are visible to the guests.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.is_admin
                        ? <Fragment>
                            <div className="signup_steps_content_wrapper">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <h3 className="steps_header">Contact Details</h3>
                                        <div className="floating-form">
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <div className="country_phone_wrapper">
                                                        <div className="floating-label">
                                                            <input className="floating-input"
                                                                type='text'
                                                                maxLength={15}
                                                                value={this.state.formData.phone_number}
                                                                name="phone_number"
                                                                autoComplete="off"
                                                                placeholder='Phone Number'
                                                                onChange={this.handleInputChange}
                                                            />
                                                            <div className="error-text">
                                                                {this.validator.message('phone_number', this.state.formData.phone_number, 'required|phone_number')}
                                                            </div>
                                                        </div>
                                                        <div className="country_phone">
                                                            <Select
                                                                value={this.state.formData.country_code}
                                                                onChange={this.handleSelectChange}
                                                                options={this.state.country_code_options}
                                                                name="country_code"
                                                                className="language_select"
                                                                classNamePrefix="react_select"
                                                                theme={getSelectTheme}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <FormElements
                                                        type="text"
                                                        maxLength={50}
                                                        value={this.state.formData.website}
                                                        validator={this.validator}
                                                        validations='required|url'
                                                        onChange={this.handleInputChange}
                                                        label="Website"
                                                        name="website"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <FormElements
                                                        type="text"
                                                        maxLength={50}
                                                        value={this.state.formData.email_address}
                                                        validator={this.validator}
                                                        validations='required|email'
                                                        onChange={this.handleInputChange}
                                                        label="Email Address"
                                                        name="email_address"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="tip_container">
                                            <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                                            <div className="tip_text">
                                                <p>These are the details that the guests would use to reach out to the hotel.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="signup_steps_content_wrapper">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <h3 className="steps_header">Brand</h3>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <DropZone
                                                    validator={this.validator}
                                                    validations="required"
                                                    validatorOptions={{ messages: { required: "Please select logo." } }}
                                                    title="logo"
                                                    minWidth={450}
                                                    isLogo
                                                    minHeight={150}
                                                    btnText="Choose Logo"
                                                    inputName="assets[hotel_logo_light]"
                                                    isEdit={this.state.isEdit}
                                                    imageVal={this.state.formData.hotel_logo_light}
                                                />
                                            </div>
                                            {/* <div className="col-sm-6">
                                                <DropZone
                                                    validator={this.validator}
                                                    validations="required"
                                                    validatorOptions={{ messages: { required: "Please select hotel logo." } }}
                                                    title="dark logo"
                                                    btnText="Choose Dark Logo File"
                                                    inputName="assets[hotel_logo_dark]"
                                                />
                                            </div> */}
                                            <div className="col-sm-6">
                                                <DropZone
                                                    validator={this.validator}
                                                    validations="required"
                                                    validatorOptions={{ messages: { required: "Please select hotel photo." } }}
                                                    maxFileSize={5242880}
                                                    minWidth={1024}
                                                    minHeight={720}
                                                    aspectRatio="16:9"
                                                    title="hotel photo"
                                                    btnText="Choose Hotel Photo"
                                                    inputName="assets[hotel_background_image]"
                                                    hotelBackgroundImage
                                                    isEdit={this.state.isEdit}
                                                    imageVal={this.state.formData.hotel_background_image}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="row hotel_photo_dropzone">
                                            <div className="col-sm-12">
                                                <DropZone
                                                    validator={this.validator}
                                                    validations="required"
                                                    validatorOptions={{ messages: { required: "Please select hotel background image." } }}
                                                    maxFileSize={5242880}
                                                    title="hotel photo"
                                                    btnText="Choose Hotel Photo File"
                                                    inputName="assets[hotel_background_image]"
                                                    hotelBackgroundImage
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="tip_container">
                                            <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                                            <div className="tip_text">
                                                <p>The hotel photo and logo will be used in communicating with the guests. Width of hotel logo and hotel photo must be greater than or equal to the height.</p>
                                                <br />
                                                <div><strong>Logo:</strong> Min Width: 450px & Min Height: 150px</div>
                                                <div><strong>Hotel photo:</strong> Min Width: 1024px & Min Height: 720px</div>
                                                <br />

                                                <p>Hotel photo of 1920x1080 is recommended.</p>
                                                {/* <p style={{ marginTop: '20px' }}>
                                                    <div>Light Logo: Required to be put on dark background</div>
                                                    <div>Dark Logo: Required to be put on light background</div>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="signup_steps_content_wrapper">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <h3 className="steps_header">Technical Contact</h3>
                                        <div className="floating-form">
                                            <input type="hidden" name="contacts[0][contact_type]" value="technical_contact" />
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <FormElements
                                                        type="text"
                                                        maxLength={100}
                                                        value={this.state.formData["contacts[0][full_name]"]}
                                                        validator={this.validator}
                                                        validations='required'
                                                        validatorOptions={{ messages: { required: "Please enter full name" } }}
                                                        onChange={this.handleInputChange}
                                                        label="Full Name"
                                                        name="contacts[0][full_name]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <FormElements
                                                        type="email"
                                                        maxLength={50}
                                                        value={this.state.formData["contacts[0][email_address]"]}
                                                        validator={this.validator}
                                                        validations='required|email'
                                                        validatorOptions={{ messages: { required: "Please enter email address" } }}
                                                        onChange={this.handleInputChange}
                                                        label="Email Address"
                                                        name="contacts[0][email_address]"
                                                    />
                                                </div>
                                                <div className="form_element">
                                                    <div className="country_phone_wrapper">
                                                        <div className="floating-label">
                                                            <input className="floating-input"
                                                                type='text'
                                                                maxLength={20}
                                                                value={this.state.formData["contacts[0][phone_number]"]}
                                                                name="contacts[0][phone_number]"
                                                                autoComplete="off"
                                                                placeholder='Phone Number'
                                                                onChange={this.handleInputChange}
                                                            />
                                                            <div className="error-text">
                                                                {this.validator.message('contacts[0][phone_number]', this.state.formData['contacts[0][phone_number]'], 'required|phone_number', { messages: { required: "Please enter phone number" } })}
                                                            </div>
                                                        </div>
                                                        <div className="country_phone">
                                                            <Select
                                                                value={this.state.formData['contacts[0][country_code]']}
                                                                onChange={this.handleSelectChange}
                                                                options={this.state.country_code_options}
                                                                name="contacts[0][country_code]"
                                                                className="language_select"
                                                                classNamePrefix="react_select"
                                                                theme={getSelectTheme}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <PhoneNumber
                                                        type="text"
                                                        value={this.state.formData["contacts[0][phone_number]"]}
                                                        validator={this.validator}
                                                        validations='required|numeric'
                                                        validatorOptions={{ messages: { required: "Please enter phone number" } }}
                                                        onChange={this.handleInputChange}
                                                        country_code_option={this.state.formData['contacts[0][country_code]']}
                                                        name="contacts[0][phone_number]"
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="tip_container">
                                            <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                                            <div className="tip_text">
                                                <p>This will be used by our team to reach out for system upgrades and IT related queries.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="signup_steps_content_wrapper">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <h3 className="steps_header">General Manager</h3>
                                        <div className="floating-form">
                                            <input type="hidden" name="contacts[1][contact_type]" value="general_manager" />
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <FormElements
                                                        type="text"
                                                        maxLength={100}
                                                        value={this.state.formData["contacts[1][full_name]"]}
                                                        validator={this.validator}
                                                        validations='required'
                                                        validatorOptions={{ messages: { required: "Please enter full name" } }}
                                                        onChange={this.handleInputChange}
                                                        label="Full Name"
                                                        name="contacts[1][full_name]"
                                                    />
                                                </div>
                                            </div>
                                            <div className="form_element_wrapper">
                                                <div className="form_element">
                                                    <FormElements
                                                        type="email"
                                                        maxLength={50}
                                                        value={this.state.formData["contacts[1][email_address]"]}
                                                        validator={this.validator}
                                                        validations='required|email'
                                                        validatorOptions={{ messages: { required: "Please enter email address" } }}
                                                        onChange={this.handleInputChange}
                                                        label="Email Address"
                                                        name="contacts[1][email_address]"
                                                    />
                                                </div>
                                                <div className="form_element">
                                                    <div className="country_phone_wrapper">
                                                        <div className="floating-label">
                                                            <input className="floating-input"
                                                                type='text'
                                                                maxLength={100}
                                                                value={this.state.formData["contacts[1][phone_number]"]}
                                                                name="contacts[1][phone_number]"
                                                                autoComplete="off"
                                                                placeholder='Phone Number'
                                                                onChange={this.handleInputChange}
                                                            />
                                                            <div className="error-text">
                                                                {this.validator.message('contacts[1][phone_number]', this.state.formData['contacts[1][phone_number]'], 'required|phone_number', { messages: { required: "Please enter phone number" } })}
                                                            </div>
                                                        </div>
                                                        <div className="country_phone">
                                                            <Select
                                                                value={this.state.formData['contacts[1][country_code]']}
                                                                onChange={this.handleSelectChange}
                                                                options={this.state.country_code_options}
                                                                name="contacts[1][country_code]"
                                                                className="language_select"
                                                                classNamePrefix="react_select"
                                                                theme={getSelectTheme}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <PhoneNumber
                                                type="text"
                                                value={this.state.formData["contacts[1][phone_number]"]}
                                                validator={this.validator}
                                                validations='required|numeric'
                                                validatorOptions={{ messages: { required: "Please enter phone number" } }}
                                                onChange={this.handleInputChange}
                                                name="contacts[1][phone_number]"
                                            /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-4 col-md-12">
                                            <div className="tip_container">
                                                <div className="tip_image"><img src='/images/status.svg' /></div>
                                                <div className="tip_text">
                                                    <p>Provide details of the general manager to get in contact for features upgrades & notifications.</p>
                                                </div>
                                            </div>
                                        </div>
                                    */}
                                </div>
                            </div>
                        </Fragment>
                        : null
                    }
                    <NeedHelp />
                    {this.props.is_admin
                        ? <div className="continue-btn">
                            <div className="formSubmitButton">
                                <button type="submit" disabled={this.state.loading} className="themeButton">
                                    {this.state.loading ? "Please wait.." : this.state.isEdit ? "Save" : "Continue"}
                                </button>
                            </div>
                        </div>
                        : null
                    }
                </form>
            </SignupStepLayout>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        hotel: state.hotel,
        is_admin: state.is_admin
    };
};

export default connect(mapStateToProps)(withAuth(HotelInfo));