import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment';
import { Redirect, withRouter } from 'react-router';
import { csvFileToJSON } from '../../utils/csv';
import { toast } from 'react-toastify';
import { setCsvData } from '../../actions';

class DropZoneCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,
    }
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ loading: true })
    if (acceptedFiles.length == 1) {
      acceptedFiles.forEach((file) => {
        csvFileToJSON(file)
          .then(csvData => {
            let { headers, data } = csvData;
            if (data.length) {
              data = data.map(row => {
                if ("arrival_date" in row) {
                  row.arrival_date = moment(row.arrival_date).format();
                }
                if ("departure_date" in row) {
                  row.departure_date = moment(row.departure_date).format();
                }
                row.invalid = [];
                return row;
              });

              console.log('CSV FILE TO JSON', data);
              this.props.dispatch(setCsvData({ headers, data }));
              if (this.props.location.pathname === '/uploadcsv') {
                this.props.onDropComplete();
              } else {
                this.setState({ redirect: '/uploadcsv' });
              }
            } else {
              toast.error('No records found in csv');
              this.setState({ loading: false });
            }
          })
          .catch(err => {
            console.error(err);
            toast.error(err.message);
          });
      })
    } else {
      toast.warning('You can upload only one csv file');
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <section>
        {this.state.loading
          ? (<div className="csv_container_wrapper">
            <div className="csv_details">
              <div className="dragdrop">Processing CSV...</div>
            </div>
          </div>)
          : (<Dropzone onDrop={this.onDrop} accept='.csv'>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="csv_container_wrapper">
                  <div className="csv_details">
                    <div className="file-icon-csv"><img src="/images/file-csv.svg" width="50" /></div>
                    <div className="dragdrop">Drag CSV spreadsheet here.</div>
                    <div className="csv_text">Upload a CSV file with the first row having the headers of the columns.<br/>In the next step we will help you match your headers to the right fields. </div>
                    <button className="themeButton">or Browse to Upload..</button>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>)
        }
      </section>
    )
  }
}

DropZoneCSV.propTypes = {
  onDropComplete: PropTypes.func
}

export default connect()(withRouter(DropZoneCSV));