import React, { Component, createRef } from "react";

import FormElements from "../components/formElements/FormElements";
import SignupStepLayout from './../components/SignupStepLayout';
import Switch from "react-switch";

import withAuth from "../lib/auth";
import { formToJSON } from "../utils";
import axios from '../axios';
import EditorHtml from '../components/common/EditorHtml';
import SimpleReactValidator from "simple-react-validator";
import { setKey } from "../actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoaderSpinner from "../components/common/LoaderSpinner";
import NeedHelp from "../components/common/NeedHelp";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            checked: true,
            formData: {
                terms_and_conditions: "",
                covid_declarations: [""]
            },
            redirect: false
        };
        this.isEdit = this.props.location.pathname.includes('/edit');
        this.form = new createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute"
            }
        });
    }

    componentDidMount() {
        document.scrollingElement.scrollTop = 0;
        if (this.isEdit) {
            // call fetchHotelTerms()
            this.fetchHotelTerms()
        }
    }

    fetchHotelTerms = () => {
        this.setState({...this.state, loading: true});
        axios.get('/dashboard/hotel/declarations')
            .then(res => {
                if (res.status === 200) {
                    let api_response = res.data;
                    let { status } = api_response;
                    if (status) {
                        let { covid_declarations, terms_and_conditions } = api_response.data;
                        covid_declarations = JSON.parse(covid_declarations);
                        let checked = true;
                        if(Array.isArray(covid_declarations) && covid_declarations.length===0){
                            this.validator.purgeFields();
                            checked = false;
                        }
                        this.setState({ ...this.state, checked, formData: { covid_declarations, terms_and_conditions } })
                    } else {
                        toast.error('Unable to save terms. Please try again.');
                    }
                } else {
                    console.log(res);
                    toast.error('Unable to update terms. Please try again.')
                }
            })
            .catch(e => {
                console.error(e);
                toast.error('Unable to update terms. Please try again.')
            })
            .finally(() => this.setState({ ...this.state, loading: false }));
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    addDeclaration = () => {
        let { covid_declarations } = this.state.formData;
        let add = covid_declarations.every(d => d !== "");
        if (add) covid_declarations.push(""); else return;
        this.setState({ ...this.state, formData: { ...this.state.formData, covid_declarations } });
    }

    removeDeclaration = (index) => {
        let { covid_declarations } = this.state.formData;
        if (covid_declarations.length === 1) {
            toast.error('You cannot remove all declarations.');
            return
        };
        covid_declarations = covid_declarations.filter((t, i) => i !== index);
        this.validator.purgeFields();
        this.setState({ ...this.state, formData: { ...this.state.formData, covid_declarations } });
    }

    handleDeclaration = (e, i) => {
        let { value } = e.target;
        let { covid_declarations } = this.state.formData;
        covid_declarations[i] = value;
        this.setState({ ...this.state, formData: { ...this.state.formData, covid_declarations } });
    }

    handleSwitch = (checked) => {
        if (!checked) this.validator.purgeFields();
        this.setState({ checked })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, loading: true });
        if (this.validator.allValid()) {
            let postData = formToJSON(this.form.current);
            console.log('postData', postData);
            if (this.state.checked) {
                postData.covid_declarations = JSON.stringify(postData.covid_declarations);
            } else {
                postData.covid_declarations = "[]";
            }
            console.log('postData', postData);

            axios({
                method: this.isEdit ? 'put' : 'post',
                url: '/dashboard/hotel/declarations',
                data: postData
            })
                .then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        let api_response = res.data;
                        let { status, message } = api_response;
                        if (status) {
                            let { hotel } = this.props;
                            if (!this.isEdit) {
                                if (hotel) {
                                    hotel.step_completed = 4;
                                    this.props.dispatch(setKey('hotel', hotel));
                                }
                                this.props.history.replace("/payment");
                            } else {
                                toast.success(message);
                            }
                        } else {
                            toast.error('Unable to save terms. Please try again.');
                        }
                    } else {
                        console.log(res);
                        toast.error('Unable to save terms. Please try again.')
                    }
                })
                .catch(e => {
                    console.error(e);
                    toast.error('Unable to save terms. Please try again.')
                })
                .finally(() => this.setState({ ...this.state, loading: false }));
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false }, this.handleScroll);
        }
    }

    render() {
        let { formData, loading } = this.state;
        let { covid_declarations, terms_and_conditions } = formData;
        return (
            <SignupStepLayout>
                {loading ? <LoaderSpinner /> : null}
                <form autoComplete="off" onSubmit={this.handleSubmit} ref={this.form}>
                    <div className="signup_steps_content_wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">Terms and Conditions</h3>
                                <div className="floating-form">
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <EditorHtml terms_and_conditions={terms_and_conditions} onChange={this.handleInputChange} validator={this.validator} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' alt="tip" /></div>
                                    <div className="tip_text">
                                        <p>Please provide the terms and conditions that should be printed on the registration card.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="signup_steps_content_wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">
                                    {"COVID-19 Declarations"}
                                    <span className="switch_declaration">
                                        <Switch
                                            onChange={this.handleSwitch}
                                            checked={this.state.checked}
                                            onHandleColor="#fff"
                                            onColor="#1772ad"
                                            handleDiameter={10}
                                            //offColor="rgba(0,0,0,0.3)"
                                            width={32}
                                            height={18}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                    </span>
                                </h3>
                                {this.state.checked
                                    ? (<div className="floating-form">
                                        {/* <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <textarea placeholder="Add an introductory text.."></textarea>
                                            </div>
                                        </div> */}
                                        <div className="yes_questions">
                                            {"Yes/No Questions"}
                                        </div>
                                        {covid_declarations.map((d, i) => {
                                            let last = i == covid_declarations.length - 1;
                                            return (
                                                <div key={i} className="form_element_wrapper">
                                                    <div className="form_element">
                                                        <FormElements
                                                            type="text"
                                                            label={last ? "Add Question" : ""}
                                                            value={d}
                                                            maxLength={200}
                                                            validator={this.validator}
                                                            validations="required"
                                                            validatorOptions={{ messages: { required: "Please enter covid declaration." } }}
                                                            name={`covid_declarations[${i}]`}
                                                            disableEnter
                                                            onChange={(e) => this.handleDeclaration(e, i)}
                                                        />
                                                    </div>
                                                    <span className="close_btn" onClick={() => this.removeDeclaration(i)}><img src="/images/close.svg" alt="close" /></span>
                                                </div>
                                            );
                                        })}
                                        <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <div className="addmore_btn">
                                                    <button type="button" disabled={covid_declarations[covid_declarations.length - 1] == ""} onClick={this.addDeclaration}>Add More <img src='/images/iconbtn-plus.svg' alt="add icon" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                    : null
                                }
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' alt="tip" /></div>
                                    <div className="tip_text">
                                        <p>You can specify any COVID-19 related declarations to be filled up by the guest.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-12">
                            <div className="tip_container">
                                <div className="tip_image"><img src='/images/status.svg' /></div>
                                <div className="tip_text">
                                    <p>
                                        {"Tip about the selected field. Lorem salutan tibus vitae elit libero, a pharetra augue. Nec dubitamus multa iter quae et nos invenerat. Morbi fringilla convallis sapien, id pulvinar odio volutpat."}
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    <NeedHelp />
                    <div className="continue-btn">
                        <div className="formSubmitButton">
                            <button type="submit" disabled={this.state.loading} className="themeButton">
                                {this.state.loading ? "Please wait.." : "Continue"}
                            </button>
                        </div>
                    </div>
                </form>
            </SignupStepLayout>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotel: state.hotel
    }
}

export default connect(mapStateToProps)(withAuth(Terms));