import React from 'react';
import SignupStepLayout from '../SignupStepLayout';

import TabComponent from "./TabComponent";
import TermsAndCondition from "./TermsAndCondition"

import LoaderSpinner from '../common/LoaderSpinner';
import axios from '../../axios';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Select from 'react-select';

import AddLanguageModal from '../modal/AddLanguageModal';

import { connect } from "react-redux";
import withAuth from "../../lib/auth";

import { toast } from 'react-toastify';

class LanguageSupport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languagePopup: false,
            selectedOption: {},
            lang_code_option: [],
            languageData: [],
            langSupport: {
                hotel: [],
                app_text: [],
                field_label: [],
                payment: [],
                terms_and_conditions: [],
                hotel_detail:[]
            },
            loading: true,
        };
    }

    handleChange = selectedOption => {
        console.log(`Option selected:`, selectedOption);
        if (selectedOption.value === "add") {
            if (this.state.languageData.length <= 0) {
                this.fetchLanuageData();
            } else {
                this.setState({ languagePopup: true });
            }
            return true;
        } else {
            this.setState({ selectedOption }, () => this.fetchData(selectedOption.value));
        }
    };




    languagePopupToggle = (val) => {
        if(val){
            let {value} = this.state.selectedOption; 
            this.fetchData(value);
        }
        this.setState({ languagePopup: !this.state.languagePopup });   
    };

    componentDidMount() {
        this.fetchData("");
    }

    prepareDataObject = (data) => {
        if (Object.keys(data) !== 0 && data.constructor === Object) {
            this.setState({
                ...this.state,
                langSupport: {
                    ...this.state.langSupport,
                    ...data
                }
            })
        } else {
            this.setState({
                ...this.state,
                langSupport: {
                    hotel: [],
                    app_text: [],
                    field_label: [],
                    payment: [],
                    terms_and_conditions: []
                }
            })
        }

    }

    fetchLanuageData = () => {
        axios.get("dashboard/hotel/languages")
            .then((response) => {
                console.log("response====", response)
                if (response.status == 200) {
                    let { data } = response.data;
                    this.setState({
                        ...this.state,
                        languageData: data,
                        languagePopup: true
                    })
                } else {
                    toast.error("Error in get language data.")
                }
            }).catch((error) => {
                toast.error("Error in get language data.")
            })
    }

    fetchData = (lang_code) => {
        this.setState({
            ...this.state,
            loading: true
        })
        axios.get("/dashboard/hotel/languageSupport/",
            { params: { lang_code: lang_code } })
            .then((response) => {
                console.log("response====", response)
                if (response.status == 200) {
                    let { data } = response.data;
                    let { supported_langauges, translations } = data;
                    this.prepareDataObject(translations)
                    if (Array.isArray(supported_langauges) && supported_langauges.length > 0) {
                        let leng_data = supported_langauges.map(d => ({ value: d.code, label: (<div>{d.name}</div>) }));
                        leng_data.unshift({ value: 'add', label: (<div>{'+ Add More Language'}</div>) })
                         
                              let {selectedOption} = this.state;
                           
                            if (Object.keys(selectedOption).length === 0 && selectedOption.constructor === Object) {
                            let default_lang = leng_data.find(ele => ele.value == "en")
                            this.setState({
                                ...this.state,
                                selectedOption: default_lang != undefined ? default_lang : {},
                            })
                         }
                         this.setState({
                            ...this.state,
                            lang_code_option: leng_data
                        })
                    }
                } else {
                    toast.error("Error in get language support data.")
                }
            }).catch((error) => {
                toast.error("Error in get language support data.")
            }).finally(() => {
                this.setState({
                    ...this.state,
                    loading: false
                })
            })
    }



    render() {
        let { loading, langSupport, lang_code_option, selectedOption } = this.state;
        return (
            <React.Fragment>
                {loading ? <LoaderSpinner /> :
                    <SignupStepLayout>
                        <div className="languages_tabs_support">
                            <div className="language_select_signup">
                                <Select
                                    value={this.state.selectedOption}
                                    onChange={this.handleChange}
                                    options={lang_code_option}
                                    className="language_select"
                                    classNamePrefix="react_select"
                                    theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#ecf1f5',
                                            primary: '#ecf1f5',
                                        },
                                    })}
                                />
                            </div>
                            <div className="language_support">
                                <Tabs>
                                    <TabList>
                                        <Tab>Hotel Details</Tab>
                                        <Tab>Field Labels</Tab>
                                        <Tab>Application Text</Tab>
                                        <Tab>Terms & Declarations</Tab>
                                        <Tab>Payment</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <TabComponent
                                            data={langSupport.hotel}
                                            heading={"Hotel Details"}
                                            selectedOption={selectedOption}
                                            hotel_detail ={langSupport.hotel_detail}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <TabComponent
                                            data={langSupport.field_label}
                                            heading={"Field Labels"}
                                            selectedOption={selectedOption}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <TabComponent
                                            data={langSupport.app_text}
                                            heading={"Application Text"}
                                            selectedOption={selectedOption}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <TermsAndCondition
                                            data={langSupport.terms_and_conditions}
                                            heading={"Terms & Declarations"}
                                            selectedOption={selectedOption}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <TabComponent
                                            data={langSupport.payment}
                                            heading={"Payment"}
                                            selectedOption={selectedOption}
                                        />
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                        {this.state.languagePopup ?
                            <AddLanguageModal
                                showNoteModal={this.state.languagePopup}
                                onHide={this.languagePopupToggle}
                                languageData={this.state.languageData}
                                addedOption={this.state.lang_code_option}
                            />
                            :
                            ""}
                    </SignupStepLayout>
                }
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        hotel: state.hotel,
    };
};

export default connect(mapStateToProps)(withAuth(LanguageSupport));