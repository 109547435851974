import React from 'react';
import { matchPath } from "react-router"
import $ from "jquery";
import { Auth } from 'aws-amplify';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import axios from '../axios'

export const getCurrentSession = async () => {
  return Auth.currentSession().then((data) => {
    return data;
  }).catch((e) => {
    return false;
  })
}

export function getStorage(key, type, defaultValue) {
  let data = localStorage.getItem(key);
  if (data) {
    switch (type) {
      case 'array':
      case 'object':
        try { data = JSON.parse(data) }
        catch (e) { data = defaultValue }
        break;
      case 'string':
        break;
      default:
        data = defaultValue;
        break;
    }
  } else {
    data = defaultValue;
  }
  return data;
}

// export const getStatusDetails = (statusKey) => {
//   let str = null;
//   let statusMappings = {
//     "checkin_pending": [{
//       "chipTxt": "Pending",
//       "chipClass": "pending",
//       "display_text": "Checkin Pending."
//     }],
//     "profile_uploaded": [{
//       "chipTxt": "Profile",
//       "chipClass": "profile",
//       "display_text": "Profile.",
//       "tooltip": "<li className=\"completePoint\">Profile Completed</li><li className=\"completePoint\">Document could not be verified</li>"
//     }],
//     "checkin_completed_with_payment": [{ "chipTxt": "Completed", "chipClass": "completed", "display_text": "Checkin Completed with Payment." }],
//     "checkin_completed_without_payment": [{ "chipTxt": "Completed", "chipClass": "completed", "display_text": "Checkin Completed without Payment." }],
//     "checkin_completed_with_alert": [
//       { "chipTxt": "Completed", "chipClass": "completed" },
//       { "chipTxt": "Alert", "chipClass": "alert", "display_text": "Checkin Completed with Alert." }
//     ],
// //     "staying": [{ "chipTxt": "Staying", "chipClass": "completed", "display_text": "Staying." }],
// //     "checkout": [{ "chipTxt": "Checked Out", "chipClass": "pending", "display_text": "Checked Out." }],
// //     "payment_failure": [{ "chipTxt": "Alert", "chipClass": "alert", "display_text": "Payment failed." }],
// //     "cancelled": [{ "chipTxt": "Cancelled", "chipClass": "alert", "display_text": "Cancelled." }]
//   }
//   if (statusMappings[statusKey]) str = statusMappings[statusKey];
//   return str;
// }

export const getStatusDetails = (statusKey, uploadAlert) => {
  let str = null;
  let statusMappings = {
    "checkin_pending": [{
      "chipTxt": "Pending",
      "chipClass": "pending",
      "display_text": "Checkin Pending.",

    }],
    "profile_uploaded": [{
      "chipTxt": "Profile",
      "chipClass": "profile",
      "display_text": "Profile",
      "tooltip": "<li className=\"completePoint\" >Profile uploaded.</li><li >Payment pending.</li>"
    }],
    "checkin_completed_with_payment": [{
      "chipTxt": "Completed",
      "chipClass": "completed",
      "display_text": "Checkin Completed with Payment."
    }],
    "checkin_completed_without_payment": [{
      "chipTxt": "Completed",
      "chipClass": "completed",
      "display_text": "Checkin Completed without Payment."
    },
    {
      "chipTxt": "Alert",
      "chipClass": "alert",
      "display_text": "Payment Pending.",
      "tooltip": "<li >Payment pending.</li>"
    }],
    "checkin_completed_with_alert": [{
      "chipTxt": "Completed",
      "chipClass": "completed",
      "display_text": "Checkin Completed with alert."
    },
    {
      "chipTxt": "Alert",
      "chipClass": "alert",
      "display_text": "Checkin Completed with alert.",
      "tooltip": "<li >Document uploaded could not be verified.</li>"
    }],
    "payment_failure": [{
      "chipTxt": "Profile",
      "chipClass": "profile",
      "display_text": "Profile",
      "tooltip": "<li className=\"completePoint\" >Profile uploaded.</li><li >Payment failed.</li>"
    },
    {
      "chipTxt": "Alert",
      "chipClass": "alert",
      "display_text": "Payment failed ",
      "tooltip": "<li >Payment failed.</li>"
      }],
    "profile_uploaded_with_alert": [{
      "chipTxt": "Profile",
      "chipClass": "profile",
      "display_text": "Profile uploaded with alert",
      "tooltip": "<li className=\"completePoint\" >Profile uploaded.</li><li >Payment pending.</li>"
    },
    {
      "chipTxt": "Alert",
      "chipClass": "alert",
      "display_text": "Checkin Completed with alert.",
      "tooltip": "<li >Document uploaded could not be verified.</li>"
    }],
    "profile_uploaded_with_alert_alert": [{
      "chipTxt": "Alert",
      "chipClass": "alert",
      "display_text": "Checkin Completed with alert.",
      "tooltip": "<li >Document uploaded could not be verified.</li>"
    }],
    "staying": [{ "chipTxt": "Staying", "chipClass": "completed", "display_text": "Staying." }],
    "checkout": [{ "chipTxt": "Checked Out", "chipClass": "pending", "display_text": "Checked Out." }],
    "cancelled": [{ "chipTxt": "Cancelled", "chipClass": "alert", "display_text": "Cancelled." }]
  }
  if (statusMappings[statusKey]) {
    if (uploadAlert != null) {
      if (statusMappings[statusKey] && statusMappings[statusKey]) {
        if (statusMappings[statusKey].length > 1) {
          statusMappings[statusKey][1].tooltip = "<li >Document uploaded could not be verified.</li>" + statusMappings[statusKey][1].tooltip;
        } else {
          statusMappings[statusKey].push({
            "chipTxt": "Alert",
            "chipClass": "alert",
            "display_text": "Checkin Completed with alert.",
            "tooltip": "<li >Document uploaded could not be verified.</li>"
          })
        }
      }
    }
    str = statusMappings[statusKey];
  }
  return str;
}

export const nl2br = (str) => {
  var newlineRegex = /(\r\n|\r|\n)/g;
  if (typeof str !== 'string') {
    return str;
  }

  return str.split(newlineRegex).map(function (line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index });
    }
    return line;
  });
};

export const downloadImage =  (urlArray) => {


  if (Array.isArray(urlArray) && urlArray.length > 0) {
    urlArray.map(async(detail) => {
      let filename = detail.substring(detail.lastIndexOf('/') + 1);
      const res = await axios({ url: detail, method: 'OPTIONS', responseType: 'blob' })
    .then((response) => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}`);
      document.body.appendChild(link);
      link.click();
    });
      //     axios({
      //       url: detail,
      //       method: 'GET',
      //       headers: config.environment.headers,
      //       mode: 'cors'
      //     }).then((response) => {
      //       console.log('==response==', response)
      //       const url = window.URL.createObjectURL(new Blob([response.data]));
      //       const link = document.createElement('a');
      //       link.href = url;
      //       link.setAttribute('download', filename);
      //       document.body.appendChild(link);
      //       link.click();
      //     });
      //     // let xhr = new XMLHttpRequest();
      //     // xhr.setRequestHeader("Con", photoId);
      //     // xhr.open("GET", detail, true);
      //     // xhr.responseType = "blob";
      //     // xhr.onload = function (e) {
      //     //   if (this.status == 200) {
      //     //     const blob = this.response;
      //     //     const a = document.createElement("a");
      //     //     document.body.appendChild(a);
      //     //     const blobUrl = window.URL.createObjectURL(blob);

      //     //     a.href = blobUrl;
      //     //     a.download = filename;
      //     //     a.click();
      //     //     setTimeout(() => {
      //     //       window.URL.revokeObjectURL(blobUrl);
      //     //       document.body.removeChild(a);
      //     //     }, 0);
      //     //   }
      //     // };
      //     // xhr.send();
    })
  }
}

// -------------------------------------------------------------------------- API UTILS -------------------------------------------------------------------
export const encodeQuery = (params) => {
  let q = "";
  let keys = Object.keys(params);
  if (keys.length) {
    // return Object.keys(params).map(key => key + '=' + params[key]).join('&');
    q = '?' + keys.map((key) => (encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))).join('&');
  }
  return q;
}

export const getCountryStateCity = (params = {}) => {
  return new Promise((resolve, reject) => {
    axios.get('/getCountryStateCity' + encodeQuery(params)).then(resolve).catch(reject);
  })
}

// -------------------------------------------------------------------------- FORM UTILS -------------------------------------------------------------------
export const formToJSON = (formElement, flatten = true) => {
  var formData = new FormData(formElement), convertedJSON = {};

  formData.forEach(function (value, key) {
    convertedJSON[key] = value;
  });

  if (flatten) {
    convertedJSON = unflatten(convertedJSON);
  }
  return convertedJSON;
}

// -------------------------------------------------------------------------- OBJECT UTILS -------------------------------------------------------------------
export const flatten = (data) => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + "[" + i + "]");
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + "[" + p + "]": p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, "");
  return result;
};

export const unflatten = (data) => {
  if (Object(data) !== data || Array.isArray(data))
    return data;
  var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = "",
      m;
    while (m = regex.exec(p)) {
      cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[""] || resultholder;
}

// -------------------------------------------------------------------------- REDIRECTION UTILS -------------------------------------------------------------------
export function getRedirectPage(hotelStep, history) {
  console.log("hotelStep========", hotelStep)
  let redirectStep = "/hotelinfo";
  if (hotelStep) {
    switch (hotelStep) {
      case 0:
      case "0":
        redirectStep = "/hotelinfo";
        break;
      case 1:
      case "1":
        redirectStep = "/billing";
        break;
      case 2:
      case "2":
        redirectStep = "/guestdetail";
        break;
      case 3:
      case "3":
        redirectStep = "/terms";
        break;
      case 4:
      case "4":
        redirectStep = "/payment";
        break;
      case 5:
      case "5":
        redirectStep = "/team";
        break;
      case 6:
      case "6":
        const pathname = history.location.pathname;
        if (matchPath(pathname, { path: "/login", strict: true }) || matchPath(pathname, { path: "/", strict: true })) {
          redirectStep = "/dashboard";
        } else {
          redirectStep = false
        }
        break;
      default:
        redirectStep = "/hotelinfo";
    }
  }
  return redirectStep;
}

export const uuid = (length = 32) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const formatNumber = (number) => (new Intl.NumberFormat().format(number));

export const scrollToError = (className = "srv-validation-message", parentClass = "form_element") => {
  let errorElem = $('.' + className + ':first');
  if (errorElem.length) {
    let parentElem = errorElem.closest('.' + parentClass);
    if (parentElem.length) {
      $('html, body').animate({
        scrollTop: (parentElem.offset().top - 200)
      }, 'slow');
    }
  }
}

// -------------------------------------------------------------------------- CSV VALIDATION UTILS -------------------------------------------------------------------
const csvSchema = {
  external_booking_id: (value) => (value !== ""),
  first_name: (value) => (value !== ""),
  last_name: (value) => (value !== ""),
  email_address: (value)=>(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/gm.test(value)),
  no_of_adult: (value) => (/^\d+$/.test(value)),
  no_of_child: (value) => (/^\d+$/.test(value)),
  nationality: (value) => (value !== ""),
  phone_number: (value) => {
    if(!value || value === "" || !(/^[+]{1}[0-9]{2,3}[0-9]{7,15}$/gm.test(value))) return false;
    const phoneNumber = parsePhoneNumberFromString(value);
    if (phoneNumber !== undefined) {
      return phoneNumber.isPossible();
    } else {
      return false;
    }
  },
  arrival_date: (arrival_date) => {
    if (!arrival_date || arrival_date === "") return false;
    arrival_date = moment(arrival_date);
    let current_date = moment().format('YYYY-MM-DD');
    return moment(arrival_date).isSameOrAfter(current_date);
  },
  departure_date: (arrival_date, departure_date) => {
    if (!departure_date || departure_date === "") return false;
    arrival_date = moment(arrival_date);
    departure_date = moment(departure_date);
    // let current_date = moment().format('YYYY-MM-DD');

    // check is arrival is valid
    // let validArrival = moment(arrival_date).isSameOrAfter(current_date);

    // check if departure is greater than arrival
    let validDeparture = moment(departure_date).isSameOrAfter(arrival_date);

    return validDeparture;
  }
}

export const checkRequired = (object) => {
  var errors = Object.keys(csvSchema).filter((key) => {
    let valid = false;
    switch (key) {
      case "departure_date":
        valid = csvSchema[key](object.arrival_date, object.departure_date);
        break;
      case "phone_number":
      case "email_address":
        valid = csvSchema["phone_number"](object["phone_number"]) || csvSchema["email_address"](object["email_address"]);
        break;
      default:
        valid = csvSchema[key](object[key]);
    }
    return !valid;
  })
  return errors;
}

export const checkField = (key, object) => {
  if (key in csvSchema) {
    switch (key) {
      case "departure_date":
        return csvSchema[key](object.arrival_date, object.departure_date);
      case "phone_number":
      case "email_address":
        return (csvSchema["phone_number"](object["phone_number"]) || csvSchema["email_address"](object["email_address"]));
      default:
        return csvSchema[key](object[key]);
    }
  } else {
    return true;
  }
}