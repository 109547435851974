import { toast } from 'react-toastify';

const Tostify = store => next => action => {
  if (action.type == "FAILED_RESPONSE") {
    let data = action.data;
    if (data.hasOwnProperty('code')) {
      if (data.code == "UsernameExistsException") {
        toast.error("An account already exist with entered email id. Please login.")
      } else {
        toast.error(data.message)
      }

    }
    action.data = {}
    return next(action);
  } else {
    return next(action);
  }
}

export default Tostify;