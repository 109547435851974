import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'
import DataTable from 'react-data-table-component'
import { startCase } from 'lodash';
import { checkRequired, checkField, uuid } from '../../utils';
import DatePicker from '../common/DatePicker';
import { TextField } from '@material-ui/core';
import ConfirmModal from '../modal/ConfirmModal';
import RowDetails from './RowDetails';

class Review extends Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: this.getColumns(),
			data: this.getData(),
			showConfirmModal: false,
			selectedRow: false,
			show_error_fields: false
		}
	}

	toggleConfirmModal = () => this.setState(prevState => ({ ...prevState, showConfirmModal: !prevState.showConfirmModal }))

	confirmDelete = (id) => this.setState({ ...this.state, showConfirmModal: true, selectedRow: id })

	getColumns = () => {
		let tableHeaders = [];
		let { requiredFields } = this.props;
		if (Array.isArray(requiredFields)) {
			tableHeaders = requiredFields.map(h => ({
				name: startCase(h), selector: h, sortable: true,
				cell: row => {
					let valid = checkField(h, row);
					if (!valid || row.invalid.includes(h)) {
						if (h === "arrival_date" || h === "departure_date") {
							return (<DatePicker
								name={`row[${row.id}][${h}]`}
								disablePast={true}
								minDateMessage=""
								invalidDateMessage=""
								emptyLabel
								value={(row[h] ? row[h] : '')}
								onChange={this.handleFieldInputChange}
							/>)
						} else {
							return (
								<div>
									<TextField
										name={`row[${row.id}][${h}]`}
										placeholder={startCase(h)}
										onChange={this.handleFieldInputChange}
										defaultValue={(row[h] ? row[h] : "")}
									/>
								</div>
							)
						}
					} else {
						return row[h];
					}
				},
				conditionalCellStyles: [
					{
						when: row => !checkField(h, row),
						style: {
							boxShadow: '0px 0px 3px 1px rgba(242,38,19,0.8) inset',
							color: 'white'
						},
					}
				]
			}))

			tableHeaders.push({
				cell: (row) => <span className="delete-icon" onClick={() => this.confirmDelete(row.id)}><i className="fa fa-trash" aria-hidden="true"></i></span>,
				selector: 'action',
				name: 'Action',
				ignoreRowClick: true,
				button: false,
				allowOverflow: false
			})
		}
		return tableHeaders;
	}

	getData = () => {
		let tableData = [];
		let { reservationData } = this.props;
		if (Array.isArray(reservationData)) {
			tableData = reservationData.map(row => ({ ...row, id: uuid(), show: true }));
		}
		return tableData;
	}

	handleFieldInputChange = (e) => {
		let { value, name } = e.target;
		let { data } = this.state;

		// get index and key from name
		const regexp = /(?<=\[).+?(?=\])/gm;
		const [id, key] = [...name.match(regexp)];

		// get index of row and update
		let index = data.findIndex(r => r.id === id);
		let row = data[index];
		row[key] = value;
		// row.invalid = [...new Set([...row.invalid, ...checkRequired(row)])];
		let invalid = checkRequired(row);
		// console.log(row.external_booking_id, invalid);
		row.invalid = [...new Set([...row.invalid, ...invalid])];
		data[index] = row;

		// map data to new objects so that the update is Immutable
		data = data.map(d => ({ ...d }));

		this.setState({ ...this.state, data: [...data] });
	}

	removeRow = () => {
		let { data, selectedRow } = this.state;
		data = [...data.filter(d => d.id !== selectedRow)];
		this.setState({ data });
	}

	handleSwitch = show_error_fields => {
		let { data } = this.state;
		if (show_error_fields) {
			data = data.map(d => {
				if (d.invalid.length > 0) {
					d.show = true;
				} else {
					d.show = false;
				}
				return d;
			})
		} else {
			data = data.map(d => ({ ...d, show: true }));
		}
		data = [...data];
		this.setState({ ...this.state, show_error_fields, data });
	}

	render() {
		let { data } = this.state;
		let { loading } = this.props;
		return (<fieldset className="stepThree clearfix">
			<div className="fieldsetMain">
				<div className="row">
					<div className="col-md-12">
						<div className="fieldsetHeader">
							<h1>Bulk Add {data.length} Reservations</h1>
						</div>
						<div className="switch_wrapper_table">
							<Switch
								checked={this.state.show_error_fields}
								onChange={this.handleSwitch}
								onHandleColor="#fff"
								onColor="#1772ad"
								handleDiameter={15}
								width={40}
								height={24}
								checkedIcon={false}
								uncheckedIcon={false}
							/>
							<div className="switch_wrapper_text">
								{"Only show rows with problems"}
							</div>
						</div>
						<div className="fieldsetData clearfix simpleTable">
							<DataTable
								pagination
								columns={this.state.columns}
								data={this.state.data.filter(d => d.show)}
								expandableRows
								expandableRowsComponent={<RowDetails row />}
								customStyles={this.props.customStyles}
							/>
						</div>
					</div>
				</div>
				<div className="row fieldsetFooter">
					<div className="col-md-12">
						<button className="themeButton mr-16" disabled={loading} onClick={() => this.props.saveReservation(this.state.data)}>
							{loading ? "Please wait..." : "Submit"}
						</button>
					</div>
				</div>
			</div>
			<ConfirmModal
				title="Confirmation"
				showModal={this.state.showConfirmModal}
				onConfirm={this.removeRow}
				onHide={this.toggleConfirmModal}
			>
				<p>Would you like to delete this record?</p>
			</ConfirmModal>
		</fieldset>)
	}
}

Review.propTypes = {
	reservationData: PropTypes.array.isRequired
}

export default Review;
