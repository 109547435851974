import React from 'react'
import PropTypes from 'prop-types';
import FieldDataWrapper from './FieldDataWrapper';
import LoaderSpinner from "../common/LoaderSpinner";

function Match(props) {
	if(props.loading){
		return (<LoaderSpinner />);		
	}
	
	let { fieldMappings, reservationData, fieldOptions, requiredFields, mapReservationFields, setField } = props;
	let disable_continue = fieldMappings.some(f => !f.confirmed && !f.ignored);
	return (<fieldset className="stepTwo clearfix">
		<div className="fieldsetMain">
			{Array.isArray(fieldMappings)
				? fieldMappings.map((field, i) => (
					<FieldDataWrapper key={field.old_key}
						field={field}
						index={i}
						fieldMappings={fieldMappings}
						requiredFields={requiredFields}
						reservationData={reservationData}
						setField={setField}
						fieldOptions={fieldOptions}
					/>
				))
				: null
			}
			<div className="row fieldsetFooter">
				<div className="col-md-12">
					<button className="themeButton mr-16" disabled={disable_continue} onClick={mapReservationFields}>Continue</button>
				</div>
			</div>
		</div>
	</fieldset>)
}

Match.propTypes = {
	mapReservationFields: PropTypes.func.isRequired,
	fieldOptions: PropTypes.array.isRequired,
	reservationData: PropTypes.array.isRequired,
	requiredFields: PropTypes.array.isRequired,
	fieldMappings: PropTypes.array.isRequired,
	setField: PropTypes.func.isRequired
}

export default Match;
