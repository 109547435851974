import React from "react";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import CSVUploadModal from '../modal/BulkCSVUploadModal';
import DataPMSModal from '../modal/DataPMSModal';
import OnlineIntModal from '../modal/OnlineIntModal';

class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterSortOrder: false,
            NoteModal: false,
            onlineIntModal: false,
            dataPMSModal: false,
            OrderByfilterDropDown: false,
            guestTypeFilter: [
                { display_name: "Arrivals", default_active: true, value: 1, type: "arrival", disabled: false },
                { display_name: "Staying", default_active: false, value: 2, type: "staying", disabled: false },
                { display_name: "Departing", default_active: false, value: 3, type: "departing", disabled: false },
                // { display_name: "Statistics", default_active: false, value: 4, type: "statistics", disabled: true },
            ],
            orderByFilter: [
                { display_name: "Last Name", value: "last_name", color: "", color_d_name: "", type: "" },
                { display_name: "Pending", value: "checkin_pending", color: "pending", color_d_name: "", type: "withoutStay" },
                // { display_name: "With Payment", value: "checkin_completed_with_payment", color: "green", color_d_name: "Complete", type: "withoutStay" },
                { display_name: "Completed", value: "completed", color: "green", color_d_name: "Complete", type: "withoutStay" },
                { display_name: "Alert", value: "alert", color: "Warnings", color_d_name: "Warnings", type: "withoutStay" },

                { display_name: "Arrival Date", value: "arrival_date", color: "", color_d_name: "", type: "staying" },
                { display_name: "Departure Date", value: "departure_date", color: "", color_d_name: "", type: "staying" }
            ],



        }
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.filters != nextProps.filters) {
            let guestFilter = this.state.guestTypeFilter;
            guestFilter.forEach((element, index) => {
                if (element.type == nextProps.filters.selectGuestType) {
                    guestFilter[index].default_active = true;
                } else {
                    guestFilter[index].default_active = false;
                }
            });
            this.setState({ guestTypeFilter: guestFilter })
        }
    }

    NoteModalToggle = () => {
        this.setState({ NoteModal: !this.state.NoteModal });
    };

    OnlineIntModalToggle = () => {
        this.setState({ onlineIntModal: !this.state.onlineIntModal });
    };

    dataPMSModalToggle = () => {
        this.setState({ dataPMSModal: !this.state.dataPMSModal });
    };


    guestFilterSelect = (e) => {
        let guestFilter = this.state.guestTypeFilter;
        guestFilter.forEach((element, index) => {
            if (element.type == e.currentTarget.dataset.type) {
                guestFilter[index].default_active = true;
                this.props.selectFiltes(guestFilter[index], "guestType");
            } else {
                guestFilter[index].default_active = false;
            }
        });
        this.setState({ guestTypeFilter: guestFilter })
    }


    SearchToggle = () => {
        this.setState({ filterSortOrder: !this.state.filterSortOrder });
    }

    handleClickOutside(event) {       
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                OrderByfilterDropDown : false
            })
        } else{
            // console.log('You clicked inside of me!');
        }
    }

    OrderByToggle = (e) => {
        this.setState({ OrderByfilterDropDown: !this.state.OrderByfilterDropDown });
    }

    render() {
        let guestTypeFilter = this.state.guestTypeFilter;
        let filtersVal = this.props.filters;
        let orderByFilter = this.state.orderByFilter;
        let  {hotelIntegration} = this.props;

        let enable_csv_import =true;
        if (hotelIntegration && Object.keys(hotelIntegration).length !== 0 && hotelIntegration.constructor === Object) {
            if(hotelIntegration?.pms_gateway){
                let {pms_gateway} = hotelIntegration;
                    if (Array.isArray(pms_gateway) && pms_gateway.length > 0) {
                        let payment_confival = pms_gateway[0];
                        if(payment_confival?.vendor_config){
                            let {vendor_config} = payment_confival;
                            let {config} = vendor_config;
                                let {csv_import} = config; 
                                enable_csv_import =csv_import?csv_import:false; 
                        }
                    }
                }
            
        }

        console.log("===enable_csv_import===",enable_csv_import)


        return (
            <div className="row">
                <div className="col-lg-6">
                    <div className="filtertype">

                        <ul className="d-flex">
                            {(
                                (Array.isArray(guestTypeFilter) && guestTypeFilter.length > 0 ?
                                    <React.Fragment>
                                        {guestTypeFilter.map((filter) => {
                                            return <li
                                                disabled={filter.disabled}
                                                key={filter.type}
                                                name={filter.display_name}
                                                data-type={filter.type}
                                                className={filter.default_active ? "active" : ""}
                                                onClick={(e) => !filter.disabled ? this.guestFilterSelect(e) : ""}>
                                                {filter.display_name}
                                            </li>
                                        })}
                                    </React.Fragment> :
                                    <h4>Loading..</h4>)
                            )}
                        </ul>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="pull-right filterDays">
                        <ul className="">
                            {(filtersVal.selectGuestType != "staying" ?
                                <React.Fragment>
                                    <li className={filtersVal.withDays == 0 ? "active" : ""} onClick={(e) => this.props.selectFiltes(0, "withDays")}>Today</li>
                                    <li className={filtersVal.withDays == 1 ? "active" : ""} onClick={(e) => this.props.selectFiltes(1, "withDays")}>Tomorrow</li>
                                    <li className={filtersVal.withDays == 2 ? "active" : ""} onClick={(e) => this.props.selectFiltes(2, "withDays")}>Next Day</li>
                                </React.Fragment> : "")}
                            <li className="orderByFilterDropdown" ref={this.wrapperRef}>
                                <div className="sortingFilter" onClick={this.OrderByToggle} >
                                    <span className="sortLabel">Order by</span>
                                    {(Object.keys(filtersVal.orderByObj) !== 0 &&
                                        filtersVal.orderByObj.constructor === Object) ?

                                        <span className={"drlabelValueFirst " + filtersVal.orderByObj.color} >
                                            {filtersVal.orderByObj.display_name}</span>
                                        :
                                        <span className="drlabelValueFirst "></span>
                                    }

                                    <i className="fa fa-caret-down"></i>
                                </div>
                                <ul className={"sortingFilterDropDown " + (this.state.OrderByfilterDropDown ? 'sortingFilterDropDownOpen' : 'sortingFilterDropDownClose')}>
                                    {(
                                        (Array.isArray(orderByFilter) && orderByFilter.length > 0 ?
                                            <React.Fragment>
                                                {orderByFilter.map((filter, index) => {
                                                    if (filter.type == "staying" && filtersVal.selectGuestType == "staying" || filter.type == "") {
                                                        return (
                                                            <li key={index} onClick={(e) => { this.props.selectFiltes(filter, "orderBy"); this.OrderByToggle(e) }}>
                                                                <span className={"drlabelValueFirst " + filter.color}>{filter.color_d_name}</span>
                                                                <span className="drlabelValuesecond">{filter.display_name}</span>
                                                            </li>
                                                        )
                                                    } else if (filter.type == "withoutStay" && filtersVal.selectGuestType != "staying") {
                                                        return (
                                                            <li key={index} onClick={(e) => { this.props.selectFiltes(filter, "orderBy"); this.OrderByToggle(e) }}>
                                                                <span className={"drlabelValueFirst " + filter.color}>{filter.color_d_name}</span>
                                                                <span className="drlabelValuesecond">{filter.display_name}</span>
                                                            </li>
                                                        )
                                                    }

                                                })}
                                            </React.Fragment> :
                                            <h6>Loading..</h6>)
                                    )}
                                </ul>
                            </li>
                            <li>
                                <div className="moreFilterActionButton">
                                {enable_csv_import?
                                    <DropdownButton id="dropdown-basic-button" title=""
                                        key="right"
                                        drop="down">
                                        <div className="section-header">
                                            <div className="section-header-title">Thru CSV Upload</div>
                                            {/* <Dropdown.Item href={process.env.PUBLIC_URL + '/Sample_Reservation.csv'} target="_blank" download="Sample_Reservation.csv">
                                                Download Sample CSV
                                            </Dropdown.Item> */}
                                            <Dropdown.Item onClick={this.NoteModalToggle}>Upload CSV</Dropdown.Item>
                                           
                                        </div>
                                        {/* <div className="section-header">
                                            <div className="section-header-title">PMS Data Exchange</div>
                                            <Dropdown.Item onClick={this.dataPMSModalToggle}>Opera</Dropdown.Item>
                                        </div>
                                        <div className="section-header">
                                            <div className="section-header-title">Online Integration</div>
                                            <Dropdown.Item onClick={this.OnlineIntModalToggle}>Opera Web Services (OWS)</Dropdown.Item>
                                            <Dropdown.Item onClick={this.OnlineIntModalToggle}>MEWS</Dropdown.Item>
                                        </div> */}
                                    </DropdownButton>
                                    :""}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <CSVUploadModal
                    showModal={this.state.NoteModal}
                    onHide={this.NoteModalToggle}
                />
                <DataPMSModal
                    showNoteModal={this.state.dataPMSModal}
                    onHide={this.dataPMSModalToggle}
                />
                <OnlineIntModal
                    showNoteModal={this.state.onlineIntModal}
                    onHide={this.OnlineIntModalToggle}
                />
            </div>
        );
    }
}


export default Filters;
