import React, { Component, createRef } from "react";

import FormElements from "../formElements/FormElements";
import Switch from "react-switch";
import { formToJSON } from "../../utils";
import axios from '../../axios';
import EditorHtml from '../common/EditorHtml';
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import LoaderSpinner from "../common/LoaderSpinner";

class TermsAndCondition extends Component {
    constructor(props) {
        super(props);
        
        let covid_declarations = props.data.find(({ tag }) => tag == "covid_declarations");
        let declaration = [""];
        let checked = true;
        if (covid_declarations != undefined) {
            covid_declarations = JSON.parse(covid_declarations.text);
            declaration = (covid_declarations && covid_declarations.length > 0 ? covid_declarations : [""])
            checked = (covid_declarations && covid_declarations.length > 0 ? true :false)
        }
        this.state = {
            loading: false,
            checked: checked,
            declarations: declaration,
        };
        this.form = new createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute"
            }
        });
    }

    componentDidMount() {
        document.scrollingElement.scrollTop = 0;
    }

    addDeclaration = () => {
        let { declarations } = this.state;
        let add = declarations.every(d => d !== "");
        if (add) declarations.push("");
        this.setState({ declarations });
    }

    removeDeclaration = (index) => {
        let { declarations } = this.state;
        if (declarations.length === 1) {
            toast.error('You cannot remove all declarations.');
            return
        };
        declarations = declarations.filter((t, i) => i !== index);
        this.validator.purgeFields();
        this.setState({ declarations });
    }

    handleDeclaration = (e, i) => {
        let { value } = e.target;
        let { declarations } = this.state;
        declarations[i] = value;
        this.setState({ declarations });
    }

    handleSwitch = (checked) => {
        if (!checked) this.validator.purgeFields();
        this.setState({ checked })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ ...this.state, loading: true });
        if (this.validator.allValid()) {
            let postData = formToJSON(this.form.current);
            console.log('postData', postData);
            let { selectedOption} = this.props;
            console.log("Object.keys(postData).length==",Object.keys(postData).length)
            let updateData =[];
            Object.keys(postData).forEach(function (ele) {
                if(ele== "terms_and_conditions" ||ele=="covid_declarations" ){
                            updateData.push({tag:ele,text: Array.isArray(postData[ele])?JSON.stringify(postData[ele]):postData[ele]})
                }
                if(Object.keys(postData).length==1){
                    updateData.push({tag:"covid_declarations",text: "[]" })
                }
            }, Object.create(null));
            let updateObject = {
                lang_code: selectedOption.value,
                translations: updateData
            }
            console.log('updateObject', updateObject);
            axios.put(`/dashboard/hotel/languageSupport/`, updateObject)
                .then((data) => {
                    if (data.status == 200) {
                        toast.success('Term and condition added successfully.');
                    } else {
                        toast.error('Unable to save terms. Please try again.');
                    }
                }).catch((error) => {
                    toast.error('Unable to save terms. Please try again.');
                }).finally(() => {
                    this.setState({ ...this.state, loading: false });
                })
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false }, this.handleScroll);
        }
    }

    render() {
        let { declarations, loading } = this.state;
       
        let termAndCondition = this.props.data.find(({ tag }) => tag == "terms_and_conditions");
        let  terms_and_conditions= (termAndCondition && termAndCondition.text && termAndCondition.text !=""?termAndCondition.text:"");
        return (
            <React.Fragment>
                {loading ? <LoaderSpinner /> : null}
                <form autoComplete="off" onSubmit={this.handleSubmit} ref={this.form}>
                    <div className="signup_steps_content_wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="language_support_wrapper">
                                    <h3 className="steps_header">{this.props.heading}</h3>
                                    <button type="submit" className="grey_button saveButton">Save Changes</button>
                                </div>
                                <div className="floating-form">
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <EditorHtml
                                                validator={this.validator}
                                                term_condition={terms_and_conditions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="signup_steps_content_wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">
                                    {"COVID-19 Declarations"}
                                    <span className="switch_declaration">
                                        <Switch
                                            onChange={this.handleSwitch}
                                            checked={this.state.checked}
                                            onHandleColor="#fff"
                                            onColor="#1772ad"
                                            handleDiameter={10}
                                            //offColor="rgba(0,0,0,0.3)"
                                            width={32}
                                            height={18}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                        />
                                    </span>
                                </h3>
                                {this.state.checked
                                    ? (<div className="floating-form">
                                        {/* <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <textarea placeholder="Add an introductory text.."></textarea>
                                            </div>
                                        </div> */}
                                        <div className="yes_questions">
                                            {"Yes/No Questions"}
                                        </div>
                                        {declarations.map((d, i) => {
                                            let last = i == declarations.length - 1;
                                            return (
                                                <div key={i} className="form_element_wrapper">
                                                    <div className="form_element">
                                                        <FormElements
                                                            type="text"
                                                            label={last ? "Add Question" : ""}
                                                            value={d}
                                                            maxLength={200}
                                                            validator={this.validator}
                                                            validations="required"
                                                            validatorOptions={{ messages: { required: "Please enter covid declaration." } }}
                                                            name={`covid_declarations[${i}]`}
                                                            disableEnter
                                                            onChange={(e) => this.handleDeclaration(e, i)}
                                                        />
                                                    </div>
                                                    <span className="close_btn" onClick={() => this.removeDeclaration(i)}><img src="/images/close.svg" alt="close" /></span>
                                                </div>
                                            );
                                        })}
                                        <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <div className="addmore_btn">
                                                    <button type="button" disabled={declarations[declarations.length - 1] == ""} onClick={this.addDeclaration}>Add More <img src='/images/iconbtn-plus.svg' alt="add icon" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        hotel: state.hotel
    }
}

export default connect(mapStateToProps)((TermsAndCondition));