import React from "react";
import Header from './common/Header';
import SignupHeader from "./common/SignupHeader";

class Layout extends React.Component {
    render() {
        let props = this.props;
        return (
            <div className={`clearfix content-area ${props.isSignUP ? "signup-area" : ""}`}>
                {props.isSignUP && !props.dashboardBtn
                    ? <SignupHeader steps={props.steps} isLogoutBtn={props.isLogoutBtn} />
                    : <Header 
                        dashboardBtn={props.dashboardBtn} 
                        searchHandle={props.searchHandle} 
                        searchValue ={props.searchValue}
                       />
                }
                {props.children}
            </div>
        )
    }
}

export default Layout;
