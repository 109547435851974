import { constants } from "../constants/index";
import { getHotelData,getGuestDetail,hotelIntegrationFun } from "../services/index";
import { getRedirectPage,getCurrentSession } from "../utils";
import { setKey } from "./commonActions";
import { logoutUser } from "./authAction";

export const getHotelDetails = (history) => {
  return (dispatch) => {
    getCurrentSession().then((data) => {
      if (data) {
        getHotelData()
          .then((response) => {
            
            if (response.status == 200) {
              let dataval = response.data
              if (response && dataval?.data) {
                let data = dataval.data;
                dispatch(setKey('hotel', data)).then(async ()=>{
                 let redirectPath = await getRedirectPage(data.step_completed, history);
                  console.log("After login redirecting to: ", redirectPath);
                  if (redirectPath) {
                    history.push(redirectPath);
                  }
                })
              } else {
                history.push("/hotelinfo");
                  dispatch(setKey('hotel', false));
              }
            } else {
              history.push("/hotelinfo");
               dispatch(setKey('hotel', false));
            }
          }).catch(function (error) {
            // dispatch({ type: constants.FETCH_HOTEL_FAILED, error });
            dispatch({ type: constants.FAILED_RESPONSE, data: error });
            // history.push("/hotelinfo");
          });
      } else {
        dispatch(logoutUser());
      }
    })
  };
};



export const getGuestDetails = (history) => {
  return (dispatch) => {
    getGuestDetail()
          .then(async (response) => {
            if (response.status == 200) {
                let {data} = response.data;
                dispatch(setKey('guestDetails', data));
  
            } 
          }).catch(function (error) {
            dispatch(setKey('guestDetails', []));
            // dispatch({ type: constants.FETCH_HOTEL_FAILED, error });
            // dispatch({ type: constants.FAILED_RESPONSE, data: error });
          });
    
  };
};


export const hotelIntegrations = () => {
  return (dispatch) => {
    hotelIntegrationFun()
          .then(async (response) => {
            if (response.status == 200) {
                let {data} = response.data;
                if(response.status)dispatch(setKey('hotelIntegration', data));
  
            } 
          }).catch(function (error) {
            dispatch(setKey('hotelIntegration', []));
            // dispatch({ type: constants.FETCH_HOTEL_FAILED, error });
            // dispatch({ type: constants.FAILED_RESPONSE, data: error });
          });
    
  };
};
