import React from "react";
import PropTypes from "prop-types";

function FormElements(props) {
  const onKeyPress = (e) => {
    if (props.disableEnter && e.key === 'Enter') e.preventDefault();
    if (props.onKeyPress) props.onKeyPress(e);
  }

  return (
    <div className="floating-label">
      <input className="floating-input"
        type={props.type}
        disabled={props.disabled}
        readOnly={props.readOnly}
        value={props.value}
        min={props.min}
        max={props.max}
        maxLength= {props.maxLength!=null?props.maxLength:""}
        required={props.required}
        name={props.name}
        onKeyPress={onKeyPress}
        autoComplete="off"
        placeholder={props.placeholder ? props.placeholder : " "}
        formNoValidate
        onChange={props.onChange}
        onBlurCapture ={props.onBlur}
      />
      <label className={props.errorMessage && props.errorMessage.length > 0 ? "error-text" : ""}>{props.label}</label>
      <div className="error-text">
        {props.errorMessage && props.errorMessage.length > 0 ? (<span className="errorMessage">{props.errorMessage}</span>) : null}
        {props.validator !== false ? props.validator.message(props.name, props.value, props.validations, props.validatorOptions) : null}
      </div>
    </div>
  );
}

FormElements.defaultProps = {
  disabled: false,
  readOnly: false,
  validator: false,
  validations: false,
  validatorOptions: {},
  maxLength: 100
}

FormElements.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validatorOptions: PropTypes.object.isRequired
};

export default FormElements;
