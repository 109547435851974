import React, { Component, createRef, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";

import withAuth from "../../lib/auth";
import { formToJSON} from "../../utils";
import FormElements from '../formElements/FormElements';
import Select from 'react-select';

import axios from '../../axios';

import TeamMemberEdit from "./TeamMemberEdit";
import SignupStepLayout from '../SignupStepLayout';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import CheckMemberModal from "../modal/CheckMemberModal";
import LoaderSpinner from "../common/LoaderSpinner";
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import countryPhoneCode from '../../utils/countryPhoneCode.json';
import Flag from "react-world-flags";
import ConfirmModal from '../modal/ConfirmModal';
import { toast } from 'react-toastify';


class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            verifyModal: false,
            myprofile: {},
            members: [],
            formData: {
                user_email: ""
            },
            redirect: false,
            email_response: [],
            countryCodeOption:"",
            confirmModal:false,
            deleteUser:{
                index:"",
                userDetails:{}
            }
        }
        this.form = new createRef();
        this.validator = new SimpleReactValidator({
            messages: {
                required: "Please enter :attribute",
                email: "Please enter a valid email address"
            },
            validators: {
                phone_number: {
                    message: 'Please enter a valid phone number',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0-9]{4,15}$/i);
                    }
                }
            }
        });
    }

    verifyModalToggle = () => {
        this.setState({ verifyModal: !this.state.verifyModal });
    }

    async componentDidMount() {
        let {hotel} = this.props
        if (hotel) {
            let { country_code } = hotel;
            this.setState({
                ...this.state,
                countryCodeOption: await this.getCountryCodeOption(country_code)
            })
        }

        document.scrollingElement.scrollTop = 0;
        let currentUserAttributes = this.props.currentUserAttributes;
        this.getTeamMambers();
        if (currentUserAttributes) {
            let email = currentUserAttributes.find(({ Name }) => Name === "email");
            let is_admin = currentUserAttributes.find(({ Name }) => Name === "custom:is_admin");
            let myprofile={};
            myprofile = { email: email.Value, role:is_admin.Value };
            this.setState({
                ...this.state,
                myprofile
            })
        }
    }

    getTeamMambers = _ =>{
         axios.get('/dashboard/hotel/team')
        .then(res => {
            console.log(res);
            if (res.status === 200) {
                let api_response = res.data;
                let {  data } = api_response;
               if (Array.isArray(data) && data.length > 0) {
                    let members = [];
                    let {formData} = this.state;
                    data.forEach((element,index) => {
                        let phoneNumber = parsePhoneNumberFromString(element.phone_number);
                        if (phoneNumber != undefined) {
                            members.push({
                                cognito_user_id:element.cognito_user_id,
                                is_admin:element.is_admin,
                                phone_number: phoneNumber.nationalNumber,
                                newAdded:false
                            });
                            formData[`team[${index}][email]`] =element.cognito_user_id;
                            formData[`team[${index}][phone_number]`] = phoneNumber.nationalNumber;
                            formData[`team[${index}][country_code]`] ="+"+phoneNumber.countryCallingCode;
                            formData[`team[${index}][is_admin]`] =element.is_admin;
                      
                        }
                       
                });
                this.setState({
                    ...this.state,
                    members,
                    formData
                })
                }
            } else {
                console.log(res);
            }
        })
        .catch(console.error)
        .finally(() => this.setState({ ...this.state, loading: false }));
    }


    handleSelectChange = (selectObj) =>{
        let {  value } = selectObj;
        this.validator.showMessageFor("admin_role");
        this.setState({
            ...this.state,
            myprofile:{
                ...this.state.myprofile,
                role:value
            }
        })
    }
 
    handleInputChange = (e) => {
        let { name, value } = e.target;
        this.validator.showMessageFor(name);
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [name]: value
            }
        })
    }

    // handleInputChange = (index,e) => {
    //    let { name, value } = e.target;
    //     this.validator.showMessageFor(name);
    //     if(index>=0){
    //         let state = this.state;
    //         let { members } = state;
    //          if(name.indexOf('is_admin')!=-1){
    //             members[index].is_admin=parseInt(value.value);
    //         }
    //         if(name.indexOf('email')!=-1){
    //             members[index].cognito_user_id=value;
    //         }
    //         if(name.indexOf('phone_number')!=-1){
    //             members[index].phone_number=value;
    //         }
    //         this.setState({
    //             ...state,
    //             members
    //         })
    //     }else{
    //           this.setState({
    //             ...this.state,
    //             myprofile:{
    //                 ...this.state.myprofile,
    //                 email:value
    //             }
    //         })
    //     }
    // }

    addEmail = () => {
        let state = this.state;
        let { members } = state;
        members.push({
            cognito_user_id:"",
            is_admin:"",
            phone_number:"",
            newAdded:true
        });
        this.setState({
            ...state,
            members
        })
    }

    toggleConfirmModal = () =>
    this.setState({
        ...this.state,
        confirmModal: !this.state.confirmModal,
        deleteUser:{
            ...this.state.deleteUser,
            index:"",
            userDetails:{}
        }
    });

    confirmation = (data) => {
        let { deleteUser } = this.state;
        this.setState({
            confirmModal: true
        }, () => {
            if (Object.keys(deleteUser.userDetails) !== 0 && deleteUser.userDetails.constructor === Object) {
                let {userDetails} =deleteUser;
                let params ={
                    team:[
                        {
                            email: userDetails.cognito_user_id
                        }
                    ]
                }
                axios.delete('/dashboard/hotel/team', {data:params})
                    .then((response) => {
                        if (response.status == 200) {
                            toast.success('User deleted successfully.')
                            this.removeEmailFromArray(deleteUser.index);
                            // let { members } = this.state;
                            // members = members.filter((t, i) => i !== deleteUser.index);
                            // this.validator.purgeFields();
                            // this.setState({ members });
                            
                        } else {
                            toast.error('Error in delete user.')
                        }

                    })
                    .catch((error) => {
                        toast.error('Error in delete user.')
                    })
            }
        });
    }


    removeEmailFromArray = (index) => {
        let { members, formData } = this.state;
        let lastIndex = members.length - 1;
        members = members.filter((t, i) => i !== index);
        for (let i = index; i < lastIndex; i++) {
            if (`team[${index}][email]` in formData) {
                formData[`team[${index}][email]`] = formData[`team[${index + 1}][email]`];
                formData[`team[${index}][phone_number]`] = formData[`team[${index + 1}][phone_number]`];
                formData[`team[${index}][country_code]`] = formData[`team[${index + 1}][country_code]`];
                formData[`team[${index}][is_admin]`] = formData[`team[${index + 1}][is_admin]`];
            }
        }
        delete formData[`team[${lastIndex}][email]`];
        delete formData[`team[${lastIndex}][phone_number]`];
        delete formData[`team[${lastIndex}][country_code]`];
        delete formData[`team[${lastIndex}][is_admin]`];
        this.setState({ members, formData });
    }



    removeEmail = (index,type) => {

        if(type){
            this.removeEmailFromArray(index)
            this.validator.purgeFields();
        }else{
            let { members } = this.state;
            let deleteData = members.find((t, i) => i === index);
            this.setState({
                ...this.state,
                confirmModal: !this.state.confirmModal,
                deleteUser:{
                    ...this.state.deleteUser,
                    index:index,
                    userDetails:deleteData
                }
            });
        }
     
    }

    setResponseData = (data) => {
        let state = this.state;
        let { email_response } = state;
        if (Array.isArray(data) && data.length) {
            data.forEach(e => {
                let { email } = e;
                // check if email exist in state
                let index = email_response.findIndex(e => e.email === email);
                // console.log(email, index);
                if (index === -1) {
                    email_response.push(e);
                } else {
                    email_response[index] = e;
                }
            })
        }
        state.email_response = email_response;
        this.setState(state);
    }

    onSubmit = (e) => {
        e.preventDefault();
    
        if (this.validator.allValid()) {
            this.setState({ ...this.state, loading: true })
            let postData = formToJSON(this.form.current);
            console.log('postData==', postData);
            if (Array.isArray(postData.team)) {
                let {countryCodeOption} = this.state;
                postData.team = postData.team.map(d => {
                    d.phone_number = countryCodeOption.value + d.phone_number;
                    delete d.country_code;
                    return d;
                }).filter(t => t);
            }
            console.log('postData', postData);
            axios.put('/dashboard/hotel/team', postData)
                .then(res => {
                    console.log(res);
                    if (res.status === 200) {
                        let api_response = res.data;
                        let { status, data } = api_response;
                        if (status) {
                          toast.success("User updated successfully.")
                          this.getTeamMambers()
                        } else {
                            console.log('api_response', data);
                            this.setResponseData(data);
                        }
                    } else {
                        toast.error("Error in update user.")
                        console.log(res);
                    }
                })
                .catch(console.error)
                .finally(() => {
                    this.setState({ ...this.state, loading: false });
                });
        } else {
            this.validator.showMessages();
            this.setState({ ...this.state, loading: false });
        }
    }

  

    getCountryCodeOption = (country_code) => {
        let country = countryPhoneCode.find(c => c.code === country_code), option;
        if (country) {
            let { dial_code, code } = country;
            option = { value: dial_code, label: (<div><Flag code={code} width="30" /> {dial_code}</div>) };
        }
        return option;
    }

    getSelectTheme = (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#ecf1f5',
            primary: '#ecf1f5',
        }
    });


    render() {
        let { redirect, loading, myprofile,formData } = this.state;
        if (redirect) {
            return <Redirect to={redirect} />
        }
        let options = [{ value: '1', label: 'Admin' }, { value: '0', label: 'Front Office' }];
        let adminSelect= {};
        if(myprofile.role){
            adminSelect = (myprofile.role==1?{ value: '1', label: 'Admin' }:{ value: '0', label: 'Front Office' })
        }
      
        return (
            <SignupStepLayout>
                {loading ? <LoaderSpinner /> : null}
                <form autoComplete="off" onSubmit={this.onSubmit} ref={this.form}>
                    <div className="signup_steps_content_wrapper">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">My Profile</h3>
                                <div className="floating-form">
                                    <div className={'team_member_wrapper'}>
                                        <div className="form_element_wrapper">
                                            <div className="form_element">
                                                <FormElements
                                                    type="text"
                                                    value={myprofile.email}
                                                    disableEnter
                                                    disabled={true}
                                                    label="Email Address"
                                                    validator={this.props.validator}
                                                    validations='required|email'
                                                    validatorOptions={{ messages: { required: "Please enter email address" } }}
                                                    onChange={(e)=>this.handleInputChange("",e)}
                                                    name={"admin_email"}
                                                />
                                            </div>
                                            <div className="form_element">
                                                <div className="thru_react_select">
                                                    <label>Role</label>
                                                    <Select
                                                        options={options}
                                                        name={"admin_role"}
                                                        value={adminSelect}
                                                        isDisabled={true}
                                                        className="tax_select"
                                                        classNamePrefix="react_select"
                                                        placeholder=""
                                                        onChange={this.handleSelectChange}
                                                        theme={this.getSelectTheme}
                                                    />
                                                    <div className="error-text">
                                                        {this.validator.message("admin_role", (adminSelect.label? adminSelect.value : ""), 'required', { messages: { required: "Please select role" } })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' /></div>
                                    <div className="tip_text">
                                        <p>Edit your profile .</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.is_admin
                    ? <Fragment>
                    <div className="signup_steps_content_wrapper invite_teams">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h3 className="steps_header">Team</h3>
                                <div className="floating-form">
                                    {this.state.members.map((e, i) => {
                                        return (<TeamMemberEdit
                                            countryCodeOption={this.state.countryCodeOption}
                                            key={i}
                                            formData={formData}
                                            email_response={this.state.email_response}
                                            hotel={this.props.hotel}
                                            validator={this.validator}
                                            onRemove={this.removeEmail}
                                            disabledProps={false}
                                            onChange={this.handleInputChange}
                                            index={i}
                                            newAdded ={e.newAdded}
                                        />)
                                    })}
                                    <div className="form_element_wrapper">
                                        <div className="form_element">
                                            <div className="addmore_btn">
                                                <button type="button" onClick={this.addEmail}>Add More <img src='/images/iconbtn-plus.svg' alt="add icon" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="tip_container">
                                    <div className="tip_image"><img src='/images/status.svg' alt="status" /></div>
                                    <div className="tip_text">
                                        <p>Edit and add  your team members to give them access to your hotel’s Thru dashboard.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="continue-btn">
                        <div className="formSubmitButton">
                            <button disabled={this.state.loading} className="themeButton">
                                {this.state.loading ? "Please wait.." : "Save"}
                            </button>
                        </div>
                    </div>
                    </Fragment>
                    :null
                    }
                </form>
                <CheckMemberModal
                    showNoteModal={this.state.verifyModal}
                    onHide={this.verifyModalToggle}
                />
                 <ConfirmModal
                            title={"Confirmation"}
                            showModal={this.state.confirmModal}
                            onConfirm={this.confirmation}
                            onHide={this.toggleConfirmModal}
                            confirmBtnText="Yes"
                        >
                            <p>Would you like to delete this user?</p>
                        </ConfirmModal>
            </SignupStepLayout>
        );
    }
}

const mapStateToProps = state => ({
    hotel: state.hotel,
    currentUserAttributes:state.currentUserAttributes,
    is_admin: state.is_admin
})

export default connect(mapStateToProps)(withAuth(withRouter(Account)));