import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

class NeedHelp extends PureComponent {
  render(){
    return (
      <div className="help_container">
        <div className="tip_container">
          <div className="tip_title">Need Help?</div>
          <div className="tip_text">
            <p>Feel free to <a href="mailto:sales@thru.app?subject=Contact Support">get in touch</a> with us and we will be happy to assist.</p>
          </div>
        </div>
      </div>
    );
  }
}

NeedHelp.propTypes = {

};

export default NeedHelp;