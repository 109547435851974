import config from './app-config';
import axios from 'axios';
import { Auth } from 'aws-amplify';

let ax = axios.create({
    baseURL: config.environment.api_baseurl,
    headers: {
        common: config.environment.headers,
        post: {
            'Content-Type': config.environment.headers["Content-Type"]
        }
    }
});

ax.interceptors.request.use(
    async config => {
        let token = (await Auth.currentSession()).getIdToken().getJwtToken();
        if (token) {
            config.headers.authorization = 'Bearer ' + token;
        }
        return config;
    },
    error => Promise.reject(error)
);

export default ax;