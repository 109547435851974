import React from "react";
import { Modal } from 'react-bootstrap';
import FormElements from '../formElements/FormElements';

class CheckoutInviteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Modal
                show={this.props.showCheckoutInviteModal}
                onHide={this.props.onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Checkout Invite</Modal.Title>
                    <div className="modalPopupClose clearfix" onClick={this.props.onHide}>
                        <img src="/images/popup_close.png" alt="close" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="inviteModalData clearfix">
                        <div className="formfieldNumber clearfix floating-form">
                            <FormElements
                                name="phone_number"
                                type="number"
                                label="Phone Number"
                                onChange={() => { }}
                            />
                        </div>
                        <div className="formfieldNumber clearfix floating-form">
                            <FormElements
                                name="balance_due"
                                type="number"
                                label="Balance Due"
                                onChange={() => { }}
                            />
                        </div>                      
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="themeButton">Send to Guest</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CheckoutInviteModal;