import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';

// import FormElements from '../../formElements/FormElements';
import CardSetupForm from './CardSetupForm';
import Loader from "../../common/Loader";

class AddCardModal extends React.Component {

    render() {
        return (
            <Modal
                show={this.props.showInviteModal}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Card Information </Modal.Title>
                    <div className="modalPopupClose clearfix" onClick={this.props.onHide}>
                        <img src='/images/popup_close.png' alt="close" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {this.props.configs && this.props.stripePromise
                        ? (<Elements stripe={this.props.stripePromise}>
                            <CardSetupForm
                                showConfirmModal={this.props.showConfirmModal}
                                onHide={this.props.onHide}
                                fetchPaymentMethods={this.props.fetchPaymentMethods}
                                configs={this.props.configs}
                            />
                        </Elements>)
                        : <Loader/>
                    }
                    {/* <div className="add_card_modal clearfix">
                        <div className="clearfix floating-form">
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="Name on Card" />
                                </div>
                            </div>
                            <div className="form_element_wrapper">
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="Card Number" />
                                </div>
                            </div>
                            <div className="form_element_wrapper">
                                <div className="form_element width-month">
                                    <FormElements
                                        type="text"
                                        label="MM" />
                                </div>
                                <div className="form_element width-250">
                                    <FormElements
                                        type="text"
                                        label="YYYY" />
                                </div>
                                <div className="form_element">
                                    <FormElements
                                        type="text"
                                        label="Security Code" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Modal.Body>
                {/* <Modal.Footer>
                    <button className="themeButton">Save</button>
                </Modal.Footer> */}
            </Modal>
        );
    }
}

export default AddCardModal;