import React from 'react';

export default function Loader() {
    return(
        <div className="loadingio-spinner-spinner-degwbik4ii">
            <div className="ldio-qs451jwlpr">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
        </div>
    );
}