export const constants = {

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',

    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILED: 'LOGOUT_FAILED',

    // GET_CURRENT_USER_DATA_REQUEST: 'GET_CURRENT_USER_DATA_REQUEST',
    // GET_CURRENT_USER_DATA_SUCCESS: 'GET_CURRENT_USER_DATA_SUCCESS',
    // GET_CURRENT_USER_DATA_FAILED : 'GET_CURRENT_USER_DATA_FAILED',

    GET_RESERVATION_REQUEST: 'GET_RESERVATION_REQUEST',
    GET_RESERVATION_SUCCESS: 'GET_RESERVATION_SUCCESS',
    GET_RESERVATION_FAILED: 'GET_RESERVATION_FAILED',

    GET_BALANCE_SUCCESS: 'GET_BALANCE_SUCCESS',

    USER_SIGNUP_REQUEST: 'USER_SIGNUP_REQUEST',
    // USER_SIGNUP_SUCCESS: 'USER_SIGNUP_SUCCESS',
    USER_SIGNUP_FAILED: 'USER_SIGNUP_FAILED',

    VARIFY_EMAIL_AND_PHONE_REQUEST: 'VARIFY_EMAIL_AND_PHONE_REQUEST',
    VARIFY_EMAIL_AND_PHONE_SUCCESS: 'VARIFY_EMAIL_AND_PHONE_SUCCESS',
    VARIFY_EMAIL_AND_PHONE_FAILED: 'VARIFY_EMAIL_AND_PHONE_FAILED',

    ARRIVED_SUCCESS_FAILED: 'ARRIVED_SUCCESS_FAILED',
    ARRIVED_REQUEST: 'ARRIVED_REQUEST',

    FAILED_RESPONSE: 'FAILED_RESPONSE',

    CSV_DATA_ADD: 'CSV_DATA_ADD',
    CSV_DATA_REMOVE: 'CSV_DATA_REMOVE',

    SET_KEY: 'SET_KEY',
    CLEAR_STATE: "CLEAR_STATE"
}