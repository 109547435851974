import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import Loader from "../common/Loader";

export default function ShowIdModal(props) {

    let [guestDocuments, setGuestDocuments] = useState([]);
    let [showModal, setShowModal] = useState(false);
    let [loading, setLoading] = useState(false);

    const downloadIds = _ => {
        if (Array.isArray(guestDocuments) && guestDocuments.length > 0) {
            guestDocuments.forEach((data, index) => {
                if (data.docid_front_url) {
                    let downloadLink = document.createElement('a');
                    downloadLink.download = data.docid_type + `_document_front.jpg`;
                    downloadLink.href = data.docid_front_url;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();
                }
                if (data.docid_back_url) {
                    let downloadLink = document.createElement('a');
                    downloadLink.download = data.docid_type + `_  document_back.jpg`;
                    downloadLink.href = data.docid_back_url;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    downloadLink.remove();
                }
            });
        }
    }
    const getData = (url) => {
        return new Promise((resolve, reject) => {
            fetch(url, {
                mode: 'cors'
            })
                .then(res => res.blob())
                .then(res => {
                    console.log("res 2", res);
                    const reader = new FileReader();
                    reader.readAsDataURL(res);
                    reader.onloadend = function () {
                        var base64data = reader.result;
                        resolve(base64data);
                    }
                })
                .catch(err => {
                    console.log("Error in fetch document front image", err);
                });
        })
    }
    const  mapOrder = (a, order, key) =>{
        const map = order.reduce((r, v, i) => ((r[v] = i), r), {})
        return a.sort((a, b) => map[a[key].toLowerCase()] - map[b[key].toLowerCase()])
      }
    const fetchDocuments = async _ => {
        let { documents } = props;
        let allDocument = [];
        if (Array.isArray(documents) && documents.length > 0) {
            documents.forEach(async (ids,index) => {
                if (ids.docid_front_url != null || ids.docid_back_url != null) {
                    ids.docid_front_url = ids.docid_front_url != null ? await getData(ids.docid_front_url) : null;
                    ids.docid_back_url = ids.docid_back_url != null ? await getData(ids.docid_back_url) : null;
                    allDocument.push(ids)
                }
                Promise.all(allDocument).then(allDocs => {
                    console.log("allDocs==", allDocs);
                    if (Array.isArray(allDocs) && allDocs.length > 0) {
                        setGuestDocuments(mapOrder(allDocs,['passport',"visa"],"docid_type"));
                    }
                })
                if(index==(documents.length-1)){
                    setLoading(false)
                }
            });
        } else {
            setLoading(false)
            setGuestDocuments([]);
           
        }

    }

    useEffect(_ => {
        setShowModal(props.showIDModal)
        setLoading(true);
        fetchDocuments();
    }, [props.documents, props.showIDModal]);

    // const ref = React.createRef();
    return (
        <Modal
            show={showModal}
            // onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">{props.idShowPopupName}</Modal.Title>
                <div className="modalPopupClose clearfix" onClick={(e) => props.showIdModalToggle([], "")}>
                    <img src='/images/popup_close.png' alt="close" />
                </div>
            </Modal.Header>
            <Modal.Body >
                {
                    loading ? <Loader/> :
                        <React.Fragment>
                            {(guestDocuments.length > 0) ?
                                <React.Fragment>
                                    {guestDocuments.map((data, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="text-center" >
                                                    <h4 className="memberName"><b>{data.docid_type?
                                                        data.docid_type.charAt(0).toUpperCase() + data.docid_type.slice(1)
                                                        : ""}</b></h4>
                                                </div>
                                                {data.docid_front_url != null ?
                                                    <React.Fragment>
                                                        <div className="modalimageid clearfix" >
                                                            <img src={data.docid_front_url} alt="not found" />
                                                        </div>
                                                    </React.Fragment> : ""}

                                                {data.docid_back_url ?
                                                    <React.Fragment>
                                                        <div className="modalimageid clearfix">
                                                            <img src={data.docid_back_url} alt="not found" />
                                                        </div>
                                                    </React.Fragment> : ""}
                                            </div>
                                        )
                                    })}
                                </React.Fragment> :
                                <React.Fragment>
                                    <div className="modalimageid clearfix">
                                        No Document Available..
                      </div>
                                </React.Fragment>}
                        </React.Fragment>
                }


            </Modal.Body>

            {(guestDocuments.length > 0) ?
                <Modal.Footer>
                    <React.Fragment>
                        <div className="actionbutton d-inline" onClick={downloadIds}>
                            <button className="themeButton" >Download ID Card</button>
                        </div>
                    </React.Fragment>
                </Modal.Footer>
                : null

            }

        </Modal>
    );
}