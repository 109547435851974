import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AcceptedIDWrapper extends PureComponent {
  render(){
    return (
      <div className="header_detail_wrapper accepted_content">
        <div className="acceptedIDwrapper">
          <div className="form_element">
            <label className={"container_check" + (this.props.readOnly ? " readonly-check" : "")}>
              {this.props.identity.document_type}
              <input type="checkbox"
                onChange={this.props.onChange}
                readOnly={this.props.readOnly}
                checked={this.props.checked}
              />
              <span className="checkmark_check" />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

AcceptedIDWrapper.defaultProps = {
  readOnly: false
}

AcceptedIDWrapper.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  identity: PropTypes.shape({
    document_type: PropTypes.string,
    document_id: PropTypes.number,
    country_id: PropTypes.number
  }),
};

export default AcceptedIDWrapper;