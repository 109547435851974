import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormElements from '../formElements/FormElements';
import Select from 'react-select';
import Flag from "react-world-flags";
import countryPhoneCode from '../../utils/countryPhoneCode.json';

class TeamMemberEdit extends Component {
    constructor(props) {
        super(props);
        let country_code_options = countryPhoneCode.map(d => ({ value: d.dial_code, label: (<div><Flag code={d.code} width="30" /> {d.dial_code}</div>) }));
        this.state = {
            country_code_options: country_code_options
        }
    }

    componentDidMount() {
        let {index } = this.props;
        let country_code_name = `team[${index}][country_code]`;
        let state = this.state;
        state[country_code_name] = this.props.countryCodeOption;
        this.setState(state);
    }

    getCountryCodeOption = (country_code) => {
        let country = countryPhoneCode.find(c => c.code === country_code), option;
        if (country) {
            let { dial_code, code } = country;
            option = { value: dial_code, label: (<div><Flag code={code} width="30" /> {dial_code}</div>) };
        }
        return option;
    }

    handleChange = (e) => {
        let { name, value } = e.target;
        this.props.onChange(e);
        this.setState({
            ...this.state,
            [name]: value
        });
    }

    handleSelect = (e, target) => {
        let { name } = target;
        this.props.onChange(this.props.index,{ target: { name: name, value: e } });
        this.setState({
            ...this.state,
            [name]: e
        });
    }

    getSelectTheme = (theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
            ...theme.colors,
            primary25: '#ecf1f5',
            primary: '#ecf1f5',
        }
    });

    getMessage = () => {
        let { email_response, formData, index,newAdded } = this.props;
        let returnHTML = [];
        let name = `team[${this.props.index}][email]`;
        if (Array.isArray(email_response) && email_response.length && formData[name]) {
            let index = email_response.findIndex(e => e.email === this.props.formData[name]);
            if (index !== -1) {
                let { message, status } = email_response[index];
                returnHTML.push(<div key="status" className="verify_wrapper">
                    <span className={`status ${status ? 'verified' : 'not_verified'}`}>{message}</span>
                </div>)
                if (status === false) {
                    returnHTML.push(<span key="close" className="close_btn" onClick={() => this.props.onRemove(index,newAdded)}>
                        <img src="/images/close.svg" alt="close" />
                    </span>)
                }
            } else {
                returnHTML.push(<span key="close" className="close_btn" onClick={() => this.props.onRemove(index,newAdded)}>
                    <img src="/images/close.svg" alt="close" />
                </span>)
            }
        } else {
            returnHTML.push(<span key="close" className="close_btn" onClick={() => this.props.onRemove(index,newAdded)}>
                <img src="/images/close.svg" alt="close" />
            </span>)
        }
        return returnHTML;
    }


    getDisabledState = () => {
        let disabled = false;
        let { email_response, formData } = this.props;
         let name = `team[${this.props.index}][email]`;
        if (Array.isArray(email_response) && email_response.length && formData[name]) {
            let index = email_response.findIndex(e => e.email === this.props.formData[name]);
            if (index !== -1) {
                disabled = email_response[index]['status'];
            }
        }
        return disabled;
    }

    render() {
        let { index,formData,newAdded} = this.props;
        this.name = `team[${this.props.index}][email]`;
        let role_name = `team[${index}][is_admin]`;
        this.phone_name = `team[${this.props.index}][phone_number]`;
        let country_code_name = `team[${index}][country_code]`;
        let options = [{ value: '1', label: 'Admin' }, { value: '0', label: 'Front Office' }];
        let selectOption = formData.is_admin==1?{ value: '1', label: 'Admin' }: { value: '0', label: 'Front Office' }
        let disabled = this.getDisabledState();
        return (
            <div className={'team_member_wrapper'}>
                <div className="form_element_wrapper ">
                    <div className={"form_element " + (newAdded ? "" : ' disabled')} >
                    <FormElements
                            type="text"
                            value={this.props.formData[this.name]}
                            disableEnter
                            // disabled={disabled}
                            label="Email Address"
                            validator={this.props.validator}
                            validations='required|email'
                            validatorOptions={{ messages: { required: "Please enter email address" } }}
                            onChange={(e)=>newAdded?this.handleChange(e):null}
                            name={this.name}
                        />
                    </div>
                    {this.getMessage()}
                </div>
                <div className="form_element_wrapper">
                <div className="form_element">
                        <div className="country_phone_wrapper">
                            <div className="floating-label">
                                <input className="floating-input"
                                    type='text'
                                    value={this.props.formData[this.phone_name]}
                                    name={this.phone_name}
                                    autoComplete="off"
                                    placeholder='Phone Number'
                                    onChange={(e)=>this.handleChange(e)}
                                />
                                <div className="error-text">
                                    {this.props.validator.message(this.phone_name, this.props.formData[this.phone_name], 'required|phone_number', { messages: { required: "Please enter phone number" }})}
                                </div>
                            </div>
                            <div className="country_phone">
                                <Select
                                     value={this.state[country_code_name]}
                                    onChange={this.handleSelect}
                                    options={this.state.country_code_options}
                                    name={country_code_name}
                                    isDisabled={true}
                                    className="language_select"
                                    classNamePrefix="react_select"
                                    theme={this.getSelectTheme}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form_element">
                        <div className="thru_react_select">
                            <label>Role</label>
                            <Select
                                options={options}
                                name={role_name}
                                value={selectOption}
                                disabled={disabled}
                                className="tax_select"
                                classNamePrefix="react_select"
                                placeholder=""
                                onChange={this.handleSelect}
                                theme={this.getSelectTheme}
                            />
                            <div className="error-text">
                                {this.props.validator.message(role_name, (role_name ? selectOption.value : ""), 'required', { messages: { required: "Please select role" } })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

TeamMemberEdit.propTypes = {
    index: PropTypes.number.isRequired,
    hotel: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired
}

export default TeamMemberEdit
