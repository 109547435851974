import React from "react";
import Layout from "../components/Layout";
import FormElements from "../components/formElements/FormElements";
import Select from "react-select";
import { connect } from "react-redux";
import { userSignUp } from "../actions";
import countryPhoneCode from "../utils/countryPhoneCode.json";
import Flag from "react-world-flags";
import { checkValidation } from "../lib/formValidation";
import { withRouter } from 'react-router-dom';
import GeneralModal from "../components/modal/GeneralModal";
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import SignupModal from "../components/modal/SignupModal";

const options_phone = countryPhoneCode.map((d) => ({
  value: d.dial_code,
  label: (
    <div>
      <Flag code={d.code} width="30" /> {d.dial_code}
    </div>
  ),
}));

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      privacyModal: false,
      serviceModal: false,
      signupModal: false,
      selectedOption: {
        value: "+91",
        label: (
          <div>
            <Flag code="IND" width="30" /> {"+91"}
          </div>
        ),
      },
      validationData: {
        password: "",
        email: "",
        phone_number: "",
        formErrors: {
          password: "",
          email: "",
          phone_number: "",
        },
      },
      signupReqestObj: {
        username: "",
        password: "",
        attributes: {
          email: "",
          phone_number: "",
          'custom:is_admin': "1"
        },
      },
    };
  }

  togglePrivacyModal = () => this.setState({ privacyModal: !this.state.privacyModal });

  toggleServiceModal = () => this.setState({ serviceModal: !this.state.serviceModal });

  toggleSignupModal = () => this.setState({ signupModal: !this.state.signupModal });

  formValid = ({ formErrors, ...rest }) => {

    let valid = true;
    Object.entries(formErrors).forEach(([key, value]) => {
      if (value.length > 0) {
        return (valid = false);
      }
    });

    // if (valid) {
      Object.entries(rest).forEach(async ([key, value], index) => {
        if (value.length <= 0) {
          await this.updateValidFormState(key, value);
          return (valid = false);
        }
      });
    // }
    return valid;
  };

  updateValidFormState = async (name, value) => {
    let formErrors = this.state.validationData.formErrors;
    let formValidationData = await checkValidation(name, value, formErrors);
    this.setState({
      ...this.state,
      validationData: {
        ...this.state.validationData,
        formErrors: formValidationData,
        [name]: value,
      },
    });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
    let { phone_number } = this.state.validationData;
    let phoneNumber = parsePhoneNumberFromString(phone_number);
    if (phoneNumber != undefined) {
      let value = selectedOption.value + phoneNumber.nationalNumber;
      this.updateValidFormState("phone_number", value);
    }
  };

  handleBlur =(e)=>{
    let { name } = e.target;
    let value = e.target.value;
    if (name == "phone_number") {
      let phone_code = this.state.selectedOption.value;
        value = phone_code + value;
      this.updateValidFormState(name, value);
    }else{
      this.updateValidFormState(name, value);
    }
    
  }

  handleChangeInput = (e) => {
    let { name } = e.target;
    let value = e.target.value;

    if (name == "password") {
      this.setState({
        ...this.state,
        signupReqestObj: {
          ...this.state.signupReqestObj,
          [name]: value,
        },
      });
    } else {
      if (name == "phone_number") {
        let phone_code = this.state.selectedOption.value;
        value = phone_code + value;
        this.setState({
          ...this.state,
          signupReqestObj: {
            ...this.state.signupReqestObj,
            attributes: {
              ...this.state.signupReqestObj.attributes,
              [name]: value,
            },
          },
        });
      } else
        if (name == "email") {
          this.setState({
            ...this.state,
            signupReqestObj: {
              ...this.state.signupReqestObj,
              "username": value,
              attributes: {
                ...this.state.signupReqestObj.attributes,
                [name]: value,
              },
            },
          });
        }
    }
  };

  userSignupHandle = (e) => {
    e.preventDefault();
    if (this.formValid(this.state.validationData)) {
      let { loading } = this.props;
      if (!loading) {
        let signUpData = this.state.signupReqestObj;
        this.props.dispatch(userSignUp(signUpData, this.props.history));
      }
    }
  };

  render() {
    let { loading } = this.props;
    const { selectedOption } = this.state;
    let formErrors = this.state.validationData.formErrors;
    return (
      <Layout isSignUP >
        <div className="signup_container">
          {/* Sign Up Form */}
          <div className="signup_form">
            <div className="signup_form_content">
              <h1>Get Started</h1>
              <h3>Your first 25 Thru credits are on us.</h3>
              <form
                autoComplete="off"
                method="post"
                onSubmit={this.userSignupHandle}
              >
                <div className="floating-form">
                  {/* <div className="username_signup">
                    <FormElements
                      type="text"
                      label="Business Username"
                      name="username"
                      errorMessage={formErrors.username}
                      onChange={this.handleChangeInput}
                    />
                    <span className="avaailable_badge_signup">available</span>
                  </div> */}
                  <div className="email_signup">
                    <FormElements
                      type="text"
                      name="email"
                      errorMessage={formErrors.email}
                      label="Email Address"
                      onChange={this.handleChangeInput}
                      onBlur ={this.handleBlur}
                    />
                    {/* <span className="check_email"><img src='/images/check.svg' width="28" /></span> */}
                  </div>
                  <ButtonToolbar>
                    {['top'].map(placement => (
                      <OverlayTrigger
                        key={placement}
                        placement={placement}
                        overlay={
                          <Tooltip id={`tooltip-${placement}`}>
                            <div>
                              <ul>
                                <li>Minimum Length of 8 characters</li>
                                <li>Numerical character (0-9)</li>
                                <li>Special character</li>
                                <li>Uppercase letter</li>
                                <li>Lowercase letter</li>
                              </ul>
                            </div>
                          </Tooltip>
                        }
                      >
                        <div className="password_signup">
                          <FormElements
                            type="password"
                            name="password"
                            errorMessage={formErrors.password}
                            label="Password"
                            onChange={this.handleChangeInput}
                            onBlur ={this.handleBlur}
                          />
                          {/* <span className="check_email"><img src='/images/check.svg' width="28" /></span> */}
                        </div>
                      </OverlayTrigger>
                    ))}
                  </ButtonToolbar>

                  <div className="country_phone_wrapper">
                    <FormElements
                      type="text"
                      name="phone_number"
                      label=""
                      placeholder="Phone Number"
                      errorMessage={formErrors.phone_number}
                      onChange={this.handleChangeInput}
                      onBlur ={this.handleBlur}
                    />
                    <div className="country_phone">
                      <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options_phone}
                        className="language_select"
                        classNamePrefix="react_select"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "#ecf1f5",
                            primary: "#ecf1f5",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="formSubmitButton">
                  <button type="submit" className="themeButton">
                    {loading ? "Loading.." : "Create Account"}
                  </button>
                </div>
              </form>
              <div className="privacy_policy">
                By creating an account, you agree to our <a onClick={this.toggleServiceModal}>Terms of Service</a>{" "}
                and <a onClick={this.togglePrivacyModal}>Privacy Policy</a>.
              </div>
            </div>
            <div className="signup_process">
              <div className="signup_process_wrapper">
                This one-time sign up process, which takes less than <b>10 minutes</b>, will ensure a seamless experience for team members.
                <div><a onClick={this.toggleSignupModal}>Sign Up Process Preview -&gt;</a></div>
              </div>
            </div>
          </div>

        </div>
        <GeneralModal
          title={"Privacy and Data Policy"}
          showModal={this.state.privacyModal}
          onHide={this.togglePrivacyModal}
        >
          <div className="privacy_policy_container">
            <p>
              <h2>1. Application of this Privacy and Data Policy</h2>
              <div>

                <p>
                  a) This is the ‘Privacy and Data Policy’ of Paragon Digital Lifestyle Inc. and its parent company Paragon
                  Business Solutions Ltd. (together referred to by the brand name “Thru”) in relation to the use of Thru’s
                  Platform by you. This Privacy and Data Policy is in addition to the other terms agreed upon between Thru and
                  you and by accepting the General Terms and Conditions or Thru Software as a Service (SaaS) Agreement for
                  Hotels, you also agree to and accept this Privacy and Data Policy. The capitalized terms used in this
                  Privacy and Data Policy shall have the same meaning as defined in the General Terms and Conditions (if you
                  are a Guest) or Thru Software as a Service (SaaS) Agreement for Hotels (if you are a Hotel), unless the said
                  terms are specifically defined herein.
          </p>
                <p>
                  b) Thru respects your privacy and is committed to protecting it through its compliance with its Privacy and
                  Data Policy. This Policy describes (i) the types of information that Thru may collect from you when you
                  access or use its Platform, (ii) the manner in which Thru will use the information collected from you, (iii)
                  the manner in which Thru will share the information collected from you with third parties, and (iv) the way
                  in which you can manage the information collected by Thru from you.
          </p>
                <p>
                  c) This policy applies only to the information Thru collects through its Platform and does not apply to
                  information that you provide to, or that is collected by any a third-party when you visit that third-party’s
                  platform or website by clicking on links available on Thru’s Platform. Thru encourages you to consult
                  directly with such third parties about their privacy practices.
          </p>
                <p>
                  d) Please read this Privacy and Data Policy carefully. By accessing or using the Platform, you agree to this
                  Privacy and Data Policy and you consent to Thru’s collection, use, disclosure, retention, storage and
                  protection of your personal information as described herein.
          </p>
                <p>
                  e) This Privacy and Data Policy may change from time to time and you are requested to check this Privacy and
                  Data Policy periodically for updates. Your continued use of Thru’s Platform after it makes any change to
                  this Privacy and Data Policy, shall be deemed to be your acceptance to those changes.

          </p>

              </div>
            </p>
            <p>
              <h2>2. Information we collect from you</h2>
              <div>Thru collects certain information from you to provide its services to you. This information is described
        below:</div>

              <p><h3>a) From Hotels</h3>
                <p>
                  To access Thru’s services on Thru’s Platform, you shall be required to create an account with Thru. For
                  that, you must provide us with some personal data so that we can provide our services to you. This includes
                  the Hotel’s name, address, email address, authorized person’s name, username, password, phone number, etc.
                  We may require you to provide to us your contact information such as your email address or phone number, to
                  authenticate your account and keep it, and our services, secure, and to help prevent spam, fraud, and abuse.
                  We also use contact information like phone number and email address to enable certain account features (for
                  example, for login verification), and to send you information about our services. If you provide us with
                  your phone number, you agree to receive text messages from Thru to that number. We also require the Hotels
                  to provide us with the Data of Guests such as their name(s), mobile numbers, reservation details, check-in
                  and check-out dates and amount to be paid by the Guest(s) to the Hotel, which data is obtained by Hotels
                  from the Guests and shared with Thru for availing Thru’s services such as contactless check-in. Thru shall
                  not be held responsible for any claims brought against it on the basis that the Hotel(s) did not have the
                  authorization from the Guest(s) to share the Data of Guests with Thru.
          </p>
              </p>
              <p><h3>b) From Guests</h3>
                <p>
                  In order to provide the Guest(s) with contactless check-in service, Thru will require the Guest to provide
                  personal information on Thru’s Platform such as phone number, email address, name and age of all Guests,
                  scan of all adult Guests’ identification, signature of the Guest(s), scans of debit cards/credit cards,
                  details of bank account/credit card/debit card for processing current or prospective payments. The Guest(s)
                  shall be given an option to retain some of this information with Thru to avail a faster check-in / check-out
                  process the next time the Guest uses Thru. By providing us with phone number, the Guest(s) agrees to receive
                  text messages from Thru to that number as per applicable laws. We may send you email and mobile
                  notifications and you can control the same from the settings on the Platform.
          </p>
                <p>
                  The abovementioned information obtained from the Guest(s) shall be retained by Thru for up to seven (7) days
                  from the date of the Guests’ scheduled checkout. The Hotel shall be given the option to download the said
                  information from Thru’s Platform within that window and retain it thereafter if the Hotel so desires.
          </p>
              </p>

            </p>
            <p>
              <h3>c) Payment information</h3>
              <p>
                You may provide us with payment information, including your bank account details, credit or debit card number,
                card expiration date, CVV code, and billing address. This information shall be used by Thru to process the
                transactions as provided under the General Terms and Conditions or Thru Software as a Service (SaaS) Agreement
                for Hotels. Please note that all such payment transactions are carried out by Thru through third-party payment
                gateways.
      </p>
            </p>
            <p>
              <h2>3. Further information collected about you</h2>
              <p>We also collect the following information when you use and/or navigate Thru’s Platform:</p>
              <div>

                <p><h3>a) Cookies</h3>
                  <p>We use cookies that are stored on your computer or mobile device to collect additional website usage data
                  and to operate our services. Your web browser may notify you whenever Thru’s Platform is attempting the
                  use of your cookies and you may choose to allow or disallow such a use. However, please note that some of
                  our services may not function properly if you disable cookies. Those cookies are used by us to better
                  understand how you interact with our services, to monitor aggregate usage patterns, and to personalize and
                  otherwise operate our services such as by providing account security, personalizing the content we show
              you including advertisements, and remembering your language preferences. </p>
                </p>
                <p><h3>b) Links to external websites</h3>
                  <p>We are required to keep track of how you interact with the links to external websites available on Thru’s
                  Platform such as the third party payment gateway. If you click on the link to the external website, that
                  external website operator might find out that you accessed their website through Thru and they may also
                  collect other data from you, such as cookie identifiers or your IP address. Thru does not make any
                  representations about the privacy practices adopted by those external websites and you are recommended to
              go through their privacy practices.</p>
                </p>
                <p><h3>c) Location</h3>
                  <p>We collect information about your current location and the location from where you sign up on or use the
                  Platform by using signals such as your IP address or device settings. This information is required for us
                  to maintain the security and reliability of your account. You may set your location preferences through
                  the settings feature on the Platform.

            </p>
                </p>
                <p><h3>d) Other data</h3> <p>
                  We may collect other data from you (including metadata) which includes information such as your IP
                  address, browser type, operating system, the referring web page, pages visited, location, your mobile
                  carrier, device information (including device and application IDs), search terms (including those not
                  submitted as queries), and cookie information. This data is used to operate our services and ensure their
                  secure, reliable, and robust performance.
            </p>
                </p>

              </div>
            </p>
            <p>
              <h2>4. Use of the information collected</h2>
              <p>Thru uses the information collected from and about you for a variety of purposes, including to:</p>
              <div className="bullets">
                <ul>



                  <li>Communicate between the Hotels and Guests for providing services;</li>
                  <li> Reach out to the Guests to provide them with the link to Thru’s Platform from where they can perform
                  contactless check-in;
          </li>
                  <li> Provide the Hotel with the relevant information of the Guest(s) for the Guest to avail the services of
            the Hotel via Thru’s Platform;</li>
                  <li> Process and complete your transactions;</li>
                  <li>  Process and respond to your queries;</li>
                  <li>Administer our Services and diagnose technical problems;</li>
                  <li>Send you communications regarding the services as provided herein, by way of email or text or other
            notifications;</li>
                  <li>Generate and review reports and data about, and to conduct research on, our user base and service usage
            pattern;</li>
                  <li>Assist Thru or its affiliates to improve or further develop its services and products;</li>
                  <li> Provide you with customer support;</li>
                  <li> Provide you with policies about your account;</li>
                  <li> Notify you about changes in Thru’s Platform;</li>
                  <li> Allow you to participate in interactive features offered through the Platform;</li>
                  <li>In any other way Thru may describe when asking you to provide the information;</li>
                  <li> For any other purpose with your consent.</li>
                </ul>

              </div>
            </p>
            <p>
              <h2>5. How we share the information we collect</h2>
              <p>Thru may disclose information collected from or about you, in the following ways:</p>
              <div>

                <p><h3>a) Guests’ information with Hotels</h3>
                  <p>
                    The information obtained from the Guests such as phone number, email address, name and age of all Guests,
                    scan of all adult Guests’ identification, signature of the Guest(s), scans of debit cards/credit cards,
                    details of bank account/credit card/debit card for processing current or prospective payments, maybe
                    shared by Thru with the Hotel. This abovementioned information obtained from the Guest(s) shall be
                    retained by Thru for up to seven (7) days from the date of the Guests’ scheduled checkout. The Hotel shall
                    be given the option to download the said information from Thru’s Platform within that window and retain it
                    thereafter if the Hotel so desires. Thru does not make any representation as to how the Hotel deals with
                    that information after obtaining it from Thru’s Platform and the Guests are encouraged to check the
                    Hotel’s privacy policies separately.
            </p>
                </p>
                <p><h3>b) Legal authorities</h3>
                  <p>

                    We may share your information with law enforcement, government agencies, courts, and/or other
                    organizations, when we believe in good faith that such sharing is reasonably necessary in order to
                    investigate, prevent, or take action regarding possible illegal activities or to comply with legal
                    process. Thru may also share your information to investigate and address threats or potential threats to
                    the physical safety of any person, to investigate and address violations of this Privacy and Data Policy
                    or the Terms of Service, or to investigate and address violations of the rights of third parties and/or to
                    protect the rights, property and safety of Thru, its employees, users, or the public. This may involve the
                    sharing of your information with law enforcement, government agencies, courts, and/or other organizations
                    on account of legal request such as court orders or government demand to comply with the law.
            </p>
                </p>
                <p>
                  <h3>c) External agencies</h3>

                  <p>
                    The payment information provided by you to us is passed on to third-party payment processors. By
                    submitting your credit or debit card account information on Thru’s Platform, you expressly consent to the
                    sharing of this information with third-party payment processors or payment gateways (including but not
                    limited to vendors who provide fraud detection services to us and other third parties). Thru may retain
                    any payment information with your consent for providing faster services in the future.
            </p>
                  <p>
                    To help us better understand your use of Thru’s Platform, we may use third-party web analytics, such as
                    Google Analytics for our services. These service providers use technology to automatically collect certain
                    usage-related information from Thru’s Platform. The information collected by this technology will be
                    disclosed to or collected directly by these service providers, who may use the information to evaluate
                    your use of Thru’s Platform. To prevent Google Analytics from collecting or using your information, you
                    may install the Google Analytics Opt-Out Browser add-on.
            </p>
                </p>
                <p>
                  <h3> d) General Information Disclosures</h3>

                  <p>
                    Thru may share the information collected from and about you to the following:
            </p>

                  <div className="bullets">
                    <ul>

                      <li> To our subsidiaries and affiliates, which are entities under common ownership or control of Paragon
                      Digital Lifestyle Inc. and our ultimate parent company Paragon Business Solutions Ltd.
                </li>
                      <li> To a successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or
                      other sale or transfer of some or all of Thru’s assets, whether as a going concern or as part of
                      bankruptcy, liquidation or similar proceeding, in which personal information held by Thru about the
                  users of our services are among the assets transferred.</li>
                      <li> To fulfill the purpose for which you provided the information to Thru.</li>
                      <li> For any other purpose disclosed by Thru when you provided the information.</li>
                      <li> Thru may share your information in any other circumstances where you have given consent.</li>
                    </ul>
                  </div>
                </p>
                <p>

                  <h3>e) Non-personal information</h3>

                  <p>
                    We may share or disclose non-personal data to third parties, such as aggregated information like the total
                    number of times Thru’s services were availed in a given period, the pattern of usage, other reports on
                    usage,
                    etc. Such information will be encrypted and not lead to the personal identification of any user.
            </p>
                </p>
                <p>

                  <h2> 6. Communication choices</h2>

                  <p>
                    When the Hotel signs up for an account on Thru’s Platform, the Hotel is deemed to have opted to receive
                    notifications from Thru’s Platform. When the Guest(s) uses Thru’s Platform to avail services such as
                    contactless check-in, the Guest(s) is deemed to have opted to receive notifications from Thru’s Platform.
                    The
                    Hotel can manage notification preferences in the settings on Thru’s Platform, but you cannot opt out of
                    receiving certain administrative policy, service policy, or legal policy notifications from Thru.
            </p>
                </p>

              </div>
            </p>
            <p>
              <h2>7. Reviewing, changing or deleting information by the Hotel</h2>
              <p>If the Hotel would like to review, change or delete personal information provided by it on Thru’s Platform, or permanently delete the Hotel’s account, the Hotel can use the “Contact Us” option on Thru’s Platform.</p>
              <div>

                <p><h2>8. Accessing & correcting your personal information</h2>

                  <p>1. Thru will take reasonable steps to accurately record the personal information that you provide to Thru and any subsequent updates that you inform Thru of.</p>
                  <p>2. You can access the information provided by you on Thru’s Platform. You are encouraged to review, update, and correct the personal information that you provide on the Platform and you may request that Thru delete personal information about you that is inaccurate, incomplete, or irrelevant for legitimate purposes, or are being processed in a way which infringes any applicable legal requirement. For any issues relating to access to your personal information, you may contact Thru through the “Contact Us” option on Thru’s Platform or on the contact details given in this Privacy and Data Policy below.</p>
                </p>
                <p><h2>9. Security measures taken to protect User’s information</h2>
                  <p>1. Thru has implemented appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access to your information and to maintain data security. These safeguards take into account the sensitivity of the information that Thru collects, processes and stores and the current state of technology. Thru follows generally accepted industry standards to protect the personal information submitted to it, both during transmission and upon receipt. The third-party service providers with respect to payment gateway and payment processing are all validated as compliant with the payment card industry standard.</p>
                  <p>2. Thru assumes no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond Thru’s control. You play an important role in keeping your personal information secure. You should not share your username, password, or other security information about your account with anyone. If Thru receives instructions using your username and password, Thru will consider that you have authorized the instructions.</p>
                </p>
                <p><h2>10. Data retention and account termination</h2>
                  <p>1. The Hotel can close its account on Thu’s Platform by visiting the settings option on Thru’s Platform or using “Contact Us” option on Thru’s Platform. Upon receiving such a request from the Hotel, Thru will remove its account details from Thru’s Platform. But Thru may retain information about the Hotel for the purposes authorized under this Privacy and Data Policy unless prohibited by law. Thru will comply with such a request by either deleting the personal information or de-identifying it so that it is anonymous and not attributed to the Hotel’s identity. For example, Thru may retain information to prevent, investigate, or identify possible wrongdoing in connection with its services or to comply with legal obligations.</p>
                  <p>2. The Guest(s) may request Thru to delete their information from Thru’s Platform after their check-out by using “Contact Us” option on Thru’s Platform. Thru will comply with such a request by either deleting the Guests’ personal information or de-identifying it so that it is anonymous and not attributed to the Guests’ identity. For example, Thru may retain information to prevent, investigate, or identify possible wrongdoing in connection with its services or to comply with legal obligations. However, the Hotel may retain the said information of the Guests after downloading it from Thru’s Platform as permitted under the agreement, as mandated by law or otherwise and the Guest is encouraged to check with the Hotel directly regarding such retention.</p>
                </p>

              </div>
            </p>
            <p>
              <h2>11. Changes to this Privacy and Data Policy</h2>
              <p>
                Thru reserves the right to amend this Privacy and Data Policy from time to time to reflect changes in the law, Thru’s data collection and use practices, the features of services, or advances in technology. Please check this page periodically for changes. Use of information that is collected by Thru is subject to the Privacy and Data Policy in effect at the time such information is used. If Thru makes any material changes to this Privacy and Data Policy, Thru will post the changes here. Please review the changes carefully. The continued use of Thru’s services by you following the posting of changes to this Privacy and Data Policy will constitute your consent to and acceptance of those changes.
      </p>
            </p>
            <p>
              <h2>12. Contact us</h2>
              <div>

                <p> If you have any queries relating to the processing / usage of information provided by you or this Privacy and Data Policy, you may send an email to the Data Protection Officer at <a href="mailto: dpo@thru.app">dpo@thru.app</a> .</p>
                <p> The residents of European Union or United Kingdom may exercise their rights under the General Data Protection Regulation by raising a request directly at the abovementioned email address.</p>
                <p> California law permits users who are California residents to request and obtain from us once a year, free of charge, a list of the third parties to whom we have disclosed their personal information (if any) for their direct marketing purposes in the prior calendar year, as well as the type of personal information disclosed to those parties. To make such a request, please contact us at the abovementioned email address.</p>

              </div>
            </p>
          </div>
        </GeneralModal>
        <GeneralModal
          title={"Terms of Service of Thru"}
          showModal={this.state.serviceModal}
          onHide={this.toggleServiceModal}
        >
          <div className="privacy_policy_container">
            <div>

              <p>
                <h2>1. Acceptance of the terms</h2>
                <div>

                  <p>a)
                  These Terms of Service of Thru (together with the Privacy Policy referred to as the “Terms”) are intended to
                  make you aware of your legal rights and responsibilities with respect to your access to and use of the
                  website of www.thru.app and any related mobile or software applications (“Thru’s Platform”). Please read
                  these Terms carefully. By accessing or using Thru’s Platform, you represent to us that you are of minimum
                  legal age to use our services (as per the laws of your country) and you agree to these Terms and enter into
                  a legally binding contract with Paragon Business Solutions Ltd. (if you are based out of India) or Paragon
                  Digital Lifestyle Inc. (if you are based out of a country other than India), (hereinafter referred to by the
                  brand name “Thru”)." You may choose not to use Thru’s Platform if you do not accept the Terms or are unable
                  to be bound by the Terms.
          </p>
                  <p>b)
                  In order to use Thru’s Platform, you must first agree to the terms of this Agreement. You can convey your
                  acceptance to this Agreement by clicking the “I Agree” button where it is made available to you by Thru on
                  its website.
          </p>

                </div>
              </p>
              <p>
                <h2>2. Thru’s Privacy and Data Policy</h2>
                <div>Thru’s Privacy and Data Policy describes how Thru’s Platform collects, uses and shares information about you.
                You can access Thru’s Privacy Policy through the link <a href="https://thru.app/privacypolicy" target="_blank" rel="noopener noreferrer">here</a>. Please read the Privacy
                and Data Policy carefully. By proceeding to use the platform services, you agree to be bound by the Privacy and
                Data Policy and you consent to the collection, use and sharing of your information (as described in the Privacy
                and Data Policy), including the transfer of this information outside your country to other parts of the world
        for storage, processing and use by Thru and its affiliates.</div>
              </p>
              <p>
                <h2>3. Checking-in via Thru’s Platform</h2>
                <div>

                  <p>a)
                  In order to use Thru’s Platform for availing contactless check-in and other services, Thru will send you,
                  via sms / email, a link to Thru’s Platform. You must enter you details as requested on that link, such as
                  phone number, email address, name and age of all guests, scan of all adult guests’ identification,
                  signature, scans of debit cards/credit cards, details of bank account/credit card/debit card for processing
                  current or prospective payments. You shall be given an option to retain some of this information with Thru
                  to avail a faster check-in / check-out process the next time the you use Thru. Use of any personal
                  information you provide to us during this process is governed by our Privacy and Data Policy.
          </p>
                  <p>b)
                  Thru will engage the services of a payment gateway and the payment details provided by you will be shared
                  with that third-party payment gateway. By consenting to this Agreement, you give us you express consent to
                  share your payment details such as details of credit / debit card / bank accounts with the payment gateway.
                  Thru does not make any representations with respect to the third-party payment gateway and you are
                  encouraged to read terms and the privacy policies of the same separately.

          </p>
                  <p>c)
                  By providing the abovementioned information to us, you represent to us that the said information is true,
                  accurate and correct. You may not impersonate someone else or provide details of someone else who is not a
                  guest and does not have a confirmed booking with the hotel. You acknowledge that any such false information
                  may cause Thru or third parties to incur substantial economic damages and losses for which you may be held
                  liable and accountable. Thru will not be liable for any losses that may be incurred by you due to the
                  incorrect data provided by you.

          </p>

                </div>
              </p>
              <p>
                <h2>4. Things you are not allowed to do
      </h2>
                <p>We also collect the following information when you use and/or navigate Thru’s Platform:</p>
                <div>

                  <p>
                    <p>a) You are not allowed to misuse Thru’s services, for example, by interfering with them or accessing them
                    using a method other than the interface and the instructions that we provide. You may not access or use
                    the interface, systems or network of Thru’s Platform or Thru’s services which are not meant for access by
                    you. Doing so will also entail legal consequences including damages. Moreover, Thru shall not be
              responsible for any loss suffered by you by way of such unauthorized, wrongful or illegal access. </p>
                  </p>
                  <p>
                    <p>b) You agree to use Thru’s Platform only for purposes that are permitted by the Terms and any applicable
                    law, regulation or generally accepted practices or guidelines in the relevant jurisdictions. You agree
                    that you will not engage in any activity that interferes with or disrupts Thru’s Platform (or the servers
              and networks which are connected to Thru’s Platform). </p>
                  </p>

                </div>
              </p>


              <h2>5. The permissions you grant to us</h2>
              <p>
                By agreeing to these Terms, you allow for us to share any and all data collected from you, with the hotel in which
                you have checked in. You further allow us to retain your data with us for up to 7 days after your scheduled
                check-out date. You acknowledge that the hotel you have checked in to may retain your data which Thru has shared
                with it, for a longer duration and Thru does not make any representation as to the hotel’s privacy and data
                policies and you are encouraged to check with the hotel regarding the same. You also agree to grant us other
                permissions mentioned in Thru’s Privacy and Data Policy.
    </p>
              <h2>6. Changes to these Terms
    </h2>
              <p>
                Thru may vary or amend or change or update these Terms, from time to time entirely at its own discretion. You
                shall be responsible for checking these Terms from time to time and ensuring continued compliance with these
                Terms. Your use of Thru’s Platform after any such amendment or change in the Terms shall be deemed to be your
                express acceptance to such amended/changed terms and you also agree to be bound by such changed/amended Terms.

    </p>
              <h2>7. Translation of the terms
    </h2>
              <p>
                Thru may provide a translation of the English version of the Terms into other languages. You understand and agree
                that any translation of the Terms into other languages is only for your convenience and that the English version
                shall govern the terms of your relationship with Thru. Furthermore, if there are any inconsistencies between the
                English version of the Terms and its translated version, the English version of the Terms shall prevail over
                others.
    </p>
              <h2>8. Ownership of Thru’s Platform and Proprietary Rights
    </h2>
              <p>
                Thru is the sole and exclusive owner of Thru’s Platform. Thru also exclusively owns the copyrights, trademarks,
                service marks, logos, trade names, trade dress and other intellectual and proprietary rights throughout the world
                (“IP Rights”) associated with its services Thru’s Platform, which may be protected by copyright, patent,
                trademark, trade secrets, and other applicable intellectual property and proprietary rights and laws. Thru gives
                you a limited, non-assignable and non-exclusive right to use Thru’s Platform to avail contactless check-in and
                other services, in the manner permitted by these Terms.

    </p>
              <h2>9. User feedback
    </h2>
              <p>a)
              If you share or send any ideas, suggestions, changes or documents regarding Thru’s existing business (“Feedback”),
              you agree that (i) your Feedback does not contain the confidential, secretive or proprietary information of third
              parties, (ii) Thru is under no obligation of confidentiality with respect to such Feedback, and shall be free to
              use the Feedback on an unrestricted basis, and (iv) By providing the Feedback, you grant to us a binding,
              non-exclusive, royalty-free, perpetual, global license to use, modify, develop, publish, distribute and sublicense
              the Feedback, and you irrevocably waive, against Thru and its users any claims/assertions, whatsoever, of any
              nature, with regard to such Feedback.
    </p>
              <p>b)
              You agree that (1) your Feedback and its contents will automatically become the property of Thru, without any
              compensation to you; (2) Thru may use or redistribute the Feedback and their contents for any purpose and in any
              way; (3) there is no obligation for Thru to review the Feedback; and (4) there is no obligation to keep any
              Feedback confidential.
    </p>
              <h2>10.
              Disclaimer of Warranties
    </h2>
              <p>
                You acknowledge and agree that Thru is merely providing Thru’s Platform for offering services such as contactless
                check-in. Thru, its affiliates and their respective officers, directors, employees, agents, affiliates, branches,
                subsidiaries, and licensors (“Thru Parties”) disclaim all warranties, express or implied, in connection with its
                services and Thru’s Platform and your use thereof to the fullest extent permissible by law. Thru takes no
                responsibility for (i) any bugs, viruses, trojan horses, or the like which may be transmitted to or through Thru’s
                Platform through the actions of any third parties, (ii) any loss of your data from Thru’s Platform through the
                actions of any third party, and (iii) any loss or misuse of your bank account/credit card/debit card/ etc.
                information submitted by you to the third party payment gateway.

    </p>
              <h2>11.
              Limitation of Liability

    </h2>
              <p>
                The liability of Thru shall be limited to the fullest extent permitted by applicable law. In no event shall Thru
                or Thru Parties be liable for any damages resulting from (i) any bugs, viruses, trojan horses, or the like which
                may be transmitted to or through Thru’s Platform or services through the actions of any third parties, (ii) any
                loss of your data from Thru’s Platform through the actions of any third party, and (iii) any loss or misuse of
                your payment information by the third party payment gateway, (iv) any interruption or cessation of transmission to
                or from Thru’s servers, (v) the disclosure of information pursuant to these Terms or Thru’s Privacy and Data
                Policy, and/or (vi) delay or failure in performance resulting from causes beyond Thru’s reasonable control. In no
                event shall Thru or Thru Parties be liable to you for any indirect, incidental, special, punitive, exemplary or
                consequential damages, whatsoever, however caused and under any theory of liability, including but not limited to,
                any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation, any
                loss of data suffered, cost of procurement of substitute goods or services, or other intangible loss.

    </p>

              <p>
                <h2>12. General terms
      </h2>
                <div>

                  <p>
                    <h3>a) Interpretation</h3>
            The section and subject headings in these Terms are included for reference only and shall not be used to
            interpret any provisions of these Terms. Any words or phrases used in these Terms must be interpreted in the
            context in which they have been used herein.
          </p>
                  <p>
                    <h3>b) Entire Agreement and Waiver
            </h3>The Terms, together with the ‘Privacy and Data Policy’, shall constitute the entire agreement between
            you and Thru concerning Thru’s Platform and services. No failure or delay by us in exercising any right,
            power or privilege under the Terms shall operate as a waiver of such right or acceptance of any variation of
            the Terms and nor shall any single or partial exercise by either party of any right, power or privilege
            preclude any further exercise of that right or the exercise of any other right, power or privilege.
          </p>
                  <p>
                    <h3>c) Severability</h3>If any provision of these Terms is deemed unlawful, invalid, inoperable or
            unenforceable by a judicial court for any reason, then that provision shall be deemed severed from these
            Terms, and the remainder of the Terms shall continue in full force and effect.

          </p>
                  <p>
                    <h3>d) Partnership or Agency</h3>
                    <p>None of the provisions of these Terms shall be deemed to constitute a partnership or agency between you
                    and Thru and you shall have no authority to bind Thru in any form or manner, whatsoever.
            </p>
                  </p>
                  <p>
                    <h3>e) Force Majeure </h3>
                    <p>Thru shall not be liable for any default or breach of these Terms to the extent performance of Thru’s
                    obligations or attempts to cure any breach were delayed or prevented by reason of any act of god (such as
                    fire, natural disaster, epidemics, etc.), accidents, acts of governments, war, riots or any other cause
              (whether natural or manmade) beyond the control of Thru.</p>
                  </p>

                </div>
              </p>
              <p>
                <h2>13. Grievance redressal mechanism</h2>
                <p>In case of any grievances, you may write to the Grievances Redressal Officer at <a href="mailto: gro@thru.app">gro@thru.app</a>.</p>

              </p>


            </div>
          </div>
        </GeneralModal>
        <SignupModal
          showModal={this.state.signupModal}
          onHide={this.toggleSignupModal}
        />
      </Layout>
    );
  }
}

function mapState(state) {
  return {
    loading: state.loading,
    hotel: state.hotel
  }
}

export default connect(mapState)(withRouter(Signup));
