import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import checkboxCheck from '../../assets/images/checkboxcheck.png';

class StepsBar extends PureComponent {
	render() {
		let { steps, currentStep } = this.props;
		return (
			<div className="stepBarCsv clearfix">
				<div className="row stepBarCsvRow align-items-center justify-content-center">
					{Array.isArray(steps) && steps.length
						? steps.map(step => (
							<div key={step.count} className="col">
								<div className={'stepsHeading'
									+ (step.count < currentStep.count ? ' stepsCompleted' : '')
									+ (step.count === currentStep.count ? ' activeSteps' : '')
								}>
									<div className="stepsCircle clearfix">
										{currentStep.count <= step.count
											? <span>{step.count}</span>
											: <span><img src={checkboxCheck} alt="Checkbox" /></span>
										}
									</div>
									<div className="stepsText clearfix">
										<p>{step.name}</p>
									</div>
								</div>
							</div>
						))
						: null
					}
					{/* <div className="col">
						<div className="stepsHeading stepsCompleted">
							<div className="stepsCircle clearfix">
								<span className="hide">1</span>
								<span><img src={checkboxCheck} alt="Checkbox" /></span>
							</div>
							<div className="stepsText clearfix">
								<p>Upload</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="stepsHeading activeSteps">
							<div className="stepsCircle clearfix">
								<span>2</span>
								<span className="hide"><img src={checkboxCheck} alt="Checkbox" /></span>
							</div>
							<div className="stepsText clearfix">
								<p>Match</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="stepsHeading">
							<div className="stepsCircle clearfix">
								<span>3</span>
								<span className="hide"><img src={checkboxCheck} alt="Checkbox" /></span>
							</div>
							<div className="stepsText clearfix">
								<p>Review</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="stepsHeading">
							<div className="stepsCircle clearfix">
								<span>4</span>
								<span className="hide"><img src={checkboxCheck} alt="Checkbox" /></span>
							</div>
							<div className="stepsText clearfix">
								<p>Complete</p>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		)
	}
}

StepsBar.propTypes = {
	steps: PropTypes.array.isRequired,
	currentStep: PropTypes.object.isRequired,
}

export default StepsBar
