import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
//import FileViewer from 'react-file-viewer';
import renderHTML from 'react-render-html';

class PolicyAgreement extends Component {

    render() {
        let { data, showAgreementModal, onHide, onSubmit } = this.props;
        return (
            <Modal
                show={showAgreementModal}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>{data ? data.display_name : ""}</Modal.Title>
                    <div className="modalPopupClose clearfix" onClick={onHide}>
                        <img src='/images/popup_close.png' alt="close" />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalForm clearfix">
                        {data !== false
                            ? (<div className="privacy_policy_container saas_agreement">
                                {renderHTML(data.content)}
                                {/* <div className="header_detail_wrapper accepted_content">
                                    <div className="acceptedIDwrapper">
                                        <div className="form_element">
                                            <label className="container_check">
                                                {"I accept the Terms and conditions."}
                                                <input type="checkbox" onChange={this.handleCheckbox} />
                                                <span className="checkmark_check" />
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <FileViewer
                                    fileType='docx'
                                    filePath='/files/privacy.docx'
                                /> */}
                            </div>)
                            : null
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="themeButton" onClick={onSubmit}>Agree</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

PolicyAgreement.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onSubmit: PropTypes.func.isRequired
};

export default PolicyAgreement;