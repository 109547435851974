import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

class GeneralModal extends Component {
    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                className="gerneral_modal"
                centered
            >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">{this.props.title}</Modal.Title>
              <div className="modalPopupClose clearfix" onClick={this.props.onHide}>
                <img src='/images/popup_close.png' alt="close" />
              </div>
            </Modal.Header>
            <Modal.Body>
              {this.props.children}
            </Modal.Body>
          </Modal>
        )
    }
}

GeneralModal.propTypes = {
  title: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default GeneralModal
