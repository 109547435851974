import { Auth } from "aws-amplify";
import { constants } from "../constants/index";
import { getHotelDetails,getGuestDetails,hotelIntegrations} from "./hotelAction";
import { setCookie, removeCookie } from "../lib/session";
import { clearState } from "./commonActions";
import { setKey } from "./commonActions";

export const loginUser = (username, password, history) => {
  return (dispatch) => {
    dispatch({ type: constants.LOGIN_REQUEST, data: {} });
    Auth.signIn(username, password)
      .then((user) => {
        dispatch({ type: constants.LOGIN_SUCCESS, data: user });
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          localStorage.setItem("signUpUser", JSON.stringify({ username: username, password: password }));
          history.replace("/complete-new-password");
        } else {
          let { signInUserSession, attributes } = user;
          let { idToken } = signInUserSession;
          let { jwtToken } = idToken;
          let { email } = attributes;
          setCookie("jwtToken", jwtToken);
          setCookie("userEmail", email);
          dispatch(currentAuthenticatedUser(history));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code == "UserNotConfirmedException") {
          localStorage.setItem("signUpUser", JSON.stringify({ username: username, password: password }));
          localStorage.setItem("userType", "unconfirmedUser");
          history.replace('/verifyConfirmUser');
        } else {
          dispatch({ type: constants.FAILED_RESPONSE, data: err });
        }
      });
  };
};

export const logoutUser = (history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: constants.LOGOUT_REQUEST, data: {} });
      await Auth.signOut({ global: true });
      dispatch({ type: constants.LOGOUT_SUCCESS, data: {} });
      setTimeout(() => {
        removeCookie("jwtToken");
        localStorage.clear();
        dispatch(clearState());
        history.replace("/login");
      }, 500);
    } catch (error) {
      console.log("error signing out: ", error);
      dispatch({ type: constants.LOGOUT_FAILED, data: {} });
      dispatch({ type: constants.FAILED_RESPONSE, data: error });
      setTimeout(() => {
        removeCookie("jwtToken");
        localStorage.clear();
        dispatch(clearState());
        history.replace("/login");
      }, 500);
    }
  };
};

export const currentAuthenticatedUser = (history) => {
  return (dispatch) => {
    Auth.currentAuthenticatedUser().then(user => {
      let { attributes } = user;
      attributes.username = user.username;
      localStorage.setItem('userAttributes', JSON.stringify(attributes))
      Auth.userAttributes(user).then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          dispatch(setKey('currentUserAttributes', data));
          let is_admin = data.find(({ Name }) => Name === "custom:is_admin");
          if (is_admin) {
            let { Value } = is_admin;
            is_admin = Value === "1" ? true : false;
            dispatch(setKey('is_admin', is_admin));
          }
          let email_verified = data.find(({ Name }) => Name === "email_verified");
          let phone_number_varified = data.find(({ Name }) => Name === "phone_number_verified");
          if (phone_number_varified == undefined ||
            phone_number_varified == "undefined" ||
            phone_number_varified.Value === "false") {
            localStorage.setItem('authType', 'phone_number')
            history.replace("/mobileverify");
          } else
            if (email_verified == undefined ||
              email_verified == "undefined" ||
              email_verified.Value === "false") {
              localStorage.setItem('authType', 'email')
              history.replace("/emailverify");
            }else{
              dispatch(getHotelDetails(history));
              dispatch(getGuestDetails(history));
              dispatch(hotelIntegrations(history));
         }
        }
      }).catch((e) => {
       if (e.code == 'UserNotFoundException' || e.code == "NotAuthorizedException") {
          removeCookie("jwtToken");
          localStorage.clear()
          history.replace('/login');
        }
      })
    }).catch(err => {
      console.log("currentAuthenticatedUser=====err===", err)
      dispatch({ type: constants.FAILED_RESPONSE, data: err });
    });
  };
};


export const currentLoggedInUser = (data, type) => {
  return async (dispatch) => {
    dispatch({ type: type, data });
  };
};

export const updateReduxState = (type) => {
  return async (dispatch) => {
    dispatch({ type });
  };
};
