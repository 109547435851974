import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom';
import { getBalance } from '../../actions/notificationAction';
import { connect } from 'react-redux';
import moment from 'moment';
import appConfig from '../../app-config';

class NotificationToast extends PureComponent {

    componentDidMount() {
        this.props.dispatch(getBalance());
    }

    getBalanceData = () => {
        let { balance, type } = this.props;
        let validity_alert = false;
        let message = "";
        if (balance) {
            let { is_unlimited, balance_reservation, plan_expiry } = balance;
            if (is_unlimited) {
                if (plan_expiry) {
                    let diff = moment(plan_expiry).diff(moment(), 'days') + 1;
                    console.log('Difference', diff);
                    if (diff < 0) {
                        validity_alert = true
                        type = "alert";
                        message = `Your plan has expired. Please contact Thru support to enjoy uninterrupted service.`;
                    } else if (diff < 7) {
                        validity_alert = true
                        type = "warning";
                        if (diff == 0) {
                            message = `Your plan is expiring today. Please contact Thru support to enjoy uninterrupted service.`;
                        } else {
                            message = `Your plan will expire in ${diff} days. Please contact Thru support to enjoy uninterrupted service.`;
                        }
                    }
                } else {
                    console.log("No plan expiry defined for unlimited plan");
                }
            } else {
                if (balance_reservation === 0) {
                    validity_alert = true;
                    type = "alert";
                    message = "Your Thru credits are over. All the services are currently stopped. Please Top-up immediately to resume your services.";
                } else if (balance_reservation < appConfig.auto_recharge.threshold_limit) {
                    validity_alert = true;
                    type = "warning";
                    message = "You are running low on Thru Credits. Please Top-up to enjoy uninterrupted service."
                }
            }

            return { validity_alert, type, message };
        } else {
            return { validity_alert, type, message };
        }
    }

    render() {
        let { type, validity_alert, message } = this.getBalanceData();
        if (validity_alert) {
            return (
                <div className={`notification_toast_container ${type ? type : ''}`}>
                    <span>{message}</span>
                    <Link to="/billing/edit">Top-up Now</Link>
                </div>
            )
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    balance: state.balance
})

export default connect(mapStateToProps)(NotificationToast);
