import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Loader from '../common/Loader';
import moment from 'moment';

class TopUpRecharge extends Component {
  render() {
    let { payment_methods, balance, autoRechargeDetails, is_admin } = this.props;
    let details_exist = (payment_methods
      && Array.isArray(payment_methods.cardDetails)
      && payment_methods.cardDetails.length);
    let auto_recharge = autoRechargeDetails && autoRechargeDetails.auto_recharge;
    auto_recharge = auto_recharge ? "On" : "Off";
    return (
      <div className="topup_recharge_wrapper">
        {balance
          ? <Fragment>
            <div className="reservation_account">
              {balance.is_unlimited ? '\u221E' : balance.balance_reservation}
            </div>
            {balance.is_unlimited
              ? <Fragment>
                <div className="reservation_remaining">Unlimited Reservations</div>
                <div className="auto_recharge">Plan Expiry: <span className="themeColor">{moment(balance.plan_expiry).format('MMM D, YYYY')}</span></div>
              </Fragment>
              : <Fragment>
                <div className="reservation_remaining">Reservations Remaining</div>
                <div className="auto_recharge">Auto Recharge: <span className="themeColor">{auto_recharge}</span></div>
                {is_admin
                  ? (<div className="topup_btns">
                    <button className="themeButton" disabled={!details_exist} onClick={this.props.toggleTopupModal}>Top-Up</button>
                    {/* <button className="secondaryBtn">Setup Recharge</button> */}
                  </div>)
                  : null
                }
              </Fragment>
            }
          </Fragment>
          : <Loader />
        }
      </div>
    );
  }
}

TopUpRecharge.propTypes = {
  toggleTopupModal: PropTypes.func.isRequired
};

export default TopUpRecharge;