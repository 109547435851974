import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getSelectTheme } from '../../utils/ui';
import Collapsible from 'react-collapsible';
import AcceptedIDWrapper from './AcceptedIDWrapper';

class ExceptionContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: this.getSelectedOption()
    }
  }

  onTrigger = () => (<div className="dropdown_trigger"></div>)

  getSelectedOption = () => {
    let { country_options, exceptions, index } = this.props;
    let exception = exceptions[index];
    let { country_id } = exception;
    let selectedCountry = country_options.find(o => (o.value === country_id));
    return selectedCountry;
  }

  handleSelectChange = (e, target) => {
    let { value } = e;
    this.setState({ ...this.state, selectedCountry: e }, () => {
      let { exceptions, index } = this.props;
      let exception = exceptions[index];
      exception.country_id = value;
      this.props.setException(exception, index);
    });
  }

  getFilteredCountryOptions = () => {
    let { country_options, exceptions, hotel } = this.props;
    let countries = exceptions.map(e => e.country_id);
    countries.push(hotel.country_id);
    country_options = country_options.filter(co => (!countries.includes(co.value)));
    return country_options;
  }

  getSelectedDocCount = () => {
    let { identity_docs } = this.props;
    let { selectedCountry } = this.state;
    if (selectedCountry) {
      return identity_docs.filter(d => d.country_id === selectedCountry.value && d.checked).length;
    } else {
      return 0;
    }
  }

  render() {
    let count = this.getSelectedDocCount();
    let { index } = this.props;
    return (
      <Fragment>

        <div className="exception_content">
          <div className="trigger_wrapper_exception">
            <Select
              className="country_team"
              name={`ids[${index}][country_id]`}
              classNamePrefix="react_select"
              value={this.state.selectedCountry}
              options={this.getFilteredCountryOptions()}
              onChange={this.handleSelectChange}
              theme={getSelectTheme}
            />
            <div className="selected_id">
              <span>{count > 0 ? `+${count}` : count}</span>
            </div>
          </div>
          <Collapsible transitionTime={100} trigger={this.onTrigger()}>
            <div className="id_exception_wrapper">
              {Array.isArray(this.props.identity_docs)
                ? this.props.identity_docs.map((identity, index) => {
                  if (this.state.selectedCountry && identity.country_id === this.state.selectedCountry.value) {
                    return (<AcceptedIDWrapper
                      key={identity.document_id}
                      readOnly={identity.document_type.toLowerCase() === "passport"}
                      checked={identity.checked}
                      onChange={(e) => this.props.handleIDCheckbox(e, index)}
                      identity={identity}
                    />)
                  } else {
                    return null;
                  }
                })
                : null
              }
            </div>
          </Collapsible>
          <span className="close_btn" onClick={() => this.props.onRemove(this.props.index)}>
            <img src="/images/close.svg" alt="close" />
          </span>
        </div>
        <div className="error-text">
          {this.props.validator.message(`ids[${index}][country_id]`, (this.state.selectedCountry ? this.state.selectedCountry.value : ''), 'required', { messages: { required: "Please select country" } })}
        </div>
      </Fragment>
    );
  }
}

ExceptionContent.defaultProps = {
  country_options: []
}

ExceptionContent.propTypes = {
  index: PropTypes.number.isRequired,
  setException: PropTypes.func.isRequired,
  exceptions: PropTypes.array.isRequired,
  hotel: PropTypes.object.isRequired,
  handleIDCheckbox: PropTypes.func.isRequired,
  identity_docs: PropTypes.array.isRequired,
  country_options: PropTypes.array.isRequired
}

export default ExceptionContent;