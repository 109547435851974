import React from "react";
import { withRouter } from "react-router-dom";

class StepsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [
                { count: 1, name: "Hotel Information", path: "/hotelinfo" },
                { count: 2, name: "Billing Details", path: "/billing" },
                { count: 3, name: "Guest Details", path: "/guestdetail" },
                { count: 4, name: "Terms & Conditions", path: "/terms" },
                { count: 5, name: "Payment Gateway", path: "/payment" },
                { count: 6, name: "Team Members", path: "/team" }
            ],
            currentPath: "",
            currentCount: 0
        }
    }

    componentDidMount() {
        let path = this.props.location.pathname;
        let step = this.state.steps.find(s => s.path === path);
        if (step) this.setState({ currentCount: step.count, currentPath: step.path });
    }

    render() {
        let { steps, currentPath, currentCount } = this.state;
        return (
            //<div style={this.props.style} className={`steps_header ${this.props.sticky ? 'sticky' : ''}`}>
            <div className="steps_header">
            <div className="steps_header_wrapper">
                {steps.map((step) => {
                    return (<a key={step.count} className={"steps" + (currentPath == step.path ? " active" : "") + (currentCount > step.count ? " complete" : "")}>
                        <span className="steps_num">
                            <span className="steps_number">{step.count}</span>
                            {currentCount > step.count
                                ? <span className="complete_image"><img width="28" src='/images/Checkbox-check.svg' alt="check"/></span>
                                : null
                            }
                        </span>
                        <span className="steps_info">{step.name}</span>
                    </a>)
                })}
            </div>
        </div>);
    }
}
export default withRouter(StepsHeader);