import React, { Component } from "react";
import Layout from "../components/Layout";
import { withRouter, Link } from "react-router-dom";
import Credits from "../components/credits";
import withAuth from "../lib/auth";
import { connect } from "react-redux";

class TopUp extends Component {
  state = {
    showView: "topup", // topup, credits
  }

  handleView = (showView) => this.setState({ showView });

  render() {
    let { showView } = this.state;
    switch (showView) {
      case "topup":
        return (
          <Layout isSignUP steps>
            <div className="signup_container">
              <div className="mobile_validate_form">
                <div className="signup_form_content">
                  <div className="topup_content">
                    <h1>Registration Complete</h1>
                    <h3>Glad to have you onboard.</h3>
                    <div className="credit_text">
                      <div className="credit_image">
                        <img src="/images/coins.svg" alt="icon" />
                      </div>
                      <div className="credit_content">
                        {"Your account now has 25 free Thru credits to get you started. You can top-up any time or link your billing account for automatic top-ups."}
                      </div>
                    </div>
                    <div>
                      <button type="button" className="secondaryBtn" onClick={() => this.handleView("credits")}>Top-Up Credits</button>
                      <Link to="/dashboard">
                        <button type="submit" className="themeButton">Go to Dashboard</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        );
      case "credits":
        return (
          <Layout isSignUP dashboardBtn>
            <div className="signup_main_container">
              <div className="signup_steps_container_wrapper">
                <div className="signup_steps_container edit">
                  <Credits />
                </div>
              </div>
            </div>
          </Layout>
        );
      default:
        return null
    }
  }
}
const mapStateToProps = (state) => {
  return {
    hotel: state.hotel,
  };
};

export default connect(mapStateToProps)(withAuth(withRouter(TopUp)));
